import React from 'react';
// import UnderConstruction from 'components/common/UnderConstruction';
import styles from './styles.module.css';
import classnames from 'classnames';
import { Link, Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import { routes } from 'constants/routes.js';
import { links } from 'constants/productionManagement.js';
import ProductionSummary from './ProductionSummary';
import ProductionLpoEvents from './ProductionLpoEvents';

export const ProductionManagementPage = ({ location }) => {
  return (
    <div className={styles.scrollableContainer}>
      <div className={styles.navBar}>
        {links.map(link => {
          // Determine if the home link (production management parent) is the current location
          const isHomeLink =
            location.pathname === `${routes.productionManagementParent}/` ||
            location.pathname === routes.productionManagementParent ||
            location.pathname === routes.home;

          // Set home link active if the location is the home link and the link we're mapping over is the home link.
          const isHomeLinkActive =
            isHomeLink &&
            (link.url === routes.productionManagement.summary ||
              link.url === routes.home);

          return (
            <Link
              key={link.url}
              className={classnames(styles.link, {
                [styles.active]:
                link.url === location.pathname || isHomeLinkActive
              })}
              to={link.url}
            >
              {link.routeName}
            </Link>
          );
        })}
      </div>
      <div className={styles.content}>
        <Route
          exact
          path={routes.home}
          component={() => <ProductionSummary />}
        />
        <Route
          exact
          path={routes.productionManagementParent}
          component={() => <ProductionSummary />}
        />
        <Route
          exact
          path={routes.productionManagement.summary}
          component={() => <ProductionSummary />}
        />
        <Route
          path={routes.productionManagement.lpoEvents}
          component={() => <ProductionLpoEvents />}
        />
        {/* <Route
          path={routes.productionManagement.fieldSpecialistScorecard}
          component={() => <UnderConstruction />}
        /> */}
      </div>
    </div>
  );
};

export default withRouter(ProductionManagementPage);
