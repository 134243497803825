import moment from 'moment';

const returnforWOPRandWitMultipleCase = (woConnected, witPrConnected, prConnected) => {
  if (
    (woConnected && witPrConnected) || (prConnected && witPrConnected) || (woConnected && prConnected && witPrConnected)
  ) {
    return true;
  }

}
const returnforWOMultipleCase = (woConnected, workOrders) => {
  if (
    (woConnected && workOrders && workOrders.length > 1)
  ) {
    return true;
  }


}
const returnforWOSingleCase = (woConnected, workOrders) => {
  if (woConnected && workOrders && workOrders.length === 1) {
    return true;
  }
}
const returnforAIMultipleCase = (aiConnected, actionItems) => {
  if (
    (aiConnected && actionItems && actionItems.length > 1)
  ) {
    return true;
  }
}
const returnforAISingleCase = (aiConnected, actionItems) => {
  if (aiConnected && actionItems && actionItems.length === 1) {
    return true;
  }
}
const returnforWOPRAIWitMultipleCase = (woConnected, aiConnected, witPrConnected, prConnected) => {
  if (
    (woConnected && aiConnected) ||
    (prConnected && aiConnected) ||
    (witPrConnected && aiConnected) ||
    (woConnected && prConnected && witPrConnected && aiConnected)
  ) {
    return true;
  }
}

const returnWOPRMultipleSDCase = (woConnected,prConnected) =>{
  if( woConnected && prConnected ) {
    return true;
  }
}

const returnWOPRWITMultipleSDCase = (woConnected,prConnected, witPrConnected) =>{
  if( woConnected && prConnected && witPrConnected ) {
    return true
  }
}

const returnforWOandWitSDMultipleCase = (woConnected,witPrConnected) => {
if (woConnected && witPrConnected){
  return true;
}
}

const returnPRsingle = (prConnected,pullRequest) => {
  if (prConnected && pullRequest.scheduleDate){
    return true;
  }
}

const returnWitSingle = (witPrConnected,witPullRequest)=>{
  if(witPrConnected && witPullRequest.submittedDate){
    return true;
  }
}
const schDateReturn = (date) => {
  return date
        ? moment(date).format('M/D/YYYY')
        : 'N/A';
}
// Function to determine the text in the Depedency cell in the LPO Events table.
export const renderWorkOrderCell = woPrObject => {
  const {
    woConnected,
    prConnected,
    witPrConnected,
    aiConnected,
    pullRequest,
    workOrders,
    witPullRequest,
    actionItems
  } = woPrObject;


  if (returnWOPRMultipleSDCase(woConnected,prConnected)){
    return 'Multiple';
  }
  if (returnforWOPRandWitMultipleCase(woConnected, witPrConnected, prConnected)) {
    return 'Multiple';
  }


  if (returnforWOMultipleCase(woConnected, workOrders)) {
    return 'Multiple';
  }

  if (returnforWOSingleCase(woConnected, workOrders)) {
    return workOrders[0].caseId || 'N/A';
  }

  if (prConnected && pullRequest) {
    return pullRequest.caseId || 'N/A';
  }

  if (witPrConnected) {
    return witPullRequest.caseId;
  }

  if (aiConnected && actionItems && actionItems.length > 1) {
    return 'Multiple';
  }
  if (aiConnected && actionItems && actionItems.length === 1) {
    return actionItems[0].actionItemsId || 'N/A';
  }
  if (
    returnforWOPRAIWitMultipleCase(woConnected, aiConnected, witPrConnected, prConnected)
  ) {
    return 'Multiple';
  }
  return 'N/A';
};

// Function to determine the text in the ScheduledDate cell in the LPO Events table.
export const scheduledDateText = woPrObject => {
  const {
    woConnected,
    prConnected,
    aiConnected,
    workOrders,
    pullRequest,
    witPrConnected,
    witPullRequest,
    actionItems
  } = woPrObject;

  if (returnWOPRMultipleSDCase(woConnected,prConnected)) {
    return 'Multiple';
  }

  if (returnWOPRWITMultipleSDCase(woConnected,prConnected, witPrConnected)) {
    return 'Multiple';
  }

  if (returnforWOandWitSDMultipleCase(woConnected,witPrConnected)) {
    return 'Multiple';
  }

  if (prConnected && witPrConnected) {
    return 'Multiple';
  }

    if (returnforWOSingleCase(woConnected,workOrders)) {
      return schDateReturn( workOrders[0].scheduledStartDate);
    }
    if (returnforWOMultipleCase(woConnected,workOrders)) {
      return 'Multiple';
    }
  

   if (returnforAISingleCase(aiConnected,actionItems)) {
      return schDateReturn(actionItems[0].submittedDate);
    }
    if (returnforAIMultipleCase(aiConnected,actionItems)) {
      return 'Multiple';
    }



  if (returnPRsingle(prConnected,pullRequest)) {
    return moment(pullRequest.scheduleDate).format('M/D/YYYY');
  }

  if (returnWitSingle(witPrConnected,witPullRequest)) {
    return moment(witPullRequest.submittedDate).format('M/D/YYYY');
  }

  return 'N/A';
};

export const sortDate = (a, b) => {
  if (a === b) return 0;
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
};
