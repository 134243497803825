import React, { Component } from 'react';
import { connect } from 'react-redux';
import { prodLpoEventsCardTitles } from 'constants/productionManagement';
import {
  getLpoEventsInfo,
  getLpoEventsTable,
  selectCurrentLpoEventDetails,
  selectCurrentLpoEventsCard,
  selectCurrentLpoEventsData,
  selectFilteredLpoTable,
  selectLpoEventsTableRequestStatus,
  selectShouldLoadData,
  selectShowLpoEventDetailsModal,
  setCurrentLpoEventDetails,
  setCurrentLpoEventsCard,
  toggleLpoEventDetailsModal
} from 'store/reducers/production';
import { selectCurrentAsset } from 'store/reducers/navigation';
import TableCards from 'components/common/TableCards';
import styles from './styles.module.css';
import { requestStatuses } from 'constants/values';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import LoadingPage from 'components/common/LoadingPage';
import { openAndThirdPartyEventsColumns, ShutInWellsColumns } from 'constants/lpoEventTableColumns';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { appInsights } from 'App';
import NoContent from 'components/common/NoContent';
import EventDetailsModal from './EventDetailsModal';
import classnames from 'classnames';
import { getCountLpoTable} from 'utils/production'

const mapStateToProps = state => ({
  currentLpoEventDetails: selectCurrentLpoEventDetails(state),
  currentLpoEventsCard: selectCurrentLpoEventsCard(state),
  currentProductionLpoEvents: selectCurrentLpoEventsData(state),
  filtered: selectFilteredLpoTable(state),
  lpoTableRequestStatus: selectLpoEventsTableRequestStatus(state),
  selectedProductionAsset: selectCurrentAsset(state),
  shouldLoadData: selectShouldLoadData(state),
  showLpoEventDetailsModal: selectShowLpoEventDetailsModal(state)
});

const mapDispatchToProps = {
  getLpoEventsInfo,
  getLpoEventsTable,
  setCurrentLpoEventDetails,
  setCurrentLpoEventsCard,
  toggleLpoEventDetailsModal
};

export class LpoEventsTable extends Component {
  state = {
    loaded: false
  };

  setLpoEvent = event => {
    const {
      setCurrentLpoEventDetails,
      toggleLpoEventDetailsModal
    } = this.props;
    setCurrentLpoEventDetails(event);
    toggleLpoEventDetailsModal();
  };

  tableColumns = {
    [prodLpoEventsCardTitles.openLpoEvents]: openAndThirdPartyEventsColumns(
      this.setLpoEvent
    ),
    [prodLpoEventsCardTitles.thirdPartyEvents]: openAndThirdPartyEventsColumns(
      this.setLpoEvent
    ),
    [prodLpoEventsCardTitles.shutInWells]: ShutInWellsColumns(this.setLpoEvent)
  };

  componentDidMount = () => {
    if (this.props.shouldLoadData) {
      this.props.getLpoEventsTable();
      this.setState({ loaded: true });
    }
  };

  componentDidUpdate = () => {
    if (this.props.shouldLoadData && !this.state.loaded) {
      this.props.getLpoEventsTable();
      this.setState({ loaded: true });
    }
  };

  render() {
    const {
      currentLpoEventsCard,
      currentProductionLpoEvents,
      filtered,
      getLpoEventsInfo,
      getLpoEventsTable,
      lpoTableRequestStatus,
      setCurrentLpoEventsCard,
      showLpoEventDetailsModal
    } = this.props;
    
    const cards = {...currentProductionLpoEvents.cards};
    const currentProductionLpoEventsUpdated = getCountLpoTable(currentLpoEventsCard,cards,filtered);
    try {
      return (
        <div>
          <TableCards
            cards={[
              {
                width: 'longer',
                title: prodLpoEventsCardTitles.openLpoEvents,
                isDisabled: false,
                number: currentProductionLpoEventsUpdated.openLPOEvents,
                action: () => {
                  setCurrentLpoEventsCard(
                    prodLpoEventsCardTitles.openLpoEvents
                  );
                  getLpoEventsTable();
                },
                isSelected:
                  currentLpoEventsCard ===
                  prodLpoEventsCardTitles.openLpoEvents
              },

              {
                width: 'longer',
                title: prodLpoEventsCardTitles.thirdPartyEvents,
                isDisabled: false,
                number: currentProductionLpoEventsUpdated.thirdPartyEvents,
                action: () => {
                  setCurrentLpoEventsCard(
                    prodLpoEventsCardTitles.thirdPartyEvents
                  );
                  getLpoEventsTable();
                },
                isSelected:
                  currentLpoEventsCard ===
                  prodLpoEventsCardTitles.thirdPartyEvents
              },
              {
                width: 'longer',
                title: prodLpoEventsCardTitles.shutInWells,
                isDisabled: false,
                number: currentProductionLpoEventsUpdated.shutInWells,
                action: () => {
                  setCurrentLpoEventsCard(prodLpoEventsCardTitles.shutInWells);
                  getLpoEventsTable();
                },
                isSelected:
                  currentLpoEventsCard === prodLpoEventsCardTitles.shutInWells
              }
            ]}
          />

          {showLpoEventDetailsModal && <EventDetailsModal />}

          {lpoTableRequestStatus === requestStatuses.success ? (
            <div className={classnames(styles.reactTable, 'reactTableLPO')}>
              <ReactTable
                columns={this.tableColumns[currentLpoEventsCard]}
                data={filtered}
                defaultPageSize={9999}
                minRows={0}
                showPagination={false}
                resizable={false}
                className={'summaryTable'}
                defaultSorted={[
                  {
                    id: 'lossRate',
                    desc: true
                  },
                  {
                    id: 'daysInShutIn',
                    desc: true
                  }
                ]}
                NoDataComponent={() => (
                  <NoContent
                    containerStyle={styles.noData}
                    type={'data available.'}
                  />
                )}
              />
            </div>
          ) : (
            <LoadingPage
              containerStyles={styles.loading}
              requestStatus={lpoTableRequestStatus}
              retryRequest={getLpoEventsTable}
            />
          )}
        </div>
      );
    } catch (err) {
      appInsights.trackException({
        error: new Error(err),
        severityLevel: SeverityLevel.Error
      });

      return (
        <LoadingPage
          containerStyles={styles.loading}
          requestStatus={requestStatuses.failure}
          retryRequest={() => {
            getLpoEventsTable();
            getLpoEventsInfo();
          }}
        />
      );
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LpoEventsTable);
