/* istanbul ignore file */
import React, { Component } from 'react';
import { Redirect, Route, Router } from 'react-router-dom';
import AdminPage from 'components/AdminPage';
import AppLayout from './components/Layout/AppLayout';
import PageHeader from './components/Layout/PageHeader';
import ExceptionManagementPage from 'components/ExceptionManagementPage';
import ProductionManagementPage from 'components/ProductionManagementPage';
import { Provider } from 'react-redux';
import configureStore from 'store/configureStore';
import { routes } from 'constants/routes';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import ControlsPage from './components/ControlsPage';
import { msalInstance } from './index.js';


// Setup the Redux Store
const store = configureStore();

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();

// Setting up Azure Application Insights
export const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_INSTRUMENT_KEY,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory }
    }
  }
});
appInsights.loadAppInsights();

// Handle Private Routing. If we restrict user types from routes it is done here.
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {

      const isAdmin = store.getState().users.userInfo.isAdmin;

      const { pathname } = props.location;

      if (pathname === routes.admin) {
        return isAdmin ? (
          <AdminPage {...props} />
        ) : (
          <Redirect to={routes.home} />
        );
      }

      return <Redirect to={routes.home} />;
    }}
  />
);

// Add/edit/remove new Routes here.
class App extends Component {
  componentDidMount() {
    setTimeout(function () {
      localStorage.clear();
      msalInstance.logoutRedirect();
    }, 1000 * 60 * 60 * 24)

  }

  render() {
    return (
      <Provider store={store}>
        <Router history={browserHistory}>
          <AppLayout>
            <PageHeader />
            <PrivateRoute path={routes.admin} component={AdminPage} />
            <Route
              exact
              path={routes.controls}
              component={ControlsPage}
            />
            <Route
              exact
              path={routes.home}
              component={ProductionManagementPage}
            />
            <Route exact path={routes.feedback} />
            <Route
              path={routes.productionManagementParent}
              component={ProductionManagementPage}
            />
            <Route
              path={routes.exceptionManagementParent}
              component={ExceptionManagementPage}
            />
          </AppLayout>
        </Router>
      </Provider>
    );
  }
}


export default withAITracking(appInsights, App);
