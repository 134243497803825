import { lpoStatusPlanned } from 'constants/productionManagement';
import moment from 'moment';

// Mapping Open LPO Events & Third Party Events Objects to CSV
export const mapOpenLpoEventToCsv = events =>
  events.map(event => {
    const {
      wO_PR,
      affectedProductionDetails,
      collectionPoint,
      comments,
      daysInEvent,
      downTimeType,
      eventLoss,
      initialDownDate,
      lossRate,
      lpoType,
      reasonCode,
      reasonForCriticality,
      route,
      status,
      wellName
    } = event;

    const mappedEvent = {
      'Well Name': wellName,
      'Collection Point': collectionPoint,
      Route: route,
      'Initial Down Date': moment(initialDownDate).format('M/D/YYYY'),
      'Days In Event': daysInEvent,
      'Loss Rate': lossRate,
      'Event Loss': eventLoss,
      'LPO Type': lpoType,
      'Reason Code': reasonCode,
      'Reason Description': reasonForCriticality,
      Comments: comments,
      'Planned LPO': lpoStatusPlanned[status],
      'Downtime Type': downTimeType,
      'Affected Gas Production Loss Rate':
      affectedProductionDetails.gas.lossRate,
      'Affected Gas Production Event Loss':
      affectedProductionDetails.gas.eventLoss,
      'Affected Oil Production Loss Rate':
      affectedProductionDetails.oil.lossRate,
      'Affected Oil Production Event Loss':
      affectedProductionDetails.oil.eventLoss
    };

    // Map a Pull Request if one exists.
    if (wO_PR.prConnected) {
      mappedEvent['Dependency: Pull Request'] = wO_PR.pullRequest.caseId;
      mappedEvent['Pull Request Status'] = wO_PR.pullRequest.status;
      mappedEvent['Pull Request Submitted By'] = wO_PR.pullRequest.submittedBy;
      mappedEvent['Pull Request WBS Number'] = wO_PR.pullRequest.wbsNumber;
      mappedEvent['Pull Request Scheduled Date'] = moment(
        wO_PR.pullRequest.scheduleDate
      ).format('M/D/YYYY');
    }

    // Map Work Orders if any exists.
    if (wO_PR.woConnected) {
      wO_PR.workOrders.forEach((workOrder, index) => {
        mappedEvent['Dependency: Work Order'] = workOrder.caseId;
        mappedEvent[`Work Order ${index + 1} Priority`] = workOrder.priority;
        mappedEvent[`Work Order ${index + 1} Status`] = workOrder.status;
        mappedEvent[`Work Order ${index + 1} Reason`] = workOrder.reason;
        mappedEvent[`Work Order ${index + 1} Submitted By`] =
          workOrder.submittedBy;
        mappedEvent[`Work Order ${index + 1} Scheduled Start Date`] = moment(
          wO_PR.workOrders.scheduledStartDate
        ).format('M/D/YYYY');
        mappedEvent[`Work Order ${index + 1} Scheduled End Date`] = moment(
          workOrder.scheduledEndDate
        ).format('M/D/YYYY');
      });
    }

    return mappedEvent;
  });

// Mapping Shut In Wells Objects to CSV
export const mapShutInWellsToCsv = wells =>
  wells.map(well => {
    const {
      wO_PR,
      dateOfShutIn,
      daysInShutIn,
      reasonCode,
      wellName,
      lawtRate
    } = well;

    const mappedShutInWells = {
      'Well Name': wellName,
      'Date of Shut-In': moment(dateOfShutIn).format('M/D/YYYY'),
      'Days In Shut-In': daysInShutIn,
      'LAWT Rate (BOED)': lawtRate,
      'Reason Code': reasonCode
    };

    // Map a Pull Request if one exists.
    if (wO_PR.prConnected) {
      mappedShutInWells['Dependency Pull Request'] = wO_PR.pullRequest.caseId;
    }

    // Map Work Orders if any exists.
    if (wO_PR.woConnected) {
      wO_PR.workOrders.forEach((workOrder, index) => {
        mappedShutInWells[`Dependency Work Order ${index + 1} `] =
          workOrder.caseId;
      });
    }

    return mappedShutInWells;
  });
