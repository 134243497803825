import React, { Component } from 'react';
import { lpoEventDetailModalLabels, lpoStatusPlanned } from 'constants/productionManagement';
import styles from './styles.module.css';
import { formatNumber } from 'utils/formatNumber';
import { connect } from 'react-redux';
import { selectCurrentLpoEventDetails, selectCurrentModalPageIndex } from 'store/reducers/production';
import moment from 'moment';
import Popover from 'components/common/Popover';
import { getCurrentWOIndex } from 'utils/lpoEventDetails';
import { workOrderRequestSteps } from 'constants/statusSteps';
import StepComponent from 'components/common/StepComponent'

const labels = lpoEventDetailModalLabels;

const mapStateToProps = state => ({
  event: selectCurrentLpoEventDetails(state),
  currentWorkOrderIndex: selectCurrentModalPageIndex(state),

});

export class OpenWorkOrderModal extends Component {

  render() {
    let { currentWorkOrderIndex } = this.props;
    const { event } = this.props;
    const { workOrders, prConnected, aiConnected, witPrConnected, witPullRequest, pullRequest } = event.wO_PR;
    currentWorkOrderIndex = getCurrentWOIndex(currentWorkOrderIndex, prConnected, aiConnected, witPrConnected, witPullRequest, pullRequest);
    const scheduleDate = (workOrders[currentWorkOrderIndex].scheduledStartDate && workOrders[currentWorkOrderIndex].scheduledStartDate !== '0001-01-01T00:00:00') ? moment(workOrders[currentWorkOrderIndex].scheduledStartDate).format('M/D/YYYY') : '';
    const completedDate = (workOrders[currentWorkOrderIndex].completeDate && workOrders[currentWorkOrderIndex].completeDate !== '0001-01-01T00:00:00') ? (moment(workOrders[currentWorkOrderIndex].completeDate).format('M/D/YYYY')) : '';
    const submittedDate = (workOrders[currentWorkOrderIndex].submittedDate && workOrders[currentWorkOrderIndex].submittedDate !== '0001-01-01T00:00:00') ? (moment(workOrders[currentWorkOrderIndex].submittedDate).format('M/D/YYYY')) : '';
    const lastStepIgnore = workOrders[currentWorkOrderIndex].status !== 'Complete' ? true : false;
    const initialDowndate = event.initialDownDate ? (moment(event.initialDownDate).format('M/D/YYYY')) : '';
    return (
      <div>
        <div className={styles.container}>
          <div className={styles.stepWrapper}>
            <StepComponent status={workOrders[currentWorkOrderIndex].status} statusSteps={workOrderRequestSteps} lastStepIgnore={lastStepIgnore} />
          </div>
          <div className={styles.topDetails}>
            <ul className={styles.detailColumn}>
              <li>
                <span>{labels.wellName}</span>
                {event.wellName}
              </li>
              <li>
                <span>{labels.scheduleDate}</span>
                {scheduleDate}
              </li>
              <li>
                <span>{labels.collectionPoint}</span>
                {event.collectionPoint}
              </li>
              <li>
                <span>{labels.route}</span>
                {event.route}
              </li>
              <li>
                <span>{labels.downTimeType}</span>
                {event.downTimeType}
              </li>
              <li>
                <span>{labels.priority}</span>
                {workOrders[currentWorkOrderIndex].priority}
              </li>
              <li>
                <span>{labels.planned}</span>
                {!!event.status && lpoStatusPlanned[event.status]}
              </li>
              <li>
                <span>{labels.completedDate}</span>
                {completedDate}
              </li>
              <li>
                <span>{labels.initialDownDate}</span>
                {initialDowndate}
              </li>
            </ul>
            <ul className={styles.detailColumn}>
              <li className={styles.noboldli}>
                <span>{labels.woStatus}</span>
                <Popover
                  text={workOrders[currentWorkOrderIndex].status}
                  overflow={true}
                >
                  <span className={styles.nobold}>
                    {workOrders[currentWorkOrderIndex].status}
                  </span>
                </Popover>
                {/* <span class={styles.nobold} title={workOrders[currentWorkOrderIndex].status}>{workOrders[currentWorkOrderIndex].status}
                </span> */}
              </li>
              <li className={styles.noboldli}>
                <span>{labels.createdBy}</span>
                <Popover
                  text={workOrders[currentWorkOrderIndex].createdBy}
                  overflow={true}
                >
                  <span className={styles.nobold}>
                    {workOrders[currentWorkOrderIndex].createdBy}
                  </span>
                </Popover>
              </li>
              <li>
                <span>{labels.workOrder}</span>
                {workOrders[currentWorkOrderIndex].caseId}
              </li>
              <li>
                <span>{labels.lpoType}</span>
                {event.lpoType}
              </li>
              <li>
                <span>{labels.daysInEvent}</span>
                {event.daysInEvent}
              </li>
              <li>
                <span>{labels.submittedDate}</span>
                {submittedDate}
              </li>
              <li>
                <span>{labels.reasonCode}</span>
                {event.reasonCode}
              </li>
            </ul>
          </div>

          <p className={styles.reason}>
            <span>{labels.reason}</span>
            {workOrders[currentWorkOrderIndex].reason}
          </p>
          <p className={styles.reason}>
            <span>{labels.reasonForCriticality}</span>
            {event.reasonForCriticality}
          </p>

          <p className={styles.affectedTableTitle}>
            <span>{labels.affectedProductionDetails}</span>
          </p>
          <table
            className={styles.affectedTable}
            cellPadding="0"
            cellSpacing="0"
          >
            <thead>
              <tr>
                <th />
                <th>{labels.lossRate}</th>
                <th>{labels.eventLoss}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{labels.oilProduction}</td>
                <td>
                  {formatNumber(event.affectedProductionDetails.oil.lossRate)}
                  {labels.bopd}
                </td>
                <td>
                  {formatNumber(event.affectedProductionDetails.oil.eventLoss)}
                  {labels.boe}
                </td>
              </tr>
              <tr>
                <td>{labels.gasProduction}</td>
                <td>
                  {formatNumber(event.affectedProductionDetails.gas.lossRate)}
                  {labels.mcfd}
                </td>
                <td>
                  {formatNumber(event.affectedProductionDetails.gas.eventLoss)}
                  {labels.mcf}
                </td>
              </tr>
            </tbody>
          </table>

          <p className={styles.commentsTitle}>{labels.comments}</p>
          <p className={styles.comments}>{event.comments}</p>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(OpenWorkOrderModal);
