import { createThunk } from 'store/createThunk';
import * as backendAPI from 'api/controls';
import types from 'constants/actionTypes';

export const setControlTimePeriod = (year, month) => ({
  type: types.SET_CONTROL_TIME_PERIOD,
  payload: { year, month },
});

export const setControlRequestRoleOptions = roleId => ({
  type: types.SET_CONTROL_REPORT_ROLE_OPTION,
  payload: roleId,
});

export const setControlRequestAssetOptions = assetId => ({
  type: types.SET_CONTROL_REPORT_ASSET_OPTION,
  payload: assetId,
});

export const getUserControlItems = createThunk({
  apiCall: backendAPI.getUserControlItems,
  type: types.GET_CONTROL_ITEMS_REQUEST,
});

export const initPillarCounts = () => ({
  type: types.INIT_PILLAR_COUNTS,
});

export const setDirectReportCheckbox = checkboxEvent => ({
  type: types.DIRECT_REPORT_CHECKBOX,
  payload: checkboxEvent,
});

export const getPillarOptions = createThunk({
  apiCall: backendAPI.getPillarOptions,
  type: types.GET_PILLAR_OPTIONS_REQUEST,
});

export const changeOccurrenceStatus = createThunk({
  apiCall: backendAPI.changeOccurrenceStatus,
  type: types.CHANGE_OCCURRENCE_STATUS_REQUEST,
});

export const toggleAddControlModal = () => ({
  type: types.TOGGLE_ADD_CONTROL_MODAL,
});

export const addControlItem = createThunk({
  apiCall: backendAPI.addControlItem,
  type: types.CREATE_CUSTOM_CONTROL_REQUEST,
});

export const resetControlToolRequestStatus = () => ({
  type: types.RESET_CONTROL_TOOL_REQUEST_STATUS,
});

export const toggleFormControlModal = record => ({
  type: types.TOGGLE_FORM_CONTROL_MODAL,
  payload: record,
});

export const editControlItem = createThunk({
  apiCall: backendAPI.editControlItem,
  type: types.EDIT_CONTROL_ITEM_REQUEST,
});

export const toggleDeleteControlModal = record => ({
  type: types.TOGGLE_DELETE_CONTROL_MODAL,
  payload: record,
});

export const turnOffDeleteControlModal = record => ({
  type: types.TURN_OFF_DELETE_CONTROL_MODAL,
  payload: record,
});

export const deleteControlItem = createThunk({
  apiCall: backendAPI.deleteControlItem,
  type: types.DELETE_CONTROL_ITEM_REQUEST,
});
