import React from 'react';
import styles from './styles.module.css';
import moment from 'moment';

export const ECCommentModal = props => {
  const { record, currentCard } = props;
  let activeCard = '';
  activeCard =
    currentCard === 'All Exceptions' || 'Surface Exceptions'  || 'Sub-surface Exceptions'
      ? record.exceptionType.startsWith('PR - ')
        ? 'Pull Request Status'
        : record.exceptionType.startsWith('WO ')
        ? 'WO Priority Level'
        : record.exceptionType
      : currentCard;
  let data = null;
  switch (activeCard) {
    case 'No Work Order':
      data = {
        reasonCode: record.reasonCode,
      };
      break;
    case 'WO Priority Level':
      const woscheduledDate = record.woScheduledDate?  moment(record.woScheduledDate).format('M/D/YYYY'):'';
      data = {
        reasonCode: record.reasonCode,
        workorder: record.woCaseId,
        priority: record.priority,
        scheduled: woscheduledDate,
      };
      break;
    case 'Complete but Non-Producing for Surface':
      const woCompletedDate = record.woCompletedDate? moment(record.woCompletedDate).format('M/D/YYYY'):'' ;
      data = {
        reasonCode: record.reasonCode,
        workorder: record.woCaseId,
        priority: record.priority,
        completedDate: woCompletedDate,
      };
      break;
    case 'No Pull Request':
      data = {
        reasonCode: record.reasonCode,
      };
      break;
    case 'Pull Request Status':
     const scheduledDate = record.scheduledDate?moment(record.scheduledDate).format('M/D/YYYY') :'';
      data = {
        reasonCode: record.reasonCode,
        pullRequest: record.pullRequest,
        prStatus: record.prStatus,
        daysInStep: record.daysInStep,
        scheduled: scheduledDate
      };
      break;
    case 'Complete but Non-Producing for Sub-Surface':
      const completedDate = record.completedDate? moment(record.completedDate).format('M/D/YYYY'):'';
      data = {
        reasonCode: record.reasonCode,
        pullRequest: record.pullRequest,
        completedDate: completedDate,
      };
  break;
      case 'Troubleshooting':
        data = {
          na:'NA'
        };
        break;
    default:

      break;
  }
  return (
    <div className={styles.flexContainer}>
      {data && (
        <div>
          {data.reasonCode && (
            <div>
              <span className={styles.modalLabel}>Reason Code:</span>
              <span>{data.reasonCode}</span>
            </div>
          )}
          {data.workorder && (
            <div>
              <span className={styles.modalLabel}>Work Order:</span>
              <span>{data.workorder}</span>
            </div>
          )}
          {data.priority && (
            <div>
              <span className={styles.modalLabel}>Priority:</span>
              <span>{data.priority}</span>
            </div>
          )}
          {(activeCard==='Pull Request Status' || activeCard==='WO Priority Level')&& (
            <div>
              <span className={styles.modalLabel}>Scheduled Date:</span>
              <span>{data.scheduled}</span>
            </div>
          )}
          {(activeCard==='Complete but Non-Producing for Surface' || activeCard==='Complete but Non-Producing for Sub-Surface') && (
            <div>
              <span className={styles.modalLabel}>Completed Date:</span>
              <span>{data.completedDate}</span>
            </div>
          )}
          {data.pullRequest && (
            <div>
              <span className={styles.modalLabel}>Pull Request:</span>
              <span>{data.pullRequest}</span>
            </div>
          )}
          {data.prStatus && (
            <div>
              <span className={styles.modalLabel}>PR Status:</span>
              <span>{data.prStatus}</span>
            </div>
          )}
          {data.daysInStep && (
            <div>
              <span className={styles.modalLabel}>Days In Step:</span>
              <span>{data.daysInStep}</span>
            </div>
          )}
          {data.na && (
            <div>
               <span>{data.na}</span>
            </div>
          )}
  
          <button className={styles.close_btn} onClick={props.onCloseClick}>
            close
          </button>
        </div>
      )}
    </div>
  );
};
