import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from 'components/AdminPage/styles.module.css';
import { requestStatuses } from 'constants/values';
import {
  selectAdminToolRequestStatus,
  turnOffModal,
  selectErrorMessage,
} from 'store/reducers/admin';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  requestStatus: selectAdminToolRequestStatus(state),
  errorMessage: selectErrorMessage(state)
});

const mapDispatchToProps = {
  turnOffModal
};

export const ButtonArea = props => {
  const {
    formItems,
    requestStatus,
    saveAction,
    saveAttempted,
    errorMessage
   } = props;

  // Equivalent to setting a React Component State
  const [saveAttemptedState, setSaveAttemptedState] = useState(false);
  // Equivalent to ComponentDidMount
  // useEffect(() => {
  //   resetAdminToolRequestStatus();
  //   }, [resetAdminToolRequestStatus]);
  const invalidFormItems = Object.values(formItems).filter(
    formItem => formItem.valid === false && formItem.required === true
  );

  let validForm = true;

  if (invalidFormItems && invalidFormItems.length > 0) {
    validForm = false;
  }

  return (
    <div className={styles.flexContainer}>
      <div className={styles.buttonsArea}>
        <button
          className={styles.saveBtn}
          onClick={() => {
            setSaveAttemptedState(true);
            saveAttempted(invalidFormItems);
            if (validForm) {
              saveAction();
            }
          }}
        >
          save
        </button>
        <button className={styles.cancelBtn} onClick={()=> {
          props.turnOffModal();
          }}>
          cancel
        </button>

        <div className={styles.formErrorSection}>
          <span className={styles.error}>
            {requestStatus === requestStatuses.failure && errorMessage}
            {saveAttemptedState &&
              !validForm &&
              'please complete all required fields before submitting'}
          </span>
        </div>
      </div>
    </div>
  );
};

ButtonArea.propTypes = {
  saveAction: PropTypes.func.isRequired,
  turnOffModal: PropTypes.func.isRequired,
  formItems: PropTypes.object.isRequired,
  saveAttempted: PropTypes.func.isRequired,
  requestStatus: PropTypes.string.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ButtonArea);
