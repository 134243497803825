import React from 'react';
import styles from './styles.module.css';
import { connect } from 'react-redux';
import {
  getSummary,
  getSummaryChart,
  getSummaryTable,
  setHeirarchy,
} from 'store/reducers/production';
import {
  selectCurrentAsset,
  selectCurrentRoute,
  setAsset,
  setRoute,
} from 'store/reducers/navigation';
import classnames from 'classnames';
import Popover from 'components/common/Popover';
import { hierarchyLevelConstants } from 'constants/productionManagement';
import { isHierarchyLevelWeLL } from 'utils/production';

const mapStateToProps = state => ({
  selectedProductionRoute: selectCurrentRoute(state),
  selectedProductionAsset: selectCurrentAsset(state),
});

const mapDispatchToProps = {
  getSummary,
  getSummaryChart,
  getSummaryTable,
  setAsset,
  setRoute,
  setHeirarchy,
};

export const RouteDiff = ({
  difference,
  dashboardAssetId,
  dashboardDifference,
  dashboardAssetName,
  getSummary,
  getSummaryChart,
  getSummaryTable,
  id,
  name,
  selectedProductionAsset,
  selectedProductionRoute,
  setAsset,
  setRoute,
  isSuperItendent,
  setHeirarchy,
  hierarchyLevel,
}) => {
  const routeSelected = selectedProductionRoute === id;

  return (
    <div
      className={classnames(styles.routeCard, {
        [styles.routeSelected]: routeSelected,
      })}
      onClick={() => {
        if (!isSuperItendent) {
         isHierarchyLevelWeLL(hierarchyLevel)
            ? setHeirarchy(hierarchyLevelConstants.routeLevel)
            : setHeirarchy(hierarchyLevel);
          routeSelected ? setAsset(selectedProductionAsset) : setRoute(id);
          getSummary();
          getSummaryTable();
          getSummaryChart();
        } else {
          setHeirarchy(hierarchyLevelConstants.assetLevel);
          setAsset(dashboardAssetId);
          getSummary();
          getSummaryTable();
          getSummaryChart();
        }
      }}
    >
      <div className={styles.name}>
        {isSuperItendent ? (
          <Popover text={dashboardAssetName} overflow={true}>
            {dashboardAssetName}
          </Popover>
        ) : (
          <Popover text={name} overflow={true}>
            {name}
          </Popover>
        )}
      </div>
      <div
        className={
          isSuperItendent
            ? classnames(styles.difference, {
                [styles.differenceRed]: dashboardDifference < 0,
                [styles.differenceGreen]: dashboardDifference > 0,
              })
            : classnames(styles.difference, {
                [styles.differenceRed]: difference < 0,
                [styles.differenceGreen]: difference > 0,
              })
        }
      >
        {isSuperItendent ? dashboardDifference : difference}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RouteDiff);
