import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';
import TinyPopover, { ArrowContainer } from 'react-tiny-popover';
import classnames from 'classnames';

class Popover extends Component {
  constructor() {
    super();
    this.state = {
      isPopoverOpen: false
    };

    this.togglePopover = this.togglePopover.bind(this);
  }

  togglePopover() {
    this.setState({ isPopoverOpen: !this.state.isPopoverOpen });
  }

  render() {
    const { children, image, overflow, text } = this.props;

    return (
      <TinyPopover
        isOpen={this.state.isPopoverOpen}
        position={'top'}
        content={({ position, targetRect, popoverRect }) => (
          <ArrowContainer
            position={position}
            targetRect={targetRect}
            popoverRect={popoverRect}
            arrowColor={'var(--grey-charcoal)'}
            arrowSize={5}
            arrowStyle={{ opacity: 1.0, zIndex: 10000 }}
          >
            <div
              className={styles.hoverContent}
              style={image && { height: '44px' }}
            >
              {image && (
                <img className={styles.hoverImage} src={image} alt={text} />
              )}
              <span className={styles.hoverText}>{text}</span>
            </div>
          </ArrowContainer>
        )}
      >
        <div
          className={classnames(styles.childComponent, {
            [styles.overflow]: overflow
          })}
          onMouseOver={this.togglePopover}
          onMouseOut={this.togglePopover}
        >
          {children}
        </div>
      </TinyPopover>
    );
  }
}

export default Popover;

Popover.propTypes = {
  text: PropTypes.string,
  image: PropTypes.string,
  children: PropTypes.node,
  overflow: PropTypes.bool
};
