import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from 'components/AdminPage/styles.module.css';
import ChevronDown from 'assets/img/chevron-down.svg';
import Select, { components } from 'react-select';
import mapOptionsUtil from 'utils/mapOptions';
import multiLpoStyles from './styles.module.css';

const customStyles = {
  multiValue: provided => {
    return {
      ...provided,
      backgroundColor: 'rgba(0, 102, 178, 0.2)'
    };
  },
  multiValueLabel: provided => {
    return {
      ...provided,
      color: 'var(--blue-deepSea)',
      fontFamily: 'Gotham',
      fontSize: 16,
      fill: 'var(--blue-deepSea)'
    };
  },
  valueContainer: provided => {
    return {
      ...provided,
      color: 'var(--blue-deepSea)'
    };
  },
  option: provided => {
    return {
      ...provided,
      borderBottom: '1px solid var(--grey-metal)',
      color: 'var(--grey-charcoal)',
      fontFamily: 'Gotham',
      fontSize: 16,
      padding: 14
    };
  },
  indicatorSeparator: () => {
    return {
      display: 'none'
    };
  },
  clearIndicator: () => {
    return {
      display: 'none'
    };
  },
  dropdownIndicator: provided => {
    return {
      ...provided,
      color: 'var(--blue-deepSea)',
      cursor: 'pointer',
      fill: 'var(--blue-deepSea)',
      marginRight: 2,
      stroke: 'var(--blue-deepSea)',
      padding: 0
    };
  },
  control: provided => {
    return {
      ...provided,
      border: '1px solid var(--grey-metal)',
      borderColor: 'var(--grey-metal)',
      boxShadow: 'none',

      '&:hover': {
        borderColor: 'var(--grey-metal)',
        boxShadow: 'none'
      }
    };
  },
  menu: provided => {
    return {
      ...provided,
      fontFamily: 'Gotham',
      zIndex: 4
    };
  }
};

const determineBasis = (fullWidth, oneThird) => {
  if (fullWidth) {
    return styles.fullBasis;
  }

  if (oneThird) {
    return styles.oneThirdBasis;
  }

  return styles.basis;
};

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={ChevronDown} alt={props.label} />
    </components.DropdownIndicator>
  );
};

class MultiSelectLpo extends Component {
  componentWillUnmount = () => {
    if (!this.props.disableReset) this.props.resetAction();
  };

  mapOptions = options => {
    let mappedOptions = options;
    if (typeof options[0] !== 'object') {
      mappedOptions = mapOptionsUtil(options);

      if (this.props.showPlaceholder) {
        mappedOptions.unshift({ label: 'Select Option', value: '' });
      }
    } else {
      if (
        this.props.showPlaceholder &&
        this.props.placeholderText &&
        mappedOptions[0].label !== this.props.placeholderText
      ) {
        mappedOptions.unshift({ label: this.props.placeholderText, value: '' });
      }
    }
    return mappedOptions;
  };

  render = () => {
    const {
      disabled,
      options,
      resetAction,
      placeholderText,
      setAction,
      label,
      oneThird,
      fullWidth,
      value
    } = this.props;

    const mappedOptions = this.mapOptions(options);

    return (
      <div className={determineBasis(fullWidth, oneThird)}>
        <div className={multiLpoStyles.headerRow}>
          <label className={styles.label}>{label}</label>
          <span
            className={multiLpoStyles.clearAll}
            onClick={() => setAction([])}
          >
            Clear All
          </span>
        </div>
        <Select
          className={styles.selectBox}
          styles={customStyles}
          disabled={disabled}
          onChange={setAction}
          value={value}
          aria-label={label || 'Multi Select'}
          options={mappedOptions}
          isMulti={true}
          resetAction={resetAction}
          placeholder={placeholderText}
          closeMenuOnSelect={false}
          components={{ DropdownIndicator }}
          blurInputOnSelect={false}
          autoFocus={false}
          isSearchable={true}
          maxMenuHeight={250}
        />
      </div>
    );
  };
}

MultiSelectLpo.propTypes = {
  disabled: PropTypes.bool,
  showPlaceholder: PropTypes.bool,
  placeholderText: PropTypes.string,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        option: PropTypes.string,
        value: PropTypes.string
      })
    ).isRequired,
    PropTypes.arrayOf(PropTypes.string).isRequired
  ]).isRequired,
  resetAction: PropTypes.func.isRequired,
  setAction: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.object).isRequired,
  label: PropTypes.string,
  oneThird: PropTypes.bool,
  fullWidth: PropTypes.bool
};

export default MultiSelectLpo;
