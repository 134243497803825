import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  modalPageSwapLeft,
  modalPageSwapRight,
  selectCurrentLpoEventDetails,
  selectCurrentLpoEventsCard,
  selectCurrentModalPageIndex,
  selectNumOfModalPages,
  selectShowArrowButtons,
  toggleLpoEventDetailsModal,
  selectNumOfWitPr,
  selectNumOfPR,
  selectNumOfWorkOrders
} from 'store/reducers/production';
import Modal from 'components/common/Modal';
import SwapButton from 'components/common/SwapButton';
import styles from './styles.module.css';
import { getCustomTitleEventDetailModal,renderModal } from 'utils/lpoEventDetails'

const mapStateToProps = state => ({
  currentLpoEventDetails: selectCurrentLpoEventDetails(state),
  currentLpoEventsCard: selectCurrentLpoEventsCard(state),
  currentModalPageIndex: selectCurrentModalPageIndex(state),
  numOfModalPages: selectNumOfModalPages(state),
  numOfWorkOrders: selectNumOfWorkOrders(state),
  numOfWitPR: selectNumOfWitPr(state),
  numOfPR: selectNumOfPR(state),
  showArrowButtons: selectShowArrowButtons(state)
});

const mapDispatchToProps = {
  toggleLpoEventDetailsModal,
  modalPageSwapLeft,
  modalPageSwapRight,
};


export const EventDetailsModal = ({
  currentLpoEventDetails,
  currentLpoEventsCard,
  toggleLpoEventDetailsModal,
  currentModalPageIndex,
  numOfModalPages,
  modalPageSwapLeft,
  modalPageSwapRight,
  showArrowButtons,
  numOfWorkOrders,
  numOfWitPR,
  numOfPR
}) => {
  const modal = renderModal(
    currentLpoEventDetails,
    currentLpoEventsCard,
    currentModalPageIndex,
    numOfWorkOrders,
    numOfWitPR,
    numOfPR
  );

  let customModalTitle = 'work order details';
  customModalTitle = getCustomTitleEventDetailModal(customModalTitle, showArrowButtons, currentModalPageIndex, numOfWorkOrders, numOfModalPages, currentLpoEventDetails,numOfWitPR,numOfPR);
  return (
    <Fragment>
      {showArrowButtons && (
        <div>
          <div className={styles.leftArrow}>
            <SwapButton isLeft={true} action={modalPageSwapRight} />
          </div>
          <div className={styles.rightArrow}>
            <SwapButton action={modalPageSwapLeft} />
          </div>
        </div>
      )}
      <Modal
        size={modal.size}
        title={showArrowButtons ? customModalTitle : modal.title}
        onCloseClick={toggleLpoEventDetailsModal}
      >
        {modal.modal}
      </Modal>
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EventDetailsModal);
