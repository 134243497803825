import { connect } from 'react-redux';
import styles from './styles.module.css';
import React, { Component } from 'react';
import { requestStatuses, summaryTypes } from 'constants/values';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import LoadingPage from 'components/common/LoadingPage';
import {
  assetDifferenceColumns,
  assetMeasuredProductionColumns,
  assetTargetColumns,
  routeMeasuredProductionColumns,
  assetDashboardDifferenceColumns,
  assetDashboardTargetColumns,
  assetMeasuredDashboardColumns,
  routeAllocatedProductionColumns,
  collectionPointProductionColumns,
} from 'constants/summaryTableColumns';
import {
  prodSummaryCardTitles,
  hierarchyLevelConstants,
} from 'constants/productionManagement';
import CustomRouteTargetForm from './CustomRouteTargetForm';
import {
  getSummary,
  getSummaryChart,
  getSummaryTable,
  getTargetHistory,
  setHeirarchy,
  resetTargetRequestStatus,
  selectCurrentSummaryCard,
  selectCurrentSummaryData,
  selectNewTargetValues,
  selectShouldLoadData,
  selectShowCustomTargetModal,
  selectShowCustomRouteTargetModal,
  selectSummaryTableData,
  selectSummaryTableRequestStatus,
  selectTableTargetEditing,
  selectTableTargets,
  selectTargetRequestStatus,
  selectIsAllocatedProduction,
  setCurrentSummaryCard,
  setNewTargetValues,
  setSummaryTableResolvedData,
  toggleCustomTargetModal,
  updateTableTarget,
  selectHierarchy,
  setCollectionPoint,
  getAssetLevelTargets,
  getRouteLevelTargets,
  selectRouteTargetRequestStatus,
  toggleCustomRouteTargetModal,
  selectAssetTargetRequestStatus,
  setAssetTargetDetails,
  setRouteTargetDetails,
  setAssetLevelPostTargetStatus,
  setRouteLevelPostTargetStatus,
} from 'store/reducers/production';
import { purgeCache } from 'store/reducers/cache';
import {
  selectCurrentAsset,
  selectCurrentRoute,
  setRoute,
  setAsset,
} from 'store/reducers/navigation';
import Modal from 'components/common/Modal';
import CustomAssetTargetForm from '../CustomAssetTargetForm';
import TableCards from 'components/common/TableCards';
import SuccessModal from 'components/common/SuccessModal';
import TargetHistorySection from './TargetHistorySection';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { appInsights } from 'App';
import NoContent from 'components/common/NoContent';
import  { selectIsGuestUser, selectIsReadOnlyUser, selectUserInfo } from 'store/reducers/users';
import AssetCheck from 'utils/assetCheck';
import { getCardValues } from 'utils/getCardValues';
import { getSummaryData } from 'utils/getSummaryData';
import classnames from 'classnames';
import {
  getColumnForSummaryTable,
  getDataTypeForTableData,
  getCurrentColumn,
} from 'utils/production';

const mapStateToProps = state => ({
  currentSummaryCard: selectCurrentSummaryCard(state),
  editing: selectTableTargetEditing(state),
  isGuestUser: selectIsGuestUser(state),
  isReadOnlyUser: selectIsReadOnlyUser(state),
  selectedProductionAsset: selectCurrentAsset(state),
  selectedProductionRoute: selectCurrentRoute(state),
  shouldLoadData: selectShouldLoadData(state),
  showCustomTargetModal: selectShowCustomTargetModal(state),
  summaryData: selectCurrentSummaryData(state),
  summaryTableData: selectSummaryTableData(state),
  summaryTableRequestStatus: selectSummaryTableRequestStatus(state),
  tableTargets: selectTableTargets(state),
  targetRequestStatus: selectTargetRequestStatus(state),
  targets: selectNewTargetValues(state),
  hierarchyLevel: selectHierarchy(state),
  isAllocatedProduction: selectIsAllocatedProduction(state),
  assetLevelTargetStatus: selectAssetTargetRequestStatus(state),
  routeLevelTargetStatus: selectRouteTargetRequestStatus(state),
  showCustomRouteTargetModal: selectShowCustomRouteTargetModal(state),
  userInfo:selectUserInfo(state)
});

const mapDispatchToProps = {
  getSummary,
  getSummaryChart,
  getSummaryTable,
  getTargetHistory,
  setAsset,
  setHeirarchy,
  resetTargetRequestStatus,
  setCurrentSummaryCard,
  setNewTargetValues,
  setRoute,
  setSummaryTableResolvedData,
  toggleCustomTargetModal,
  updateTableTarget,
  purgeCache,
  setCollectionPoint,
  getAssetLevelTargets,
  getRouteLevelTargets,
  toggleCustomRouteTargetModal,
  setAssetTargetDetails,
  setRouteTargetDetails,
  setAssetLevelPostTargetStatus,
  setRouteLevelPostTargetStatus,
};

const modalSuccessTitle = 'Successfully saved!';

export class SummaryCardTable extends Component {
  state = {
    loaded: false,
  };

  setNewRoute = id => {
    const {
      getSummary,
      getSummaryChart,
      getSummaryTable,
      setRoute,
      setHeirarchy,
    } = this.props;
    setHeirarchy('routeHierarchyLevel');
    setRoute(id);
    getSummary();
    getSummaryTable();
    getSummaryChart();
  };

  setNewAsset = id => {
    const {
      getSummary,
      getSummaryChart,
      getSummaryTable,
      setAsset,
      setHeirarchy,
    } = this.props;
    setAsset(id);
    setHeirarchy('assetHierarchyLevel');
    getSummary();
    getSummaryTable();
    getSummaryChart();
  };

  setWellLevel = id => {
    const {
      setCollectionPoint,
      setHeirarchy,
      getSummary,
      getSummaryChart,
      getSummaryTable,
    } = this.props;
    setCollectionPoint(id);
    setHeirarchy(hierarchyLevelConstants.wellLevel);
    getSummary();
    getSummaryTable();
    getSummaryChart();
  };

  updateTableTarget = payload => {
    this.props.updateTableTarget(payload);
  };

  tableColumns = {
    'asset-Production': assetMeasuredProductionColumns(this.setNewRoute),
    'asset-Difference': assetDifferenceColumns(this.setNewRoute),
    'route-Production': routeMeasuredProductionColumns,
    'route-Allocated-Production': routeAllocatedProductionColumns(
      this.setWellLevel
    ),
    'well-level-Production': collectionPointProductionColumns,
    'asset-Target': assetTargetColumns(
      this.setNewRoute,
      this.updateTableTarget
    ),
  };
  tableColumnsDashboard = {
    'asset-Production': assetMeasuredDashboardColumns(this.setNewAsset),
    'asset-Difference': assetDashboardDifferenceColumns(this.setNewAsset),
    'asset-Target': assetDashboardTargetColumns(this.setNewAsset),
  };

  componentDidMount = () => {
    if (this.props.shouldLoadData) {
      this.props.getSummaryTable();
      this.setState({ loaded: true });
    }
  };

  componentDidUpdate = () => {
    if (this.props.shouldLoadData && !this.state.loaded) {
      this.props.getSummaryTable();
      this.setState({ loaded: true });
    }
  };

  render() {
    const {
      currentSummaryCard,
      getSummary,
      getSummaryChart,
      getSummaryTable,
      getTargetHistory,
      isGuestUser,
      isReadOnlyUser,
      resetTargetRequestStatus,
      selectedProductionRoute,
      setCurrentSummaryCard,
      setNewTargetValues,
      setSummaryTableResolvedData,
      showCustomTargetModal,
      summaryData,
      summaryTableData,
      summaryTableRequestStatus,
      targetRequestStatus,
      targets,
      toggleCustomTargetModal,
      purgeCache,
      isSuperItendent,
      hierarchyLevel,
      isAllocatedProduction,
      assetLevelTargetStatus,
      routeLevelTargetStatus,
      showCustomRouteTargetModal,
      toggleCustomRouteTargetModal,
      userInfo
    } = this.props;
    const isProdEngineer = userInfo.roleTitle === 'Production Engineer';
    const dataType = getDataTypeForTableData(summaryTableData);
    const isAsset = AssetCheck(selectedProductionRoute);
    let cards = {
      production: 0,
      difference: 0,
      target: 0,
    };
    cards = getCardValues(summaryData, cards);
    let summaryTable = [];
    summaryTable = getSummaryData(
      isAsset,
      currentSummaryCard,
      dataType,
      summaryTableData,
      isSuperItendent,
      hierarchyLevel
    );
    const submitTargets = values => {
      purgeCache();
      setNewTargetValues(values);
      this.props.setAssetLevelPostTargetStatus();
      this.props.getAssetLevelTargets().then(() => {
        getSummary();
        getSummaryTable();
        getSummaryChart();
      });
    };

    const submitRouteTargets = values => {
      purgeCache();
      setNewTargetValues(values);
      this.props.setRouteLevelPostTargetStatus();
      this.props.getRouteLevelTargets().then(() => {
        getSummary();
        getSummaryTable();
        getSummaryChart();
      });
    };

    const customModalTitle = `${
      targets && targets.isCustom ? 'Edit' : 'Set'
    } Custom Production Team Targets`;

    const currentColumn = getCurrentColumn(
      isAsset,
      hierarchyLevel,
      isAllocatedProduction,
      currentSummaryCard
    );
    const columns = getColumnForSummaryTable(
      isSuperItendent,
      hierarchyLevel,
      this.tableColumnsDashboard[currentColumn],
      this.tableColumns[currentColumn]
    );
    // Difference Table has its own Default Sort.
    const defaultSorted =
      currentSummaryCard === prodSummaryCardTitles.difference
        ? [{ id: 'boed.difference', desc: true }]
        : [
            { id: 'route.name', desc: false },
            { id: 'boed._7DayDelta', desc: true },
          ];
    try {
      return (
        <div>
          <TableCards
            cards={[
              {
                title: `${isAllocatedProduction ? 'Allocated ' : 'Measured '} ${
                  prodSummaryCardTitles.measuredProduction
                }`,
                isDisabled:
                  summaryTableRequestStatus === requestStatuses.pending,
                number: cards.production,
                action: () => {
                  setCurrentSummaryCard(
                    prodSummaryCardTitles.measuredProduction
                  );
                  getSummaryTable();
                },
                isSelected:
                  currentSummaryCard ===
                  prodSummaryCardTitles.measuredProduction,
              },
              {
                title: prodSummaryCardTitles.target,
                isDisabled:
                  summaryData.type === summaryTypes.route ||
                  !isAsset ||
                  summaryTableRequestStatus === requestStatuses.pending ||
                  hierarchyLevel === hierarchyLevelConstants.wellLevel,
                number: cards.target,
                action: () => {
                  setCurrentSummaryCard(prodSummaryCardTitles.target);
                  getSummaryTable();
                  this.props.getAssetLevelTargets();
                  this.props.getRouteLevelTargets();
                },
                link: {
                  onClick: () => {
                    if (summaryData) {
                      toggleCustomTargetModal();
                    }
                  },
                  text: !isSuperItendent
                    ? !isGuestUser
                      ? !isReadOnlyUser                       
                        ? assetLevelTargetStatus === requestStatuses.success
                          ? 'Set Custom' 
                          :  ''
                        :  isProdEngineer ?'Set Custom': ''
                      : ''
                    : '',
                },
                isSelected: currentSummaryCard === prodSummaryCardTitles.target,
                hideRefresh: true,
              },
              {
                title: prodSummaryCardTitles.difference,
                isDisabled:
                  summaryData.type === summaryTypes.route ||
                  !isAsset ||
                  summaryTableRequestStatus === requestStatuses.pending ||
                  hierarchyLevel === hierarchyLevelConstants.wellLevel,
                number: cards.difference,
                isDifference: true,
                action: () => {
                  setCurrentSummaryCard(prodSummaryCardTitles.difference);
                  getSummaryTable();
                },
                isSelected:
                  currentSummaryCard === prodSummaryCardTitles.difference,
              },
              // hiding the Target history section that can be reused Later
              // {
              //   title: prodSummaryCardTitles.targetHistory,
              //   isDisabled:
              //     summaryTableRequestStatus === requestStatuses.pending,
              //   action: () => {
              //     setCurrentSummaryCard(prodSummaryCardTitles.targetHistory);
              //     getTargetHistory();
              //   },
              //   isSelected:
              //     currentSummaryCard === prodSummaryCardTitles.targetHistory,
              // },
            ]}
          >
            {currentSummaryCard === prodSummaryCardTitles.target &&
            !isSuperItendent &&
            !isGuestUser ? (
              !isReadOnlyUser ? (
                
                routeLevelTargetStatus === requestStatuses.success ? (
                  <button
                    className={styles.customRouteButtonLink}
                    onClick={() => {
                      toggleCustomRouteTargetModal();
                    }}
                  >
                    Set Custom Route
                  </button>
                ) :   ''
              ) :  isProdEngineer ?
              (
                <button
                  className={styles.customRouteButtonLink}
                  onClick={() => {
                    toggleCustomRouteTargetModal();
                  }}
                >
                  Set Custom Route
                </button>
              )
              
              : '' 
            ) : null}
          </TableCards>

          {showCustomTargetModal && (
            <Modal
              title={customModalTitle}
              onCloseClick={() => {
                this.props.setAssetTargetDetails([]);
                this.props.getAssetLevelTargets();
                toggleCustomTargetModal();
              }}
              size="modalContainerCustomAsset"
            >
              <CustomAssetTargetForm
                targets={targets}
                closeModal={() => {
                  this.props.setAssetTargetDetails([]);
                  this.props.getAssetLevelTargets();
                  toggleCustomTargetModal();
                }}
                submitTargets={submitTargets}
              />
            </Modal>
          )}

          {showCustomRouteTargetModal && (
            <Modal
              title={'Custom Route Production Team Targets'}
              onCloseClick={() => {
                this.props.setRouteTargetDetails([]);
                this.props.getRouteLevelTargets();
                toggleCustomRouteTargetModal();
              }}
              size={'modalContainerCustomAsset'}
            >
              <CustomRouteTargetForm
                targets={targets}
                closeModal={() => {
                  this.props.setRouteTargetDetails([]);
                  this.props.getRouteLevelTargets();
                  toggleCustomRouteTargetModal();
                }}
                submitTargets={submitRouteTargets}
              />
            </Modal>
          )}

          {targetRequestStatus === requestStatuses.success && (
            <SuccessModal
              title={modalSuccessTitle}
              toggleModal={resetTargetRequestStatus}
            />
          )}

          {summaryTableRequestStatus === requestStatuses.success &&
            currentSummaryCard !== prodSummaryCardTitles.targetHistory && (
              <div className={classnames(styles.reactTable, 'reactTable')}>
                <ReactTable
                  columns={columns}
                  data={summaryTable}
                  defaultPageSize={9999}
                  minRows={0}
                  showPagination={false}
                  resizable={false}
                  className={'summaryTable'}
                  defaultSorted={defaultSorted}
                  ref={r => {
                    if (r != null) {
                      if (!isAsset) {
                        // Include missing calculated delta data for routes
                        r.state.resolvedData.map(
                          well =>
                            (well.delta =
                              well['boed.measured'] - well['boed._7DayAverage'])
                        );
                      }
                      setSummaryTableResolvedData(r.state.resolvedData);
                    }
                  }}
                  NoDataComponent={() => (
                    // If response is empty, show No Content.
                    <NoContent
                      containerStyle={styles.noData}
                      type={'data available.'}
                    />
                  )}
                />
              </div>
            )}

          {summaryTableRequestStatus === requestStatuses.success &&
            currentSummaryCard === prodSummaryCardTitles.targetHistory && (
              <TargetHistorySection />
            )}

          {summaryTableRequestStatus !== requestStatuses.success && (
            <LoadingPage
              containerStyles={styles.loading}
              requestStatus={summaryTableRequestStatus}
              retryRequest={
                currentSummaryCard !== prodSummaryCardTitles.targetHistory
                  ? getSummaryTable
                  : getTargetHistory
              }
            />
          )}
        </div>
      );
    } catch (err) {
      appInsights.trackException({
        error: new Error(err),
        severityLevel: SeverityLevel.Error,
      });

      return (
        <LoadingPage
          containerStyles={styles.loading}
          requestStatus={requestStatuses.failure}
          retryRequest={() => {
            getSummary();
            getSummaryTable();
            getSummaryChart();
          }}
        />
      );
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SummaryCardTable);
