export const msalConfig = {
    auth: {
        env: process.env.REACT_APP_NODE_ENV,
        clientId: process.env.REACT_APP_AD_CLIENT_ID,
        tenantId: process.env.REACT_APP_AD_TENANT,
        authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AD_TENANT}`,
        redirectUri: process.env.REACT_APP_REDIRECT_URI,
    },
    cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const apiScope = {
    scopes: [process.env.REACT_APP_API_SCOPE]
};
