import React from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import { selectCurrentLpoEventsCard, selectFilteredLpoTable } from 'store/reducers/production';
import styles from './styles.module.css';
import { mapOpenLpoEventToCsv, mapShutInWellsToCsv } from 'utils/objectCsvMapping';
import { appInsights } from 'App';
import { aiEvents } from 'constants/aiEvents';
import { prodLpoEventsCardTitles } from 'constants/productionManagement';

const mapStateToProps = state => ({
  tableData: selectFilteredLpoTable(state),
  filename: selectCurrentLpoEventsCard(state)
});

export const LpoEventsExportCSV = ({ filename, tableData }) => (

  <CSVLink
    data={
      filename === prodLpoEventsCardTitles.shutInWells
        ? mapShutInWellsToCsv(tableData)
        : mapOpenLpoEventToCsv(tableData)
    }
    className={styles.csvLink}
    filename={`${filename.replace(/ /g, '_')}.csv`}
  >
    <div
      className={styles.exportButton}
      onClick={() => {
        appInsights.trackEvent({ name: aiEvents.exportLpoEventsTable });
      }}
    >
      export table
    </div>
  </CSVLink>
);

export default connect(mapStateToProps)(LpoEventsExportCSV);
