export const actionItemStatusSteps = [
    'Action Item Creation', 
    'ALCR Approval',
    'Action Item Review',
    'Complete or Cancelled (Canceled Case, System Closed, User Closed Action Item)'
  ];

  export const witStatusSteps = [
    'Pending-ALCRReview',
    'Pending Land-PE',
    'Pending-WIEReview',
    'Pending Approval',
    'Pending-Economics/ AFE',
    'Pending-Pre Scheduling',
    'Pending-Execution',
    'Pending- JobClose',
    'Pending-Tour'
  ]

  export const prStatusSteps = [
    'ALCR Review',
    'Land/PE Review',
    'Planner Review',
    'WBS Review',
    'Pre-scheduling',
    'Active Schedule',
    'Pending-Tour'
  ]
  export const workOrderRequestSteps =[
    'Q - Work request',
    'SJOB - Scoping / Planning',
    'R - Ready To Schedule and Open',
    'SCH - Scheduled',
    'WM - Waiting on Materials',
    'D - Waiting Work Order Completion'
  ]