import React from 'react';
import styles from './styles.module.css';
import { connect } from 'react-redux';
import moment from 'moment';
import { selectControlTimePeriod, setControlTimePeriod, getUserControlItems, initPillarCounts } from 'store/reducers/controls';
// SVGs
import { ReactComponent as ChevronLeftSVG } from 'assets/img/chevron-left.svg';
import { ReactComponent as ChevronRightSVG } from 'assets/img/chevron-right.svg';
import { ReactComponent as ChevronRightDisabledSVG } from 'assets/img/chevron-right-grey.svg';
import { ReactComponent as CalendarIconSVG } from 'assets/img/icon-calendar.svg';

const mapStateToProps = state => {
  const { year, month } = selectControlTimePeriod(state);
  return { year, month };
};

const mapDispatchToProps = {
  setControlTimePeriod,
  getUserControlItems,
  initPillarCounts
};

const increaseMonth = (year, month) => {
  if (month === 11) {
    month = 0;
    year += 1;
  } else {
    month += 1;
  }
  return { year, month };
};

const decreaseMonth = (year, month) => {
  if (month === 0) {
    month = 11;
    year -= 1;
  } else {
    month -= 1;
  }
  return { year, month };
};

export const MonthPicker = ({ year, month,...props }) => {
  const currentYear = moment().year();
  const currentMonth = moment().month();
  const initializePillarItemWithCounts = () =>{
    props.getUserControlItems().then(({ type }) => {
      // If the action was a success, generate pillars state and init pillar counts
      if (!!type && (typeof type === 'string') && type.includes('SUCCESS')) {
        props.initPillarCounts();
      }
    });
  }
  const canIncrease = currentMonth !== 11 ? !(year === currentYear && month === currentMonth + 1) :
    !(year === currentYear + 1 && month === 0);
  const { year: decreasedYear, month: decreasedMonth } = decreaseMonth(year, month);
  const { year: increasedYear, month: increasedMonth } = increaseMonth(year, month);
  return (
    <div className={styles.container}>
      <div className={styles.chevronLeft}>
        {(
          <ChevronLeftSVG
            onClick={() => {
              props.setControlTimePeriod(decreasedYear, decreasedMonth);
              initializePillarItemWithCounts()


            }}
          />
        )}
      </div>
      <div className={styles.iconAndText}>
        <div className={styles.calendarIcon}>
          <CalendarIconSVG />
        </div>
        <div className={styles.monthYearText}>
          {moment.months(month).toLowerCase()} {year}
        </div>
      </div>
      <div className={styles.chevronRight}>
        {canIncrease ? (
          <ChevronRightSVG
            onClick={() => {
              props.setControlTimePeriod(increasedYear, increasedMonth);
              initializePillarItemWithCounts();
            }
            }
          />
        ) : (
          <ChevronRightDisabledSVG />
        )}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MonthPicker);
