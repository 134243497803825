import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ChevronDown from 'assets/img/chevron-down.svg';
import Select, { components } from 'react-select';
import styles from './styles.module.css';

const customStyles = {
  multiValue: provided => {
    return {
      ...provided,
      backgroundColor: 'rgba(0, 102, 178, 0.2)'
    };
  },
  multiValueLabel: provided => {
    return {
      ...provided,
      color: 'var(--blue-deepSea)',
      fontFamily: 'Gotham',
      fontSize: 16,
      fill: 'var(--blue-deepSea)'
    };
  },
  valueContainer: provided => {
    return {
      ...provided,
      color: 'var(--blue-deepSea)'
    };
  },
  option: provided => {
    return {
      ...provided,
      borderBottom: '1px solid var(--grey-metal)',
      color: 'var(--grey-charcoal)',
      fontFamily: 'Gotham',
      fontSize: 16,
      padding: 14
    };
  },
  indicatorSeparator: () => {
    return {
      display: 'none'
    };
  },
  clearIndicator: () => {
    return {
      display: 'none'
    };
  },
  dropdownIndicator: provided => {
    return {
      ...provided,
      color: 'var(--blue-deepSea)',
      cursor: 'pointer',
      fill: 'var(--blue-deepSea)',
      marginRight: 2,
      stroke: 'var(--blue-deepSea)',
      padding: 0
    };
  },
  control: (provided, { hasValue }) => {
    let fontStyle = 'normal';
    if (!hasValue) {
      fontStyle = 'italic';
    }

    return {
      ...provided,
      border: '1px solid var(--grey-metal)',
      borderColor: 'var(--grey-metal)',
      boxShadow: 'none',
      fontStyle,

      '&:hover': {
        borderColor: 'var(--grey-metal)',
        boxShadow: 'none'
      }
    };
  },
  menu: provided => {
    return {
      ...provided,
      fontFamily: 'Gotham',
      zIndex: 4
    };
  }
};

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={ChevronDown} alt={props.label} />
    </components.DropdownIndicator>
  );
};

class ReactSelectInput extends Component {
  componentWillUnmount = () => {
    if (!this.props.disableReset) this.props.resetAction();
  };

  mapOptions = options => {
    let mappedOptions = options;
    if (typeof options[0] !== 'object') {
      mappedOptions = options.map(option => ({
        label: option,
        value: option
      }));

      if (this.props.showPlaceholder) {
        mappedOptions.unshift({ label: 'Select Option', value: '' });
      }
    } else {
      if (
        this.props.showPlaceholder &&
        this.props.placeholderText &&
        mappedOptions[0].label !== this.props.placeholderText
      ) {
        mappedOptions.unshift({ label: this.props.placeholderText, value: '' });
      }
    }
    return mappedOptions;
  };

  render = () => {
    const {
      disabled,
      options,
      placeholder,
      setAction,
      label,
      value,
      fillSpace,
      isMulti,
      resetAction
    } = this.props;
    const isMultiProvided = isMulti ? isMulti :false;
    return (
      <div className={!fillSpace ? styles.defaultStyles : styles.fullStyle}>
        <Select
          className={styles.selectBox}
          styles={customStyles}
          isDisabled={disabled}
          onChange={selected => setAction(selected)}
          value={value}
          aria-label={label || 'Select'}
          options={this.mapOptions(options)}
          isMulti={isMultiProvided}
          resetAction={resetAction}
          placeholder={placeholder}
          closeMenuOnSelect={true}
          components={{ DropdownIndicator }}
          blurInputOnSelect={false}
          autoFocus={false}
          isSearchable={false}
          maxMenuHeight={250}
        />
      </div>
    );
  };
}

ReactSelectInput.propTypes = {
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        option: PropTypes.string,
        value: PropTypes.string
      })
    ).isRequired,
    PropTypes.arrayOf(PropTypes.string).isRequired
  ]).isRequired,
  setAction: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
    PropTypes.object.isRequired,
    PropTypes.array.isRequired
  ]),
  label: PropTypes.string,
  fillSpace: PropTypes.bool
};

export default ReactSelectInput;
