import React from 'react';
import Downtime from 'components/ExceptionManagementPage/Downtime';
import styles from './styles.module.css';
import classnames from 'classnames';
import { Link, Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import { routes } from 'constants/routes.js';
import { links } from 'constants/exceptionManagement.js';

export const ExceptionManagementPage = ({ location }) => {
  return (
    <div className={styles.scrollableContainer}>
      <div className={styles.navBar}>
        {links.map(link => {
          // Determine if the home link (exception management parent) is the current location
          const isHomeLink =
            location.pathname === `${routes.exceptionManagementParent}/` ||
            location.pathname === routes.exceptionManagementParent;

          // Set home link active if the location is the home link and the link we're mapping over is the home link.
          const isHomeLinkActive =
            isHomeLink && link.url === routes.exceptionManagement.downtime;

          return (
            <Link
              key={link.url}
              className={classnames(styles.link, {
                [styles.active]:
                link.url === location.pathname || isHomeLinkActive
              })}
              to={link.url}
            >
              {link.routeName}
            </Link>
          );
        })}
      </div>
      <div className={styles.content}>
        <Route
          exact
          path={routes.exceptionManagementParent}
          component={() => <Downtime />}
        />
        <Route
          exact
          path={routes.exceptionManagement.downtime}
          component={() => <Downtime />}
        />
      </div>
    </div>
  );
};

export default withRouter(ExceptionManagementPage);
