import React from 'react';
import PropTypes from 'prop-types';
import TextAreaInput from 'components/common/TextAreaInput';
import styles from 'components/AdminPage/styles.module.css';

export const ModalTextAreaInput = ({
  fullWidth,
  label,
  placeholder,
  setAction,
  state,
  stateKey
}) => {
  const { errorText, valid, value } = state;

  return (
    <div className={!fullWidth ? styles.basis : styles.fullBasis}>
      <label className={styles.label}>
        {label}
        {!valid && <span className={styles.error}>{errorText}</span>}
      </label>
      <TextAreaInput
        setAction={value => setAction(stateKey, value)}
        value={value}
        fullWidth={fullWidth}
        placeholder={placeholder}
      />
    </div>
  );
};

ModalTextAreaInput.propTypes = {
  fullWidth: PropTypes.bool,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  setAction: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
  stateKey: PropTypes.string.isRequired
};
