import { authorizedRequest, checkApiResponse, checkCache } from 'api/request';
import { POST, GET } from 'api/methods';
import {
  selectCurrentLpoEventsCard,
  selectCurrentSummaryCard,
  selectNewTargetValues,
  selectProductionDate,
  selectTableTargets,
  selectTargetHistoryDaysAgo,
  selectCustomTargetAssetName,
  selectIsAllocatedProduction,
  selectHierarchy,
  selectCollectionPointId,
  selectAssetTargetInputBody,
  selectRouteTargetInputBody,
  selectTargetType,
} from 'store/reducers/production';

import {
  selectCurrentAsset,
  selectCurrentRoute,
  selectCurrentAssetIdException,
  selectCurrentRouteLPO,
} from 'store/reducers/navigation';
import {
  lpoEventsApiCalls,
  prodSummaryCardTitles,
  summaryApiCalls,
  hierarchyLevelConstants,
} from 'constants/productionManagement';
import AssetCheck from 'utils/assetCheck';

const baseUrl = '/productionmanagement';

const defaultResponse = {};
const defaultResponseArr = [];

const returnProductionDate = (productionDate) => {
  return productionDate ? `?productionDate=${productionDate}&` : '?'
}
const returnRouteId = (routeId) => {
  return routeId ? `?routeId=${routeId}` : ''
}


export const apiEndpoints = {
  getAssetSummary: (assetId, productionDate, isAllocatedProduction, targetType) =>
    `${baseUrl}/summary/asset/${assetId}${returnProductionDate(productionDate)
    }isAllocatedProduction=${isAllocatedProduction}&isAssetTarget=${targetType}`,
  getRouteSummary: (routeId, productionDate, isAllocatedProduction) =>
    `${baseUrl}/summary/route/${routeId}${returnProductionDate(productionDate)
    }isAllocatedProduction=${isAllocatedProduction}`,
  getCollectionPointSummary: (
    collectionPointId,
    productionDate,
    isAllocatedProduction
  ) =>
    `${baseUrl}/summary/collectionpoint/${collectionPointId}${returnProductionDate(productionDate)
    }isAllocatedProduction=${isAllocatedProduction}`,
  getAssetSummaryTable: (
    assetId,
    productionDate,
    type,
    isAllocatedProduction,
    targetType
  ) =>
    `${baseUrl}/summary/asset/${assetId}/children/${type}${returnProductionDate(productionDate)
    }isAllocatedProduction=${isAllocatedProduction}&isAssetTarget=${targetType}`,
  getRouteSummaryTable: (
    routeId,
    productionDate,
    type,
    isAllocatedProduction
  ) =>
    `${baseUrl}/summary/route/${routeId}/children/${type}${returnProductionDate(productionDate)
    }isAllocatedProduction=${isAllocatedProduction}`,
  getCollectionPointSummaryTable: (
    collectionPointId,
    productionDate,
    type,
    isAllocatedProduction
  ) =>
    `${baseUrl}/summary/collectionpoint/${collectionPointId}/children/${type}${returnProductionDate(productionDate)
    }isAllocatedProduction=${isAllocatedProduction}`,
  getAssetTargetHistory: (assetId, daysAgo) =>
    `${baseUrl}/targethistory?assetId=${assetId}&daysAgo=${daysAgo}`,
  getRouteTargetHistory: (routeId, daysAgo) =>
    `${baseUrl}/targethistory?routeId=${routeId}&daysAgo=${daysAgo}`,
  getCollectionPointTargetHistory: (collectionPointId, daysAgo) =>
    `${baseUrl}/targethistory?collectionpointId=${collectionPointId}&daysAgo=${daysAgo}`,
  getAssetSummaryChart: (assetId, productionDate, isAllocatedProduction, targetType) =>
    `${baseUrl}/summary/asset/${assetId}/chart${returnProductionDate(productionDate)
    }isAllocatedProduction=${isAllocatedProduction}&isAssetTarget=${targetType}`,
  getRouteSummaryChart: (routeId, productionDate, isAllocatedProduction) =>
    `${baseUrl}/summary/route/${routeId}/chart/${returnProductionDate(productionDate)
    }isAllocatedProduction=${isAllocatedProduction}`,
  getCollectionPointSummaryChart: (
    collectionPointId,
    productionDate,
    isAllocatedProduction
  ) =>
    `${baseUrl}/summary/collectionpoint/${collectionPointId}/chart/${returnProductionDate(productionDate)
    }isAllocatedProduction=${isAllocatedProduction}`,
  postAssetAdjustTarget: assetId =>
    `${baseUrl}/summary/asset/${assetId}/adjust-targets`,
  saveTableTargets: `${baseUrl}/summary/routes/adjust-targets`,
  getLpoEventsTable: (tableType, assetId, routeId) =>
    `${baseUrl}/lpo/${assetId}/${tableType}${returnRouteId(routeId)
    }`,
  getLpoEventsInfo: (assetId, routeId) =>
    `${baseUrl}/lpo/${assetId}${returnRouteId(routeId)}`,
  getassetLevelTargets: `${baseUrl}/summary/asset/adjust-monthly-targets`,
  getRouteLevelTargets: `${baseUrl}/summary/routes/adjust-route-targets`,
};
const hierarchyLevelTwoFunctionSummary = (hierarchy, collectionPointId, productionDate, isAllocatedProduction, routeId) => {
  return hierarchy === hierarchyLevelConstants.wellLevel
    ? apiEndpoints.getCollectionPointSummary(
      collectionPointId,
      productionDate,
      isAllocatedProduction
    )
    : apiEndpoints.getRouteSummary(
      routeId,
      productionDate,
      isAllocatedProduction
    );
}
const hierarchyLevelTwoFunctionSummaryTable = (hierarchy, collectionPointId, productionDate, isAllocatedProduction, routeId, tableType) => {
  return hierarchy === hierarchyLevelConstants.wellLevel
    ? apiEndpoints.getCollectionPointSummaryTable(
      collectionPointId,
      productionDate,
      tableType,
      isAllocatedProduction
    )
    : apiEndpoints.getRouteSummaryTable(
      routeId,
      productionDate,
      tableType,
      isAllocatedProduction
    );
}
const hierarchyLevelTwoFunctionTargetHistory = (hierarchy, collectionPointId, daysAgo, routeId) => {
  return hierarchy === hierarchyLevelConstants.wellLevel
    ? apiEndpoints.getCollectionPointTargetHistory(collectionPointId, daysAgo)
    : apiEndpoints.getRouteTargetHistory(routeId, daysAgo);
}
const hierarchyLevelTwoSummaryChart = (hierarchy, collectionPointId, routeId, productionDate, isAllocatedProduction) => {
  return hierarchy === hierarchyLevelConstants.wellLevel
    ? apiEndpoints.getCollectionPointSummaryChart(
      collectionPointId,
      productionDate,
      isAllocatedProduction
    )
    : apiEndpoints.getRouteSummaryChart(
      routeId,
      productionDate,
      isAllocatedProduction
    );
}
export const saveTableTargets = async ({ getState }) => {
  const tableTargets = selectTableTargets(getState());

  const body = Object.keys(tableTargets)
    .map(key => {
      const { routeId, oilTarget, gasTarget, waterTarget } = tableTargets[key];
      return {
        oilTarget,
        gasTarget,
        waterTarget,
        routeId,
      };
    })
    .filter(target => !!target.routeId);

  const stringifiedBody = body;

  return authorizedRequest(
    apiEndpoints.saveTableTargets,
    POST,
    stringifiedBody
  ).then(response => checkApiResponse(response));
};


export const getSummary = async ({
  getState,
  cache,
  cacheStamp,
  endpointCapture,
}) => {
  const assetId = selectCurrentAsset(getState());
  const routeId = selectCurrentRoute(getState());
  const isAllocatedProduction = selectIsAllocatedProduction(getState());
  const productionDate = selectProductionDate(getState());
  const hierarchy = selectHierarchy(getState());
  const collectionPointId = selectCollectionPointId(getState());
  const targetType = selectTargetType(getState());
  const isAsset = AssetCheck(routeId);

  const endpoint = isAsset
    ? apiEndpoints.getAssetSummary(
      assetId,
      productionDate,
      isAllocatedProduction,
      targetType
    )
    : hierarchyLevelTwoFunctionSummary(hierarchy, collectionPointId, productionDate, isAllocatedProduction, routeId)
  return checkCache(
    cache,
    cacheStamp,
    endpoint,
    endpointCapture,
    defaultResponse
  );
};

export const getSummaryTable = async ({
  getState,
  cache,
  cacheStamp,
  endpointCapture,
}) => {
  const assetId = selectCurrentAsset(getState());
  const routeId = selectCurrentRoute(getState());
  const currentSummaryCard = selectCurrentSummaryCard(getState());
  const tableType = summaryApiCalls[currentSummaryCard];
  const isAsset = AssetCheck(routeId);
  const isAllocatedProduction = selectIsAllocatedProduction(getState());
  const productionDate = selectProductionDate(getState());
  const hierarchy = selectHierarchy(getState());
  const collectionPointId = selectCollectionPointId(getState());
  const targetType = selectTargetType(getState());

  const endpoint = isAsset
    ? apiEndpoints.getAssetSummaryTable(
      assetId,
      productionDate,
      tableType,
      isAllocatedProduction,
      targetType
    )
    : hierarchyLevelTwoFunctionSummaryTable(hierarchy, collectionPointId, productionDate, isAllocatedProduction, routeId, tableType)
  if (currentSummaryCard === prodSummaryCardTitles.targetHistory) {
    return getTargetHistory({ getState });
  }
  return checkCache(
    cache,
    cacheStamp,
    endpoint,
    endpointCapture,
    defaultResponseArr
  );
};
export const getTargetHistory = async ({ getState }) => {
  const assetId = selectCurrentAsset(getState());
  const routeId = selectCurrentRoute(getState());
  const daysAgo = selectTargetHistoryDaysAgo(getState());
  const isAsset = AssetCheck(routeId);
  const hierarchy = selectHierarchy(getState());
  const collectionPointId = selectCollectionPointId(getState());
  const endpoint = isAsset
    ? apiEndpoints.getAssetTargetHistory(assetId, daysAgo)
    : hierarchyLevelTwoFunctionTargetHistory(hierarchy, collectionPointId, daysAgo, routeId);
  return authorizedRequest(endpoint, GET, null).then(response =>
    checkApiResponse(response)
  );
};

export const getSummaryChart = async ({
  getState,
  cache,
  cacheStamp,
  endpointCapture,
}) => {
  const assetId = selectCurrentAsset(getState());
  const routeId = selectCurrentRoute(getState());
  const isAllocatedProduction = selectIsAllocatedProduction(getState());
  const productionDate = selectProductionDate(getState());
  const isAsset = AssetCheck(routeId);
  const hierarchy = selectHierarchy(getState());
  const collectionPointId = selectCollectionPointId(getState());
  const targetType = selectTargetType(getState());

  const endpoint = isAsset
    ? apiEndpoints.getAssetSummaryChart(
      assetId,
      productionDate,
      isAllocatedProduction,
      targetType
    )
    : hierarchyLevelTwoSummaryChart(hierarchy, collectionPointId, routeId, productionDate, isAllocatedProduction);
  return checkCache(
    cache,
    cacheStamp,
    endpoint,
    endpointCapture,
    defaultResponse
  );
};

export const postAssetAdjustTarget = async ({ getState }) => {
  const assetId = selectCustomTargetAssetName(getState());
  const newTargetValues = selectNewTargetValues(getState());
  const body = newTargetValues;

  return authorizedRequest(
    apiEndpoints.postAssetAdjustTarget(assetId),
    POST,
    body
  ).then(response => checkApiResponse(response));
};

export const getLpoEventsTable = async ({
  getState,
  cache,
  cacheStamp,
  endpointCapture,
}) => {
  const assetId = selectCurrentAssetIdException(getState());
  const routeId = selectCurrentRouteLPO(getState());
  const isAsset = AssetCheck(routeId);
  const currentLpoCard = selectCurrentLpoEventsCard(getState());
  const tableType = lpoEventsApiCalls[currentLpoCard];
  const endpoint = isAsset
    ? apiEndpoints.getLpoEventsTable(tableType, assetId)
    : apiEndpoints.getLpoEventsTable(tableType, assetId, routeId);
  return checkCache(
    cache,
    cacheStamp,
    endpoint,
    endpointCapture,
    defaultResponse
  );
};

export const getLpoEventsInfo = async ({
  getState,
  cache,
  cacheStamp,
  endpointCapture,
}) => {
  const assetId = selectCurrentAssetIdException(getState());
  const routeId = selectCurrentRouteLPO(getState());
  const isAsset = AssetCheck(routeId);
  const endpoint = isAsset
    ? apiEndpoints.getLpoEventsInfo(assetId)
    : apiEndpoints.getLpoEventsInfo(assetId, routeId);
  return checkCache(
    cache,
    cacheStamp,
    endpoint,
    endpointCapture,
    defaultResponse
  );
};

export const getAssetLevelTargets = async ({ getState }) => {
  const assetArr = selectCurrentAsset(getState());
  const bodyArr = selectAssetTargetInputBody(getState());
  const body =
    bodyArr.length <= 0
      ? assetArr.split(',').map(key => {
        return {
          assetId: key,
          assetName: '',
          yearlyDto: [],
        };
      })
      : bodyArr;
  const stringifiedBody = body;

  return authorizedRequest(
    apiEndpoints.getassetLevelTargets,
    POST,
    stringifiedBody
  ).then(response => checkApiResponse(response));
};

export const getRouteLevelTargets = async ({ getState }) => {
  const assetArr = selectCurrentAsset(getState());
  const bodyArr = selectRouteTargetInputBody(getState());
  const body =
    bodyArr.length <= 0
      ? assetArr.split(',').map(key => {
        return {
          assetId: key,
          assetName: '',
          routeLevelData: [],
        };
      })
      : bodyArr;

  const stringifiedBody = body;

  return authorizedRequest(
    apiEndpoints.getRouteLevelTargets,
    POST,
    stringifiedBody
  ).then(response => checkApiResponse(response));
};
