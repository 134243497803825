import React, { Component } from 'react';
import styles from './styles.module.css';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { formatNumber } from 'utils/formatNumber';

export class TableCards extends Component {
  render() {
    const { cards, children, isCentered } = this.props;

    return (
      <div
        className={classnames(styles.cardContainer, {
          [styles.cardContainerCenter]: isCentered
        })}
      >
        {cards.map(card => {
          // Because Javascript
          const noNumber = !card.number && card.number !== 0;

          return (
            <div
              className={classnames(styles.card, {
                [styles.centered]: noNumber,
                [styles.longerCard]: card.width === 'longer',
                [styles.primary]: card.width === 'primary',
                [styles.secondary]: card.width === 'secondary',
                [styles.disabled]: card.isDisabled,
                [styles.selectedCard]: card.isSelected
              })}
              key={card.title}
              onClick={
                !card.isSelected && !card.isDisabled ? card.action : null
              }
            >
              {!!card.new && card.new > 0 && (
                <div className={styles.newBubble}>
                  <span
                    className={
                      card.new < 100
                        ? styles.bubbleNumber
                        : styles.bubbleNumberSmall
                    }
                  >
                    {card.new}
                  </span>
                </div>
              )}

              <span
                className={classnames(styles.title, {
                  [styles.titlePrimary]: card.width === 'primary',
                  [styles.titleSecondary]: card.width === 'secondary'
                })}
              >
                {card.title}
                {!noNumber && ':'}
              </span>

              {!!card.comingSoon ? (
                <span
                  className={classnames(styles.formattedNumber, {
                    [styles.formattedNumberPrimary]: card.width === 'primary',
                    [styles.formattedNumberSecondary]:
                    card.width === 'secondary'
                  })}
                >
                  Coming Soon
                </span>
              ) : (
                !noNumber && (
                  <span
                    className={classnames(styles.formattedNumber, {
                      [styles.green]: card.number > 0 && card.isDifference,
                      [styles.red]: card.number < 0 && card.isDifference,
                      [styles.formattedNumberPrimary]: card.width === 'primary',
                      [styles.formattedNumberSecondary]:
                      card.width === 'secondary'
                    })}
                  >
                    {formatNumber(card.number)}
                  </span>
                )
              )}

              {!!card.link && card.isSelected && !card.isDisabled && (
                <span className={styles.link} onClick={card.link.onClick}>
                  {card.link.text}
                </span>
              )}

              {!!card.link &&
              card.isSelected &&
              !card.isDisabled &&
              !card.hideRefresh && (
                <span className={styles.separator}>/</span>
              )}

              {card.isSelected && !card.isDisabled && !card.hideRefresh && (
                <span className={styles.refresh} onClick={card.action}>
                  refresh
                </span>
              )}
            </div>
          );
        })}
        {children && <div className={styles.childCard}>{children}</div>}
      </div>
    );
  }
}

TableCards.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.func.isRequired,
      comingSoon: PropTypes.bool,
      isDifference: PropTypes.bool,
      isDisabled: PropTypes.bool,
      isSelected: PropTypes.bool,
      link: PropTypes.shape({
        onClick: PropTypes.func.isRequired,
        text: PropTypes.string.isRequired
      }),
      new: PropTypes.number,
      number: PropTypes.number,
      overrideStyles: PropTypes.string,
      title: PropTypes.string.isRequired,
      width: PropTypes.string
    })
  ).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  isCentered: PropTypes.bool,
  hideRefresh: PropTypes.bool
};

export default TableCards;
