import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const Button = ({ click, text, type, disabled }) => {
  const buttonStyles = {
    cancel: 'cancelButton',
    cancelText: 'cancelText',
    clear: 'clear',
    commentSave: 'commentSave',
    feedback: 'feedback',
    newRecord: 'newRecord',
    newRecordRole: 'newRecordRole',
    taskComplete: 'taskComplete',
    taskListLoadMore: 'taskListLoadMore',
    taskSave: 'taskSave',
    thresholdSave: 'thresholdSave',
    controlPrimary: 'controlPrimary',
    controlSecondary: 'controlSecondary'
  };
  return (
    <div
      className={`${styles[buttonStyles[type]]} ${
        disabled ? styles.disabled : ''
      }`}
      onClick={disabled ? null : click}
    >
      {text}
    </div>
  );
};

Button.propTypes = {
  click: PropTypes.func,
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default Button;
