import React, { Component } from 'react';
import styles from './styles.module.css';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  getSummary,
  getSummaryChart,
  getSummaryTable,
  selectCurrentSummaryData,
  selectProductionDate,
  setProductionDate
} from 'store/reducers/production';
import SelectInput from 'components/common/SelectInput';

const mapStateToProps = state => ({
  currentProductionSummary: selectCurrentSummaryData(state),
  currentProductionDate: selectProductionDate(state)
});

const mapDispatchToProps = {
  getSummary,
  setProductionDate,
  getSummaryTable,
  getSummaryChart
};

export class ProdDaySelect extends Component {
 
  render() {
    const {
      currentProductionDate,
      currentProductionSummary,
      getSummary,
      getSummaryTable,
      getSummaryChart,
      setProductionDate
    } = this.props;
    return (
      <div className={styles.prodDayComponent}>
        <span className={styles.label}>Production Day</span>
        {!!currentProductionSummary.productionDaysAvailable &&
        currentProductionSummary.productionDaysAvailable.length > 0 && (
          <SelectInput
            options={currentProductionSummary.productionDaysAvailable.map(
              prodDay => {
                return {
                  name: moment(prodDay).format('MM/DD/YYYY'),
                  value: prodDay
                };
              }
            )}
            resetAction={() => {
              return;
            }}
            noSort ={true}
            setAction={productionDay => {
                setProductionDate(productionDay);
                getSummary();
                getSummaryTable();
                getSummaryChart();
              
            }}
            value={currentProductionDate}
            showPlaceholder={false}
            label={'Production Day'}
           />
        )}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProdDaySelect);
