import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';
import { requestStatuses } from 'constants/values';
import spinner from 'assets/img/spinner.gif';

const loadingText = {
  failure: 'Oh no! We\'ve had a problem loading content.',
  pending: 'Loading',
  retry: 'try again',
  notFound: 'No data was found.'
};

const LoadingPage = ({ containerStyles, requestStatus, retryRequest }) => (
  <div className={containerStyles}>
    <div className={styles.status}>
      {requestStatus === requestStatuses.pending ? (
        <Fragment>
          <img src={spinner} alt="loading spinner" className={styles.spinner} />
          {loadingText.pending}
        </Fragment>
      ) : (
        <div className={styles.retryContainer}>
          {requestStatus === requestStatuses.failure && loadingText.failure}
          {requestStatus === requestStatuses.notFound && loadingText.notFound}
          {requestStatus !== requestStatuses.notFound && retryRequest && (
            <div className={styles.retryButton} onClick={retryRequest}>
              {loadingText.retry}
            </div>
          )}
        </div>
      )}
    </div>
  </div>
);

LoadingPage.propTypes = {
  containerStyles: PropTypes.string,
  retryRequest: PropTypes.func
};

export default LoadingPage;
