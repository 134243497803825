import { controlStatuses } from 'constants/controls';
import moment from 'moment';

const CURRENT_YEAR = moment().year();
const CURRENT_MONTH = moment().month();

export const sortControls = (controls, year, month) => {
  let sortedCategories;
  if (year === CURRENT_YEAR && month === CURRENT_MONTH) {
    sortedCategories = controls.reduce((sortedCategories, controlItem) => {
      if (controlItem.occurrence.isOverdue && controlItem.occurrence.status !== controlStatuses.complete) {
        sortedCategories.overdue.push(controlItem);
      } else if (controlItem.occurrence.status === controlStatuses.complete) {
        sortedCategories.complete.push(controlItem);
      } else {
        sortedCategories.incomplete.push(controlItem);
      }
      return sortedCategories;
    }, { incomplete: [], complete: [], overdue: [] });
    return [
      ...sortedCategories.incomplete.sort(sortByFrequency),
      ...sortedCategories.complete.sort(sortByFrequency),
      ...sortedCategories.overdue.sort(sortByFrequency)
    ];
  } else {
    sortedCategories = controls.reduce((sortedCategories, controlItem) => {
      if (controlItem.occurrence.status === controlStatuses.complete) {
        sortedCategories.complete.push(controlItem);
      } else {
        sortedCategories.incomplete.push(controlItem);
      }
      return sortedCategories;
    }, { complete: [], incomplete: [] });
    return [
      ...sortedCategories.complete.sort(sortByFrequency),
      ...sortedCategories.incomplete.sort(sortByFrequency)
    ]
  }
};

const sortByFrequency = (controlItemA, controlItemB) => {
  const { sortorder: sortorderA } = controlItemA;
  const { sortorder: sortorderB } = controlItemB;

  const frequencyCompare = (a, b) =>
    a < b ?
      -1 :
      a > b ?
        1 : 0;

  return sortorderA === sortorderB ?
    frequencyCompare(sortorderA, sortorderB) :
    sortorderA < sortorderB ? -1 : 1;
};
