import React from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import { selectData, selectActiveTool } from 'store/reducers/admin';
import styles from './styles.module.css';
import { flattenObject } from 'utils/flatten';
import moment from 'moment';
import { appInsights } from 'App';
import { aiEvents } from 'constants/aiEvents';

const mapStateToProps = state => ({
  tableData: selectData(state),
  filename: selectActiveTool(state),
});

export const ExportAdminCSV = ({ filename, tableData }) => {
  const buttonText =
    filename === 'users' ? 'export admin usage report' : 'export table';
  let tableDataUsers = [];
  if (filename === 'users') {
    tableDataUsers = tableData.map(a => {
      let assets = [];
      assets = a.userAssets
        ? a.userAssets.map(b => {
            return b.name;
          })
        : null;
      const assetStr = assets ? assets.join(',') : null;
      const active = a.isActive ? 'Active' : 'Inactive';
      return {
        'User Name': a.firstName + '' + a.lastName,
        'User Email': a.email,
        Role: a.roleTitle,
        'Asset(s)': assetStr,
        'Last Log in date': moment(a.lastLoginDate).format('M/D/YYYY'),
        'Days since last log in': a.daysSinceLastLogin,
        'Active/Inactive' : active
      };
    });
  } else {
    tableDataUsers = tableData;
  }
  return (
    <CSVLink
      data={tableDataUsers.map(row => flattenObject(row))}
      className={styles.csvLink}
      filename={`${filename.replace(/ /g, '_')}.csv`}
    >
      <div
        className={
          filename === 'users' ? styles.exportButtonUsers : styles.exportButton
        }
        onClick={() => {
          appInsights.trackEvent({ name: aiEvents.exportAdminTable });
        }}
      >
        {buttonText}
      </div>
    </CSVLink>
  );
};

export default connect(mapStateToProps)(ExportAdminCSV);
