import React, { Component } from 'react';
import styles from './styles.module.css';
import { connect } from 'react-redux';
import Button from 'components/common/Button';
import RequiredText from 'components/common/RequiredText';
import { selectUserInfo } from 'store/reducers/users';
import {
  putThresholdAdjust,
  selectDowntimeThresholds,
  selectNewThresholdCategoryValue,
  selectNewThresholdValues,
  selectThresholdRequestStatus,
  setNewThresholdCategoryValue,
  setNewThresholdValues,
  toggleThresholdAdjustModal,
  setTimeValue,
  getExceptionDowntimeGeneralInfo,
  selectThresholdTimeValueObject,
  getExceptionDowntimeThresholds,
  getExceptionDowntimeTable,
} from 'store/reducers/exception';
import { purgeCache } from 'store/reducers/cache';
import { selectCurrentAssetName,selectCurrentAssetException } from 'store/reducers/navigation';
import {
  downtimeCardTitles,
  secondaryHeirarchy,
  selectPrimaryCards,
  surfaceCategories,
  subsurfaceCategories,
  thresholdAdjustModalLabels,
} from 'constants/exceptionManagement.js';
import classnames from 'classnames';
import { determineInvalidThresholdDays } from 'utils/validation';
import SelectInput from 'components/common/SelectInput';
import Popover from 'components/common/Popover';
import InfoIcon from 'assets/img/info.svg';
import { requestStatuses } from 'constants/values';
import { selectIsGuestUser, selectIsReadOnlyUser } from 'store/reducers/users';
import { selectShouldLoadData } from 'store/reducers/production';

const mapStateToProps = state => ({
  currentAssetName: selectCurrentAssetName(state),
  currentAssetNameException: selectCurrentAssetException(state),
  thresholdAdjustFormInputs: selectDowntimeThresholds(state),
  selectedNewThresholdCategoryValue: selectNewThresholdCategoryValue(state),
  thresholdRequestStatus: selectThresholdRequestStatus(state),
  thresholds: selectNewThresholdValues(state),
  isGuestUser: selectIsGuestUser(state),
  isReadOnlyUser: selectIsReadOnlyUser(state),
  thresholdsDaysValue: selectThresholdTimeValueObject(state),
  shouldLoadData: selectShouldLoadData(state),
  userInfo:selectUserInfo(state)
});

const mapDispatchToProps = {
  putThresholdAdjust,
  setNewThresholdCategoryValue,
  setNewThresholdValues,
  toggleThresholdAdjustModal,
  getExceptionDowntimeGeneralInfo,
  getExceptionDowntimeThresholds,
  getExceptionDowntimeTable,
  purgeCache,
  setTimeValue,
};

export class ThresholdAdjustForm extends Component {
  constructor(props) {
    super(props);

    const { thresholds, thresholdsDaysValue } = this.props;

    this.state = {
      _thresholds: thresholds,
      _thresholdsDays: thresholdsDaysValue,
      showErrorMessage: false,
      errorMessage: '',
      isInvalidFormItem: {},
      selectedHours: true,
      loaded: false,
    };
  }
  componentDidMount = () => {
    this.props.getExceptionDowntimeGeneralInfo();
  };
  handleChange = (name, threshold) => {
    const { value } = threshold;
    let temp = this.state._thresholdsDays;
    temp[name] = +value;
    const isInvalid = determineInvalidThresholdDays(value);
    let showErrorMessage = isInvalid;
    Object.keys(this.state.isInvalidFormItem).forEach(key => {
      if (this.state.isInvalidFormItem[key] && key !== name) {
        showErrorMessage = true;
      }
    });

    this.setState({
      _thresholdsDays: temp,
      isInvalidFormItem: {
        ...this.state.isInvalidFormItem,
        [name]: isInvalid,
      },
      showErrorMessage,
      errorMessage:
        'please complete all fields before saving and minimum days should be 1',
    });
  };

  render() {
    const {
      currentAssetName,
      currentAssetNameException,
      isGuestUser,
      isReadOnlyUser,
      putThresholdAdjust,
      selectedNewThresholdCategoryValue,
      setNewThresholdCategoryValue,
      setNewThresholdValues,
      thresholdAdjustFormInputs,
      thresholdRequestStatus,
      toggleThresholdAdjustModal,
      purgeCache,
      userInfo
    } = this.props;

    const thresholdAdjustFormInputsOrdered = [
      //Subsurface Order
      thresholdAdjustFormInputs[0],
      thresholdAdjustFormInputs[1],
      thresholdAdjustFormInputs[2],
      thresholdAdjustFormInputs[11],
      thresholdAdjustFormInputs[13],
      thresholdAdjustFormInputs[12],
      thresholdAdjustFormInputs[10],
      thresholdAdjustFormInputs[9],
      {
        description: 'Sub-surface Exception Category. Exception is flagged to track progress (Days in Step) across a WIT pull request’s lifecycle, which are: 1) Pending-LandReview or Pending-PEReview, 2) Pending-WIEReview, 3) Pending-WIETL/SuperApproval, 4) Pending-Economics/ AFE, 5) Pending-PreScheduling, 6)Pending-JobCompletion',
        exceptionTypeId: 26,
        name: 'WIT Pull Request Status'
      },
      thresholdAdjustFormInputs[17],
      thresholdAdjustFormInputs[18],
      thresholdAdjustFormInputs[19],
      thresholdAdjustFormInputs[20],
      thresholdAdjustFormInputs[21],
      thresholdAdjustFormInputs[22],
      //Surface
      thresholdAdjustFormInputs[4],
      thresholdAdjustFormInputs[5],
      thresholdAdjustFormInputs[14],
      thresholdAdjustFormInputs[15],
      thresholdAdjustFormInputs[16],
      thresholdAdjustFormInputs[3],
      //Other
      thresholdAdjustFormInputs[8],
      thresholdAdjustFormInputs[6],
      thresholdAdjustFormInputs[7],

    ];

    const { showErrorMessage, errorMessage } = this.state;
    const isAdmin = userInfo.isAdmin;
    const submitThresholds = values => {
      Object.keys(values).forEach(key => {
        if (values[key] <= 0) {
          if (key !== 'Pull Request Status' && key !== 'WO Priority Level') {
            this.setState({
              showErrorMessage: true,
              errorMessage:
                'please complete all fields before saving and minimum days should be 1',
            });
          }
        }
      });
      setNewThresholdValues(values);
      purgeCache();
      if (this.state.showErrorMessage !== true) {
        putThresholdAdjust().then(() => {
          this.props.getExceptionDowntimeGeneralInfo();
          if (thresholdRequestStatus === requestStatuses.failure) {
            this.setState({
              showErrorMessage: true,
              errorMessage: 'error - try again',
            });
          }
        });
      }
    };
    const assetNameassigned = currentAssetName.includes(',')? 'Multiple' : currentAssetName;
    const isEditableThreshold = !isAdmin ? currentAssetName.includes(currentAssetNameException) ? true : false : true;
    return (
      <div>
        <div className={styles.modalLabel}>
          {thresholdAdjustModalLabels.productionTeam}
        </div>
        {assetNameassigned}
        <br />
        <div className={styles.modalLabel}>
          {thresholdAdjustModalLabels.assetModified}
        </div>
        {currentAssetNameException}
        <br />

        <div className={styles.modalLabel}>
          {thresholdAdjustModalLabels.thresholdCategory}
        </div>
        <SelectInput
          options={selectPrimaryCards}
          resetAction={() => {return;}}
          setAction={category => {
            setNewThresholdCategoryValue(category);
          }}
          value={selectedNewThresholdCategoryValue}
          placeholderText={'Select Category'}
          disableReset={true}
          disabled={false}
        />

        <div className={styles.title}>Threshold Adjustment Details:</div>
        <div className={styles.formBox}>
          {thresholdAdjustFormInputsOrdered
            .filter(({ name }) => {
              let isVisible = false;

              if (secondaryHeirarchy[selectedNewThresholdCategoryValue]) {
                isVisible = secondaryHeirarchy[
                  selectedNewThresholdCategoryValue
                ].includes(name);
              }
              if (subsurfaceCategories[selectedNewThresholdCategoryValue]) {
                isVisible = subsurfaceCategories[
                  selectedNewThresholdCategoryValue
                ].includes(name);
              }
              if (surfaceCategories[selectedNewThresholdCategoryValue]) {
                isVisible = surfaceCategories[
                  selectedNewThresholdCategoryValue
                ].includes(name);
              }
              if (name === selectedNewThresholdCategoryValue) {
                isVisible = true;
              }

              return isVisible;
            })
            .map(i => {
              const disabled =
                i.name === downtimeCardTitles.nonWoPrRelated ||
                isGuestUser || !isEditableThreshold ||
                isReadOnlyUser;

              return (
                <div key={i.name} className={styles.formRow}>
                  <div
                    className={
                      i.name !== downtimeCardTitles.prActiveSchedule &&
                      i.name !== downtimeCardTitles.prPrescheduling &&
                      i.name !== downtimeCardTitles.prLandAndPe &&
                      i.name !== downtimeCardTitles.prWbsReview &&
                      i.name !== downtimeCardTitles.prPlannerReview &&
                      i.name !== downtimeCardTitles.woPriorityLevel1 &&
                      i.name !== downtimeCardTitles.woPriorityLevel2 &&
                      i.name !== downtimeCardTitles.woPriorityLevel3  &&
                      i.name !== downtimeCardTitles.witPrLandAndPe &&
                      i.name !== downtimeCardTitles.witPrWIEReview &&
                      i.name !== downtimeCardTitles.witPrWIETLSuperApproval &&
                      i.name !== downtimeCardTitles.witPrEconomicsAFE &&
                      i.name !== downtimeCardTitles.witPrPreScheduling &&
                      i.name !== downtimeCardTitles.witPrJobCompletion
                        ? styles.popOver
                        : styles.popOverIndented
                    }
                  >
                    <Popover text={`${i.description}`}>
                      <img src={InfoIcon} alt={`${i.description}`} />
                    </Popover>
                  </div>
                  <div className={styles.label}>
                    {thresholdAdjustModalLabels[i.name]}
                  </div>

                  {i.name !== downtimeCardTitles.pullRequestStatus &&
                    i.name !== downtimeCardTitles.woPriorityLevel &&
                    i.name !== downtimeCardTitles.witPullRequestStatus && (
                      <>
                        <div className={styles.value}>
                          <input
                            className={classnames({
                              [styles.required]: this.state.isInvalidFormItem[
                                i.name
                              ],
                              [styles.disabled]: disabled,
                            })}
                            value={this.state._thresholdsDays[i.name]}
                            onChange={event => {
                              this.handleChange(i.name, event.target);
                            }}
                            type="number"
                            min={'1'}
                            disabled={disabled}
                          />
                        </div>
                        <div className={styles.labelHours}>days</div>
                      </>
                    )}
                </div>
              );
            })}
        </div>
        <div className={styles.buttonRow}>
          <div className={styles.left}>
            {!isGuestUser
              ? !isReadOnlyUser && (
                  <Button
                    text={'save'}
                    type={'thresholdSave'}
                    className={'saveBtn'}
                    click={() => submitThresholds(this.state._thresholdsDays)}
                    disabled={showErrorMessage}
                  />
                )
              : null}
            <Button
              text={'cancel'}
              type={'cancelText'}
              click={toggleThresholdAdjustModal}
            />
          </div>
        </div>
        <div className={styles.errorMessage}>
          <RequiredText
            showRequiredText={showErrorMessage}
            text={errorMessage}
          />
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThresholdAdjustForm);
