/* istanbul ignore file */
import 'normalize.css/normalize.css';
import './assets/css/styles.css';
import './assets/css/colors.css';
import './assets/css/fontSizes.css';
import './assets/css/steps.css';
import { PublicClientApplication, InteractionType, EventType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { msalConfig } from '../src/authConfig';
import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

export const msalInstance = new PublicClientApplication(msalConfig);

const authRequest = {
  scopes: ['openid', 'profile']
};
// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
    >
      <App />
    </MsalAuthenticationTemplate>
  </MsalProvider>,
  document.getElementById('root') || document.createElement('div')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();