import moment from 'moment';
import rangeRight from 'lodash/rangeRight';

export const env = process.env.NODE_ENV;
export const isDevelopment = env === 'development';
export const isStaging = env === 'staging';
export const isProduction = env === 'production';
export const isTest = env === 'test';
export const activeDirectoryTokenKey = 'adal.idtoken';
export const localStorageKey = 'ChevronMCBUOpsFramework';

export const months = moment.monthsShort().map(month => month.toUpperCase());
export const currentMonth = moment().month();
export const currentYear = moment().year();

export const notFound = 'Not Found';
export const badRequest = 'Bad Request';

export const requestStatuses = {
  pending: 'PENDING',
  success: 'SUCCESS',
  failure: 'FAILURE',
  empty: 'EMPTY',
  notFound: 'NOTFOUND'
};

export const taskTimeframeOptions = {
  weekly: 'weekly',
  monthly: 'monthly',
  quarterly: 'quarterly',
  yearly: 'yearly'
};

export const dateRanges = {
  thisWeek: 'This Week',
  lastWeek: 'Last Week',
  thisQuarter: 'This Quarter',
  lastQuarter: 'Last Quarter',
  thisYear: 'This Year'
};

export const dateRangeOptions = [
  dateRanges.thisWeek,
  dateRanges.lastWeek,
  dateRanges.thisQuarter,
  dateRanges.lastQuarter,
  dateRanges.thisYear
];

export const taskYearOrigin = 2019;

export const selectAvailableMonths = year => {
  if (year === currentYear) {
    return months.slice(0, currentMonth + 1);
  }

  return months;
};

export const selectAvailableYears = () => {
  return rangeRight(taskYearOrigin, moment().year() + 1);
};

export const selectColor = value => {
  if (value > 74) {
    return 'green';
  } else if (value < 50) {
    return 'red';
  } else if (value > 50 && value < 74) {
    return 'yellow';
  } else {
    return 'na';
  }
};

export const powerbiResourceUrl = 'https://analysis.windows.net/powerbi/api';

export const productionEmpty = {
  route: { routeId: '0', assetId: '', name: '' },
  asset: { assetId: '0', name: '', routes: [] }
};

export const productionAssetDefault =
{
  assetId: 'MCA_GCY',
  name: 'VAN FMT',
  routes: [
    {
      routeId: '3080001',
      assetId: 'MCA_GCY',
      name: '3080001 - VAN RT 2',
    },
    {
      routeId: '3080002',
      assetId: 'MCA_GCY',
      name: '3080002 - VAN GAS PLANT',
    },
    {
      routeId: '3080004',
      assetId: 'MCA_GCY',
      name: '3080004 - VAN SWD BEAT',
    },
    {
      routeId: '3080006',
      assetId: 'MCA_GCY',
      name: '3080006 - VAN RT 25',
    },
    {
      routeId: '3089999',
      assetId: 'MCA_GCY',
      name: '3089999 - VAN REMOVED',
    },
  ],
};

/* Types of Summary/General Info. Used to determine which heading to use on the
 LPO Events, Production Summary, and Exception Info endpoint. */
export const summaryTypes = {
  asset: 'Asset',
  route: 'Route'
};
