import React, { Component } from 'react';
import styles from './styles.module.css';
import Button from 'components/common/Button';
import RequiredText from 'components/common/RequiredText';
import { targetFormInputs } from 'constants/productionManagement.js';
import classnames from 'classnames';
import { calculateBoed } from 'utils/calculateBoed';
import { formatNumber } from 'utils/formatNumber';
import { determineInvalidTarget } from 'utils/validation';
import { connect } from 'react-redux';
import { selectIsGuestUser, selectIsReadOnlyUser,selectUserInfo } from 'store/reducers/users';
import { selectSelectedAssetsProdManagement } from 'store/reducers/navigation';
import SuccessModal from 'components/common/SuccessModal';
import {
  setCustomTargetAsset,
  selectRouteTargetDetails,
  selectRouteTargetRequestStatus,
  selectRouteTargetPostStatus,
  setRouteTargetDetails,
  getRouteLevelTargets,
  resetRouteLevelPostTargetStatus,
} from 'store/reducers/production';
//did not changed asset for target to be known
//did changed getYearListForSelectedAsset
import {
  getAssetListForTargetModal,
  getYearListForSelectedRoute,
  getRouteListForSelectedRoute,
} from 'utils/production';
import LoadingPage from 'components/common/LoadingPage';

const mapStateToProps = state => ({
  isGuestUser: selectIsGuestUser(state),
  isReadOnlyUser: selectIsReadOnlyUser(state),
  assetName: selectSelectedAssetsProdManagement(state),
  routeTargetRequestStatus: selectRouteTargetRequestStatus(state),
  routeTargetDetails: selectRouteTargetDetails(state),
  routeTargetPostStatus: selectRouteTargetPostStatus(state),
  userInfo:selectUserInfo(state)
});
const mapDispatchToProps = {
  setCustomTargetAsset,
  setRouteTargetDetails,
  getRouteLevelTargets,
  resetRouteLevelPostTargetStatus,
};
const modalSuccessTitle = 'Successfully saved!';

export class CustomRouteTargetForm extends Component {
  constructor(props) {
    super(props);

    const { oilTarget, gasTarget, waterTarget, isCustomTarget } =
      props.targets[0];

    this.state = {
      _targets: {
        oilTarget: oilTarget || 0,
        waterTarget: waterTarget || 0,
        gasTarget: gasTarget || 0,
        isCustomTarget,
      },
      _routeTargetDetails: { ...this.props.routeTargetDetails },
      routeTargetDetails: [...this.props.routeTargetDetails],
      selectedAsset: this.props.routeTargetDetails[0].assetId || '',
      selectedRoute:
        this.props.routeTargetDetails[0].routeLevelData[0].routeId || '',
      routeYearDeFault:
        this.props.routeTargetDetails[0].routeLevelData[0].yearlyDto[0].year,
      showRequired: false,
      gasTargetInvalid: false,
      oilTargetInvalid: false,
      waterTargetInvalid: false,
    };
  }
  splitKeyTarget(keyTarget) {
    return keyTarget.split('T')[0];
  }

  handleChange(el, event, key) {
    const { value } = event.target;
    let tempFullYrTarget = this.state.routeTargetDetails;
    let showRequired = false;

    if (!el.isCustomTarget) {
      el.isCustomTarget = true;
    }

    el[key] = +value;
    const gasTargetInvalid = determineInvalidTarget(el['gasTarget'], 'gas');
    const oilTargetInvalid = determineInvalidTarget(el['oilTarget'], 'oil');
    const waterTargetInvalid = determineInvalidTarget(
      el['waterTarget'],
      'water'
    );
    showRequired = gasTargetInvalid || oilTargetInvalid || waterTargetInvalid;

    let selectedYear = this.state.routeYearDeFault;
    let selectedRoute = this.state.selectedRoute;
    let selectedAsset = this.state.selectedAsset;
    let selectedAssetIndex = tempFullYrTarget.findIndex(
      k => k.assetId === selectedAsset
    );
    let selectedRouteIndex = tempFullYrTarget[
      selectedAssetIndex
    ].routeLevelData.findIndex(k => k.routeId === selectedRoute);
    let selectedYearIndex = tempFullYrTarget[selectedAssetIndex].routeLevelData[
      selectedRouteIndex
    ].yearlyDto.findIndex(j => j.year === selectedYear);
    const selectedMonthIndex = tempFullYrTarget[
      selectedAssetIndex
    ].routeLevelData[selectedRouteIndex].yearlyDto[
      selectedYearIndex
    ].yearTargets.findIndex(i => i.monthName === el.monthName);
    tempFullYrTarget[selectedAssetIndex].routeLevelData[
      selectedRouteIndex
    ].yearlyDto[selectedYearIndex].yearTargets[selectedMonthIndex] = { ...el };
    this.setState({
      routeTargetDetails: tempFullYrTarget,
      showRequired,
      gasTargetInvalid,
      oilTargetInvalid,
      waterTargetInvalid,
    });
  }
  handleAssetChange = event => {
    let temp = event.target.value;
    let routeDetailsValue = this.state.routeTargetDetails;
    let assetIndex = routeDetailsValue.findIndex(i => {
      return i.assetId === event.target.value;
    });
    this.setState({
      selectedAsset: temp,
      selectedRoute: routeDetailsValue[assetIndex].routeLevelData[0].routeId,
    });
  };
  handleYearChange = event => {
    this.setState({
      routeYearDeFault: event.target.value,
    });
  };
  handleRouteChange = event => {
    let routeValue = event.target.value;
    this.setState({
      selectedRoute: routeValue,
    });
  };


  updateTargets(_values) {
    let updated = this.state._targets;
    let targetAssetName = this.state.selectedAsset;
    updated.isCustomTarget = true;
    let routeTarget = this.state.routeTargetDetails;
    this.setState({ _targets: updated, showRequired: false });
    this.props.setCustomTargetAsset(targetAssetName);
    this.props.setRouteTargetDetails(routeTarget);
    this.props.submitTargets(updated);
  }

  render() {
    const {
      isGuestUser,
      closeModal,
      isReadOnlyUser,
      routeTargetDetails,
      routeTargetRequestStatus,
      routeTargetPostStatus,
      userInfo
    } = this.props;
    const { showRequired } = this.state;
    const isProdEngineer = userInfo.roleTitle === 'Production Engineer';
    const routeOptions =
      routeTargetDetails.length > 0
        ? getRouteListForSelectedRoute(
            this.state.routeTargetDetails,
            this.state.selectedAsset
          )
        : [];
    const yearOptions =
      routeOptions.length > 0
        ? getYearListForSelectedRoute(routeOptions, this.state.selectedRoute)
        : [];

    const selectedYearTargets = yearOptions.filter(yeartargets => {
      return yeartargets.year === this.state.routeYearDeFault;
    });
    return routeTargetRequestStatus === 'SUCCESS' ? (
      <div className={styles.formWrapper}>
        <div className={styles.title}>
          <span>Target Adjustment Details:</span>
          <span className={styles.selectRouteYear}>
            <select
              onChange={this.handleYearChange}
              value={this.state.routeYearDeFault}
              className={styles.selectAssetDropdown}
            >
              {yearOptions.map(year => {
                return <option key={year.year}>{year.year}</option>;
              })}
            </select>
          </span>
          <span className={styles.selectAssetRouteFeild}>
            <select
              onChange={this.handleAssetChange}
              value={this.state.selectedAsset}
              className={styles.selectAssetRouteDropdown}
            >
              {getAssetListForTargetModal(this.props.assetName)}
            </select>
          </span>
          <span className={styles.selectRouteField}>
            <select
              onChange={this.handleRouteChange}
              value={this.state.selectedRoute}
              className={styles.selectRouteDropdown}
            >
              {routeOptions.map(route => {
                return (
                  <option key={route.routeId} value={route.routeId}>
                    {route.routeName}{' '}
                  </option>
                );
              })}
            </select>
          </span>
        </div>
        <div className={styles.formBox}>
          <div className={styles.formRow}>
            <div className={styles.label}>Target</div>
            {selectedYearTargets[0].yearTargets.map(el => {
              return (
                <span key={el.monthName} className={styles.valueRoute}>
                  {el.monthName}
                </span>
              );
            })}
          </div>

          {targetFormInputs.map(i => {
            return (
              <div key={i.key} className={styles.formRow}>
                <div className={styles.label}>{i.label}</div>
                {selectedYearTargets[0].yearTargets.map(el => {
                  return (
                    <input
                      className={classnames({
                        [styles.required]: this.state[`${i.key}Invalid`],
                        [styles.inputtype]: [styles.inputtype],
                      })}
                      key={el.monthName}
                      value={el[i.key]}
                      onChange={event => this.handleChange(el, event, i.key)}
                      type="all"
                      min="0"
                      disabled={isGuestUser || (isReadOnlyUser && !isProdEngineer)}
                    />
                  );
                })}
              </div>
            );
          })}

          <div className={styles.formRow}>
            <div className={styles.label}>Daily Target BOED</div>
            {selectedYearTargets[0].yearTargets.map(el => {
              return (
                <div className={styles.valueRoute} key={el.monthName}>
                  {formatNumber(calculateBoed(el.oilTarget, el.gasTarget))}
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles.buttonRow}>
          <div className={styles.left}>
            {!isGuestUser
              ? !isReadOnlyUser ? (
                  <Button
                    text={'save'}
                    type={'thresholdSave'}
                    click={() => this.updateTargets(this.state._targets)}
                    disabled={showRequired}
                  />
                )
              :  isProdEngineer ? (
                <Button
                  text={'save'}
                  type={'thresholdSave'}
                  click={() => this.updateTargets(this.state._targets)}
                  disabled={showRequired}
                />
              ): null :
               null}
            <Button text={'cancel'} type={'cancelText'} click={closeModal} />
          </div>
        </div>
        <div className={styles.errorMessage}>
          <RequiredText
            showRequiredText={showRequired}
            text={'please complete all fields before saving'}
          />
        </div>
        {routeTargetPostStatus === 'SUCCESS' && (
          <SuccessModal
            title={modalSuccessTitle}
            toggleModal={this.props.resetRouteLevelPostTargetStatus}
          />
        )}
      </div>
    ) : (
      <LoadingPage
        requestStatus={routeTargetRequestStatus}
        retryRequest={this.props.getRouteLevelTargets}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomRouteTargetForm);
