import { routeNames, routes } from 'constants/routes';

// LPO Status to Planned Yes/No mapping
export const lpoStatusPlanned = {
  UnScheduled: 'No',
  Scheduled: 'Yes',
};
// LPO Status
export const lpoScheduleStatus = {
  'Pending-ALCRReview': 'ALCR Review',
  'Pending-LandReview': 'Land/PE Review',
  'Pending-PEReview': 'Land/PE Review',
  'Pending-PlannerReview': 'Planner Review',
  'Pending-SentforBallot': 'WBS Review',
  'Pending-WBSReview': 'WBS Review',
  'Pending-PreScheduling': 'Pre-scheduling',
  'Pending-JobCompletion': 'Active Schedule',
  'Pending-Tour': 'Pending-Tour',
  'Resolved-Error':'Unknown',
  'Resolved-Divest':'Unknown',
  'Resolved-JobCompletion': 'Complete'
};

export const witScheduleStatus ={
  'Pending-ALCRReview':'Pending-ALCRReview',
  'Pending-LandReview':'Pending Land-PE',
  'Pending-PEReview':'Pending Land-PE',
  'Pending-WIEReview':'Pending-WIEReview',
  'Pending-WIESuperApproval':'Pending Approval',
  'Pending-WIETLApproval':'Pending Approval',
  'Pending-WIETL/SuperApproval':'Pending Approval',
  'Pending-Economics/AFE':'Pending-Economics/ AFE',
  'Pending-PreScheduling':'Pending-Pre Scheduling',
  'Pending-JobCompletion':'Pending-Execution',
  'Pending- JobClose':'Pending- JobClose',
  'Pending-Tour':'Pending-Tour',
  'Resolved-Error':'Unknown',
  'Resolved-Divest':'Unknown',
}

// The links for the sub-sections of the Production Management Page
export const links = [
  {
    url: routes.productionManagement.summary,
    routeName: routeNames.productionManagementObject.summary,
  },
  {
    url: routes.productionManagement.lpoEvents,
    routeName: routeNames.productionManagementObject.lpoEvents,
  },
  // {
  //   url: routes.productionManagement.fieldSpecialistScorecard,
  //   routeName: routeNames.productionManagementObject.fieldSpecialistScorecard,
  // },
];

// Used as the first option in the Route dropdown.
export const allRoutes = {
  name: 'All Routes',
  id: '0',
  value: '0',
};

// Titles for the Detail Cards in the Production Summary page
export const detailCardTitles = {
  prodChart: 'Production Chart (BOED)',
  prodDetails: 'Production Details',
  routeProdDiff: 'Route Production Differences',
  assetProdDiff: 'FOT Production Differences',
};

// Titles for the Table Cards on the Production Summary page
export const prodSummaryCardTitles = {
  measuredProduction: 'Production',
  target: 'Target',
  difference: 'Difference',
  targetHistory: 'Target History',
};

// hierarchy level used on production summary
export const hierarchyLevelConstants = {
  wellLevel: 'Well-level',
  routeLevel: 'route-level',
  assetLevel: 'asset-level',
  empty: '',
};

// Mapping for the Summary Table types
export const summaryTableTypes = {
  [prodSummaryCardTitles.measuredProduction]: 'production',
  [prodSummaryCardTitles.target]: 'target',
  [prodSummaryCardTitles.difference]: 'difference',
};

// Mapping the Summary Table to the API Call Parameters
export const summaryApiCalls = {
  [prodSummaryCardTitles.measuredProduction]: 'production',
  [prodSummaryCardTitles.target]: 'target',
  [prodSummaryCardTitles.difference]: 'difference',
};

export const allocatedOptions = [
  {
    name: 'Measured Production',
    value: false,
  },
  {
    name: 'Allocated Production',
    value: true,
  },
];

//Target options 
export const targetOptions = [
  {
    name: 'Routes',
    value: false,
  },
  {
    name: 'Assets',
    value: true,
  },
];

//key values to object

const targetFormObj = {
  oilTarget: 'oilTarget',
  gasTarget: 'gasTarget',
  waterTarget: 'waterTarget',
};

// Used in setting the Asset Target modal in the Production Summary page
export const targetFormInputs = [
  { label: 'Daily Target Oil', key: targetFormObj.oilTarget, required: true },
  { label: 'Daily Target Gas', key: targetFormObj.gasTarget, required: true },
  {
    label: 'Daily Target Water (optional)',
    key: targetFormObj.waterTarget,
    required: false,
  },
];

// Titles for the LPO Table Cards
export const prodLpoEventsCardTitles = {
  openLpoEvents: 'Open LPO Events',
  thirdPartyEvents: 'Third Party Events',
  shutInWells: 'Shut-in Wells',
};
// Mapping LPO Table Cards to API Call Parameters
export const lpoEventsApiCalls = {
  [prodLpoEventsCardTitles.openLpoEvents]: 'openlpo',
  [prodLpoEventsCardTitles.thirdPartyEvents]: 'thirdparty',
  [prodLpoEventsCardTitles.shutInWells]: 'shutin',
};

// Modal Labels
export const lpoEventDetailModalLabels = {
  affectedProductionDetails: 'Affected Production Details:',
  boe: ' BOE',
  bopd: ' BOPD',
  reason: 'WO description/reason',
  collectionPoint: 'Collection Point:',
  comments: 'EC Comments:',
  criticality: 'Criticality:',
  dateOfShutIn: 'Date of Shut-In:',
  daysInEvent: 'Days in Event:',
  daysInShutIn: 'Days in Shut-In:',
  daysInStep: 'Days in Step:',
  downTimeType: 'Downtime Type:',
  daysDown: 'Days Down:',
  eventLoss: 'Event Loss',
  gasProduction: 'Gas Production',
  initialDownDate: 'Initial Down Date:',
  tier:'Tier:',
  lastEventsComments: 'Last Event Comments:',
  lawtRate: 'LAWT Rate:',
  lossRate: 'Loss Rate',
  lpoStatus: 'LPO Status:',
  lpoId: 'LPO Id:',
  lpoType: 'LPO Type:',
  mcf: ' MCF',
  mcfd: ' MCFD',
  oilProduction: 'Oil Production',
  planned: 'Planned LPO:',
  prDate: 'PR Date:',
  prStatus: 'PR Status:',
  priority: 'Priority:',
  reasonCode: 'Reason Code:',
  reasonForCriticality: 'Reason Description:',
  rigScheduled: 'Rig Scheduled:',
  route: 'Route:',
  status: 'Status:',
  submittedBy: 'Submitted by:',
  wellName: 'Well Name:',
  woStatus: 'WO Status:',
  workOrder: 'Work Order:',
  wO_PR: 'Pull Request:',
  scheduleDate: 'Scheduled Date:',
  completedDate: 'Completed Date:',
  submittedDate: 'Submitted Date:',
  failureDate: 'Failure Date:',
  createdBy: 'Created By:',
  createdByCAI: 'Created By CAI:',
  resolvedBy:'Resolved By:',
  resolvedDate:'Resolved Date:',
  dueDate:'Due Date:',
  liftMethod:'Lift Method:',
  assignedTeam:'Assigned Team:',
  caseId: 'Case Id:',
  currentStageName: 'Current Stage Name:',
  submittedByCAI: 'Submitted By CAI:',
  wbsNumber: 'WBS Number:',
  avgTotalProductionBoed: 'AVG Total Production BOED:',
  issueDescription:'Issue Description:',
  resolvedComments:'Resolved Comments:'
};

// Options for Target History dropdown
export const targetHistorySelectOptions = [
  {
    name: 'Last 60 Days',
    value: '60',
  },
  {
    name: 'Last 90 Days',
    value: '90',
  },
  {
    name: 'Last 120 Days',
    value: '120',
  },
];

// The two types of lpo. Downtime and Constrained
export const lpoTypes = ['DT', 'CN'];

// The three connection types an LPO Event can have. Aka Depdenency Aka WoPr
export const connectionTypes = {
  workOrder: 'Work Order',
  pullRequest: 'Pull Request',
  none: 'None',
};

// All 3 connections in an array dropdown.
export const connections = [
  connectionTypes.workOrder,
  connectionTypes.pullRequest,
  connectionTypes.none,
];

/* IMPORTANT: ReasonCodes are hard-coded here purposely. The list from iDAP in the 
  LPO General Info endpoint includes 500+ Reason Codes and they only use a dozen or 
  two which are hardcoded here. That payload is used to load the Reason 
  Descriptions, however.*/
export const reasonCodes = [
  'CN',
  'CO',
  'CY',
  'DD',
  'DH',
  'DO',
  'DW',
  'EA',
  'EB',
  'FH',
  'FP',
  'FY',
  'LA',
  'LE',
  'LJ',
  'LM',
  'LO',
  'LP',
  'LQ',
  'LY',
  'NH',
  'NJ',
  'NK',
  'NM',
  'NQ',
  'NT',
  'OO',
  'OP',
  'OS',
  'OX',
  'RR',
  'UA',
];

// Created an Object out of the Reason Codes array.
export const reasonCodeObject = checked => {
  const state = {};

  reasonCodes.forEach(code => {
    state[code] = checked;
  });

  return state;
};

// Constants for types of Connections/Dependencies/WOPR
export const LpoWorkOrderPullRequestTypes = {
  workOrderType: 'WO',
  pullRequestType: 'PR',
  noneType: 'none',
};
