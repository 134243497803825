// This file contains all routing constants.

// routes contains all of the routes in the app.
export const routes = {
  admin: '/admin',
  feedback: '/feedback',
  home: '/',
  productionManagementParent: '/production-management',
  productionManagement: {
    summary: '/production-management/summary',
    lpoEvents: '/production-management/lpo-events',
    fieldSpecialistScorecard:
      '/production-management/field-specialist-scorecard'
  },
  exceptionManagementParent: '/exception-management',
  exceptionManagement: {
    downtime: '/exception-management/downtime',
    production: '/exception-management/production'
  },
  controls: '/controls'
};

// routeNames contains the text we display in the header for that route
export const routeNames = {
  admin: 'administrator settings',
  feedback: 'user feedback',
  tasks: 'tasks',
  pageNotFound: 'page not found',
  taskDetails: 'task detail',
  productionManagement: 'production management',
  productionManagementObject: {
    summary: 'summary',
    lpoEvents: 'lpo events',
    fieldSpecialistScorecard: 'field specialist scorecard'
  },
  exceptionManagement: 'exception management',
  exceptionManagementObject: {
    downtime: 'downtime',
    production: 'production'
  },
  controls: 'controls'
};

// Matching up routes to routeNames
export const routePathNames = {
  '/admin': routeNames.admin,
  '/': routeNames.productionManagement,
  '/tasks': routeNames.tasks,
  // '/feedback': routeNames.feedback,
  '/tasks/': routeNames.taskDetails,
  '/production': routeNames.productionManagement,
  '/exception': routeNames.exceptionManagement,
  '/controls': routeNames.controls
};
