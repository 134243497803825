import React from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import { selectCurrentSecondaryDowntimeCard, selectDowntimeTableData } from 'store/reducers/exception';
import styles from './styles.module.css';
import { flattenObject } from 'utils/flatten';
import { appInsights } from 'App';
import { aiEvents } from 'constants/aiEvents';

const mapStateToProps = state => ({
  tableData: selectDowntimeTableData(state),
  filename: selectCurrentSecondaryDowntimeCard(state)
});

export const DowntimeExportCSV = ({ filename, tableData }) => (
  <CSVLink
    data={
      tableData.map(row => flattenObject(row))
    }
    className={styles.csvLink}
    filename={`${filename.replace(/ /g, '_')}.csv`}
  >
    <div
      className={styles.exportButton}
      onClick={() => {
        appInsights.trackEvent({ name: aiEvents.exportDowntimeTable });
      }}
    >
      export table
    </div>
  </CSVLink>
);

export default connect(mapStateToProps)(DowntimeExportCSV);
