import { DELETE, GET, POST, PUT } from './methods';
import {
  notFound,
  badRequest,
} from 'constants/values';
import { msalInstance } from 'index';
import { appInsights } from 'App';
import { aiEvents } from 'constants/aiEvents';
import { selectCache } from 'store/reducers/cache';
import axios from 'axios';
import { apiScope } from 'authConfig.js'

// The backend API url. Loads from .env
const backEndAPIUrl = process.env.REACT_APP_BACKEND_API_URL;

// The current version of the API url. Loads from .env
const apiVersionNumber = 'v2';

// Content Type application/json. Shouldn't change.
const contentTypeHeader = { 'Content-Type': 'application/json' };

// Accept header. Shouldn't change.
export const acceptHeader = { accept: 'application/json' };

// Headers used when a request has a body.
export const bodyHeaders = {
  ...acceptHeader,
  ...contentTypeHeader,
};



axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL,
});


const acquireAccessToken = async () => {
  const activeAccount = msalInstance.getActiveAccount();
  const accounts = msalInstance.getAllAccounts();

  const request = {
    scopes: apiScope.scopes,
    account: activeAccount || accounts[0]
  };

  const authResult = await msalInstance.acquireTokenSilent(request);
  return authResult.accessToken
};

function getErrorMessage(error) {
  let message = error.response?.data?.message;

  if (message == null) {
    message = error.message;
  }

  return message;
}

//Authentication Header used for API requests.
axios.interceptors.request.use(
  async config => {
    const accessToken = await acquireAccessToken();

    if (accessToken) {
      config.headers['Authorization'] = 'Bearer ' + accessToken;
    }

    return config;
  },
  error => {
    return getErrorMessage(error);
  }
);

axios.interceptors.response.use(
  async (response) => response,
  (error) => {
    return getErrorMessage(error);
  });



export const authorizedRequest = async (url, method, body) => {
  const fullUrl = backEndAPIUrl + apiVersionNumber + url;
  return await axios({
    method: method,
    url: fullUrl,
    data: body
  }).then((response) => {
    if (response.request.status === 401) {
      appInsights.trackEven({ name: aiEvents.userUnauthorized });
      msalInstance.logoutRedirect();
    }
    return response;
  }).catch(error => {
    console.log('Error', error);
    return error;
  });
};

export const checkCache = (
  cache,
  cacheStamp,
  endpoint,
  endpointCapture,
  defaultResponse = {}
) => {
  const cachedResponse = selectCache(cache, cacheStamp, endpoint);
  const dispatchCache = endpointCapture(endpoint);
  return cachedResponse
    ? cachedResponse
    : authorizedRequest(endpoint, GET).then(response =>
      checkApiResponse(response, defaultResponse, dispatchCache)
    );
};

export const checkApiResponse = (
  response,
  defaultBody = false,
  dispatchCache
) => {
  if (response.data || response.request.status === 200) {
    return response.data;
  }
  if (response.request.status === 204) {
    return []
  }
  if (response.request.status === 404) {
    throw new Error(notFound);
  }
  if (response.request.status === 400) {
    throw new Error(badRequest);
  }
  if (response.request.status !== 200 && response.request.status !== 400) {
    throw new Error(response.data);
  }

  if (response.request.status === 204) {
    const emptyResponse = defaultBody ? defaultBody : {};
    dispatchCache && dispatchCache(emptyResponse);
    return emptyResponse;
  } else {
    return response.data.text().then(text => {
      const payload = text.length ? JSON.parse(text) : {};
      dispatchCache && dispatchCache(payload);
      return payload;
    });
  }
};
