import { authorizedRequest, checkApiResponse } from 'api/request';
import { PUT } from 'api/methods';
import {
  selectCurrentAssetIdException,
  selectCurrentRouteLPO
} from 'store/reducers/navigation';
import {
  selectCurrentSecondaryDowntimeCard,
  selectNewThresholdValues,
  selectNewThresholdCategoryValue,
  selectThresholdTimeValue
} from 'store/reducers/exception';
import { downtimeTableApiTitles } from 'constants/exceptionManagement';
import AssetCheck from 'utils/assetCheck';
import { checkCache } from 'api/request';

const baseUrl = '/exceptionmanagement';
const tableNamefunc = tableName => {
  return tableName ? `?tableName=${tableName}` : ''
}
export const apiEndpoints = {
  getExceptionDowntimeGeneralInfoAsset: assetId =>
    `${baseUrl}/downtime/info/${assetId}`,
  getExceptionDowntimeGeneralInfoRoute: (assetId, routeId) =>
    `${baseUrl}/downtime/info/${assetId}?routeId=${routeId}`,
  getExceptionDowntimeTableAsset: (assetId, tableName) =>
    `${baseUrl}/downtime/table/${assetId}${tableNamefunc(tableName)}`,
  getExceptionDowntimeTableRoute: (assetId, routeId, tableName) =>
    `${baseUrl}/downtime/table/${assetId}?routeId=${routeId}${tableNamefunc(tableName)}`,
  getExceptionDowntimeThresholds: `${baseUrl}/exceptiontypes`,
  putThresholdAdjust: (assetId, category, toggle) =>
    `${baseUrl}/downtime/asset/adjust-threshold/${assetId}?category=${category}&toggle=${toggle}`,
};

const defaultResponseArr = [];

export const getExceptionDowntimeThresholds = async ({
  cache,
  cacheStamp,
  endpointCapture,
}) =>
  checkCache(
    cache,
    cacheStamp,
    apiEndpoints.getExceptionDowntimeThresholds,
    endpointCapture
  );
export const getExceptionDowntimeTable = async ({
  getState,
  cache,
  cacheStamp,
  endpointCapture,
}) => {
  const assetId = selectCurrentAssetIdException(getState());
  const routeId = selectCurrentRouteLPO(getState());
  const isAsset = AssetCheck(routeId);
  const tableName =
    downtimeTableApiTitles[selectCurrentSecondaryDowntimeCard(getState())];
  const endpoint = isAsset
    ? apiEndpoints.getExceptionDowntimeTableAsset(assetId, tableName)
    : apiEndpoints.getExceptionDowntimeTableRoute(assetId, routeId, tableName);
  return checkCache(
    cache,
    cacheStamp,
    endpoint,
    endpointCapture,
    defaultResponseArr
  );
};

export const getExceptionDowntimeGeneralInfo = async ({
  getState,
  cache,
  cacheStamp,
  endpointCapture,
}) => {
  const assetId = selectCurrentAssetIdException(getState());
  const routeId = selectCurrentRouteLPO(getState());
  const isAsset = AssetCheck(routeId);
  const endpoint = isAsset
    ? apiEndpoints.getExceptionDowntimeGeneralInfoAsset(assetId)
    : apiEndpoints.getExceptionDowntimeGeneralInfoRoute(assetId, routeId);
  return checkCache(cache, cacheStamp, endpoint, endpointCapture);
};



export const putThresholdAdjust = async ({ getState }) => {
  const assetId = selectCurrentAssetIdException(getState());
  const category = selectNewThresholdCategoryValue(getState()) === 'Surface Exceptions' ? 'surface' : 'sub-surface';
  const values = selectNewThresholdValues(getState());
  const toggle = selectThresholdTimeValue(getState());
  let newThresholdValues = {
    troubleshooting: parseInt(values['Troubleshooting']),
    noPullRequest: parseInt(values['No Pull Request']),
    pullRequestStatus: parseInt(values['Pull Request Status']),
    landAndPEReview: parseInt(values['PR - Land & PE Review']),
    plannerReview: parseInt(values['PR - Planner Review']),
    wbsReview: parseInt(values['PR - WBS Review']),
    prescheduling: parseInt(values['PR - Prescheduling']),
    activeSchedule: parseInt(values['PR - Active Schedule']),
    completeNonProducing: parseInt(
      values['Complete but Non-Producing for Sub-Surface']),
    noWorkOrder: parseInt(values['No Work Order']),
    woPriorityLevel: parseInt(values['WO Priority Level']),
    woPriorityLevel1: parseInt(values['WO Priority Level 1']),
    woPriorityLevel2: parseInt(values['WO Priority Level 2']),
    woPriorityLevel3: parseInt(values['WO Priority Level 3']),
    completeNonProducingWO: parseInt(
      values['Complete but Non-Producing for Surface']),
    'witPendingLandPEReview': parseInt(
      values['Pending-LandReview or Pending-PEReview']),
    'witPendingWIEReview': parseInt(values['Pending-WIEReview']),
    'witPendingWIETLSuperApproval': parseInt(values['Pending-WIETL/SuperApproval']),
    'witPendingEconomicsAFE': parseInt(values['Pending-Economics/ AFE']),
    'witPendingPreScheduling': parseInt(values['Pending-PreScheduling']),
    'witPendingJobCompletion': parseInt(values['Pending-JobCompletion'])
  };

  const body = newThresholdValues;

  return authorizedRequest(
    apiEndpoints.putThresholdAdjust(assetId, category, toggle),
    PUT,
    body
  ).then(response => checkApiResponse(response));
};
