import React from 'react';
import styles from './styles.module.css';
import spinner from 'assets/img/spinner.gif';
import { controlStatuses } from 'constants/controls';
// SVGs
import { ReactComponent as GrayCircle } from 'assets/img/completion-not-checked.svg';
import { ReactComponent as GreenCircleWithCheckmark } from 'assets/img/green-circle-with-checkmark.svg';
import { ReactComponent as Failure } from 'assets/img/failure.svg';
import { ReactComponent as Overdue } from 'assets/img/grey-circle-with-white-x.svg';


const CompletionCircleGray = (status,style,clickAction,clickable) => {
  return status === controlStatuses.ready && clickable ?
  <div className={style}>
    <GrayCircle onClick={clickAction} />
  </div> : null
}
const CompletionCircleOverdue = (status,style,clickAction,clickable) => {
  return status === controlStatuses.overdue ?
  <div className={style}>
    <Overdue onClick={clickAction} />
  </div> :CompletionCircleGray(status,style,clickAction,clickable)
}
const CompletionCircleFailure = (status,style,clickAction,clickable) => {
  return status === controlStatuses.error ?
  <div className={style}>
    <Failure onClick={clickAction} />
  </div> :
    CompletionCircleOverdue(status,style,clickAction,clickable)
}
const CompletionCircleComplete= (status,style,clickAction,clickable) => {
  return status === controlStatuses.complete ?
  <div className={style}>
    <GreenCircleWithCheckmark onClick={clickAction} />
  </div> :
    CompletionCircleFailure(status,style,clickAction,clickable);
}
export const CompletionCircle = ({ status, clickable, onClick }) => {
  const clickAction = clickable ? onClick : null;
  const style = styles.circleImage + (clickable ? ` ${styles.clickable}` : '');
  return status === controlStatuses.pending ?
    <img src={spinner} alt="loading spinner" className={styles.spinner} /> :
    CompletionCircleComplete(status,style,clickAction,clickable);
};
