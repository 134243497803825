import admin from './admin';
import exception from './exception';
import metrics from './metrics';
import navigation from './navigation';
import production from './production';
import controls from './controls';
import users from './users';
import cache from './cache';
import { combineReducers } from 'redux';

// Combining all reducers into the root reducer.
// If you make a new reducer it must be added here.
const rootReducer = combineReducers({
  admin,
  exception,
  metrics,
  navigation,
  production,
  controls,
  users,
  cache
});

export default rootReducer;
