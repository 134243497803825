// Selectors
export const selectPillarsState = ({ controls }) => controls.pillarsState;
export const selectErrorMessage = ({ controls }) => controls.errorMsg;

export const selectedSuperintendentsRoleOption = ({ controls }) => controls.roleControlOption;
export const selectedSuperintendentsAssetOption = ({ controls }) => controls.assetControlOption;
export const selectDirectReportStatus = ({ controls }) =>
  controls.directReportCheckboxStatus;

export const selectDropdownRole = ({ controls }) => controls.dropdownRoleStatus;
export const selectDropdownAsset = ({ controls }) =>
  controls.dropdownAssetStatus;

export const selectControlCardsRequestStatus = ({ controls }) =>
  controls.controlCardsRequestStatus;

export const selectControlItemRequestStatus = ({ controls }) =>
  controls.controlItemRequest;

export const selectControlTimePeriod = ({ controls: { controlTimePeriod } }) =>
  controlTimePeriod;

export const selectUserControlItems = ({
  controls: {
    controlTimePeriod: { year, month },
    controlItems,
  },
}) =>
  !!controlItems[year] && !!controlItems[year][month]
    ? controlItems[year][month]
    : [];

export const selectPillarCount = ({
  controls: { pillarCounts },
}) => pillarTitle => pillarCounts[pillarTitle];

export const selectShowFormControlModal = ({ controls }) =>
  controls.showFormControlModal;

export const selectToggleRecord = ({ controls }) => controls.focusedRecord;

export const selectShowDeleteControlModal = ({ controls }) =>
  controls.showDeleteModal;

export const selectDeleteControlStatus = ({ controls }) =>
  controls.deleteControl;

export const selectPillarOptions = ({ controls }) => controls.pillarOptions;
