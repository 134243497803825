import { routeNames, routes } from 'constants/routes';

export const moreInfoTitle= 'more information'
// Sub-sections Links for the Exception Management Page
export const links = [
  {
    url: routes.exceptionManagement.downtime,
    routeName: routeNames.exceptionManagementObject.downtime
  },
  // {
  //   url: routes.exceptionManagement.production,
  //   routeName: routeNames.exceptionManagementObject.production
  // }
];

// TODO: Uncomment allcode pertaining to noDowncodeException when this No Downcode Exceptions are added to Exceptions

// Card Titles for the Downtime in the Exception Management Page.
// completeNonProducing is used to map both Surface and Subsurface
export const downtimeCardTitles = {
  allExceptions: 'All Exceptions',
  // noDowncodeException: 'No Downcode Exception',
  surfaceExceptions: 'Surface Exceptions',
  subsurfaceExceptions: 'Sub-surface Exceptions',
  // nonWoPrRelated: 'Non WO/PR Related',
  noWorkOrder: 'No Work Order',
  woPriorityLevel: 'WO Priority Level',
  woPriorityLevel1: 'WO Priority Level 1',
  woPriorityLevel2: 'WO Priority Level 2',
  woPriorityLevel3: 'WO Priority Level 3',
  completeNonProdSurface: 'Complete but Non-Producing for Surface',

  troubleshooting: 'Troubleshooting',
  noPullRequest: 'No Pull Request',
  pullRequestStatus: 'Pull Request Status',
  witPullRequestStatus: 'WIT Pull Request Status',
  completeNonProdSubsurface: 'Complete but Non-Producing for Sub-Surface',
  completeNonProducing: 'Complete but Non-Producing',
  prActiveSchedule: 'PR - Active Schedule',
  prPrescheduling: 'PR - Prescheduling',
  prLandAndPe: 'PR - Land & PE Review',
  prWbsReview: 'PR - WBS Review',
  prPlannerReview: 'PR - Planner Review',
  completeNonProducingWO: 'Complete but Non-Producing WO',
  witPrLandAndPe:'Pending-LandReview or Pending-PEReview',
  witPrWIEReview: 'Pending-WIEReview',
  witPrWIETLSuperApproval: 'Pending-WIETL/SuperApproval',
  witPrEconomicsAFE: 'Pending-Economics/ AFE',
  witPrPreScheduling: 'Pending-PreScheduling',
  witPrJobCompletion: 'Pending-JobCompletion'
};

// Primary Cards on the Downtime Exception Management Page
export const selectPrimaryCards = [
  // {
  //   name: downtimeCardTitles.noDowncodeException,
  //   value: downtimeCardTitles.noDowncodeException
  // },
  {
    name: downtimeCardTitles.surfaceExceptions,
    value: downtimeCardTitles.surfaceExceptions
  },
  {
    name: downtimeCardTitles.subsurfaceExceptions,
    value: downtimeCardTitles.subsurfaceExceptions
  },
  // {
  //   name: downtimeCardTitles.nonWoPrRelated,
  //   value: downtimeCardTitles.nonWoPrRelated
  // }
];

//time selection

export const selectTime = [
  {
    name: 'Hours',
    value: 'hours'
  },
  {
    name: 'Days',
    value: 'days'
  },
];

/* Mapping Card Titles to API Call Parameters for Exception Table API
 Right now the 2 API calls are:
  - All Exceptions: no query param
  - Troubleshooting: 'troubleshooting' as the query param

  If you add a new table/api call, you must map it here from the card title.
 */
export const downtimeTableApiTitles = {
  [downtimeCardTitles.allExceptions]: null,
  [downtimeCardTitles.subsurfaceExceptions]:'subsurfaceException',
  [downtimeCardTitles.surfaceExceptions]:'surfaceException',
  [downtimeCardTitles.troubleshooting]: 'troubleshooting',
  [downtimeCardTitles.noPullRequest]: 'no Pull Request',
  [downtimeCardTitles.pullRequestStatus]: 'pull Request Status',
  [downtimeCardTitles.completeNonProdSubsurface]:
    'Complete but Non-Producing for Sub-Surface',
    [downtimeCardTitles.noWorkOrder]: 'no work order',
    [downtimeCardTitles.woPriorityLevel]: 'WO Priority Level',
    [downtimeCardTitles.completeNonProdSurface]: 'Complete but Non-Producing for Surface'
 
};

// This array contains all of the Primary Cards that DO NOT have Secondary Card children.
export const noSecondaryDowntimeList = [
  downtimeCardTitles.allExceptions,
  downtimeCardTitles.surfaceExceptions,
  downtimeCardTitles.subsurfaceExceptions
  // downtimeCardTitles.noDowncodeException,
  // downtimeCardTitles.nonWoPrRelated
];

// This contains all of the hierarchy for the Primary Cards that DO have Secondary Card children.
export const secondaryHeirarchy = {
  [downtimeCardTitles.surfaceExceptions]: [
    downtimeCardTitles.noWorkOrder,
    downtimeCardTitles.woPriorityLevel,
    downtimeCardTitles.completeNonProdSurface,
  ],  
  [downtimeCardTitles.subsurfaceExceptions]: [
    downtimeCardTitles.troubleshooting,
    downtimeCardTitles.noPullRequest,
    downtimeCardTitles.pullRequestStatus,
    downtimeCardTitles.completeNonProdSubsurface,
    
  ]
};

export const subsurfaceCategories = {
  [downtimeCardTitles.subsurfaceExceptions]: [
    downtimeCardTitles.troubleshooting,
    downtimeCardTitles.noPullRequest,
    downtimeCardTitles.pullRequestStatus,
    downtimeCardTitles.completeNonProdSubsurface,
    downtimeCardTitles.prActiveSchedule,
    downtimeCardTitles.prPrescheduling,
    downtimeCardTitles.prLandAndPe,
    downtimeCardTitles.prWbsReview,
    downtimeCardTitles.prPlannerReview,
    downtimeCardTitles.witPullRequestStatus,
    downtimeCardTitles.witPrLandAndPe,
    downtimeCardTitles.witPrWIEReview,
    downtimeCardTitles.witPrWIETLSuperApproval,
    downtimeCardTitles.witPrEconomicsAFE,
    downtimeCardTitles.witPrPreScheduling,
    downtimeCardTitles.witPrJobCompletion
  ]
};
export const surfaceCategories = {
  [downtimeCardTitles.surfaceExceptions]: [
    downtimeCardTitles.noWorkOrder,
    downtimeCardTitles.woPriorityLevel,
    downtimeCardTitles.woPriorityLevel1,
    downtimeCardTitles.woPriorityLevel2,
    downtimeCardTitles.woPriorityLevel3,
    downtimeCardTitles.completeNonProdSurface,
  ]
};
// Text for the Adjust Threshold Modal
export const thresholdAdjustModalLabels = {
  productionTeam: 'Production Team:',
  assetModified: 'Asset Modified:',
  thresholdCategory: ' Threshold Category:',
  Troubleshooting: 'Troubleshooting (DU)',
  'No Pull Request': 'No Pull Request',
  'Pull Request Status': 'Pull Request Status',
  'PR - Active Schedule': 'Active Schedule',
  'PR - Prescheduling': 'Prescheduling',
  'PR - Land & PE Review': 'Land & PE Review',
  'PR - WBS Review': 'WBS Review',
  'PR - Planner Review': 'Planner Review',
  'Complete but Non-Producing for Sub-Surface': 'Complete but Non-Producing PR',
  'Complete but Non-Producing': 'Complete but Non-Producing PR',
  'No Work Order': 'No Work Order',
  // 'No Downcode Exception': 'No Downcode Exception',
  // 'Non WO/PR Related': 'Non WO/PR Related',
  'WO Priority Level': 'Incomplete Work Order Priority',
  'WO Priority Level 1': 'Incomplete Priority WO P1',
  'WO Priority Level 2': 'Incomplete Priority WO P2',
  'WO Priority Level 3': 'Incomplete Priority WO P3',
  'WIT Pull Request Status':'WIT Pull Request Status',
  'Complete but Non-Producing for Surface': 'Complete but Non-Producing WO',
  'Pending-LandReview or Pending-PEReview':'Pending-LandReview or Pending-PEReview',
  'Pending-WIEReview': 'Pending-WIEReview',
  'Pending-WIETL/SuperApproval': 'Pending-WIETL/SuperApproval',
  'Pending-Economics/ AFE': 'Pending-Economics/ AFE',
  'Pending-PreScheduling': 'Pending-PreScheduling',
  'Pending-JobCompletion': 'Pending-JobCompletion'
};

export const thresholdModalTitle = 'asset threshold adjustments';

export const exceptionCardsInitial = {
  Troubleshooting: { exceptions: 0, newExceptions: 0 },
  'No Pull Request': { exceptions: 0, newExceptions: 0 },
  'Pull Request Status': { exceptions: 0, newExceptions: 0 },
  'Complete but Non-Producing for Sub-Surface': {
    exceptions: 0,
    newExceptions: 0
  },
  'No Work Order': { exceptions: 0, newExceptions: 0 },
  'WO Priority Level': { exceptions: 0, newExceptions: 0 },
  // 'No Downcode Exception': { exceptions: 0, newExceptions: 0 },
  // 'Non WO/PR Related': { exceptions: 0, newExceptions: 0 },
  'Complete but Non-Producing for Surface': { exceptions: 0, newExceptions: 0 },
  'PR - Active Schedule': { exceptions: 0, newExceptions: 0 },
  'PR - Prescheduling': { exceptions: 0, newExceptions: 0 },
  'PR - Land & PE Review': { exceptions: 0, newExceptions: 0 },
  'PR - WBS Review': { exceptions: 0, newExceptions: 0 },
  'PR - Planner Review': { exceptions: 0, newExceptions: 0 },
  'WO Priority Level 1': { exceptions: 0, newExceptions: 0 },
  'WO Priority Level 2': { exceptions: 0, newExceptions: 0 },
  'WO Priority Level 3': { exceptions: 0, newExceptions: 0 },
  allExceptions: { exceptions: 0, newExceptions: 0 },
  // noDowncodeException: { exceptions: 0, newExceptions: 0 },
  // nonWoPrRelated: { exceptions: 0, newExceptions: 0 },
  subsurfaceExceptions: { exceptions: 0, newExceptions: 0 },
  surfaceExceptions: { exceptions: 0, newExceptions: 0 },
  pullRequestStatus: { exceptions: 0, newExceptions: 0 },
  workOrderStatus:{exceptions: 0, newExceptions: 0}
};
