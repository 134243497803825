import React from 'react';
import PropTypes from 'prop-types';
import SelectInput from 'components/common/SelectInput';
import styles from 'components/AdminPage/styles.module.css';

const determineBasis = (fullWidth, oneThird) => {
  if (fullWidth) {
    return styles.fullBasis;
  }

  if (oneThird) {
    return styles.oneThirdBasis;
  }

  return styles.halfBasis;
};

export const ModalSelectInput = ({
  disabled,
  fullWidth,
  label,
  options,
  placeholderText,
  setAction,
  state,
  stateKey,
  oneThird,
  fillSpace
}) => {
  const { errorText, text, valid, value } = state;

  return (
    <div className={determineBasis(fullWidth, oneThird)}>
      <label className={styles.label}>
        {label}
        {!valid && <span className={styles.error}>{errorText}</span>}
      </label>
      <SelectInput
        setAction={value => setAction(stateKey, value)}
        value={value}
        options={options ? options : [text]}
        label={label}
        resetAction={() => {
          return;
        }}
        showPlaceholder={value === ''}
        placeholderText={placeholderText}
        overrideStyles={value === '' ? styles.select : null}
        disabled={disabled}
        fillSpace={fillSpace}
      />
    </div>
  );
};

ModalSelectInput.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  options: PropTypes.array,
  setAction: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
  stateKey: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  oneThird: PropTypes.bool,
  placeholderText: PropTypes.string,
  fillSpace: PropTypes.bool
};
