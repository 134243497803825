import React from 'react';
import RouteDiff from 'components/ProductionManagementPage/ProductionSummary/RouteProdDiff/RouteDiff';
import { summaryTypes } from 'constants/values';
import { hierarchyLevelConstants } from 'constants/productionManagement';

export const getAssetListForTargetModal = targets => {
  const targetAssetList =
    targets.length > 0
      ? targets.map(target => {
        return (
          <option key={target.assetId} value={target.assetId}>
            {target.name}
          </option>
        );
      })
      : null;
  return targetAssetList;
};

export const getYearListForSelectedAsset = (
  assetTargetDetails,
  selectedAsset
) => {
  const yearOptionsAsset = assetTargetDetails.filter(assetDetails => {
    return assetDetails.assetId === selectedAsset;
  });
  return yearOptionsAsset[0].yearlyDto;
};
export const getLabelValuePatternForAsset = assets => {
  return assets
    ? assets.map(el => {
      return {
        value: el.assetId,
        label: el.name,
      };
    })
    : null;
};

export const getYearListForSelectedRoute = (
  routeTargetDetails,
  selectedRoute
) => {
  const yearOptionsRoute = routeTargetDetails.filter(routeDetails => {
    return routeDetails.routeId === selectedRoute;
  });
  return yearOptionsRoute[0].yearlyDto;
};

export const getRouteListForSelectedRoute = (
  routeTargetDetails,
  selectedAsset
) => {
  const routeOption = routeTargetDetails.filter(routeList => {
    return routeList.assetId === selectedAsset;
  });

  return routeOption[0].routeLevelData;
};

export const getCountLpoTable = (currentLpoEventsCard, cards, filtered) => {
  if (currentLpoEventsCard === 'Open LPO Events') {
    cards['openLPOEvents'] = filtered.length;
  } else if (currentLpoEventsCard === 'Shut-in Wells') {
    cards['shutInWells'] = filtered.length;
  } else {
    cards['thirdPartyEvents'] = filtered.length;
  }
  return cards;
};

export const getAssetName = assetName => {
  return assetName.includes(',') ? 'MULTIPLE' : assetName;
};
export const getAssetForDropDown = currentProductionSummary => {
  return currentProductionSummary.asset ? currentProductionSummary.asset.length > 0
    ? currentProductionSummary.asset.map(el => {
      return {
        label: el.name,
        value: el.id,
      };
    })
    : null : null;
};
export const getIsAsset = (currentProductionSummary, isAsset) => {
  return currentProductionSummary.type === summaryTypes.asset && isAsset
    ? true
    : false;
};
export const isHierarchyLevelEmpty = hierarchylevel => {
  return hierarchylevel === hierarchyLevelConstants.empty ||
    hierarchylevel === hierarchyLevelConstants.wellLevel
    ? true
    : false;
};
export const getCardTitle = (
  isSuperItendent,
  hierarchyLevel,
  detailCardTitles
) => {
  return isSuperItendent && hierarchyLevel === ''
    ? detailCardTitles.assetProdDiff
    : detailCardTitles.routeProdDiff;
};

export const getRouteProdDiv = (
  isSuperItendent,
  hierarchyLevel,
  currentProductionSummary
) => {
  return isSuperItendent && hierarchyLevel === ''
    ? !!currentProductionSummary.dashboardAsset &&
    currentProductionSummary.dashboardAsset.map(asset => {
      return (
        <RouteDiff
          {...asset}
          key={asset.dashboardAssetId}
          isSuperItendent={isSuperItendent}
        />
      );
    })
    : !!currentProductionSummary.routes &&
    currentProductionSummary.routes.map(route => {
      return (
        <RouteDiff
          {...route}
          key={route.id}
          hierarchyLevel={hierarchyLevel}
        />
      );
    });
};
export const getColumnForSummaryTable = (
  isSuperItendent,
  hierarchyLevel,
  tableColumnsDashboard,
  tableColumns
) => {
  return isSuperItendent && hierarchyLevel === ''
    ? tableColumnsDashboard
    : tableColumns;
};
export const getDataTypeForTableData = summaryTableData => {
  return summaryTableData[0]
    ? typeof summaryTableData[0].routeDashboardList !== 'undefined'
    : false;
};

export const getReasonCodes = reasonCodes => {
  return reasonCodes
    ? reasonCodes.map(reasonCode => {
      return {
        label: reasonCode,
        value: reasonCode,
      };
    })
    : null;
};

export const getLossRateTotal = (filtered, currentCard) => {
  let lossRateSum = 0;
  if (filtered) {
    for (let i = 0; i < filtered.length; i++) {
      lossRateSum +=
        currentCard !== 'Shut-in Wells'
          ? filtered[i].lossRate
          : filtered[i].lawtRate;
    }
  }
  return lossRateSum;
};

export const getEventLossTotal = (filtered, currentCard) => {
  let eventLossSum = 0;
  if (filtered) {
    for (let i = 0; i < filtered.length; i++) {
      eventLossSum +=
        currentCard !== 'Shut-in Wells'
          ? filtered[i].eventLoss
          : filtered[i].lawtRate * filtered[i].daysInShutIn;
    }
  }
  return eventLossSum;
};

export const getCurrentColumn = (
  isAsset,
  hierarchyLevel,
  isAllocatedProduction,
  currentSummaryCard
) => {
  return `${isAsset
    ? 'asset'
    : hierarchyLevel === hierarchyLevelConstants.wellLevel
      ? 'well-level'
      : isAllocatedProduction
        ? 'route-Allocated'
        : 'route'
    }-${currentSummaryCard}`;
};

export const isHierarchyLevelWeLL = hierarchylevel => {
  return hierarchylevel === hierarchyLevelConstants.wellLevel ? true : false;
};
