import React, { Component } from 'react';
import styles from './styles.module.css';
import { connect } from 'react-redux';
import moment from 'moment';
import { formatNumber } from 'utils/formatNumber';
import { Line } from 'react-chartjs-2';
import { requestStatuses } from 'constants/values';
import LoadingPage from 'components/common/LoadingPage';
import { getTargetHistory, selectSummaryTableData } from 'store/reducers/production';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { appInsights } from 'App';
import NoContent from 'components/common/NoContent';

const mapStateToProps = state => ({
  chartData: selectSummaryTableData(state)
});

const mapDispatchToProps = {
  getTargetHistory
};

export class TargetHistoryChart extends Component {
  render() {
    const { getTargetHistory, chartData } = this.props;

    if (!chartData || chartData.length === 0) {
      return (
        <NoContent containerStyle={styles.noData} type={'data available.'} />
      );
    }

    const data = {
      labels: chartData.map(({ date }) => date),
      datasets: [
        {
          type: 'line',
          label: 'Target Oil',
          data: chartData.map(({ targetOil }) => targetOil),
          showLine: false,
          pointBorderWidth: 0,
          pointHitRadius: 0,
          pointHoverRadius: 0,
          pointRadius: 0
        },
        {
          type: 'line',
          label: 'Target Gas',
          data: chartData.map(({ targetGas }) => targetGas),
          showLine: false,
          pointBorderWidth: 0,
          pointHitRadius: 0,
          pointHoverRadius: 0,
          pointRadius: 0
        },
        {
          type: 'line',
          label: 'Target Water',
          data: chartData.map(({ targetWater }) => targetWater || 0),
          showLine: false,
          pointBorderWidth: 0,
          pointHitRadius: 0,
          pointHoverRadius: 0,
          pointRadius: 0
        },
        {
          type: 'line',
          label: 'Target BOED',
          borderColor: '#b2cc34',
          borderWidth: 2,
          backgroundColor: 'rgba(0,0,0,0)',
          data: chartData.map(({ targetBOED }) => targetBOED),
          steppedLine: 'middle',
          pointBackgroundColor: '#b2cc34',
          pointBorderWidth: 0,
          pointHitRadius: 30,
          pointHoverRadius: 0,
          pointRadius: 0
        }
      ]
    };

    try {
      // Make an array of just the targetBOED values.
      const boedArr = chartData.map(({ targetBOED }) => targetBOED);

      // Take the biggest number in the array.
      const maxBoed = Math.max(...boedArr);

      // Set the Max Y Axis Value to the max number + 25% and round up to nearest Thousand.
      const maxYAxisValue = Math.round((maxBoed * 1.25) / 1000) * 1000;

      return (
        <div className={styles.chart}>
          <Line
            data={data}
            height={300}
            legend={{
              display: false
            }}
            options={{
              maintainAspectRatio: false,
              scales: {
                xAxes: [
                  {
                    stacked: false,
                    ticks: {
                      callback: value => moment(value).format('M/D'),
                      fontColor: '#333333',
                      fontFamily: 'Gotham',
                      fontStyle: '500',
                      fontSize: 12,
                      padding: 10,
                      source: 'labels',
                      maxTicksLimit: 10
                    },
                    gridLines: {
                      drawTicks: false,
                      zeroLineColor: '#dbdcdd',
                      borderDash: [2, 4]
                    }
                  }
                ],
                yAxes: [
                  {
                    stacked: false,
                    ticks: {
                      callback: value => formatNumber(value),
                      fontColor: '#aaadb3',
                      fontFamily: 'Gotham',
                      fontStyle: '500',
                      fontSize: 10,
                      padding: 10,
                      maxTicksLimit: 5,
                      beginAtZero: true,
                      suggestedMin: 0,
                      stepSize: 1000,
                      min: 0,
                      max: maxYAxisValue
                    },
                    gridLines: {
                      drawTicks: false,
                      zeroLineColor: '#dbdcdd',
                      borderDash: [2, 4]
                    }
                  }
                ]
              },
              tooltips: {
                enabled: true,
                backgroundColor: '#FFFFFF',
                titleFontSize: 0,
                titleSpacing: 0,
                titleMarginBottom: 0,
                bodyFontFamily: 'Gotham',
                bodyFontSize: 12,
                bodyFontColor: '#333333',
                bodyFontStyle: 'bold',
                cornerRadius: 8,
                borderColor: '#dbdcdd',
                borderWidth: 1,
                displayColors: false,
                mode: 'label',
                bodySpacing: 4,
                xPadding: 15,
                yPadding: 15,
                pan: {
                  enabled: false,
                  mode: 'x',
                  speed: 0.1,
                  threshold: 10,
                  rangeMin: {
                    x: moment(chartData[0].date)
                      .add(-12, 'hour')
                      .format()
                  },
                  rangeMax: {
                    x: moment(chartData[chartData.length - 1].date)
                      .add(12, 'hour')
                      .format()
                  }
                },
                zoom: {
                  enabled: false,
                  drag: false,
                  mode: 'x',
                  speed: 0.1,
                  rangeMin: {
                    x: moment(chartData[0].date).format()
                  },
                  rangeMax: {
                    x: moment(chartData[chartData.length - 1].date).format()
                  }
                },
                callbacks: {
                  label: (tooltipItem, data) => {
                    return `${
                      data.datasets[tooltipItem.datasetIndex].label
                    }: ${formatNumber(tooltipItem.yLabel)}`;
                  },
                  beforeBody: data => {
                    return `Date: ${moment(data[0].label).format('M/D')}`;
                  }
                }
              }
            }}
          />
        </div>
      );
    } catch (err) {
      appInsights.trackException({
        error: new Error(err),
        severityLevel: SeverityLevel.Error
      });

      return (
        <LoadingPage
          containerStyles={styles.loading}
          requestStatus={requestStatuses.failure}
          retryRequest={getTargetHistory}
        />
      );
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TargetHistoryChart);
