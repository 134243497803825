import React from 'react';
import PropTypes from 'prop-types';
import styles from 'components/AdminPage/styles.module.css';
import DateInput from 'components/common/DateInput';

export const ModalDatePicker = ({
  label,
  setAction,
  state,
  stateKey,
  disabled,
  oneThird
}) => {
  const { errorText, valid, value } = state;

  return (
    <div className={oneThird ? styles.oneThirdBasis : styles.basis}>
      <label className={!disabled ? styles.label : styles.disabledLabel}>
        {label}
        {!valid && <span className={styles.error}>{errorText}</span>}
      </label>
      <DateInput
        setAction={value => setAction(stateKey, value)}
        value={value}
        resetAction={() => {
          return;
        }}
        disabled={disabled}
        top={true}
      />
    </div>
  );
};

ModalDatePicker.propTypes = {
  label: PropTypes.string.isRequired,
  setAction: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
  stateKey: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  oneThird: PropTypes.bool
};
