import React from 'react';
import { formatNumber } from 'utils/formatNumber';
import { connect } from 'react-redux';
import { selectTableTargets } from 'store/reducers/production';
import PropTypes from 'prop-types';

const mapStateToProps = state => ({
  tableTargets: selectTableTargets(state)
});

export const TargetBoedCell = ({ tableTargets, route }) => (
  <span>
    {formatNumber(
      tableTargets[route.routeId]
        ? tableTargets[route.routeId].boed
        : route.boed.target
    )}
  </span>
);

TargetBoedCell.propTypes = {
  tableTargets: PropTypes.object,
  route: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(TargetBoedCell);
