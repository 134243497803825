import React, { Component } from 'react';
import { lpoEventDetailModalLabels, lpoScheduleStatus } from 'constants/productionManagement';
import styles from './styles.module.css';
import { formatNumber } from 'utils/formatNumber';
import { connect } from 'react-redux';
import { selectCurrentLpoEventDetails, selectCurrentModalPageIndex } from 'store/reducers/production';
import moment from 'moment';
import Popover from 'components/common/Popover';
import { prStatusSteps } from 'constants/statusSteps'
import StepComponent from 'components/common/StepComponent'

const labels = lpoEventDetailModalLabels;

const mapStateToProps = state => ({
  event: selectCurrentLpoEventDetails(state),
  currentPRIndex: selectCurrentModalPageIndex(state)
});

export class OpenPullRequestModal extends Component {
  render() {
    const { event } = this.props;
    let { currentPRIndex } = this.props;
    const { pullRequest } = event.wO_PR;
    const hasScheduleDate =
      pullRequest[currentPRIndex].scheduleDate && (pullRequest[currentPRIndex].scheduleDate !== '' || pullRequest[currentPRIndex].scheduleDate !== '0001-01-01T00:00:00');
    const hasSubmittedDate =
      pullRequest[currentPRIndex].submittedDate && (pullRequest[currentPRIndex].submittedDate !== '' || pullRequest[currentPRIndex].submittedDate !== '0001-01-01T00:00:00')
    //const lastStepIgnore = lpoScheduleStatus[pullRequest[currentPRIndex].status] !== 'Complete' ?true :false;
    const lastStepIgnore = (pullRequest[currentPRIndex].status === 'Complete') ||
      (pullRequest[currentPRIndex].status.startsWith('Resolved')
        && !pullRequest[currentPRIndex].status.endsWith('Error')
        && !pullRequest[currentPRIndex].status.endsWith('Divest'))
      ? false : true;
    const initialDowndate = event.initialDownDate ? (moment(event.initialDownDate).format('M/D/YYYY')) : '';
    return (
      <div className={styles.container}>
        <div className={styles.stepWrapper}>
          <StepComponent status={lpoScheduleStatus[pullRequest[currentPRIndex].status]} statusSteps={prStatusSteps} lastStepIgnore={lastStepIgnore} />
        </div>
        <div className={styles.topDetails}>
          <ul className={styles.detailColumn}>
            <li>
              <span>{labels.wellName}</span>
              {event.wellName}
            </li>
            <li>
              <span>{labels.rigScheduled}</span>
              {hasScheduleDate && (moment(pullRequest[currentPRIndex].scheduleDate).format('M/D/YYYY'))}
            </li>

            <li>
              <span>{labels.collectionPoint}</span>
              {event.collectionPoint}
            </li>
            <li>
              <span>{labels.route}</span>
              {event.route}
            </li>
            <li>
              <span>{labels.downTimeType}</span>
              {event.downTimeType}
            </li>
            <li>
              <span>{labels.lpoType}</span>
              {event.lpoType}
            </li>
            <li className={styles.noboldli}>
              <span>{labels.lpoStatus}</span>
              {!!pullRequest[currentPRIndex].status &&
                <Popover text={pullRequest[currentPRIndex].status} overflow={true}>
                  <span className={styles.nobold}>{pullRequest[currentPRIndex].status}</span>
                </Popover>
              }
            </li>
            <li>
              <span>{labels.initialDownDate}</span>
              {initialDowndate}
            </li>
          </ul>
          <ul className={styles.detailColumn}>

            <li className={styles.noboldli}>
              <span>{labels.submittedBy}</span>
              <Popover text={pullRequest[currentPRIndex].submittedBy} overflow={true}>
                <span className={styles.nobold}>{pullRequest[currentPRIndex].submittedBy}</span>
              </Popover>

            </li>
            <li>
              <span>{labels.wO_PR}</span>
              {pullRequest[currentPRIndex].caseId || ''}
            </li>
            <li>
              <span>{labels.daysInStep}</span>
              {pullRequest[currentPRIndex].daysInStep}
            </li>

            <li>
              <span>{labels.daysInEvent}</span>
              {(event.daysInEvent)}
            </li>
            {hasSubmittedDate && (
              <li>
                <span>{labels.submittedDate}</span>
                {moment(pullRequest[currentPRIndex].submittedDate).format('M/D/YYYY')}
              </li>)
            }
            <li>
              <span>{labels.reasonCode}</span>
              {event.reasonCode}
            </li>
          </ul>
        </div>

        <p className={styles.reason}>
          <span>{labels.reasonForCriticality}</span>
          {event.reasonForCriticality}
        </p>

        <p className={styles.affectedTableTitle}>
          <span>{labels.affectedProductionDetails}</span>
        </p>

        <table className={styles.affectedTable} cellPadding="0" cellSpacing="0">
          <thead>
            <tr>
              <th />
              <th>{labels.lossRate}</th>
              <th>{labels.eventLoss}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{labels.oilProduction}</td>
              <td>
                {formatNumber(event.affectedProductionDetails.oil.lossRate)}
                {labels.bopd}
              </td>
              <td>
                {formatNumber(event.affectedProductionDetails.oil.eventLoss)}
                {labels.boe}
              </td>
            </tr>
            <tr>
              <td>{labels.gasProduction}</td>
              <td>
                {formatNumber(event.affectedProductionDetails.gas.lossRate)}
                {labels.mcfd}
              </td>
              <td>
                {formatNumber(event.affectedProductionDetails.gas.eventLoss)}
                {labels.mcf}
              </td>
            </tr>
          </tbody>
        </table>

        <p className={styles.commentsTitle}>{labels.comments}</p>
        <p className={styles.comments}>{event.comments}</p>
      </div>
    );
  }
};

export default connect(mapStateToProps)(OpenPullRequestModal);
