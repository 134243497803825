import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from 'components/AdminPage/styles.module.css';
import CheckboxInput from 'components/common/CheckBoxInput';
import {
  addRole,
  editRole,
  deleteRole,
  toggleDeleteModal,
  turnOffDeleteModal,
  selectShowDeleteModal,
  selectAssetOptions,
  selectRoleOptions,
  selectSuperitendentOptions
} from 'store/reducers/admin';
import { selectRoleControlOptions } from 'store/reducers/navigation'
import { purgeCache } from 'store/reducers/cache';
import { selectUserInfo } from 'store/reducers/users';
import { connect } from 'react-redux';
import { ModalTextInput } from 'components/common/ModalFormItems/TextInput';
import ButtonArea from 'components/AdminPage/ButtonArea';
import ReactSelectInputSingle from 'components/common/ModalFormItems/ReactSelectInputSingle';
import ReactSelectInput from 'components/common/ModalFormItems/ReactSelectInput';
import { isValid } from 'utils/validation';
import Popover from 'components/common/Popover';
import { tooltipConstants } from 'constants/admin';
import DeleteModal from 'components/common/DeleteModal';

const mapStateToProps = state => ({
  assetOptions: selectAssetOptions(state),
  roleOptions: selectRoleOptions(state),
  userInfo: selectUserInfo(state),
  superitendentOptions: selectSuperitendentOptions(state),
  supervisorOptions: selectRoleControlOptions(state),
  showModal: selectShowDeleteModal(state)
});

const mapDispatchToProps = {
  addRole,
  editRole,
  deleteRole,
  toggleDeleteModal,
  turnOffDeleteModal,
  purgeCache
};
const returnArrayWitlLabelAndTitle = list => {
  return list !== null ? list.map(role => {
    return {
      label: role.title,
      value: role.id
    }
  }) : [];
}
const baselineRoleSecondary = (isSupervisor) => isSupervisor.value ? 'Supervisor' : 'Superintendent';
export class RoleModal extends Component {
  constructor(props) {
    super(props);
    let roleName = '';
    let isReadOnlyUser = true;
    let isSupervisor = false;
    let isSuperitendent = false;
    let supervisorList = [];
    let superintendentList = [];
    if (this.props.record) {
      roleName = this.props.record.title
      switch (this.props.record.roleCategory) {
        case 'ReadOnly':
         break;
        case 'Supervisor':
          isSupervisor = true;
          isReadOnlyUser = false;
          break;
        case 'Superintendent':
          isReadOnlyUser = false;
          isSuperitendent = true;
          break;
        default:
          break;
      }
      if (this.props.record.roleCategory === 'Supervisor') {
        superintendentList = returnArrayWitlLabelAndTitle (this.props.record.assignedSuperIntendentList)
       }
      if (this.props.record.roleCategory === 'Superintendent') {
        supervisorList = returnArrayWitlLabelAndTitle (this.props.record.assignedSupervisorsList) 
      }
    }
    this.state = {
      formItems: {
        roleName: {
          value: roleName,
          errorText: '',
          valid: roleName !== '',
          key: 'roleName',
          required: true
        },
        isReadOnlyUser: {
          value: isReadOnlyUser,
          valid: true,
          key: 'isReadOnlyUser',
          required: false
        },
        isSupervisor: {
          value: isSupervisor,
          valid: true,
          key: 'isSupervisor',
          required: false
        },
        isSuperitendent: {
          value: isSuperitendent,
          valid: true,
          key: 'isSuperitendent',
          required: false
        },
        supervisorList: {
          value: supervisorList,
          errorText: '',
          valid: supervisorList && supervisorList.length > 0,
          key: 'supervisorList',
          required: false,
        },
        superintendentList: {
          value: superintendentList,
          errorText: '',
          valid: superintendentList && superintendentList.length > 0,
          key: 'superintendentList',
          required: false,
        },
      },
      dirty: false
    };
  }

  setInputValue = (formInput, value) => {
    const { required } = this.state.formItems[formInput];
    const valid = isValid(value);
    if (formInput === 'isSuperitendent') {
      this.setState({
        formItems: {
          ...this.state.formItems,
          [formInput]: {
            ...this.state.formItems[formInput],
            value: true,
            valid,
            errorText: valid || !required ? '' : 'required'
          }
        }
      });
      if (
        this.state.formItems.isReadOnlyUser.value === true ||
        this.state.formItems.isSupervisor.value === true) {
        this.setState({
          formItems: {
            ...this.state.formItems,
            [formInput]: {
              ...this.state.formItems[formInput],
              value: true,
              valid,
              errorText: valid || !required ? '' : 'required'
            },
            isSupervisor: {
              ...this.state.formItems['isSupervisor'],
              value: false
            },
            isReadOnlyUser: {
              ...this.state.formItems['isReadOnlyUser'],
              value: false
            }
          },
          dirty: true
        });
      }
    }
    else if (formInput === 'isSupervisor') {
      this.setState({
        formItems: {
          ...this.state.formItems,
          [formInput]: {
            ...this.state.formItems[formInput],
            value: true,
            valid,
            errorText: valid || !required ? '' : 'required'
          }
        }
      });
      if (
        this.state.formItems.isReadOnlyUser.value === true ||
        this.state.formItems.isSuperitendent.value === true) {
        this.setState({
          formItems: {
            ...this.state.formItems,
            [formInput]: {
              ...this.state.formItems[formInput],
              value: true,
              valid,
              errorText: valid || !required ? '' : 'required'
            },
            isReadOnlyUser: {
              ...this.state.formItems['isReadOnlyUser'],
              value: false
            },
            isSuperitendent: {
              ...this.state.formItems['isSuperitendent'],
              value: false
            }
          },
          dirty: true
        });
      }

    }
    else if (formInput === 'isReadOnlyUser') {
      this.setState({
        formItems: {
          ...this.state.formItems,
          [formInput]: {
            ...this.state.formItems[formInput],
            value: true,
            valid,
            errorText: valid || !required ? '' : 'required'
          }
        }
      });
      if (
        this.state.formItems.isSupervisor.value === true ||
        this.state.formItems.isSuperitendent.value === true) {
        this.setState({
          formItems: {
            ...this.state.formItems,
            [formInput]: {
              ...this.state.formItems[formInput],
              value: true,
              valid,
              errorText: valid || !required ? '' : 'required'
            },
            isSupervisor: {
              ...this.state.formItems['isSupervisor'],
              value: false
            },
            isSuperitendent: {
              ...this.state.formItems['isSuperitendent'],
              value: false
            }
          },
          dirty: true
        });
      }
    }
    else {
      this.setState({
        formItems: {
          ...this.state.formItems,
          [formInput]: {
            ...this.state.formItems[formInput],
            value,
            valid,
            errorText: valid || !required ? '' : 'required'
          }
        },
        dirty: true
      });

    }

  };

  saveAttempted = invalidFormItems => {
    const stateCopy = this.state.formItems;
    invalidFormItems.forEach(({ key }) => {
      stateCopy[key] = { ...stateCopy[key], errorText: 'required' };
    });

    this.setState({
      formItems: stateCopy
    });
  };

  saveUser = () => {
    const {
      roleName,
      isReadOnlyUser,
      isSupervisor,
      superintendentList,
      supervisorList
    } = this.state.formItems;
    
    const baselineRole = isReadOnlyUser.value ? 'ReadOnly' : baselineRoleSecondary(isSupervisor);
    const mappedSuperintendents = superintendentList.value &&  superintendentList.value.length !== 0 ? [{ id: superintendentList.value.value, title: superintendentList.value.label }] : [];
    const mappedSupervisors = supervisorList.value ? supervisorList.value.map(supervisor => {
      return {
        id: supervisor.value,
        title: supervisor.label
      }
    }) : [];
    let formBody = {
      title: roleName.value,
      roleCategory: baselineRole,
      mappedSupervisors: mappedSupervisors,
      mappedSuperintendents: mappedSuperintendents
    };
    this.props.purgeCache();
    formBody = this.props.record ? { ...formBody, roleId: this.props.record.roleId } : { ...formBody };
    this.props.record ? this.props.editRole(formBody) : this.props.addRole(formBody);
  };
  deleteData = () => {
    const deleteRecordData = this.props.record.roleId;
    this.props.turnOffDeleteModal();
    this.props.deleteRole(deleteRecordData);
  };
  render() {
    const {
      roleName,
      isReadOnlyUser,
      isSupervisor,
      isSuperitendent,
      superintendentList,
      supervisorList
    } = this.state.formItems;
    const { superitendentOptions, supervisorOptions, showModal } = this.props;
    return (
      <Fragment>
        <div className={styles.flexContainer}>
          <ModalTextInput
            state={roleName}
            label={'Role Name:'}
            setAction={this.setInputValue}
            stateKey={'roleName'}
            placeholder={'Add role name...'}
            fullWidth={true}
          />
        </div>
        <label className={styles.checkboxesLabel}>BaseLine Role : </label>
        <div className={styles.checkBoxesFullBaselineRole}>
          <Popover text={tooltipConstants.tooltipReadonlyUser}>
            <CheckboxInput
              toggle={event => this.setInputValue('isReadOnlyUser', event.target.checked)}
              checked={isReadOnlyUser.value}
              text={'Read Only'}
            />
          </Popover>
          <Popover text={tooltipConstants.tooltipSupervisorUser}>
            <CheckboxInput
              toggle={event => this.setInputValue('isSupervisor', event.target.checked)}
              checked={isSupervisor.value}
              text={'Supervisor'}
            />
          </Popover>
          <Popover text={tooltipConstants.tooltipSuperitendentUser}>
            <CheckboxInput
              toggle={event => this.setInputValue('isSuperitendent', event.target.checked)}
              checked={isSuperitendent.value}
              text={'Superintendent '}
            />
          </Popover>
        </div>
        {
          isSupervisor.value &&
          <ReactSelectInputSingle
            state={superintendentList}
            label={'Superintendent Name: '}
            resetAction={() => { return;}}
            setAction={this.setInputValue}
            stateKey={'superintendentList'}
            options={superitendentOptions}
            placeholderText={'Select Superintendent'}

          />
        }
        {
          isSuperitendent.value &&
          <ReactSelectInput
            state={supervisorList}
            label={'Supervisor Name: '}
            resetAction={() => { return;}}
            setAction={this.setInputValue}
            stateKey={'supervisorList'}
            options={supervisorOptions}
            placeholderText={'Select Supervisors'}

          />
        }
        {this.props.record && (
          <button
            onClick={() => this.props.toggleDeleteModal()}
            className={styles.deleteBtnRole}
          >
            delete
          </button>
        )}
        <ButtonArea
          saveAction={this.saveUser}
          formItems={this.state.formItems}
          dirty={this.state.dirty}
          saveAttempted={this.saveAttempted}
        />

        {showModal && (
          <DeleteModal
            deleteData={this.deleteData}
            onCloseClick={() => this.props.turnOffDeleteModal()}
            title='role'
          />
        )}
      </Fragment>
    );
  }
}

RoleModal.propTypes = {
  superitendentOptions: PropTypes.array.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoleModal);
