import React, { Component, Fragment } from 'react';
import styles from './styles.module.css';
import { connect } from 'react-redux';
import { selectUserInfo } from 'store/reducers/users';
import { ModalTextInput } from 'components/common/ModalFormItems/TextInput';
import ReactSelectInputSingle from 'components/common/ModalFormItems/ReactSelectInputSingle';
import { ModalTextAreaInput } from 'components/common/ModalFormItems/TextAreaInput';
import { ModalDatePicker } from 'components/common/ModalFormItems/DatePicker';
import { AddLink } from 'components/ControlsPage/AddLink';
import moment from 'moment';
import { isValid } from 'utils/validation';
import { addControlItem } from 'store/reducers/controls';
import ButtonArea from 'components/ControlsPage/ButtonArea';
import {
  controlFrequencyOptions,
  controlFrequencyConstants,
} from 'constants/controls';
import { selectIsSupretendent } from 'store/reducers/users';
import { selectAssetControlOptions, selectRoleControlOptions } from 'store/reducers/navigation'
import {
  resetControlToolRequestStatus,
  selectToggleRecord,
  editControlItem,
  toggleDeleteControlModal,
  selectShowDeleteControlModal,
  turnOffDeleteControlModal,
  deleteControlItem,
  selectPillarOptions,
  getPillarOptions,
  selectDirectReportStatus
} from 'store/reducers/controls';
import DeleteModal from 'components/common/DeleteModal';
import ReactSelectInput from 'components/common/ModalFormItems/ReactSelectInput';
import {
  getMultiAsset,
  getMultiRole,
  getAssetForDirectReport,
  getRoleForDirectReports,
  getAssetPayloadForSuperitendent,
  getRolePayloadForSuperitendent,
} from 'utils/controls';

const mapStateToProps = state => ({
  pillarOptions: selectPillarOptions(state),
  userInfo: selectUserInfo(state),
  showToggleFocusRecord: selectToggleRecord(state),
  showModal: selectShowDeleteControlModal(state),
  isSuperItendent: selectIsSupretendent(state),
  isReportStatus: selectDirectReportStatus(state),
  roleOptions: selectRoleControlOptions(state),
  assetOptions: selectAssetControlOptions(state)
});

const mapDispatchToProps = {
  addControlItem,
  editControlItem,
  getPillarOptions,
  resetControlToolRequestStatus,
  toggleDeleteControlModal,
  turnOffDeleteControlModal,
  deleteControlItem
};

const disableDueDate = selectedFrequency => {
  const { OneTime } = controlFrequencyConstants;
  switch (selectedFrequency.value) {
    case OneTime:
      return false;
    default:
      return true;
  }
};

const getSlicedUrl = link => {
  let linkEl = link;
  if (linkEl.startsWith('//')) {
    linkEl = linkEl.slice(2);
  }
  return linkEl;
}

export class ControlForm extends Component {
  constructor(props) {
    super(props);
    let controlTitle = '';
    let controlDescription = '';
    let pillarId = '';
    let frequencysId = '';
    let dueDate = '';
    let frequencyLabel = '';
    let links = [];
    let dueDateReord = '';
    let roles = [];
    let assetoptMulti = [];

    if (this.props.showToggleFocusRecord) {
      switch (this.props.showToggleFocusRecord.frequency) {
        case 'monthly':
          frequencyLabel = 'Monthly';
          break;
        case 'weekly':
          frequencyLabel = 'Weekly';
          break;
        case 'quarterly':
          frequencyLabel = 'Quarterly';
          break;
        case 'semi-annually':
          frequencyLabel = 'Semi-Annually';
          break;
        case 'yearly':
          frequencyLabel = 'Yearly';
          break;
        case 'bi-monthly':
          frequencyLabel = 'Bi-Monthly';
          break;
        case 'bi-weekly':
          frequencyLabel = 'Bi-Weekly';
          break;
        case 'one-time':
          frequencyLabel = 'One Time';
          break;
        default:
      }
    }

    if (this.props.showToggleFocusRecord) {
      pillarId = {
        label: 'Custom',
        value: this.props.showToggleFocusRecord.pillarId,
      };

      controlDescription = this.props.showToggleFocusRecord.description;
      frequencysId = {
        label: frequencyLabel,
        value: this.props.showToggleFocusRecord.frequency,
      };

      assetoptMulti = getAssetForDirectReport(this.props.isSuperItendent, this.props.isReportStatus, this.props.assetValue.value);
      roles = getRoleForDirectReports(this.props.isSuperItendent, this.props.isReportStatus, this.props.roleValue.value);
      controlTitle = this.props.showToggleFocusRecord.title;
      dueDateReord =
        this.props.showToggleFocusRecord.frequency === 'one-time'
          ? this.props.showToggleFocusRecord.specifiedDueDate
          : '';
      dueDate = dueDateReord;
      links = this.props.showToggleFocusRecord.references.map(link => {
        if (link.url !== '' && link.name === '') {
          link.type = link.url;
        }
        return {
          url: link.url,
          type: link.type,
          name: link.name,
          referenceId: link.referenceId,
        };
      });
      links.forEach(function (obj) {
        obj.url = getSlicedUrl(obj.url)
        obj.type = getSlicedUrl(obj.type)

      });
    }
    this.state = {
      formItems: {
        controlTitle: {
          value: controlTitle,
          errorText: '',
          valid: controlTitle !== '',
          key: 'controlTitle',
          required: true,
        },
        controlDescription: {
          value: controlDescription,
          errorText: '',
          valid: controlDescription !== '',
          key: 'controlDescription',
          required: true,
        },
        pillar: {
          value: pillarId,
          valid: pillarId !== '',
          errorText: '',
          key: 'pillar',
          required: true,
        },
        frequencys: {
          value: frequencysId,
          errorText: '',
          valid: frequencysId !== '',
          key: 'frequencys',
          required: true,
        },
        dueDate: {
          value: dueDate,
          errorText: '',
          valid: dueDate !== '',
          key: 'dueDate',
          required: false,
        },
        links: {
          value: links,
          required: false,
          errorText: '',
          valid: links && links.length > 0,
          key: 'links',
        },
        assetoptMulti: {
          value: assetoptMulti,
          errorText: '',
          valid: assetoptMulti && assetoptMulti.length > 0,
          key: 'assetoptMulti',
          required: this.props.isReportStatus ? true : false,
        },
        roleMulti: {
          value: roles,
          errorText: '',
          valid: roles && roles.length > 0,
          key: 'roleMulti',
          required: this.props.isReportStatus ? true : false,
        },
        asset: '',
        role: '',
      },
    };
  }

  componentDidMount() {
    this.props.getPillarOptions();
  }

  setInputValue = (formInput, value) => {
    if (this.state.formItems[formInput].key === 'frequencys') {
      const { required } = this.state.formItems[formInput].value;
      const valid = isValid(value.value);
      if (value.value === 'one-time') {
        this.setState({
          formItems: {
            ...this.state.formItems,
            [formInput]: {
              ...this.state.formItems[formInput],
              value,
              valid,
              errorText: valid || !required ? '' : 'required',
            },
            dueDate: {
              ...this.state.formItems['dueDate'],
              required: true,
              valid: false,
            },
          },
        });
      } else {
        this.setState({
          formItems: {
            ...this.state.formItems,
            [formInput]: {
              ...this.state.formItems[formInput],
              value,
              valid,
              errorText: valid || !required ? '' : 'required',
            },
            dueDate: {
              ...this.state.formItems['dueDate'],
              value: '',
              required: false,
              valid: true,
              errorText: '',
            },
          },
          dirty: true,
        });
      }
    } else if (this.state.formItems[formInput].key === 'pillar') {
      const app = this.state.formItems[formInput].value.toString();
      const { required } = app;
      const valid = isValid(value.value.toString());
      this.setState({
        formItems: {
          ...this.state.formItems,
          [formInput]: {
            ...this.state.formItems[formInput],
            value,
            valid,
            errorText: valid || !required ? '' : 'required',
          },
        },
        dirty: true,
      });
    } else {
      const { required } = this.state.formItems[formInput];
      const valid = isValid(value);
      this.setState({
        formItems: {
          ...this.state.formItems,
          [formInput]: {
            ...this.state.formItems[formInput],
            value,
            valid,
            errorText: valid || !required ? '' : 'required',
          },
        },
        dirty: true,
      });
    }
  };

  saveAttempted = invalidFormItems => {
    const stateCopy = this.state.formItems;

    invalidFormItems.forEach(({ key }) => {
      stateCopy[key] = { ...stateCopy[key], errorText: 'required' };
    });

    this.setState({
      formItems: stateCopy,
    });
  };

  handleChange = (links, isPositiveNumber) => {
    let { required } = this.state.formItems['links'];
    let valid = isValid(links, isPositiveNumber);
    let errorText = valid || !required ? '' : 'required';
    const linkValid = links.map(k => {
      if (k.type !== '' && k.url === '') {
        return true;
      } else if (k.type === '' && k.url !== '') {
        return true;
      } else if (k.type === '' && k.url === '') {
        return false;
      } else {
        return false;
      }
    });

    if (linkValid.includes(true)) {
      this.setState({
        formItems: {
          ...this.state.formItems,
          links: {
            ...this.state.formItems.links,
            value: links,
            errorText: 'required',
            valid: false,
            required: true,
          },
          dirty: true,
        },
      });
    } else {
      this.setState({
        formItems: {
          ...this.state.formItems,
          links: {
            ...this.state.formItems.links,
            value: links,
            valid,
            errorText,
            required,
          },
          dirty: true,
        },
      });
    }
  };

  deleteData = () => {
    const deleteRecordData = this.props.showToggleFocusRecord.taskTypeId;
    this.props.turnOffDeleteControlModal();
    this.props.deleteControlItem(deleteRecordData);
  };

  saveCustomItems = () => {
    const {
      controlTitle,
      controlDescription,
      links,
      frequencys,
      pillar,
      dueDate,
      assetoptMulti,
      roleMulti,
    } = this.state.formItems;

    links.value.map(link => {
      return (
        (link.name = link.type),
        link.url,
        link.type,
        (link.referenceId = link.referenceId ? link.referenceId : 0)
      );
    });
    links.value.forEach(function (obj) {
      if (!obj.url.includes('//')) {
        obj.url = '//' + obj.url;
      }
    });
    const asset = this.props.userInfo.userAssets.map(a => {
      return a.assetId;
    });

    const role = this.props.userInfo.roleId;
    const roleMultiValue = getMultiRole(roleMulti);
    const multiAsset = getMultiAsset(assetoptMulti);
    const formBody = {
      title: controlTitle.value,
      description: controlDescription.value,
      pillarId: Number(pillar.value.value),
      frequency: frequencys.value.value,
      references: links.value,
      roles:
        this.props.isSuperItendent && this.props.isReportStatus
          ? getRolePayloadForSuperitendent(this.props.showToggleFocusRecord, this.props.roleValue.value, roleMultiValue)
          : [role],
      assets:
        this.props.isSuperItendent && this.props.isReportStatus
          ? getAssetPayloadForSuperitendent(this.props.showToggleFocusRecord, this.props.assetValue.value, multiAsset)
          : asset,
    };

    if (
      dueDate.value &&
      dueDate.value !== '' &&
      !disableDueDate(frequencys.value)
    ) {
      formBody.dueDate = moment(new Date(dueDate.value)).format(
        moment.HTML5_FMT.DATE
      );
    }

    if (this.props.showToggleFocusRecord) {
      formBody.isActive = true;
    }

    const taskId = this.props.showToggleFocusRecord
      ? this.props.showToggleFocusRecord.taskTypeId
      : '';
    this.props.showToggleFocusRecord
      ? this.props.editControlItem({
        taskTypeId: taskId,
        isCustomControl: true,
        formBody,
      })
      : this.props.addControlItem(formBody);
  };

  render() {
    const {
      controlTitle,
      controlDescription,
      frequencys,
      pillar,
      dueDate,
      links,
      roleMulti,
      assetoptMulti,
    } = this.state.formItems;
    const {
      pillarOptions,
      showModal,
      isSuperItendent,
      isReportStatus,
      roleOptions,
      assetOptions,
      assetValue,
      roleValue
    } = this.props;
    const pillarOption = pillarOptions.map(pillarelement => {
      return {
        label: pillarelement.name,
        value: pillarelement.pillarId,
      };
    });
    const filterPillar = pillarOption.filter(function (pillarCustom) {
      return pillarCustom.label === 'Custom';
    });
    const assetdetail = this.props.userInfo.userAssets.map(a => {
      return <li key={a.assetId}>{a.name}</li>;
    });

    const select = controlFrequencyOptions.map(a => {
      return {
        label: a.name,
        value: a.value,
      };
    });
    return (
      <Fragment>
        <div className={styles.maxheight}>
          {isSuperItendent && isReportStatus ? null : (
            <div className={styles.topDetails}>
              <ul className={styles.detailColumn}>
                <li>
                  <span className={styles.labels}>Asset:</span>
                  <ul className={styles.ulPosition}>{assetdetail}</ul>
                </li>
              </ul>
              <ul className={styles.detailColumn}>
                <li>
                  <span className={styles.labels}>Role:</span>
                  {this.props.userInfo.roleTitle}
                </li>
              </ul>
            </div>
          )}
          {isSuperItendent && isReportStatus && !(this.props.showToggleFocusRecord === undefined) ? (
            <div className={styles.topDetails}>
              <ul className={styles.detailColumn}>
                <li>
                  <span className={styles.labels}>Asset:</span>
                  <ul className={styles.ulPosition}>{assetValue.name}</ul>
                </li>
              </ul>
              <ul className={styles.detailColumn}>
                <li>
                  <span className={styles.labels}>Role:</span>
                  {roleValue.name}
                </li>
              </ul>
            </div>
          ) : null}

          <div className={styles.flexContainer}>
            <ModalTextInput
              state={controlTitle}
              label={'Control Title:'}
              setAction={this.setInputValue}
              stateKey={'controlTitle'}
              fullWidth={true}
              placeholder={'Enter action item title'}
            />

            <ModalTextAreaInput
              state={controlDescription}
              label={'Control Item Description:'}
              setAction={this.setInputValue}
              stateKey={'controlDescription'}
              fullWidth={true}
              placeholder={'Enter action item description'}
              oneThird={true}
            />
          </div>
          {isSuperItendent && isReportStatus && (this.props.showToggleFocusRecord === undefined) ? (
            <div>
              <ReactSelectInput
                state={assetoptMulti}
                label={'Link to Asset (s):'}
                resetAction={() => { return; }}
                setAction={this.setInputValue}
                stateKey={'assetoptMulti'}
                options={assetOptions}
                placeholderText={'Select assets..'}
                fullWidth={true}
              />
              <ReactSelectInput
                state={roleMulti}
                label={'Link to Role (s):'}
                resetAction={() => { return; }}
                setAction={this.setInputValue}
                stateKey={'roleMulti'}
                options={roleOptions}
                placeholderText={'Select role'}
                fullWidth={true}
              />
            </div>
          ) : null}
          <div className={styles.flexContainer}>
            <div>
              <AddLink
                onHandleChange={this.handleChange}
                state={links}
                stateKey={'links'}
                linkdetails={links}
                fullWidth={true}
              />
            </div>

            <div className={styles.flexWidth}>
              <div className={styles.oneThird}>
                <ReactSelectInputSingle
                  state={pillar}
                  label={'Pillar:'}
                  resetAction={() => { return; }}
                  setAction={this.setInputValue}
                  stateKey={'pillar'}
                  options={filterPillar}
                  fullWidthSelectInput={true}
                  placeholderText={'select Pillar'}
                />
              </div>
              <div className={styles.oneThird}>
                <ReactSelectInputSingle
                  state={frequencys}
                  label={'Control Frequency:'}
                  resetAction={() => { return; }}
                  setAction={this.setInputValue}
                  stateKey={'frequencys'}
                  options={select}
                  placeholderText={'select frequency'}
                  fullWidthSelectInput={true}
                />
              </div>

              <div className={styles.oneThird}>
                <ModalDatePicker
                  state={dueDate}
                  label={'Task Due Date:'}
                  setAction={this.setInputValue}
                  stateKey={'dueDate'}
                  disabled={disableDueDate(frequencys.value)}
                  fillspace={true}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.flexContainer}>
          <ButtonArea
            saveAction={this.saveCustomItems}
            formItems={this.state.formItems}
            dirty={this.state.dirty}
            saveAttempted={this.saveAttempted}
          />
          {this.props.showToggleFocusRecord && (
            <button
              onClick={() => this.props.toggleDeleteControlModal()}
              className={styles.deleteBtn}
            >
              delete
            </button>
          )}
        </div>
        {showModal && (
          <DeleteModal
            deleteData={this.deleteData}
            onCloseClick={() => this.props.turnOffDeleteControlModal()}
          />
        )}
      </Fragment>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ControlForm);
