import types from 'constants/actionTypes';
import { requestStatuses } from 'constants/values';
import { adminConfig, adminToolValues } from 'constants/admin';

export const initialState = {
  activeTool: adminToolValues.users,
  columns: adminConfig.users.columns,
  data: [],
  card: 'active',
  focusedRecord: null,
  requestStatus: requestStatuses.empty,
  showModal: false,
  showDeleteModal: false,
  deleteControl: false,
  errorMsg: '',
  roleOptions: [],
  assetOptions: [],
  controlOptions: [],
  pillarOptions: [],
  superitendentOptions:[],
  addRolerequest: requestStatuses.empty,
  adminToolRequest: requestStatuses.empty,
  editStandardControlrequest: requestStatuses.empty,
  editDowntimeCodeRequest: requestStatuses.empty,
  addStandardControlrequest: requestStatuses.empty,
};

export const admin = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_DATA_REQUEST:
      return {
        ...state,
        requestStatus: requestStatuses.pending,
      };
    case types.GET_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        data: payload,
        requestStatus: requestStatuses.success,
      };
    case types.GET_DATA_REQUEST_FAILURE:
      return { ...state, data: [], requestStatus: requestStatuses.failure };
    case types.GET_PILLAR_OPTIONS_REQUEST_SUCCESS:
      return {
        ...state,
        pillarOptions: payload,
      };
    case types.GET_ROLE_OPTIONS_REQUEST_SUCCESS:
      return {
        ...state,
        roleOptions: payload,
      };
    case types.GET_ASSET_OPTIONS_REQUEST_SUCCESS:
      return {
        ...state,
        assetOptions: payload,
      };
    case types.GET_CONTROL_OPTIONS_REQUEST_SUCCESS:
      return {
        ...state,
        controlOptions: payload,
      };
  case types.GET_SUPERITENDENT_LIST_REQUEST_SUCCESS:
       return {
            ...state,
            superitendentOptions: payload,
          };  
    
    case types.ADD_ROLE_REQUEST:
      return {
        ...state,
        errorMsg:'',
        adminToolRequest: requestStatuses.pending,
      };
    case types.ADD_ROLE_REQUEST_SUCCESS:
       return {
        ...state,
        adminToolRequest: requestStatuses.success,
      };
    case types.ADD_ROLE_REQUEST_FAILURE:
      return {
        ...state,
        adminToolRequest: requestStatuses.failure,
        errorMsg:'unable to save your data as an error has occurred - role with same name exists or is currently inactive- please check'
      };
      case types.EDIT_ROLE_REQUEST:
        return {
          ...state,
          errorMsg:'',
          adminToolRequest: requestStatuses.pending,
        };
      case types.EDIT_ROLE_REQUEST_SUCCESS:
         return {
          ...state,
          adminToolRequest: requestStatuses.success,
        };
      case types.EDIT_ROLE_REQUEST_FAILURE:
        return {
          ...state,
          adminToolRequest: requestStatuses.failure,
          errorMsg:'unable to save your data as an error has occurred - please check'
        };
    case types.RESET_ADD_ROLE_REQUEST:
      return {
        ...state,
        adminToolRequest:requestStatuses.empty
      }

      case types.DELETE_ROLE_REQUEST:
        return {
          ...state,
          adminToolRequest: requestStatuses.pending,
        };
      case types.DELETE_ROLE_REQUEST_SUCCESS:
        return {
          ...state,
          adminToolRequest: requestStatuses.success,
        };
      case types.DELETE_ROLE_REQUEST_FAILURE:
        return {
          ...state,
          adminToolRequest: requestStatuses.failure,
        };
      
    case types.ADD_STANDARD_CONTROL_REQUEST:
      return {
        ...state,
        adminToolRequest: requestStatuses.pending,
      };
    case types.ADD_STANDARD_CONTROL_REQUEST_SUCCESS:
      return {
        ...state,
        adminToolRequest: requestStatuses.success,
      };
    case types.ADD_STANDARD_CONTROL_REQUEST_FAILURE:
      return {
        ...state,
        adminToolRequest: requestStatuses.failure,
      };
    case types.EDIT_DOWNTIME_CODES_REQUEST:
      return {
        ...state,
        adminToolRequest: requestStatuses.pending,
      };
    case types.EDIT_DOWNTIME_CODES_REQUEST_SUCCESS:
      return {
        ...state,
        adminToolRequest: requestStatuses.success,
      };
    case types.EDIT_DOWNTIME_CODES_REQUEST_FAILURE:
      return {
        ...state,
        adminToolRequest: requestStatuses.failure,
      };
    case types.EDIT_STANDARD_CONTROL_REQUEST:
      return {
        ...state,
        adminToolRequest: requestStatuses.pending,
      };
    case types.EDIT_STANDARD_CONTROL_REQUEST_SUCCESS:
      return {
        ...state,
        adminToolRequest: requestStatuses.success,
      };
    case types.EDIT_STANDARD_CONTROL_REQUEST_FAILURE:
      const errorcontroladmin = payload.toString();
      const errorMsg = errorcontroladmin.includes('Bad Request')
        ? 'Cannot change one-time control. Please create a new control instead of updating this control.'
        : 'unable to save your data as an error has occurred - try again later';
      return {
        ...state,
        adminToolRequest: requestStatuses.failure,
        errorMsg,
      };
    case types.DELETE_STANDARD_CONTROL_REQUEST:
      return {
        ...state,
        adminToolRequest: requestStatuses.pending,
      };
    case types.DELETE_STANDARD_CONTROL_REQUEST_SUCCESS:
      return {
        ...state,
        deleteControl: true,
        adminToolRequest: requestStatuses.success,
      };
    case types.DELETE_STANDARD_CONTROL_REQUEST_FAILURE:
      return {
        ...state,
        deleteControl: false,
        adminToolRequest: requestStatuses.failure,
      };
    case types.ADMIN_TOOL_REQUEST:
      return {
        ...state,
        adminToolRequest: requestStatuses.pending,
      };
    case types.ADMIN_TOOL_REQUEST_SUCCESS:
      return {
        ...state,
        adminToolRequest: requestStatuses.success,
      };
    case types.ADMIN_TOOL_REQUEST_FAILURE:
      const obj = payload.toString();
      const errorMsg2 = obj.includes('Bad Request')
        ? 'Email is already registered'
        : 'unable to save your data as an error has occurred - try again later';
      return {
        ...state,
        adminToolRequest: requestStatuses.failure,
        errorMsg: errorMsg2,
      };
    case types.RESET_ADMIN_TOOL_REQUEST_STATUS:
      return {
        ...state,
        adminToolRequest: requestStatuses.empty,
      };
    case types.RESET_ADD_CONTROL_REQUEST_STATUS:
      return {
        ...state,
        adminToolRequest: requestStatuses.empty,
      };
    case types.RESET_EDIT_CONTROL_REQUEST_STATUS:
      return {
        adminToolRequest: requestStatuses.empty,
      };
    case types.SET_ACTIVE_TOOL:
      return {
        ...state,
        activeTool: payload,
        columns: adminConfig[payload].columns,
      };
    case types.SET_ACTIVE_CARD:
      return {
        ...state,
        card: payload,
      };
    case types.TOGGLE_MODAL:
      return {
        ...state,
        showModal: !state.showModal,
        focusedRecord: payload,
      };
    case types.TOGGLE_DELETE_MODAL:
      return {
        ...state,
        showDeleteModal: !state.showDeleteModal,
      };
    case types.TURN_OFF_MODAL:
      return {
        ...state,
        showModal: false,
        adminToolRequest: requestStatuses.empty,
      };
      
    case types.TURN_OFF_DELETE_MODAL:
      return {
        ...state,
        showDeleteModal: false,
        adminToolRequest: requestStatuses.empty,
      };
   default:
      return state;
  }
};

export default admin;

export * from './actions';
export * from './selectors';
