import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { stepActivePR, iconAdded } from 'utils/statusSteps'
import { Steps } from 'rsuite';
import styles from './styles.module.css';

class StepComponent extends Component {
  render = () => {
    const { status, statusSteps, lastStepIgnore } = this.props;
    const currentActiveStep = stepActivePR(status, statusSteps);
    return (
      <div className={styles.stepNormal}>
        <Steps current={currentActiveStep}>
          {statusSteps.map(function (name, index) {
            return (
              <Steps.Item
                key={index}
                description={name}
                icon={iconAdded(index, currentActiveStep, styles, statusSteps, lastStepIgnore)}
              />
            );
          })}
        </Steps>
      </div>
    );
  }
}

StepComponent.propTypes = {
  status: PropTypes.string,
  statusSteps: PropTypes.array

};

StepComponent.defaultProps = { status: '', statusSteps: [] };

export default StepComponent;

