import types from 'constants/actionTypes';
import { createThunk } from 'store/createThunk';
import * as backendAPI from 'api/production';

export const getSummary = createThunk({
  apiCall: backendAPI.getSummary,
  type: types.GET_PRODUCTION_SUMMARY_REQUEST,
});

export const getSummaryTable = createThunk({
  apiCall: backendAPI.getSummaryTable,
  type: types.GET_SUMMARY_TABLE_DATA_REQUEST,
});

export const getTargetHistory = createThunk({
  apiCall: backendAPI.getTargetHistory,
  type: types.GET_SUMMARY_TABLE_DATA_REQUEST,
});

export const getSummaryChart = createThunk({
  apiCall: backendAPI.getSummaryChart,
  type: types.GET_SUMMARY_CHART_DATA_REQUEST,
});

export const postAssetAdjustTarget = createThunk({
  apiCall: backendAPI.postAssetAdjustTarget,
  type: types.POST_ASSET_ADJUST_TARGET_REQUEST,
});

export const saveTableTargets = createThunk({
  apiCall: backendAPI.saveTableTargets,
  type: types.SAVE_TABLE_TARGETS_REQUEST,
});

export const getAssetLevelTargets = createThunk({
  apiCall: backendAPI.getAssetLevelTargets,
  type: types.GET_ASSET_LEVEL_TARGETS_REQUEST,
});

export const getRouteLevelTargets = createThunk({
  apiCall: backendAPI.getRouteLevelTargets,
  type: types.GET_ROUTE_LEVEL_TARGETS_REQUEST,
});

export const resetInvalidRouteTarget = () => ({
  type: types.RESET_INVALID_ROUTE_TARGET,
});

export const resetTargetRequestStatus = () => ({
  type: types.RESET_TARGET_REQUEST_STATUS,
});

export const resetTableTargetRequestStatus = () => ({
  type: types.RESET_TABLE_TARGET_REQUEST_STATUS,
});

export const setCurrentSummaryCard = summaryCard => {
  return {
    type: types.SET_CURRENT_SUMMARY_CARD,
    payload: summaryCard,
  };
};
export const setIsAllocatedProduction = bool => {
  return {
    type: types.SET_IS_ALLOCATED_PRODUCTION,
    payload: bool,
  };
};
export const setIsRouteTarget = bool => {
  return {
    type: types.SET_TARGET_TYPE,
    payload: bool,
  };
};
export const setAssetTargetDetails = obj => {
  return {
    type: types.SET_ASSET_TARGET_DETAILS,
    payload: obj,
  };
};

export const setRouteTargetDetails = obj => {
  return {
    type: types.SET_ROUTE_TARGET_DETAILS,
    payload: obj,
  };
};

export const setCollectionPoint = id => {
  return {
    type: types.SET_COLLECTION_POINT,
    payload: id,
  };
};

export const setNewTargetValues = payload => ({
  type: types.SET_NEW_TARGET_VALUES,
  payload: payload,
});

export const setCustomTargetAsset = payload => ({
  type: types.SET_CUSTOM_TARGET_ASSET,
  payload: payload,
});

export const setHeirarchy = payload => ({
  type: types.SET_HIERARCHY,
  payload: payload,
});

export const toggleCustomRouteTargetModal = () => ({
  type: types.TOGGLE_CUSTOM_ROUTE_TARGET_MODAL,
});

export const setProductionDate = date => ({
  type: types.SET_PRODUCTION_DATE,
  payload: date,
});

export const setAssetLevelPostTargetStatus = () => ({
  type: types.SET_ASSET_TARGET_POSTSTATUS,
});

export const setRouteLevelPostTargetStatus = () => ({
  type: types.SET_ROUTE_TARGET_POSTSTATUS,
});

export const resetAssetLevelPostTargetStatus = () => ({
  type: types.RESET_ASSET_TARGET_POSTSTATUS,
});

export const resetRouteLevelPostTargetStatus = () => ({
  type: types.RESET_ROUTE_TARGET_POSTSTATUS,
});

export const setSummaryTableResolvedData = resolvedData => {
  const payload = resolvedData.map(item => {
    const flattened = {};
    Object.keys(item).forEach(key => {
      if (
        key !== '_index' &&
        key !== '_nestingLevel' &&
        key !== '_original' &&
        key !== '_subRows'
      ) {
        flattened[key] = item[key];
      }
    });
    return flattened;
  }, {});
  return {
    type: types.SET_SUMMARY_TABLE_RESOLVED_DATA,
    payload: payload,
  };
};

export const toggleCustomTargetModal = () => ({
  type: types.TOGGLE_CUSTOM_TARGET_MODAL,
});

export const toggleTargetTableEditing = () => ({
  type: types.TOGGLE_TARGET_TABLE_EDITING,
});

export const updateTableTarget = payload => ({
  type: types.UPDATE_TABLE_TARGET,
  payload,
});

export const getLpoEventsTable = createThunk({
  apiCall: backendAPI.getLpoEventsTable,
  type: types.GET_PRODUCTION_LPO_EVENTS_REQUEST,
});

export const getLpoEventsInfo = createThunk({
  apiCall: backendAPI.getLpoEventsInfo,
  type: types.GET_PRODUCTION_LPO_EVENTS_INFO_REQUEST,
});

export const setCurrentLpoEventsCard = lpoEventsCard => {
  return {
    type: types.SET_CURRENT_LPO_EVENTS_CARD,
    payload: lpoEventsCard,
  };
};

export const updateFilteredLpoTable = payload => ({
  type: types.UPDATE_FILTERED_LPO_TABLE,
  payload,
});

export const toggleLpoTableFilterModal = () => ({
  type: types.TOGGLE_LPO_TABLE_FILTER_MODAL,
});

export const toggleLpoEventDetailsModal = () => ({
  type: types.TOGGLE_LPO_EVENT_DETAILS_MODAL,
});

export const setCurrentLpoEventDetails = lpoEventDetails => {
  return {
    type: types.SET_CURRENT_LPO_EVENT_DETAILS,
    payload: lpoEventDetails,
  };
};

export const setTargetHistoryDaysAgo = payload => ({
  type: types.SET_TARGET_HISTORY_DAYS_AGO,
  payload,
});

export const toggleTargetHistoryTable = () => ({
  type: types.TOGGLE_TARGET_HISTORY_TABLE,
});

export const modalPageSwapLeft = () => ({
  type: types.MODAL_PAGE_SWAP_LEFT,
});

export const modalPageSwapRight = () => ({
  type: types.MODAL_PAGE_SWAP_RIGHT,
});
