import React, { Component } from 'react';
import Modal from 'components/common/Modal';
import ReactSelectInput from 'components/common/ReactSelectInput';
import styles from './styles.module.css';

export class AssetFilterModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assets: this.props.selectedDashboardAssets,
    };
  }
  render() {
    const {
      setAssetMultiple,
      getSummary,
      getSummaryChart,
      getSummaryTable,
      getAssetLevelTargets,
      getRouteLevelTargets,
      productionAssetOptions,
      toggleAssetFilterModal,
      setHeirarchy,
    } = this.props;
    const productionAssetOptionsMultiple = productionAssetOptions.map(
      option => ({
        label: option.name,
        value: option.value,
      })
    );
    const productionAssetOptionsMultipleNew = [
      ...productionAssetOptionsMultiple,
      {
        label: 'Select All',
        value: 'All',
      },
    ];
    return (
      <Modal
        size={'modalContainerAssetFilter'}
        onCloseClick={() => toggleAssetFilterModal()}
        title="Asset Options"
      >
        <div>
          <ReactSelectInput
            options={productionAssetOptionsMultipleNew}
            resetAction={() => {return;}}
            isMulti={true}
            setAction={selectedassets => {
              selectedassets[selectedassets.length - 1].value !== 'All'
                ? this.setState({
                    assets: selectedassets,
                    all: false,
                  })
                : this.setState({
                    assets: productionAssetOptionsMultiple,
                    all: false,
                  });
            }}
            showPlaceholder={false}
            value={this.state.assets}
            fullWidth={true}
            fillSpace={true}
            label={'Select Assets'}
            disabled={productionAssetOptions.length < 2}
          />
        </div>
        <div className={styles.buttonArea}>
          <button
            className={styles.filterBtn}
            onClick={() => {
              setAssetMultiple(this.state.assets);
              setHeirarchy('');
              toggleAssetFilterModal();
              getSummary();
              getSummaryChart();
              getSummaryTable();
              getAssetLevelTargets();
              getRouteLevelTargets();
            }}
          >
            apply filters
          </button>
          <button className={styles.cancelBtn} onClick={toggleAssetFilterModal}>
            cancel
          </button>
        </div>
      </Modal>
    );
  }
}
