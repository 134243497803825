import React, { Component } from 'react';
import styles from 'components/AdminPage/AddLink/styles.module.css';
import { ReactComponent as CloseSVG } from 'assets/img/close-x-disabled.svg';
import { ReactComponent as AddSVG } from 'assets/img/blue.svg';
import { ReactComponent as CloseBlueSVG } from 'assets/img/close-x-blue.svg';

export class AddLink extends Component {
  constructor(props) {
    super(props);
    let users = [{ name: '', type: '', url: '' }];
    if (props.linkdetails.value.length > 0) {
      users = props.linkdetails.value.map(link => {
        return {
          url: link.url,
          type: link.type,
          name: link.name,
        };
      });
    }
    this.state = {
      users: users,
      flag: [],
      err: '',
    };
  }
  addClick(lastUser) {
    this.setState({
      err: '',
    });
    if (lastUser.url !== '' && lastUser.type !== '') {
      this.setState(prevState => ({
        users: [...prevState.users, { name: '', type: '', url: '' }],
      }));
    } else {
      this.setState({
        err: 'Please enter both link and text value',
      });
    }
  }

  createdUI() {
    return this.state.users.map((el, i) => (
      <div key={i} className={styles.firstPart}>
        <div className={styles.halfbasis}>
          <label className={styles.label}>
            Add Link:
            <input
              className={styles.text}
              placeholder="Add Link"
              name="url"
              value={el.url || ''}
              onChange={this.handleChange.bind(this, i)}
              onBlur={this.handleBlur.bind(this, i)}
            />
          </label>
        </div>
        <div className={styles.halfbasis}>
          <label className={styles.label}>
            Text to display:
            <input
              className={styles.text}
              placeholder="Link Description"
              name="type"
              value={el.type || ''}
              onChange={this.handleChange.bind(this, i)}
            />
          </label>
        </div>

        <div className={styles.oneTenthBasis}>
          {this.state.flag[i] && this.state.users.length !== 1 && (
            <CloseBlueSVG
              className={styles.closeblue}
              type="button"
              value="remove"
              onClick={this.removeClick.bind(this, i)}
            />
          )}
          {!this.state.flag[i] && this.state.users.length !== 1 && (
            <CloseSVG
              className={styles.close}
              type="button"
              value="remove"
              onClick={this.removeClick.bind(this, i)}
            />
          )}
          {this.state.users.length === 1 && (
            <CloseSVG
              className={styles.close}
              type="button"
              value="remove"
              onClick={this.removeClick.bind(this, i)}
            />
          )}
        </div>
      </div>
    ));
  }

  handleChange(i, e) {
    this.setState({
        err: '',
      });
      const { name, value } = e.target;
      let userslocal = [...this.state.users];
      userslocal[i] = { ...userslocal[i], [name]: value };
      this.setState({ users: [...userslocal] });
      this.props.onHandleChange(userslocal);
      if ((userslocal[i].url === '' && userslocal[i].type === '')||
       (userslocal[i].url !== '' && userslocal[i].type !== '')) {
        this.setState({
          err: ' ',
        });
      } 
      else {
        this.setState({
          err: 'Please enter both link and text value',
        });
      }
    }

  removeClick(i) {
    let userslocal = [...this.state.users];
    let usersnew = [];
    if (userslocal.length > 1) userslocal.splice(i, 1);

    usersnew = [...userslocal];

    this.setState({
      users: [...usersnew],
    });
    this.props.onHandleChange(usersnew);
  }

  handleBlur(i, ev) {
    const data = ev.target.value;
    let flag = [...this.state.flag];
    flag[i] = false;
    if (data) {
      flag[i] = true;
    }
    this.setState({ flag });
  }

  render() {
    const users = this.state.users;
    let length = users.length;
    let lastUser = users[length - 1];

    return (
      <div className={styles.addLinkRow}>
        {this.createdUI()}
        <div className={styles.oneTenthBasis}>
          <AddSVG
            className={styles.close}
            type="button"
            value="add more"
            onClick={this.addClick.bind(this, lastUser)}
          />
        </div>
        <span className={styles.error}>{this.state.err}</span>
      </div>
    );
  }
}

export default AddLink;
