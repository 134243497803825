import React, { Component, Fragment } from 'react';
import styles from './styles.module.css';
import { routeNames, routePathNames, routes } from 'constants/routes';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import classnames from 'classnames';
import {
  selectAssets,
  selectCurrentAsset,
  selectCurrentRoute,
  selectCurrentRouteLPO,
  selectRoutes,
  setAsset,
  setRoute,
  selectSelectedAssetsProdManagement,
  toggleAssetFilterModalProdManagement,
  selectCurrentAssetIdException,
  setAssetExceptionLPO,
  setRouteExceptionLPO,
  selectRoutesDefault,
  selectFls,
  selectRoleDefault,
  selectAssetDefault,
  getAssetHierarchy
} from 'store/reducers/navigation';
import {
  setDirectReportCheckbox,
  setControlRequestAssetOptions,
  setControlRequestRoleOptions,
  getUserControlItems,
  initPillarCounts,
  selectedSuperintendentsAssetOption,
  selectedSuperintendentsRoleOption,
} from 'store/reducers/controls';
import SelectInput from 'components/common/SelectInput';
import Filter from 'assets/img/filter.svg';
import {
  getUserInfo,
  selectUserInfo,
  selectIsGuestUser,
  selectIsSupretendent,
} from 'store/reducers/users';
import {
  getLpoEventsInfo,
  getLpoEventsTable,
  getSummary,
  getSummaryChart,
  getSummaryTable,
  setHeirarchy,
  selectHierarchy,
  getAssetLevelTargets,
  getRouteLevelTargets
} from 'store/reducers/production';
import {
  getExceptionDowntimeGeneralInfo,
  getExceptionDowntimeTable,
} from 'store/reducers/exception';
import CheckboxInput from 'components/common/CheckBoxInput';
import { getSelectInputForProdSummary } from 'utils/navigation';

const mapStateToProps = state => ({
  productionAssetOptions: selectAssets(state),
  productionRouteOptions: selectRoutes(state),
  defaultRouteOptions: selectRoutesDefault(state),
  selectedProductionAsset: selectCurrentAsset(state),
  selectedDefaultAsset: selectCurrentAssetIdException(state),
  selectedProductionRoute: selectCurrentRoute(state),
  selectedDefaultRoute: selectCurrentRouteLPO(state),
  isGuestUser: selectIsGuestUser(state),
  userInfo: selectUserInfo(state),
  isSuperItendent: selectIsSupretendent(state),
  prodAssets: selectSelectedAssetsProdManagement(state),
  selectFlsOptions: selectFls(state),
  assetValue: selectedSuperintendentsAssetOption(state),
  roleValue: selectedSuperintendentsRoleOption(state),
  defaultRoleValue: selectRoleDefault(state),
  defaultAssetValue: selectAssetDefault(state),
  hierarchyLevel: selectHierarchy(state)
});

const mapDispatchToProps = {
  getExceptionDowntimeGeneralInfo,
  getExceptionDowntimeTable,
  getSummary,
  getSummaryChart,
  getSummaryTable,
  getUserInfo,
  getLpoEventsTable,
  getLpoEventsInfo,
  setAsset,
  setRoute,
  toggleAssetFilterModalProdManagement,
  setAssetExceptionLPO,
  setRouteExceptionLPO,
  setControlRequestAssetOptions,
  setControlRequestRoleOptions,
  getUserControlItems,
  initPillarCounts,
  setDirectReportCheckbox,
  setHeirarchy,
  getAssetLevelTargets,
  getRouteLevelTargets,
  getAssetHierarchy
};

/* PageHeader is the blue bar at the top of the page
   Based on redux conditions and router its properties change.
   If you need dropdowns or a button in the blue bar, here
   is where you would do it. */
export class PageHeader extends Component {
  state = {
    name: routes.home,
    isTasksRoute: false,
    loaded: false,
    active: false,
  };
  componentDidMount() {
    this.props.getAssetHierarchy().then(() => this.props.getUserInfo())
  }
  componentDidUpdate() {
    if (!this.state.loaded) {
      this.props.getAssetHierarchy().then(() => this.props.getUserInfo())
      this.setState({
        loaded: true,
      });
    }
  }
  static getDerivedStateFromProps(nextProps) {
    const rootPath = nextProps.location.pathname.match(/^\/\w*\/?/)[0];
    const name = routePathNames[rootPath] || routeNames.pageNotFound;
    return { name, pathname: nextProps.location.pathname };
  }

  toggleChange = () => {
    const activeToggle = this.state.active;
    this.setState({ active: !activeToggle });
  };

  controlItemsUpdate = () => {
    this.props.getUserControlItems().then(({ type }) => {
      // If the action was a success, generate pillars state and init pillar counts
      if (!!type && (typeof type === 'string') && type.includes('SUCCESS')) {
        this.props.initPillarCounts();
      }
    });
  };

  render() {
    const {
      getExceptionDowntimeGeneralInfo,
      getExceptionDowntimeTable,
      getLpoEventsInfo,
      getLpoEventsTable,
      getSummary,
      getSummaryChart,
      getSummaryTable,
      selectedDefaultAsset,
      productionAssetOptions,
      productionRouteOptions,
      defaultRouteOptions,
      selectedProductionAsset,
      selectedProductionRoute,
      toggleAssetFilterModalProdManagement,
      setAsset,
      setRoute,
      userInfo,
      setAssetExceptionLPO,
      setRouteExceptionLPO,
      prodAssets,
      selectedDefaultRoute,
      selectFlsOptions,
      setControlRequestAssetOptions,
      setControlRequestRoleOptions,
      setDirectReportCheckbox,
      assetValue,
      roleValue,
      setHeirarchy,
      hierarchyLevel,
      getAssetLevelTargets,
      getRouteLevelTargets

    } = this.props;

    const showProductionAssetRouteFilter =
      this.state.pathname === routes.productionManagement.summary ||
      this.state.pathname === routes.productionManagementParent ||
      this.state.pathname === routes.home;

    // When to show the LPO Asset Route Filter
    const showLpoAssetRouteFilter =
      this.state.pathname === routes.productionManagement.lpoEvents;

    // When to show the Downtime Asset Route Filter
    const showDowntimeAssetRouteFilter =
      this.state.pathname === routes.exceptionManagement.downtime ||
      this.state.pathname === routes.exceptionManagementParent;

    //When to show Control asset role filter

    const showControlRoleFilter =
      this.state.pathname === routes.controls && this.props.isSuperItendent;

    return (
      <div className={styles.pageHeaderContainer}>
        <h1 className={styles.pageHeader}>{this.state.name}</h1>

        {showProductionAssetRouteFilter &&
          getSelectInputForProdSummary(
            userInfo,
            this.props.isSuperItendent,
            prodAssets,
            styles,
            Filter,
            toggleAssetFilterModalProdManagement,
            getSummary,
            getSummaryChart,
            getSummaryTable,
            selectedProductionRoute,
            productionRouteOptions,
            productionAssetOptions,
            selectedProductionAsset,
            defaultRouteOptions,
            setAsset,
            setRoute,
            setHeirarchy,
            hierarchyLevel,
            getAssetLevelTargets,
            getRouteLevelTargets
          )}
        {showLpoAssetRouteFilter && (
          <Fragment>
            <SelectInput
              options={productionAssetOptions}
              overrideStyles={styles.selectContainerSmall}
              resetAction={() => { return; }}
              setAction={asset => {
                setAssetExceptionLPO(asset);
                getLpoEventsInfo();
                getLpoEventsTable();
              }}
              showPlaceholder={false}
              value={selectedDefaultAsset}
              disableReset={true}
              label={'Production Team'}
              disabled={productionAssetOptions.length < 2}
            />

            <SelectInput
              options={defaultRouteOptions}
              overrideStyles={classnames(styles.selectContainerSmall, styles.selectInputZoomWidth)}
              resetAction={() => { return; }}
              setAction={route => {
                setRouteExceptionLPO(route);
                getLpoEventsInfo();
                getLpoEventsTable();
              }}
              showPlaceholder={false}
              value={selectedDefaultRoute}
              disableReset={true}
              label={'Route'}
            />
          </Fragment>
        )}

        {showDowntimeAssetRouteFilter && (
          <Fragment>
            <SelectInput
              options={productionAssetOptions}
              overrideStyles={styles.selectContainerSmall}
              resetAction={() => { return; }}
              setAction={asset => {
                setAssetExceptionLPO(asset);
                getExceptionDowntimeGeneralInfo();
                getExceptionDowntimeTable();
              }}
              showPlaceholder={false}
              value={selectedDefaultAsset}
              disableReset={true}
              label={'Production Team'}
              disabled={productionAssetOptions.length < 2}
            />

            <SelectInput
              options={defaultRouteOptions}
              overrideStyles={classnames(styles.selectContainerSmall, styles.selectInputZoomWidth)}
              resetAction={() => { return; }}
              setAction={route => {
                setRouteExceptionLPO(route);
                getExceptionDowntimeGeneralInfo();
                getExceptionDowntimeTable();
              }}
              showPlaceholder={false}
              value={selectedDefaultRoute}
              disableReset={true}
              label={'Route'}
            />
          </Fragment>
        )}

        {showControlRoleFilter && (
          <Fragment>
            <CheckboxInput
              toggle={event => {
                this.setState({ active: event.target.checked });
                setDirectReportCheckbox(!this.state.active);
                this.controlItemsUpdate();
              }}
              checked={this.state.active}
              text={'Direct Control Report'}
              disabled={false}
              overrideStyles={styles.directReportCheckbox}
            />
            {this.state.active ? (
              <Fragment>
                <SelectInput
                  options={selectFlsOptions}
                  overrideStyles={styles.selectContainerSmall}
                  resetAction={() => { return; }}
                  setAction={role => {
                    setControlRequestRoleOptions(role);
                    this.controlItemsUpdate();
                  }}
                  showPlaceholder={true}
                  disableReset={true}
                  value={roleValue}
                  noSort={true}
                  label={'Route'}
                />
                <SelectInput
                  options={productionAssetOptions}
                  overrideStyles={styles.selectContainerSmall}
                  resetAction={() => { return; }}
                  setAction={asset => {
                    setControlRequestAssetOptions(asset);
                    this.controlItemsUpdate();
                  }}
                  showPlaceholder={true}
                  disableReset={true}
                  value={assetValue}
                />
              </Fragment>
            ) : null}
          </Fragment>
        )}
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PageHeader)
);
