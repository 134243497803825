// Function to calculated the Barrels of Oil Equivalent D.
export const calculateBoed = (oil, gas) => {
  oil = parseInt(oil, 10);
  gas = parseInt(gas, 10);

  if (Number.isNaN(oil) || Number.isNaN(gas)) {
    return 0;
  }
  if (oil === 0 && gas === 0) {
    return 0;
  }
  if (oil < 0 || gas < 0) {
    return 0;
  }
  if (typeof oil !== 'number' || typeof gas !== 'number') {
    return 0;
  }
  // Oil + Gas and then divided by 6 provides the boed
  return Math.floor(oil + gas / 6);
};
