import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './styles.module.css';
import completionChecked from 'assets/img/completion-checked.svg';
import { resetControlToolRequestStatus } from 'store/reducers/controls';

const mapDispatchToProps = {
  resetControlToolRequestStatus
};

export const BigSuccessModal = ({ title, message, toggleModal,resetControlToolRequestStatus}) => {
  setTimeout(() => {
    toggleModal();
  }, 3000);

  useEffect(() => {
    return () => {
      resetControlToolRequestStatus();
    }
    }, [resetControlToolRequestStatus]);
  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContainer} onClick={e => e.stopPropagation()}>
        <img
          src={completionChecked}
          alt="completion-checked"
          className={styles.modalCompletionCheckedIcon}
        />
        <div className={styles.modalTitle}>{title}</div>
        <div className={styles.modalBody}>{message}</div>
      </div>
    </div>
  );
};

BigSuccessModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  toggleModal: PropTypes.func
};
export default connect(null, mapDispatchToProps)(BigSuccessModal);

