import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';
import classnames from 'classnames';

class TextAreaInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value
    };
  }

  render() {
    const {
      disabled,
      overrideStyles,
      placeholder,
      setAction
    } = this.props;

    return (
      <div className={overrideStyles}>
        <textarea
          disabled={disabled}
          className={classnames(styles.textArea, { [styles.disabled]: disabled })}
          value={this.state.value}
          onChange={event => {
            const { value } = event.target;
            this.setState({ value });
          }}
          onBlur={() => setAction(this.state.value)}
          placeholder={placeholder}
        />
      </div>
    );
  }
}

TextAreaInput.propTypes = {
  disabled: PropTypes.bool,
  overrideStyles: PropTypes.string,
  placeholder: PropTypes.string,
  setAction: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

export default TextAreaInput;
