import types from 'constants/actionTypes';
import { getLastCheckpoint } from 'utils/timestamps';

// Important function handling SUCCESS or FAILURE of _REQUEST action types.
export const createThunk = ({ apiCall, type }) => {
  const lastCheckpoint = getLastCheckpoint();
  const cacheStamp = { lastCheckpoint, type };

  return requestPayload => (dispatch, getState) => {
    const { cache } = getState();
    const endpointCapture = cacheDispatchChain(cacheStamp, dispatch);

    dispatch({ type, payload: requestPayload });

    return apiCall({ getState, payload: requestPayload, cache, cacheStamp, endpointCapture })
      .then(
        payload => dispatch({ type: `${type}_SUCCESS`, payload, requestPayload }),
        payload => dispatch({ type: `${type}_FAILURE`, payload, requestPayload }),
      );
  };
};

export const cacheDispatchChain = (cacheStamp, dispatch) =>
  endpoint =>
    response =>
      dispatch({
        type: types.CACHE_RESPONSE,
        payload: {
          cacheStamp,
          endpoint,
          response,
        },
      });

