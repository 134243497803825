import React from 'react';
import { formatNumber } from 'utils/formatNumber';
import moment from 'moment';

// This file contains the React-Table Columns configuration for the Target History Table that's on the Production Management page.

export const targetHistoryColumns = [
  {
    Header: 'Date',
    accessor: 'date',
    minWidth: 120,
    Cell: props => (
      <span>{moment(props.original.date).format('M/D/YYYY')}</span>
    ),
    headerClassName: 'centeredColumnHeader'
  },
  {
    Header: 'Target Oil',
    accessor: 'targetOil',
    minWidth: 100,
    Cell: props => <span>{formatNumber(props.original.targetOil)}</span>,
    headerClassName: 'centeredColumnHeader'
  },
  {
    Header: 'Target Gas',
    accessor: 'targetGas',
    minWidth: 100,
    Cell: props => <span>{formatNumber(props.original.targetGas)}</span>,
    headerClassName: 'centeredColumnHeader'
  },
  {
    Header: 'Target Water',
    accessor: 'targetWater',
    minWidth: 100,
    Cell: props => (
      <span>
        {props.original.targetWater
          ? formatNumber(props.original.targetWater)
          : 0}
      </span>
    ),
    headerClassName: 'centeredColumnHeader'
  },
  {
    Header: 'Target BOED',
    accessor: 'targetBOED',
    minWidth: 100,
    Cell: props => <span>{formatNumber(props.original.targetBOED)}</span>,
    headerClassName: 'centeredColumnHeader'
  }
];
