import React from 'react';
import { lpoEventDetailModalLabels, lpoStatusPlanned } from 'constants/productionManagement';
import styles from './styles.module.css';
import { formatNumber } from 'utils/formatNumber';
import { connect } from 'react-redux';
import { selectCurrentLpoEventDetails, toggleLpoEventDetailsModal } from 'store/reducers/production';
import moment from 'moment';

const labels = lpoEventDetailModalLabels;

const mapStateToProps = state => ({
  event: selectCurrentLpoEventDetails(state)
});

const mapDispatchToProps = {
  toggleLpoEventDetailsModal
};

export const OpenDetailsModal = ({ toggleLpoEventDetailsModal, event }) => {
  return (
    <div className={styles.container}>
      <div className={styles.topDetails}>
        <ul className={styles.detailColumn}>
          <li>
            <span>{labels.wellName}</span>
            {event.wellName}
          </li>
          <li>
            <span>{labels.collectionPoint}</span>
            {event.collectionPoint}
          </li>
          <li>
            <span>{labels.route}</span>
            {event.route}
          </li>
          <li>
            <span>{labels.downTimeType}</span>
            {event.downTimeType}
          </li>
          <li>
            <span>{labels.lpoType}</span>
            {event.lpoType}
          </li>
          <li>
            <span>{labels.reasonCode}</span>
            {event.reasonCode}
          </li>
        </ul>

        <ul className={styles.detailColumn}>
          <li>
            <span>{labels.initialDownDate}</span>
            {moment(event.initialDownDate).format('M/D/YYYY')}
          </li>
          <li>
            <span>{labels.daysInEvent}</span>
            {(event.daysInEvent)}
          </li>
          <li>
            <span>{labels.planned}</span>
            {!!event.status && lpoStatusPlanned[event.status]}
          </li>
        </ul>
      </div>

      <p className={styles.reason}>
        <span>{labels.reasonForCriticality}</span>
        {event.reasonForCriticality}
      </p>

      <p className={styles.affectedTableTitle}>
        <span>{labels.affectedProductionDetails}</span>
      </p>
      <table className={styles.affectedTable} cellPadding="0" cellSpacing="0">
        <thead>
        <tr>
          <th />
          <th>{labels.lossRate}</th>
          <th>{labels.eventLoss}</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>{labels.oilProduction}</td>
          <td>
            {formatNumber(event.affectedProductionDetails.oil.lossRate)}
            {labels.bopd}
          </td>
          <td>
            {formatNumber(event.affectedProductionDetails.oil.eventLoss)}
            {labels.boe}
          </td>
        </tr>
        <tr>
          <td>{labels.gasProduction}</td>
          <td>
            {formatNumber(event.affectedProductionDetails.gas.lossRate)}
            {labels.mcfd}
          </td>
          <td>
            {formatNumber(event.affectedProductionDetails.gas.eventLoss)}
            {labels.mcf}
          </td>
        </tr>
        </tbody>
      </table>

      <p className={styles.commentsTitle}>{labels.comments}</p>
      <p className={styles.comments}>{event.comments}</p>
      <button
        className={styles.closeButton}
        onClick={toggleLpoEventDetailsModal}
      >
        close
      </button>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OpenDetailsModal);
