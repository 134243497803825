export const getTrPropsForDuplicateUsers = ( rowInfo) => {
    if (rowInfo) {
      return {
        style: {
          background:
            rowInfo.original.isDuplicateUser === true ? '#FFCCCC' : '',
        },
      };
    }
    return {};
  };