import React from 'react';
import styles from './styles.module.css';
import { connect } from 'react-redux';
import { selectCurrentSummaryData } from 'store/reducers/production';
import { detailCardTitles } from 'constants/productionManagement';
import DetailCardTitle from 'components/common/DetailCardTitle';
import { formatNumber } from 'utils/formatNumber';
import { getProdDetails } from 'utils/getProdDetails'

const mapStateToProps = state => ({
  currentProductionSummary: selectCurrentSummaryData(state),
});

export const ProdDetails = ({ currentProductionSummary,isSuperItendent }) => {
  let details = {
    prodCapability: 0,
    totalOil: 0,
    totalWater: 0,
    totalGas: 0,
    productionEfficiency: 0,
  };
  details = isSuperItendent ? (currentProductionSummary.dashboardProductionDetails) : (getProdDetails (currentProductionSummary,details));
  return (
    <div className={styles.roundedSquare}>
      <DetailCardTitle title={detailCardTitles.prodDetails} />
      <table className={styles.detailsTable}>
        <tbody>
          <tr>
            <td className={styles.detailsTableKey}>Production Capability:</td>
            <td className={styles.detailsTableValue}>
              {<>{!!details && formatNumber(details.prodCapability)} BOED</>}
            </td>
          </tr>
          <tr>
            <td className={styles.detailsTableKey}>Total Oil:</td>
            <td className={styles.detailsTableValue}>
              {!!details && formatNumber(details.totalOil)} BOPD
            </td>
          </tr>
          <tr>
            <td className={styles.detailsTableKey}>Total Water:</td>
            <td className={styles.detailsTableValue}>
              {!!details && formatNumber(details.totalWater)} BWPD
            </td>
          </tr>
          <tr>
            <td className={styles.detailsTableKey}>Total Gas:</td>
            <td className={styles.detailsTableValue}>
              {!!details && formatNumber(details.totalGas)} MCFD
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default connect(mapStateToProps)(ProdDetails);
