import types from 'constants/actionTypes';
import { requestStatuses, summaryTypes ,productionEmpty} from 'constants/values';
import {
  downtimeCardTitles,
  exceptionCardsInitial,
  noSecondaryDowntimeList,
  secondaryHeirarchy
} from 'constants/exceptionManagement';
import { appInsights } from 'App';
import { aiEvents } from 'constants/aiEvents';

export const initialState = {
  currentPrimaryDowntimeCard: downtimeCardTitles.allExceptions,
  currentSecondaryDowntimeCard: downtimeCardTitles.allExceptions,
  toggleEcCommentModal: false,
  selectedException: {},
  downtimeGeneralInfo: {
    type: summaryTypes.asset,
    cards: exceptionCardsInitial,
    asset: {
      id: productionEmpty.asset.assetId
    }
  },
  downtimeTable: [],
  requestStatus: {
    downtimeGeneralInfo: requestStatuses.empty,
    downtimeTable: requestStatuses.pending,
    thresholdAdjust: requestStatuses.empty
  },
  showThresholdAdjustModal: false,
  newThresholdValues: {},
  newThresholdTimeValue: 'days',
  newThresholdTimeValueObject: {},
  newThresholdCategoryValue: downtimeCardTitles.surfaceException,
  downtimeThresholds: []
};

const exception = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_EXCEPTION_DOWNTIME_THRESHOLDS_REQUEST_SUCCESS:
      return {
        ...state,
        downtimeThresholds: payload
      };
    case types.GET_EXCEPTION_DOWNTIME_GENERAL_INFO_REQUEST:
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          downtimeGeneralInfo: requestStatuses.pending
        }
      };
    case types.GET_EXCEPTION_DOWNTIME_GENERAL_INFO_REQUEST_SUCCESS:
      const exceptionCounts = {};
      if (payload.exceptions === undefined) {
        const newThresholdTimeValueObject = {};
        return {
          ...state,
          downtimeGeneralInfo: {
            ...state.downtimeGeneralInfo,
            cards: exceptionCardsInitial
          },
         requestStatus: {
            ...state.requestStatus,
            downtimeGeneralInfo: requestStatuses.notFound
          },
          newThresholdTimeValueObject
        };
      }
      if (payload.exceptions !== undefined) {
        payload.exceptions.forEach(
          ({ exceptionName, exceptionsCount, newExceptionsCount }) => {
            exceptionCounts[exceptionName] = {
              exceptions: exceptionsCount,
              newExceptions: newExceptionsCount
            };
          }
        );


        // TODO:  Add code back in when No Downcode Exceptions return to app
        // Total of No Downcode Exceptions
        // const noDowncodeException = {
        //   exceptions:
        //   exceptionCounts[downtimeCardTitles.noDowncodeException].exceptions,
        //   newExceptions:
        //   exceptionCounts[downtimeCardTitles.noDowncodeException].newExceptions
        // };

        //total of work order exceptions
        const workOrderStatus = {
          exceptions:
            exceptionCounts[downtimeCardTitles.woPriorityLevel].exceptions +
            exceptionCounts[downtimeCardTitles.woPriorityLevel1].exceptions +
            exceptionCounts[downtimeCardTitles.woPriorityLevel2].exceptions +
            exceptionCounts[downtimeCardTitles.woPriorityLevel3].exceptions,
          newExceptions:
            exceptionCounts[downtimeCardTitles.woPriorityLevel].newExceptions +
            exceptionCounts[downtimeCardTitles.woPriorityLevel1].newExceptions +
            exceptionCounts[downtimeCardTitles.woPriorityLevel2].newExceptions +
            exceptionCounts[downtimeCardTitles.woPriorityLevel3].newExceptions
        };

        // Total of Surface Exceptions
        const surfaceExceptions = {
          exceptions:
            exceptionCounts[downtimeCardTitles.noWorkOrder].exceptions +
            exceptionCounts[downtimeCardTitles.completeNonProdSurface].exceptions +
            workOrderStatus.exceptions,
          newExceptions:
            exceptionCounts[downtimeCardTitles.noWorkOrder].newExceptions +
            exceptionCounts[downtimeCardTitles.completeNonProdSurface]
              .newExceptions +
            workOrderStatus.newExceptions
        };

        //Total of Other PR Exceptions
        const pullRequestStatus = {
          exceptions:
            exceptionCounts[downtimeCardTitles.pullRequestStatus].exceptions +
            exceptionCounts[downtimeCardTitles.prActiveSchedule].exceptions +
            exceptionCounts[downtimeCardTitles.prPrescheduling].exceptions +
            exceptionCounts[downtimeCardTitles.prLandAndPe].exceptions +
            exceptionCounts[downtimeCardTitles.prWbsReview].exceptions +
            exceptionCounts[downtimeCardTitles.prPlannerReview].exceptions+
            exceptionCounts[downtimeCardTitles.witPrLandAndPe].exceptions +
            exceptionCounts[downtimeCardTitles.witPrWIEReview].exceptions +
            exceptionCounts[downtimeCardTitles.witPrWIETLSuperApproval].exceptions +
            exceptionCounts[downtimeCardTitles.witPrEconomicsAFE].exceptions +
            exceptionCounts[downtimeCardTitles.witPrPreScheduling].exceptions +
            exceptionCounts[downtimeCardTitles.witPrJobCompletion].exceptions,
          newExceptions:
            exceptionCounts[downtimeCardTitles.pullRequestStatus].newExceptions +
            exceptionCounts[downtimeCardTitles.prActiveSchedule].newExceptions +
            exceptionCounts[downtimeCardTitles.prPrescheduling].newExceptions +
            exceptionCounts[downtimeCardTitles.prLandAndPe].newExceptions +
            exceptionCounts[downtimeCardTitles.prWbsReview].newExceptions +
            exceptionCounts[downtimeCardTitles.prPlannerReview].newExceptions+
            exceptionCounts[downtimeCardTitles.witPrLandAndPe].newExceptions +
            exceptionCounts[downtimeCardTitles.witPrWIEReview].newExceptions +
            exceptionCounts[downtimeCardTitles.witPrWIETLSuperApproval].newExceptions +
            exceptionCounts[downtimeCardTitles.witPrEconomicsAFE].newExceptions +
            exceptionCounts[downtimeCardTitles.witPrPreScheduling].newExceptions +
            exceptionCounts[downtimeCardTitles.witPrJobCompletion].newExceptions,
        };

        // Total of SubSurface Exceptions
        const subsurfaceExceptions = {
          exceptions:
            exceptionCounts[downtimeCardTitles.troubleshooting].exceptions +
            exceptionCounts[downtimeCardTitles.noPullRequest].exceptions +
            exceptionCounts[downtimeCardTitles.completeNonProdSubsurface].exceptions +
            pullRequestStatus.exceptions,
          newExceptions:
            exceptionCounts[downtimeCardTitles.troubleshooting].newExceptions +
            exceptionCounts[downtimeCardTitles.noPullRequest].newExceptions +
            exceptionCounts[downtimeCardTitles.completeNonProdSubsurface].newExceptions +
            pullRequestStatus.newExceptions
        };

        // // Total of Non WO/PR Related Exceptions
        // const nonWoPrRelated = {
        //   exceptions:
        //   exceptionCounts[downtimeCardTitles.nonWoPrRelated].exceptions,
        //   newExceptions:
        //   exceptionCounts[downtimeCardTitles.nonWoPrRelated].newExceptions
        // };

        // Total of All Primary Exceptions
        const allExceptions = {
          exceptions:
            // noDowncodeException.exceptions +
            // nonWoPrRelated.exceptions +
            subsurfaceExceptions.exceptions +
            surfaceExceptions.exceptions,
          newExceptions:
            // noDowncodeException.newExceptions +
            // nonWoPrRelated.newExceptions +
            subsurfaceExceptions.newExceptions +
            surfaceExceptions.newExceptions
        };

        // Setting all Card Counts, primary and secondary
        payload.cards = {
          ...exceptionCounts,
          allExceptions,
          // noDowncodeException,
          // nonWoPrRelated,
          subsurfaceExceptions,
          surfaceExceptions,
          pullRequestStatus,
          workOrderStatus
        };
      }



      // Get Current Threshold Values
      const newThresholdTimeValueObject = {};
      const newThresholdValues = {};
      !!payload.exceptions &&
        payload.exceptionsThreshold.forEach(
          ({ exceptionName, exceptionThreshold, exceptionThresholdinDays }) => {
            newThresholdValues[exceptionName] = exceptionThreshold;
            newThresholdTimeValueObject[exceptionName] = exceptionThresholdinDays;
          }
        );
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          downtimeGeneralInfo: requestStatuses.success
        },
        downtimeGeneralInfo: payload,
        newThresholdValues,
        newThresholdTimeValueObject
      };

    case types.GET_EXCEPTION_DOWNTIME_GENERAL_INFO_REQUEST_FAILURE:
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          downtimeGeneralInfo: requestStatuses.failure
        }
      };
    case types.GET_EXCEPTION_DOWNTIME_TABLE_REQUEST:
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          downtimeTable: requestStatuses.pending
        }
      };
    case types.GET_EXCEPTION_DOWNTIME_TABLE_REQUEST_SUCCESS:
     return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          downtimeTable: requestStatuses.success
        },
        downtimeTable: payload
      };
    case types.GET_EXCEPTION_DOWNTIME_TABLE_REQUEST_FAILURE:
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          downtimeTable: requestStatuses.failure
        }
      };
    case types.SET_EXCEPTION_PRIMARY_DOWNTIME_CARD:
      let currentSecondaryDowntimeCard;
      if (noSecondaryDowntimeList.includes(payload)) {
        currentSecondaryDowntimeCard = payload;
      } else {
        currentSecondaryDowntimeCard = secondaryHeirarchy[payload][0];// secondary card set here....
      }
      return {
        ...state,
        currentPrimaryDowntimeCard: payload,
        currentSecondaryDowntimeCard,
        newThresholdCategoryValue:
          payload === downtimeCardTitles.allExceptions
            ? downtimeCardTitles.surfaceException
            : payload
      };
    case types.SET_EXCEPTION_SECONDARY_DOWNTIME_CARD:
      return {
        ...state,
        currentSecondaryDowntimeCard: payload
      };
    case types.TOGGLE_THRESHOLD_ADJUST_MODAL:
      return {
        ...state,
        showThresholdAdjustModal: !state.showThresholdAdjustModal
      };
    case types.TOGGLE_EC_COMMENT_MODAL:
      return {
        ...state,
        toggleEcCommentModal: !state.toggleEcCommentModal,
        selectedException: payload
      };

    case types.RESET_THRESHOLD_ADJUST_REQUEST_STATUS:
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          thresholdAdjust: requestStatuses.empty
        }
      };
    case types.SET_NEW_THRESHOLD_VALUES:
      return {
        ...state,
        newThresholdValues: payload
      };
    case types.SET_NEW_THRESHOLD_CATEGORY_VALUE:
      return {
        ...state,
        newThresholdCategoryValue: payload
      };
    case types.SET_NEW_TIME_VALUE:
      return {
        ...state,
        newThresholdTimeValue: payload
      };

    case types.PUT_THRESHOLD_ADJUST_REQUEST:
      appInsights.trackEvent({ name: aiEvents.updateAssetThreshold });
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          thresholdAdjust: requestStatuses.pending
        }
      };
    case types.PUT_THRESHOLD_ADJUST_REQUEST_SUCCESS:
      return {
        ...state,
        showThresholdAdjustModal: false,
        requestStatus: {
          ...state.requestStatus,
          thresholdAdjust: requestStatuses.success
        }
      };
    case types.PUT_THRESHOLD_ADJUST_REQUEST_FAILURE:
      appInsights.trackEvent({ name: aiEvents.updateAssetThresholdFailure });
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          thresholdAdjust: requestStatuses.failure
        }
      };
    default:
      return state;
  }
};

export default exception;
export * from './actions';
export * from './selectors';
