export const getMultiAsset = assetoptMulti => {
  return assetoptMulti.value && assetoptMulti.value.length > 0
    ? assetoptMulti.value.map(asset => asset.value)
    : [];
};
export const getMultiRole = roleMulti => {
  return roleMulti.value.map(role => parseInt(role.value));
};
export const getToggleComplete = (
  isReport,
  isSuperItendent,
  toggleTaskComplete
) => {
  return isReport && isSuperItendent ? null : () => toggleTaskComplete();
};
export const getCompletionClass = (isSuperItendent, isReport, styles) => {
  return isSuperItendent && isReport
    ? styles.completionCheckDisable
    : styles.completionCheck;
};

export const getAssetProp = (assetList,assetValue) =>{
  return Array.isArray(assetList) ? assetList.filter( asset => asset.value === assetValue):null;
}
export const getRoleProp = (roleList,roleValue) =>{
  return Array.isArray(roleList) ? roleList.filter( role => role.value.toString() ===  roleValue.toString()):null;
}

export const getAssetForDirectReport = (isSuperItendent,isReportStatus,value) =>{
  return isSuperItendent && isReportStatus ? [value]:[];
}
export const getRoleForDirectReports = (isSuperItendent,isReportStatus,value) =>{
  return isSuperItendent && isReportStatus ? [value]:[];
}
export const getRolePayloadForSuperitendent = (showToggleFocusRecord,value,roleMultiValue)=>{
 return showToggleFocusRecord? [value]:  roleMultiValue
}
export const getAssetPayloadForSuperitendent = (showToggleFocusRecord,value,multiAsset)=>{
  return  showToggleFocusRecord? [value]:multiAsset
 }
  

