import React from 'react';
import { connect } from 'react-redux';
import styles from './style.module.css';


export const DeleteModal = ({  onCloseClick,deleteData,title}) => {
 
  return (
      <div className={styles.modalOverlay}>
      <div className={styles.modalContainer} onClick={e => e.stopPropagation()}>
        <div className={styles.modalTitle}>Are you sure you want to delete this {title}?</div>
        <div className={styles.modalBody}>This action cannot be undone</div>
        <div className={styles.buttonsArea}>
        <button  className={styles.saveBtn} onClick={deleteData}>Yes</button>
        <button  className={styles.cancelBtn} onClick={onCloseClick}>No</button>
        </div>
    </div>
    </div>
  );
};


export default connect()(DeleteModal);
