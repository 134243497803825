import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';
import classnames from 'classnames';
import checkedImg from 'assets/img/inputs/checkbox-checked.svg';
import checkedDisabledImg from 'assets/img/inputs/checkbox-checked-disabled.svg';
import uncheckedImg from 'assets/img/inputs/checkbox-unchecked.svg';
import uncheckedDisabledImg from 'assets/img/inputs/checkbox-unchecked-disabled.svg';

const getSvg = (checked, disabled) => {
  if (checked && !disabled) {
    return checkedImg;
  } else if (checked && disabled) {
    return checkedDisabledImg;
  } else if (!checked && !disabled) {
    return uncheckedImg;
  } else if (!checked && disabled) {
    return uncheckedDisabledImg;
  }
};

class CheckboxInput extends Component {
  render = () => {
    const {
      checked,
      disabled,
      overrideStyles,
      text,
      toggle,
      value,
      isTextLeft
    } = this.props;

    return (
      <label className={classnames(styles.checkboxOption, overrideStyles)}>
        {isTextLeft && <span className={styles.textLeft}>{text}</span>}
        <input
          disabled={disabled}
          value={value}
          className={styles.checkbox}
          defaultChecked={checked}
          onChange={toggle}
          type="checkbox"
        />
        <img
          src={getSvg(checked, disabled)}
          alt={checked ? 'checked' : 'unchecked'}
          className={styles.checkboxImg}
        />
        {!isTextLeft && text}
      </label>
    );
  };
}

CheckboxInput.propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  text: PropTypes.string,
  overrideStyles: PropTypes.string,
  toggle: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  isTextLeft: PropTypes.bool
};

CheckboxInput.defaultProps = { value: '' };

export default CheckboxInput;
