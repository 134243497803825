import React from 'react';
import styles from './styles.module.css';
import { connect } from 'react-redux';
import {
  selectCurrentSummaryData,
  selectHierarchy,
} from 'store/reducers/production';
import { detailCardTitles } from 'constants/productionManagement';
import DetailCardTitle from 'components/common/DetailCardTitle';
import { getCardTitle, getRouteProdDiv } from 'utils/production';

const mapStateToProps = state => ({
  currentProductionSummary: selectCurrentSummaryData(state),
  hierarchyLevel: selectHierarchy(state),
});
export const RouteProdDiff = ({
  currentProductionSummary,
  isSuperItendent,
  hierarchyLevel,
}) => (
  <div className={styles.roundedSquare}>
    <DetailCardTitle
      title={getCardTitle(isSuperItendent, hierarchyLevel, detailCardTitles)}
    />
    <div className={styles.routeSection}>
      {getRouteProdDiv(
        isSuperItendent,
        hierarchyLevel,
        currentProductionSummary
      )}
    </div>
  </div>
);

export default connect(mapStateToProps)(RouteProdDiff);
