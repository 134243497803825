import { createThunk } from 'store/createThunk';
import * as backendAPI from 'api/admin';
import types from 'constants/actionTypes';

export const getData = createThunk({
  apiCall: backendAPI.getData,
  type: types.GET_DATA_REQUEST
});

export const setActiveTool = tool => ({
  type: types.SET_ACTIVE_TOOL,
  payload: tool
});

export const setActiveCard = card => ({
  type: types.SET_ACTIVE_CARD,
  payload: card
});

export const toggleModal = record => ({
  type: types.TOGGLE_MODAL,
  payload: record
});



export const toggleDeleteModal = record => ({
  type: types.TOGGLE_DELETE_MODAL,
  payload: record
});

export const turnOffModal = record => ({
  type: types.TURN_OFF_MODAL,
  payload: record
});
export const turnOffDeleteModal = record => ({
  type: types.TURN_OFF_DELETE_MODAL,
  payload: record
});

export const getPillarOptions = createThunk({
  apiCall: backendAPI.getPillarOptions,
  type: types.GET_PILLAR_OPTIONS_REQUEST
});

export const getRoleOptions = createThunk({
  apiCall: backendAPI.getRoleOptions,
  type: types.GET_ROLE_OPTIONS_REQUEST
});

export const getAssetOptions = createThunk({
  apiCall: backendAPI.getAssetOptions,
  type: types.GET_ASSET_OPTIONS_REQUEST
});

/*export const getControlOptions = createThunk({
  apiCall: backendAPI.getControlOptions,
  type: types.GET_CONTROL_OPTIONS_REQUEST
});*/

// export const editControl = createThunk({
//   apiCall: backendAPI.editControl,
//   type: types.ADMIN_TOOL_REQUEST
// });

export const editUser = createThunk({
  apiCall: backendAPI.editUser,
  type: types.ADMIN_TOOL_REQUEST
});

export const addUser = createThunk({
  apiCall: backendAPI.addUser,
  type: types.ADMIN_TOOL_REQUEST
});

export const addRole = createThunk({
  apiCall: backendAPI.addRole,
  type: types.ADD_ROLE_REQUEST
});

export const editRole = createThunk({
  apiCall: backendAPI.editRole,
  type: types.EDIT_ROLE_REQUEST
});

export const deleteRole = createThunk({
  apiCall: backendAPI.deleteRole,
  type: types.DELETE_ROLE_REQUEST
});

export const resetAddRoleRequest = () => ({
  type: types.RESET_ADD_ROLE_REQUEST
});

export const getSuperitendentOptions = createThunk({
  apiCall: backendAPI.getSuperitendentOptions,
  type: types.GET_SUPERITENDENT_LIST_REQUEST
});

export const editTask = createThunk({
  apiCall: backendAPI.editTask,
  type: types.ADMIN_TOOL_REQUEST
});

export const editDowntimeCodes = createThunk({
  apiCall: backendAPI.editDowntimeCodes,
  type: types.EDIT_DOWNTIME_CODES_REQUEST
});

export const resetAdminToolRequestStatus = () => ({
  type: types.RESET_ADMIN_TOOL_REQUEST_STATUS
});


export const addStandardControl = createThunk({
  apiCall: backendAPI.addStandardControl,
  type: types.ADD_STANDARD_CONTROL_REQUEST
});

export const editStandardControl = createThunk({
  apiCall: backendAPI.editStandardControl,
  type: types.EDIT_STANDARD_CONTROL_REQUEST
});

export const deleteStandardControl = createThunk({
  apiCall: backendAPI.deleteStandardControl,
  type: types.DELETE_STANDARD_CONTROL_REQUEST
});