import React, { Component } from 'react';
import styles from './styles.module.css';
import { connect } from 'react-redux';
import {
  getLpoEventsInfo,
  getLpoEventsTable,
  selectCurrentLpoEventsData,
  selectLpoEventsTableData,
  selectShouldLoadData,
  selectShowLpoTableFilterModal,
  toggleLpoTableFilterModal,
  selectFilteredLpoTable,
  selectCurrentLpoEventsCard,
} from 'store/reducers/production';
import { selectCurrentAsset, setAssetExceptionLPO } from 'store/reducers/navigation';
import LpoEventsTable from './LpoEventsTable';
import { formatNumber } from 'utils/formatNumber';
import LpoEventsExportCSV from './LpoEventsExportCSV';
import TableFilterModalButton from './TableFilterModalButton';
import Modal from 'components/common/Modal';
import LpoTableFiltersModal from './LpoTableFiltersModal';
import { productionManagementTimestampToString } from 'utils/timestamps';
import Popover from 'components/common/Popover';
import InfoIcon from 'assets/img/info.svg';
import { summaryTypes } from 'constants/values';
import { getLossRateTotal,getEventLossTotal } from 'utils/production'

const mapStateToProps = state => ({
  currentProductionLpoEvents: selectCurrentLpoEventsData(state),
  selectedProductionAsset: selectCurrentAsset(state),
  lpoEventsTableData: selectLpoEventsTableData(state),
  showFilterModal: selectShowLpoTableFilterModal(state),
  shouldLoadData: selectShouldLoadData(state),
  currentCard: selectCurrentLpoEventsCard(state),
  filtered: selectFilteredLpoTable(state),
});

const mapDispatchToProps = {
  getLpoEventsInfo,
  getLpoEventsTable,
  toggleLpoTableFilterModal,
  setAssetExceptionLPO,
};

export class ProductionLpoEvents extends Component {
  state = {
    loaded: false,
  };

  componentDidMount = () => {
    if (this.props.shouldLoadData) {
      this.props.getLpoEventsInfo();
      this.setState({ loaded: true });
    }
  };

  componentDidUpdate = () => {
    if (this.props.shouldLoadData && !this.state.loaded) {
      this.props.getLpoEventsInfo();
      this.setState({ loaded: true });
    }
  };
 
  render() {
    const {
      currentProductionLpoEvents,
      showFilterModal,
      toggleLpoTableFilterModal,
      currentCard,
      setAssetExceptionLPO,
      filtered
    } = this.props;
    const totalLossRate =getLossRateTotal(filtered,currentCard);
    const affectedLPOTotal = getEventLossTotal(filtered,currentCard);
    const timestampText = productionManagementTimestampToString(
      currentProductionLpoEvents.lastUpdated
    );
    return (
      <div className={styles.layout}>
        {showFilterModal && (
          <Modal
            size={'modalContainerLpoFilter'}
            title="table filters"
            onCloseClick={toggleLpoTableFilterModal}
          >
            <LpoTableFiltersModal currentCard={currentCard} />
          </Modal>
        )}

        <div className={styles.titleRow}>
          {currentProductionLpoEvents.type === summaryTypes.asset ? (
            <div className={styles.titleAsset}>
              <div className={styles.alignStart}>
                <span className={styles.name}>
                  {!!currentProductionLpoEvents.asset &&
                    currentProductionLpoEvents.asset.name}
                </span>
                <Popover text={timestampText}>
                  <img
                    src={InfoIcon}
                    className={styles.infoIcon}
                    alt={timestampText}
                  />
                </Popover>
              </div>

              <div className={styles.alignEnd}>
                <TableFilterModalButton />
                <LpoEventsExportCSV />
              </div>
            </div>
          ) : (
            <div>
              <div
                className={styles.titleRowLink}
                onClick={() => {
                  setAssetExceptionLPO(currentProductionLpoEvents.asset.assetId);
                  this.props.getLpoEventsInfo();
                  this.props.getLpoEventsTable();
                }}
              >
                {!!currentProductionLpoEvents.route &&
                  currentProductionLpoEvents.asset.name}
              </div>
              <div className={styles.titleRoute}>
                <div className={styles.alignStart}>
                  <span className={styles.name}>
                    {!!currentProductionLpoEvents.route &&
                      currentProductionLpoEvents.route.name}
                  </span>
                  <Popover text={timestampText}>
                    <img
                      src={InfoIcon}
                      className={styles.infoIcon}
                      alt={timestampText}
                    />
                  </Popover>
                </div>
                <div className={styles.alignEnd}>
                  <TableFilterModalButton />
                  <LpoEventsExportCSV />
                </div>
              </div>
            </div>
          )}
        </div>

        <div>
          Daily Loss Rate (BOED):{' '}
          <span className={styles.totalAffected}>
            {formatNumber(totalLossRate)}   
          </span>
          <span style={{ marginLeft: '45px' }}>Total Affected LPO: </span>
          <span className={styles.totalAffected}>
            {formatNumber(affectedLPOTotal)}
          </span>
        </div>

        <div>
          <LpoEventsTable />
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductionLpoEvents);
