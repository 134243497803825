import React, { Component, Fragment } from 'react';
import ReactSelectInput from 'components/common/ModalFormItems/ReactSelectInput';
import styles from 'components/AdminPage/styles.module.css';
import { ModalTextAreaInput } from 'components/common/ModalFormItems/TextAreaInput';
import ButtonArea from 'components/AdminPage/ButtonArea';
import { connect } from 'react-redux';
import { editDowntimeCodes } from 'store/reducers/admin';
import { isValid } from 'utils/validation';

const mapDispatchToProps = {
  editDowntimeCodes,
};

export class DowntimeCode extends Component {
  constructor(props) {
    super(props);
    let reasonCodes = [];
    let description = '';

    if (this.props.record) {
      description = this.props.record.exceptionDescription;
      reasonCodes = this.props.record.assignedReasonCodeDetails.map(
        reasonCode => {
          return {
            label: reasonCode.reason,
            value: reasonCode.reason,
          };
        }
      );
    }

    this.state = {
      formItems: {
        description: {
          value: description,
          errorText: '',
          valid: description !== '',
          key: 'description',
          required: true,
        },
        reasonCodes: {
          value: reasonCodes,
          errorText: '',
          valid: reasonCodes && reasonCodes.length > 0,
          key: 'reasonCodes',
          required: true,
        },
      },
      dirty: false,
      exceptionCategory: '',
    };
  }

  setInputValue = (formInput, value) => {
    const { required } = this.state.formItems[formInput];
    const valid = isValid(value);
    this.setState({
      formItems: {
        ...this.state.formItems,
        [formInput]: {
          ...this.state.formItems[formInput],
          value,
          valid,
          errorText: valid || !required ? '' : 'required',
        },
      },
      dirty: true,
    });
  };

  saveAttempted = invalidFormItems => {
    const stateCopy = this.state.formItems;

    invalidFormItems.forEach(({ key }) => {
      stateCopy[key] = { ...stateCopy[key], errorText: 'required' };
    });

    this.setState({
      formItems: stateCopy,
    });
  };

  saveDowntimeCode = () => {
    const { description, reasonCodes } = this.state.formItems;

    const reasonCodeValues = reasonCodes.value.map(code => {
      return code.value;
    });

    const formBody = {
      exceptionDescription: description.value,
      reason: reasonCodeValues,
      downtimeType: this.props.record.downtimeType,
      exceptionTypeName: this.props.record.exceptionTypeName,
    };

    this.props.editDowntimeCodes({ formBody });
  };

  render() {
    const { reasonCodes, description } = this.state.formItems;

    const reasonCodeList = this.props.record.availableReasonCodeDetails.map(
      a => {
        return {
          label: a.reason,
          value: a.reason,
        };
      }
    );

    return (
      <div>
        <Fragment>
          <div className={styles.topDetails}>
            <ul className={styles.detailColumn}>
              <li>
                <span className={styles.labels}>Exception Category:</span>
                <ul className={styles.ulPosition}>
                  {this.props.record.downtimeType}
                </ul>
              </li>
              <li>
                <span className={styles.labels}>Exception Sub Category:</span>
                <ul className={styles.ulPosition}>
                  {this.props.record.exceptionTypeName}
                </ul>
              </li>
            </ul>
          </div>
          <ModalTextAreaInput
            state={description}
            label={' Exception Description:'}
            setAction={this.setInputValue}
            stateKey={'description'}
            placeholder={'Enter discription'}
            fullWidth={true}
          />
          <ReactSelectInput
            state={reasonCodes}
            label={'Active Reason Codes'}
            setAction={this.setInputValue}
            resetAction={() => { return;}}
            stateKey={'reasonCodes'}
            options={reasonCodeList}
            placeholderText={'Select reason codes'}
            fullWidth={true}
            dropdownPlacement={'top'}
          />
          <div className={styles.buttonContainer}>
            <ButtonArea
              saveAction={this.saveDowntimeCode}
              formItems={this.state.formItems}
              dirty={this.state.dirty}
              saveAttempted={this.saveAttempted}
            />
          </div>
        </Fragment>
      </div>
    );
  }
}

export default connect(null, mapDispatchToProps)(DowntimeCode);
