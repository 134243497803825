import React, { Fragment } from 'react';
import SelectInput from 'components/common/SelectInput';
import { hierarchyLevelConstants } from 'constants/productionManagement';
import classnames from 'classnames';

export const getSelectInputForProdSummary = (
  userInfo,
  isSuperItendent,
  prodAssets,
  styles,
  Filter,
  toggleAssetFilterModalProdManagement,
  getSummary,
  getSummaryChart,
  getSummaryTable,
  selectedProductionRoute,
  productionRouteOptions,
  productionAssetOptions,
  selectedProductionAsset,
  defaultRouteOptions,
  setAsset,
  setRoute,
  setHeirarchy,
  hierarchyLevel,
  getAssetLevelTargets,
  getRouteLevelTargets

) => {
  return userInfo.userAssets ? (
    userInfo.userAssets.length > 1 || isSuperItendent ? (
      <div className={styles.launchFiltersProd}>
        {prodAssets.length > 0 ? (
          prodAssets.length > 1 ? (
            <span>MULTIPLE</span>
          ) : (
            <span>{prodAssets[0].name}</span>
          )
        ) : null}

        <img
          className={styles.launchIconProd}
          src={Filter}
          alt={'Filter'}
          onClick={toggleAssetFilterModalProdManagement}
        />

        {!isSuperItendent ? (
          <SelectInput
            options={productionRouteOptions}
            overrideStyles={classnames(styles.selectContainerSmall, styles.selectInputZoomWidth)}
            resetAction={() => { return; }}
            setAction={route => {
              hierarchyLevel === hierarchyLevelConstants.wellLevel
                ? setHeirarchy(hierarchyLevelConstants.routeLevel)
                : setHeirarchy(hierarchyLevel);
              setRoute(route);
              getSummary();
              getSummaryChart();
              getSummaryTable();
            }}
            showPlaceholder={false}
            value={selectedProductionRoute}
            disableReset={true}
            label={'Route'}
          />
        ) : <span></span>}
      </div>
    ) : (
      <Fragment>
        <SelectInput
          options={productionAssetOptions}
          overrideStyles={styles.selectContainerSmall}
          resetAction={() => { return; }}
          setAction={asset => {
            setHeirarchy('');
            setAsset(asset);
            getSummary();
            getSummaryChart();
            getSummaryTable();
            getAssetLevelTargets();
            getRouteLevelTargets();

          }}
          showPlaceholder={false}
          value={selectedProductionAsset}
          disableReset={true}
          label={'Production Team'}
          disabled={productionAssetOptions.length < 2}
        />

        <SelectInput
          options={productionRouteOptions}
          overrideStyles={classnames(styles.selectContainerSmall, styles.selectInputZoomWidth)}
          resetAction={() => { return; }}
          setAction={route => {
            hierarchyLevel === hierarchyLevelConstants.wellLevel
              ? setHeirarchy(hierarchyLevelConstants.routeLevel)
              : setHeirarchy(hierarchyLevel);
            setRoute(route);
            getSummary();
            getSummaryChart();
            getSummaryTable();
          }}
          showPlaceholder={false}
          value={selectedProductionRoute}
          disableReset={true}
          label={'Route'}
        />
      </Fragment>
    )
  ) : (
    <Fragment>
      <SelectInput
        options={productionAssetOptions}
        overrideStyles={styles.selectContainerSmall}
        resetAction={() => { return; }}
        setAction={asset => {
          hierarchyLevel === hierarchyLevelConstants.wellLevel
            ? setHeirarchy(hierarchyLevelConstants.assetLevel)
            : setHeirarchy(hierarchyLevel);
          setAsset(asset);
          getSummary();
          getSummaryChart();
          getSummaryTable();
        }}
        showPlaceholder={false}
        value={selectedProductionAsset}
        disableReset={true}
        label={'Production Team'}
        disabled={productionAssetOptions.length < 2}
      />

      <SelectInput
        options={productionRouteOptions}
        overrideStyles={classnames(styles.selectContainerSmall, styles.selectInputZoomWidth)}
        resetAction={() => { return; }}
        setAction={route => {
          hierarchyLevel === hierarchyLevelConstants.wellLevel
            ? setHeirarchy(hierarchyLevelConstants.routeLevel)
            : setHeirarchy(hierarchyLevel);
          setRoute(route);
          getSummary();
          getSummaryChart();
          getSummaryTable();
        }}
        showPlaceholder={false}
        value={selectedProductionRoute}
        disableReset={true}
        label={'Route'}
      />
    </Fragment>
  );
};
