export const selectDowntimeGeneralInfo = ({ exception }) =>
  exception.downtimeGeneralInfo;

export const selectDowntimeGeneralInfoRequestStatus = ({ exception }) =>
  exception.requestStatus.downtimeGeneralInfo;

export const selectDowntimeTableData = ({ exception }) =>
  exception.downtimeTable;

export const selectDowntimeTableRequestStatus = ({ exception }) =>
  exception.requestStatus.downtimeTable;
export const selectCommentModal = ({ exception }) =>
  exception.toggleEcCommentModal; 

export const selectselectedException = ({ exception }) =>
  exception.selectedException;

export const selectCurrentPrimaryDowntimeCard = ({ exception }) =>
  exception.currentPrimaryDowntimeCard;
  
export const selectCurrentSecondaryDowntimeCard = ({ exception }) =>
  exception.currentSecondaryDowntimeCard;

export const selectShowThresholdAdjustModal = ({ exception }) =>
  exception.showThresholdAdjustModal;

export const selectThresholdRequestStatus = ({ exception }) =>
  exception.requestStatus.thresholdAdjust;

export const selectNewThresholdValues = ({ exception }) =>
  exception.newThresholdValues;

export const selectNewThresholdCategoryValue = ({ exception }) =>
  exception.newThresholdCategoryValue;

export const selectDowntimeThresholds = ({ exception }) =>
  exception.downtimeThresholds;

  export const selectThresholdTimeValue = ({ exception }) =>
  exception.newThresholdTimeValue;

  export const selectThresholdTimeValueObject = ({ exception }) =>
  exception.newThresholdTimeValueObject;
   