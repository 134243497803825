import { checkCache } from 'api/request';

export const apiEndpoints = {
  getAssetHierarchy: '/assets/hierarchy',
  getRoleHierarchy: '/roles/flsoptions',
};

export const getAssetHierarchy = async ({
  cache,
  cacheStamp,
  endpointCapture,
}) =>
  checkCache(
    cache,
    cacheStamp,
    apiEndpoints.getAssetHierarchy,
    endpointCapture
  );

export const getRoleHierarchy = async ({
  cache,
  cacheStamp,
  endpointCapture,
}) =>
  checkCache(cache, cacheStamp, apiEndpoints.getRoleHierarchy, endpointCapture);
