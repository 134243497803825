import types from 'constants/actionTypes';
import * as backendAPI from 'api/navigation';
import { createThunk } from 'store/createThunk';

export const toggleFeedbackModal = () => ({
  type: types.TOGGLE_FEEDBACK_MODAL,
});

export const toggleAssetFilterModalProdManagement = () => ({
  type: types.TOGGLE_ASSET_FILTER_MODAL_PM,
});

export const setAsset = assetId => ({
  type: types.SET_ASSET,
  payload: assetId,
});

export const setAssetExceptionLPO = assetId => ({
  type: types.SET_ASSET_LPO_EXCEPTION,
  payload: assetId,
});

export const setRouteExceptionLPO = assetId => ({
  type: types.SET_ROUTE_LPO_EXCEPTION,
  payload: assetId,
});

export const setRoute = routeId => ({
  type: types.SET_ROUTE,
  payload: routeId,
});

export const getAssetHierarchy = createThunk({
  apiCall: backendAPI.getAssetHierarchy,
  type: types.GET_ASSET_HIERARCHY_REQUEST,
});

export const getRoleHierarchy = createThunk({
  apiCall: backendAPI.getRoleHierarchy,
  type: types.GET_ROLE_HIERARCHY_REQUEST,
});
