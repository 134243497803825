import React, { Component } from 'react';
import styles from './styles.module.css';
import { connect } from 'react-redux';
import {
  getSummaryChart,
  selectShouldLoadData,
  selectSummaryChartData,
  selectSummaryChartRequestStatus
} from 'store/reducers/production';
import moment from 'moment';
import { formatNumber } from 'utils/formatNumber';
import { detailCardTitles } from 'constants/productionManagement';
import DetailCardTitle from 'components/common/DetailCardTitle';
import { Bar } from 'react-chartjs-2';
import { requestStatuses } from 'constants/values';
import LoadingPage from 'components/common/LoadingPage';
import Legend from './Legend';
import { selectCurrentAsset } from 'store/reducers/navigation';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { appInsights } from 'App';
import NoContent from 'components/common/NoContent';

// TODO: Convert date format to one that won't complain
moment.suppressDeprecationWarnings = true;

const mapStateToProps = state => ({
  chartData: selectSummaryChartData(state),
  selectedProductionAsset: selectCurrentAsset(state),
  shouldLoadData: selectShouldLoadData(state),
  summaryChartRequestStatus: selectSummaryChartRequestStatus(state)
});

const mapDispatchToProps = {
  getSummaryChart,
};

export class ProdChart extends Component {
  state = {
    loaded: false
  };

  componentDidMount = () => {
    if (this.props.shouldLoadData) {
      this.props.getSummaryChart();
      this.setState({ loaded: true });
    }
  };

  componentDidUpdate = () => {
    if (this.props.shouldLoadData && !this.state.loaded) {
      this.props.getSummaryChart();
      this.setState({ loaded: true });
    }
  };

  render() {
    const {
      chartData,
      getSummaryChart,
      summaryChartRequestStatus
    } = this.props;

    // chartLoaded is true if the requestStatus is SUCCESS
    const chartLoaded = summaryChartRequestStatus === requestStatuses.success;

    // If chartLoaded is true and response is empty, show No Content.
    if (chartLoaded) {
      if (!chartData || chartData.length === 0) {
        return (
          <div className={styles.roundedSquare}>
            <div className={styles.titleLegend}>
              <DetailCardTitle title={detailCardTitles.prodChart} />
            </div>
            <NoContent
              containerStyle={styles.noData}
              type={'data available.'}
            />
          </div>
        );
      }
    }

    const data = {
      labels: chartData.map(({ productionDate }) => productionDate),
      datasets: [
        {
          type: 'line',
          label: 'Target',
          borderColor: '#b2cc34',
          borderWidth: 1,
          backgroundColor: 'rgba(0,0,0,0)',
          data: chartData.map(({ target }) => target),
          steppedLine: 'middle',
          pointBackgroundColor: '#b2cc34',
          pointBorderWidth: 0,
          pointHitRadius: 0,
          pointHoverRadius: 0,
          pointRadius: 0
        },
        {
          type: 'bar',
          label: 'Production',
          backgroundColor: '#8c8f93',
          borderWidth: 0,
          data: chartData.map(({ production }) => production)
        },
        {
          type: 'bar',
          label: 'Downtime LPO',
          backgroundColor: '#0066b2',
          borderWidth: 0,
          data: chartData.map(({ downTimeLpo }) => downTimeLpo)
        },
        {
          type: 'bar',
          label: 'Constrained LPO',
          backgroundColor: '#ee4266',
          borderWidth: 0,
          data: chartData.map(({ constrainedLpo }) => constrainedLpo)
        },
        {
          type: 'bar',
          label: 'Shut-In LPO',
          backgroundColor: '#faab18',
          borderWidth: 0,
          data: chartData.map(({ shutinLpo }) => shutinLpo)
        }
      ]
    };

    try {
      return (
        <div className={styles.roundedSquare}>
          <div className={styles.titleLegend}>
            <DetailCardTitle title={detailCardTitles.prodChart} />
            <Legend />
          </div>

          {chartLoaded ? (
            <div className={styles.chart}>
              <Bar
                data={data}
                height={200}
                legend={{
                  display: false
                }}
                options={{
                  maintainAspectRatio: false,
                  scales: {
                    xAxes: [
                      {
                        stacked: true,
                        ticks: {
                          callback: value => moment(value).format('M/D'),
                          fontColor: '#333333',
                          fontFamily: 'Gotham',
                          fontStyle: '500',
                          fontSize: 12,
                          padding: 10,
                          source: 'labels'
                        },
                        gridLines: {
                          drawTicks: false,
                          zeroLineColor: '#dbdcdd',
                          borderDash: [2, 4]
                        }
                      }
                    ],
                    yAxes: [
                      {
                        stacked: true,
                        ticks: {
                          callback: value => formatNumber(value),
                          fontColor: '#aaadb3',
                          fontFamily: 'Gotham',
                          fontStyle: '500',
                          fontSize: 10,
                          padding: 10,
                          maxTicksLimit: 3,
                          beginAtZero: true
                        },
                        gridLines: {
                          drawTicks: false,
                          zeroLineColor: '#dbdcdd',
                          borderDash: [2, 4]
                        }
                      }
                    ]
                  },
                  tooltips: {
                    enabled: true,
                    backgroundColor: '#FFFFFF',
                    titleFontSize: 0,
                    titleSpacing: 0,
                    titleMarginBottom: 0,
                    bodyFontFamily: 'Gotham',
                    bodyFontSize: 12,
                    bodyFontColor: '#333333',
                    bodyFontStyle: 'bold',
                    cornerRadius: 8,
                    borderColor: '#dbdcdd',
                    borderWidth: 1,
                    displayColors: false,
                    mode: 'index',
                    bodySpacing: 4,
                    xPadding: 15,
                    yPadding: 15,
                    pan: {
                      enabled: false,
                      mode: 'x',
                      speed: 0.1,
                      threshold: 10,
                      rangeMin: {
                        x: moment(chartData[0].productionDate)
                          .add(-12, 'hour')
                          .format()
                      },
                      rangeMax: {
                        x: moment(
                          chartData[chartData.length - 1].productionDate
                        )
                          .add(12, 'hour')
                          .format()
                      }
                    },
                    zoom: {
                      enabled: false,
                      drag: false,
                      mode: 'x',
                      speed: 0.1,
                      rangeMin: {
                        x: moment(chartData[0].productionDate).format()
                      },
                      rangeMax: {
                        x: moment(
                          chartData[chartData.length - 1].productionDate
                        ).format()
                      }
                    },
                    callbacks: {
                      label: (tooltipItem, data) => {
                        return `${
                          data.datasets[tooltipItem.datasetIndex].label
                        }: ${formatNumber(tooltipItem.yLabel)}`;
                      }
                    }
                  }
                }}
              />
            </div>
          ) : (
            <LoadingPage
              containerStyles={styles.loading}
              requestStatus={summaryChartRequestStatus}
              retryRequest={getSummaryChart}
            />
          )}
        </div>
      );
    } catch (err) {
      appInsights.trackException({
        error: new Error(err),
        severityLevel: SeverityLevel.Error
      });

      return (
        <LoadingPage
          containerStyles={styles.loading}
          requestStatus={requestStatuses.failure}
          retryRequest={getSummaryChart}
        />
      );
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProdChart);
