import { applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './reducers';

// Sourcing composition from redux devTools or redux
const composeEnhancers =
  typeof window === 'object' &&
  process.env.NODE_ENV !== 'production' &&
  window.hasOwnProperty('__REDUX_DEVTOOLS_EXTENSION_COMPOSE__') ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

const enhancer = composeEnhancers(
  applyMiddleware(thunkMiddleware)
);

// Setting up the Redux Store and applying the CreateThunk middleware (via the enhancer).
const configureStore = () =>
  createStore(rootReducer, enhancer);

export default configureStore;
