export const cardTypes = {
  tasks: 'tasks',
  reminders: 'reminders'
};

export const failureBoldText = {
  saving: 'Failed to save draft.',
  submitting: 'Failed to submit form.'
};

export const failureExplaination = {
  saving: 'An error occurred and we were not able to save a draft of your form.',
  submitting: 'An error occurred and we were not able to submit your form.'
};

export const statusText = {
  missed: 'missed',
  complete: 'complete',
  current: 'current',
  overdue: 'overdue'
};

export const occurrenceStatuses = {
  missed: 'missed',
  complete: 'complete',
  current: 'current',
  overdue: 'overdue'
};

export const loadingFormTexts = {
  saving: 'saving draft',
  submitting: 'submitting form'
};

export const timeframes = {
  all: 'All',
  thisWeek: 'This Week',
  next30Days: 'In Next 30 Days',
  next90Days: 'In Next 90 Days',
  nextYear: 'In This Year'
};

export const timeframeOptions = [
  timeframes.all,
  timeframes.thisWeek,
  timeframes.next30Days,
  timeframes.next90Days,
  timeframes.nextYear
];

export const frequencyOptions = {
  'bi-monthly': { month: false, year: false },
  'bi-weekly': { month: false, year: true },
  'semi-annually': { month: false, year: false },
  adhoc: { month: false, year: false },
  custom: { month: false, year: false },
  daily: { month: true, year: true },
  monthly: { month: false, year: true },
  quarterly: { month: false, year: false },
  weekly: { month: true, year: true },
  yearly: { month: false, year: false }
};

export const occurrenceStatus = {
  locked: 'locked',
  ready: 'ready',
  inProgress: 'in progress',
  complete: 'complete'
};

export const sentActionTypes = {
  saving: 'saving',
  submitting: 'submitting'
};

export const unsubmittedFormMessage =
  'Click to save your progress or if completed, submit the form.';

export const noOccurrenceHistory =
  'No occurrence history to display for this timeframe.';

export const columnTitles = [
  'People',
  'Workforce safety',
  'Process safety',
  'Environment',
  'Production',
  'Cost',
  'Facilities Maintenance',
  'Custom'
];

export const controlStatuses = {
  ready: 'ready',
  complete: 'complete',
  error: 'error',
  pending: 'pending',
  overdue: 'overdue'
};

export const controlItemDetails = {
  description: 'Description:',
  links: 'Links:',
  completionCriteria: 'Completion Criteria:',
  dueBy: 'Due By:',
  asset: 'Asset:'
};

export const controlAddNewLink = {
  text: 'add custom control',
  formHeader: 'add new custom control'
};

export const controlEditNewLink = {
  text: 'Edit custom control',
  formHeader: 'Edit custom control'
};

export const controlFrequencyConstants = {
  OneTime: 'one-time',
  Weekly: 'weekly',
  Monthly: 'monthly',
  Quarterly: 'quarterly',
  SemiAnnually: 'semi-annually',
  Yearly: 'yearly',
  BiWeekly: 'bi-weekly',
  BiMonthly:'bi-monthly',
};

export const controlFrequencyOptions = [
  {
    name: 'One Time',
    value: controlFrequencyConstants.OneTime
  },
  {
    name: 'Weekly',
    value: controlFrequencyConstants.Weekly
  },
  {
    name: 'Monthly',
    value: controlFrequencyConstants.Monthly
  },
  {
    name: 'Quarterly',
    value: controlFrequencyConstants.Quarterly
  },
  {
    name: 'SemiAnnually',
    value: controlFrequencyConstants.SemiAnnually
  },
  {
    name: 'Yearly',
    value: controlFrequencyConstants.Yearly
  },
  {
    name: 'Bi-Weekly',
    value: controlFrequencyConstants.BiWeekly
  },
  {
    name: 'Bi-Monthly',
    value: controlFrequencyConstants.BiMonthly
  }
];


