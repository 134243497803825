import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';
import ArrowRight from 'assets/img/chevron-left.svg';
import ArrowLeft from 'assets/img/chevron-right.svg';

export const SwapButton = ({ action, isLeft }) => {
  return (
    <div
      className={styles.container}
      onClick={action}
      id={'swapButtonContainer'}
    >
      {isLeft ? (
        <img
          className={styles.prev}
          src={ArrowLeft}
          alt={'Swap Work Order Left'}
        />
      ) : (
        <img
          className={styles.next}
          src={ArrowRight}
          alt={'Swap Work Order Right'}
        />
      )}
    </div>
  );
};

SwapButton.propTypes = {
  isLeft: PropTypes.bool,
  action: PropTypes.func
};

export default SwapButton;
