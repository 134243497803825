import React from 'react';
import ShutInDetailsModal from 'components/ProductionManagementPage/ProductionLpoEvents/LpoEventsTable/EventDetailsModal/ShutInDetailsModal';
import WitDetailModal from 'components/ProductionManagementPage/ProductionLpoEvents/LpoEventsTable/EventDetailsModal/WitDetailModal';
import OpenDetailsModal from 'components/ProductionManagementPage/ProductionLpoEvents/LpoEventsTable/EventDetailsModal/OpenDetailsModal';
import OpenPullRequestModal from 'components/ProductionManagementPage/ProductionLpoEvents/LpoEventsTable/EventDetailsModal/OpenPullRequestModal';
import OpenWorkOrderModal from 'components/ProductionManagementPage/ProductionLpoEvents/LpoEventsTable/EventDetailsModal/OpenWorkOrderModal';
import ActionItemModal from 'components/ProductionManagementPage/ProductionLpoEvents/LpoEventsTable/EventDetailsModal/ActionItemModal';
import { prodLpoEventsCardTitles } from 'constants/productionManagement';


export const getCurrentWitIndex = (currentWitIndex, prConnected, pullRequest ) => {
  if (prConnected ) {
      currentWitIndex = currentWitIndex - pullRequest.length;
   }
   return currentWitIndex;

}

//function to get correct action Item index in action Item modal based on true dependencies
export const getCurrentAIIndex = (currentActionItemIndex, prConnected, witPrConnected, woConnected, workOrders, witPullRequest,pullRequest) => {
   if (prConnected && !witPrConnected && !woConnected) {
        currentActionItemIndex = currentActionItemIndex - pullRequest.length;
    }
    if (!prConnected && witPrConnected && !woConnected) {
        currentActionItemIndex = currentActionItemIndex - witPullRequest.length;
    }
    if (woConnected && !prConnected && !witPrConnected) {
        currentActionItemIndex = currentActionItemIndex - workOrders.length;
    }
    if (prConnected && !witPrConnected && woConnected) {
        currentActionItemIndex = currentActionItemIndex - workOrders.length - pullRequest.length;
    }
    if (!prConnected && witPrConnected && woConnected) {
        currentActionItemIndex = currentActionItemIndex - workOrders.length - witPullRequest.length;
    }
    if (!woConnected && prConnected && witPrConnected) {
        currentActionItemIndex = currentActionItemIndex - witPullRequest.length-pullRequest.length;
    }
    if (prConnected && woConnected && witPrConnected) {
        currentActionItemIndex = currentActionItemIndex - workOrders.length - witPullRequest.length -pullRequest.length;
    }

    return currentActionItemIndex;

}
//function to get correct work order index in work Order modal based on true dependencies
export const getCurrentWOIndex = (currentWorkOrderIndex, prConnected, aiConnected, witPrConnected, witPullRequest, pullRequest ) => {
    if (prConnected && !aiConnected && !witPrConnected) {
        currentWorkOrderIndex = currentWorkOrderIndex - pullRequest.length;
    }
    if (!prConnected && !aiConnected && witPrConnected) {
        currentWorkOrderIndex = currentWorkOrderIndex - witPullRequest.length;
    }
    if (prConnected && aiConnected && !witPrConnected) {
        currentWorkOrderIndex = currentWorkOrderIndex - pullRequest.length;
    }
    if (!prConnected && aiConnected && witPrConnected) {
        currentWorkOrderIndex = currentWorkOrderIndex - witPullRequest.length;
    }

    if (prConnected && !aiConnected && witPrConnected) {
        currentWorkOrderIndex = currentWorkOrderIndex - witPullRequest.length - pullRequest.length;
    }

    if (prConnected && aiConnected && witPrConnected) {
        currentWorkOrderIndex = currentWorkOrderIndex - witPullRequest.length - pullRequest.length;
    }
    return currentWorkOrderIndex;
}


// function to get heading in all event details modal based on dependencies
export const getCustomTitleEventDetailModal =(customModalTitle, showArrowButtons,currentModalPageIndex,numOfWorkOrders,numOfModalPages,currentLpoEventDetails,numOfWitPR,numOfPR)=>{
  if (showArrowButtons && currentLpoEventDetails.wO_PR.witPrConnected) {
    customModalTitle = `WIT details (${currentModalPageIndex + 1
      }/${numOfModalPages})`;
  }

  if (showArrowButtons && currentLpoEventDetails.wO_PR.prConnected) {
    customModalTitle = `pull request details (${currentModalPageIndex + 1
      }/${numOfModalPages})`;
  }
  
  if (showArrowButtons && currentLpoEventDetails.wO_PR.woConnected) {
        customModalTitle = `work order details (${currentModalPageIndex + 1
          }/${numOfModalPages})`;
      }
    
      if (showArrowButtons && currentLpoEventDetails.wO_PR.aiConnected) {
        customModalTitle = `action Item details (${currentModalPageIndex + 1
          }/${numOfModalPages})`;
      }
    
      if (
        showArrowButtons &&
        currentLpoEventDetails.wO_PR.prConnected && 
        currentLpoEventDetails.wO_PR.woConnected
         ) {
        if( currentModalPageIndex >= 0 && currentModalPageIndex < numOfPR){
        customModalTitle = `pull request details (${currentModalPageIndex + 1
        }/${numOfModalPages})`;
        }
        else {
          customModalTitle = `work order details (${currentModalPageIndex + 1
          }/${numOfModalPages})`;
        }
      }
      if (
        showArrowButtons &&
        currentLpoEventDetails.wO_PR.prConnected && 
        currentLpoEventDetails.wO_PR.aiConnected
         ) {
        if( currentModalPageIndex >= 0 && currentModalPageIndex < numOfPR){
        customModalTitle = `pull request details (${currentModalPageIndex + 1
        }/${numOfModalPages})`;}
        else {
          customModalTitle = `action Item details (${currentModalPageIndex + 1
          }/${numOfModalPages})`;
        }
      }
      if (
        showArrowButtons &&
        currentLpoEventDetails.wO_PR.prConnected && 
        currentLpoEventDetails.wO_PR.witPrConnected
         ) {
        if( currentModalPageIndex >= 0 && currentModalPageIndex < numOfPR){
        customModalTitle = `pull request details (${currentModalPageIndex + 1
        }/${numOfModalPages})`;}
        else {
          customModalTitle = `WIT details (${currentModalPageIndex + 1
          }/${numOfModalPages})`;
        }
      }
    
      if (
        showArrowButtons &&
        currentLpoEventDetails.wO_PR.woConnected && 
        currentLpoEventDetails.wO_PR.witPrConnected
         ) {
        if( currentModalPageIndex >= 0  && currentModalPageIndex < numOfWitPR){
          customModalTitle = `WIT details (${currentModalPageIndex + 1
          }/${numOfModalPages})`;
        }
        else {
          customModalTitle = `work order details (${currentModalPageIndex + 1
          }/${numOfModalPages})`;
        }
      }
    
      if (
        showArrowButtons &&
        currentLpoEventDetails.wO_PR.witPrConnected && 
        currentLpoEventDetails.wO_PR.aiConnected
         ) {
        if( currentModalPageIndex >= 0 && currentModalPageIndex < numOfWitPR){
          customModalTitle = `WIT details (${currentModalPageIndex + 1
          }/${numOfModalPages})`;
        }
        else {
          customModalTitle = `action item details (${currentModalPageIndex + 1
          }/${numOfModalPages})`;
        }
      }
    
      if (
        showArrowButtons &&
        currentLpoEventDetails.wO_PR.woConnected && 
        currentLpoEventDetails.wO_PR.aiConnected
         ) {
        if( currentModalPageIndex >= 0 && currentModalPageIndex < numOfWorkOrders){
          customModalTitle = `work order details (${currentModalPageIndex + 1
          }/${numOfModalPages})`;
        }
        else {
          customModalTitle = `action item details (${currentModalPageIndex + 1
          }/${numOfModalPages})`;
        }
      }
      if (showArrowButtons && currentLpoEventDetails.wO_PR.woConnected &&
         currentLpoEventDetails.wO_PR.prConnected &&  currentLpoEventDetails.wO_PR.aiConnected ) {
        if (currentModalPageIndex >= 0 && currentModalPageIndex < numOfPR) {
          customModalTitle = `pull request details (${currentModalPageIndex + 1
            }/${numOfModalPages})`;
        }
        else if (currentModalPageIndex >= numOfPR && currentModalPageIndex < numOfWorkOrders + numOfPR) {
          customModalTitle = `work order details (${currentModalPageIndex + 1
            }/${numOfModalPages})`;
        }
        else {
          customModalTitle = `action item details (${currentModalPageIndex + 1
            }/${numOfModalPages})`;
        }
    
      }
    
      if (showArrowButtons && currentLpoEventDetails.wO_PR.woConnected &&
         currentLpoEventDetails.wO_PR.witPrConnected &&
        currentLpoEventDetails.wO_PR.aiConnected) {
      
       if (currentModalPageIndex >= 0 && currentModalPageIndex< numOfWitPR) {
          customModalTitle = `WIT details (${currentModalPageIndex + 1
            }/${numOfModalPages})`;
        }
        else if (currentModalPageIndex >= numOfWitPR && currentModalPageIndex < numOfWorkOrders + numOfWitPR) {
          customModalTitle = `work order details (${currentModalPageIndex + 1
            }/${numOfModalPages})`;
        }
        else {
          customModalTitle = `action item details (${currentModalPageIndex + 1
            }/${numOfModalPages})`;
        }
      }
    
      if (showArrowButtons && currentLpoEventDetails.wO_PR.woConnected &&
        currentLpoEventDetails.wO_PR.prConnected && currentLpoEventDetails.wO_PR.witPrConnected) {
        if (currentModalPageIndex >= 0 && currentModalPageIndex < numOfPR) {
          customModalTitle = `pull request details (${currentModalPageIndex + 1
            }/${numOfModalPages})`;
        }
        else if (currentModalPageIndex >=numOfPR  && currentModalPageIndex < numOfWitPR+numOfPR) {
          customModalTitle = `WIT details (${currentModalPageIndex + 1
            }/${numOfModalPages})`;
        }
        else {
          customModalTitle = `work order details (${currentModalPageIndex + 1
            }/${numOfModalPages})`;
        }
      }
      if (showArrowButtons && currentLpoEventDetails.wO_PR.prConnected &&
         currentLpoEventDetails.wO_PR.witPrConnected &&
        currentLpoEventDetails.wO_PR.aiConnected) {
        if (currentModalPageIndex >= 0 && currentModalPageIndex < numOfPR) {
          customModalTitle = `pull request details (${currentModalPageIndex + 1
            }/${numOfModalPages})`;
        }
        else if (currentModalPageIndex >= numOfPR && currentModalPageIndex < numOfWitPR+numOfPR) {
          customModalTitle = `WIT details (${currentModalPageIndex + 1
            }/${numOfModalPages})`;
        }
        else {
          customModalTitle = `action item details (${currentModalPageIndex + 1
            }/${numOfModalPages})`;
        }
      }
      if (showArrowButtons && currentLpoEventDetails.wO_PR.woConnected &&
        currentLpoEventDetails.wO_PR.prConnected && currentLpoEventDetails.wO_PR.witPrConnected &&
        currentLpoEventDetails.wO_PR.aiConnected) {
        if (currentModalPageIndex  >= 0 && currentModalPageIndex < numOfPR) {
          customModalTitle = `pull request details (${currentModalPageIndex + 1
            }/${numOfModalPages})`;
        }
        else if (currentModalPageIndex >= numOfPR && currentModalPageIndex<numOfWitPR+numOfPR) {
          customModalTitle = `WIT details (${currentModalPageIndex + 1
            }/${numOfModalPages})`;
        }
        else if (currentModalPageIndex >= numOfWitPR+numOfPR && currentModalPageIndex < numOfWorkOrders + numOfWitPR +numOfPR) {
          customModalTitle = `work order details (${currentModalPageIndex + 1
            }/${numOfModalPages})`;
        }
        else {
          customModalTitle = `action item details (${currentModalPageIndex + 1
            }/${numOfModalPages})`;
        }
      }
   return customModalTitle;     
}

// function to render modal on LPO events screen based on dependencies

export const renderModal = (
    currentLpoEventDetails,
    currentLpoEventsCard,
    currentModalPageIndex,
    numOfWorkOrders,
    numOfWitPR,
    numOfPR
  ) => {
    const {
      woConnected,
      prConnected,
      witPrConnected,
      aiConnected
    } = currentLpoEventDetails.wO_PR;
  
    // If the ShutIn LPO Card is the current card, use the ShutIn Details Modal
    
    if (woConnected && prConnected && witPrConnected && aiConnected) {
      if (currentModalPageIndex >= 0 && currentModalPageIndex<numOfPR) {
        return {
          modal: <OpenPullRequestModal />,
          title: 'pull request details',
          size: 'modalContainerOpenPullRequestEventDetails',
        };
      } else if (currentModalPageIndex >= numOfPR && currentModalPageIndex< numOfPR+numOfWitPR) {
        return {
          modal: <WitDetailModal />,
          title: 'WIT details',
          size: 'modalContainerWitDetailModalDetails',
        };
      }
      else if (currentModalPageIndex >= numOfWitPR+1 && currentModalPageIndex < numOfPR + numOfWorkOrders + numOfWitPR ) {
        return {
          modal: <OpenWorkOrderModal />,
          title: 'work order details',
          size: 'modalContainerOpenWorkOrderEventDetails',
        };
      }
      else {
        return {
          modal: <ActionItemModal />,
          title: 'action item details',
          size: 'modalContainerActionItemEventDetails',
        };
      }
    }
  
    if (woConnected && witPrConnected && aiConnected) {
      if (currentModalPageIndex >= 0 && currentModalPageIndex<numOfWitPR) {
        return {
          modal: <WitDetailModal />,
          title: 'WIT details',
          size: 'modalContainerWitDetailModalDetails',
        };
      }
      else if (currentModalPageIndex >= numOfWitPR && currentModalPageIndex < numOfWorkOrders + numOfWitPR) {
        return {
          modal: <OpenWorkOrderModal />,
          title: 'work order details',
          size: 'modalContainerOpenWorkOrderEventDetails',
        };
      }
      else {
        return {
          modal: <ActionItemModal />,
          title: 'action item details',
          size: 'modalContainerActionItemEventDetails',
        };
      }
    }
    if (woConnected && prConnected && aiConnected) {
      if (currentModalPageIndex >= 0 && currentModalPageIndex<numOfPR) {
        return {
          modal: <OpenPullRequestModal />,
          title: 'pull request details',
          size: 'modalContainerOpenPullRequestEventDetails',
        };
      }
      else if (currentModalPageIndex >= numOfPR && currentModalPageIndex < numOfWorkOrders + numOfPR) {
        return {
          modal: <OpenWorkOrderModal />,
          title: 'work order details',
          size: 'modalContainerOpenWorkOrderEventDetails',
        };
      }
      else {
        return {
          modal: <ActionItemModal />,
          title: 'action item details',
          size: 'modalContainerActionItemEventDetails',
        };
      }
    }
    if (woConnected && prConnected && witPrConnected) {
      if (currentModalPageIndex  >= 0 && currentModalPageIndex<numOfPR) {
        return {
          modal: <OpenPullRequestModal />,
          title: 'pull request details',
          size: 'modalContainerOpenPullRequestEventDetails',
        };
      } else if (currentModalPageIndex >= numOfPR && currentModalPageIndex<numOfWitPR+numOfPR) {
        return {
          modal: <WitDetailModal />,
          title: 'WIT details',
          size: 'modalContainerWitDetailModalDetails',
        };
      }
      else {
        return {
          modal: <OpenWorkOrderModal />,
          title: 'work order details',
          size: 'modalContainerOpenWorkOrderEventDetails',
        };
      }
  
    }
    if (prConnected && witPrConnected && aiConnected) {
      if (currentModalPageIndex  >= 0 && currentModalPageIndex<numOfPR) {
        return {
          modal: <OpenPullRequestModal />,
          title: 'pull request details',
          size: 'modalContainerOpenPullRequestEventDetails',
        };
      } else if (currentModalPageIndex >= numOfPR && currentModalPageIndex < numOfWitPR+numOfPR) {
        return {
          modal: <WitDetailModal />,
          title: 'WIT details',
          size: 'modalContainerWitDetailModalDetails',
        };
      }
      else {
        return {
          modal: <ActionItemModal />,
          title: 'action item details',
          size: 'modalContainerActionItemEventDetails',
        };
      }
    }
  
    // If connected to both, conditionally render modal types.
    if (woConnected && prConnected) {
      if (currentModalPageIndex >= 0 && currentModalPageIndex<numOfPR) {
        return {
          modal: <OpenPullRequestModal />,
          title: 'pull request details',
          size: 'modalContainerOpenPullRequestEventDetails',
        };
      } else {
        return {
          modal: <OpenWorkOrderModal />,
          title: 'work order details',
          size: 'modalContainerOpenWorkOrderEventDetails',
        };
      }
    }
  
    // If connected to both, conditionally render modal types.
    if (woConnected && witPrConnected) {
      if (currentModalPageIndex >= 0 && currentModalPageIndex < numOfWitPR) {
        return {
          modal: <WitDetailModal />,
          title: 'WIT details',
          size: 'modalContainerWitDetailModalDetails',
        };
      } else {
        return {
          modal: <OpenWorkOrderModal />,
          title: 'work order details',
          size: 'modalContainerOpenWorkOrderEventDetails',
        };
      }
    }
  
    if (prConnected && witPrConnected) {
      if (currentModalPageIndex >= 0 && currentModalPageIndex<numOfPR) {
        return {
          modal: <OpenPullRequestModal />,
          title: 'pull request details',
          size: 'modalContainerOpenPullRequestEventDetails',
        };
      } else {
        return {
          modal: <WitDetailModal />,
          title: 'WIT details',
          size: 'modalContainerWitDetailModalDetails',
        };
      }
    }
  
    if (woConnected && aiConnected) {
      if (currentModalPageIndex >= 0 && currentModalPageIndex < numOfWorkOrders) {
        return {
          modal: <OpenWorkOrderModal />,
          title: 'work order details',
          size: 'modalContainerOpenWorkOrderEventDetails',
        };
      } else {
        return {
          modal: <ActionItemModal />,
          title: 'action item details',
          size: 'modalContainerActionItemEventDetails',
        };
      }
    }
  
    if (witPrConnected && aiConnected) {
      if (currentModalPageIndex >= 0 && currentModalPageIndex < numOfWitPR) {
        return {
          modal: <WitDetailModal />,
          title: 'WIT details',
          size: 'modalContainerWitDetailModalDetails',
        };
      } else {
        return {
          modal: <ActionItemModal />,
          title: 'action item details',
          size: 'modalContainerActionItemEventDetails',
        };
      }
    }
  
    if (prConnected && aiConnected) {
      if (currentModalPageIndex >= 0 && currentModalPageIndex<numOfPR) {
        return {
          modal: <OpenPullRequestModal />,
          title: 'pull request details',
          size: 'modalContainerOpenPullRequestEventDetails',
        };
      } else {
        return {
          modal: <ActionItemModal />,
          title: 'action item details',
          size: 'modalContainerActionItemEventDetails',
        };
      }
    }
  
  
    if (witPrConnected) {
      return {
        modal: <WitDetailModal />,
        title: 'WIT details',
        size: 'modalContainerWitDetailModalDetails',
      };
    }
  
    // If the LPO Event Table Row clicked is connected to Work Order(s), render the Work Order modals.
  
    if (woConnected) {
      return {
        modal: <OpenWorkOrderModal />,
        title: 'work order details',
        size: 'modalContainerOpenWorkOrderEventDetails',
      };
    }
  
    // If the LPO Event Table Row clicked is connected to a Pull Request, render the Pull Request modals.
    if (prConnected) {
      return {
        modal: <OpenPullRequestModal />,
        title: 'pull request details',
        size: 'modalContainerOpenPullRequestEventDetails',
      };
    }
    if (aiConnected) {
      return {
        modal: <ActionItemModal />,
        title: 'action item details',
        size: 'modalContainerActionItemEventDetails',
      };
    }
    
    if (currentLpoEventsCard === prodLpoEventsCardTitles.shutInWells) {
      return {
        modal: <ShutInDetailsModal />,
        title: 'event details',
        size: 'modalContainerShutInEventDetails',
      };
    }
    // If the LPO Event Table Row clicked is not connected to anything, render the generic Event Details modal.
    return {
      modal: <OpenDetailsModal />,
      title: 'event details',
      size: 'modalContainerOpenEventDetails',
    };
  };
  