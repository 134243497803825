import { connect } from 'react-redux';
import styles from './styles.module.css';
import React, { Component } from 'react';
import { requestStatuses } from 'constants/values';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Info from 'assets/img/info.svg';
import LoadingPage from 'components/common/LoadingPage';
import { ECCommentModal } from 'components/ExceptionManagementPage/Downtime/ECCommentModal';
import {
  allExceptionsColumns,
  completeNonProdSubsurfaceColumns,
  noPullRequestColumns,
  pullRequestStatusColumns,
  troubleshootingColumns,
  allExceptionsColumnsWithoutRouteName,
  completeNonProdSubsurfaceColumnsWithoutRouteName,
  noPullRequestColumnsWithoutRouteName,
  pullRequestStatusColumnsWithoutRouteName,
  troubleshootingColumnsWithoutRouteName,
  noWorkOrder,
  noWorkOrderWithotRouteName,
  woPriorityLevelColumns,
  woPriorityLevelColumnsWithoutRouteName,
  completeNonProdSurfaceColumns,
  completeNonProdSurfaceColumnsWithoutRouteName,
  surfaceColumnsWithoutRouteName,
  surfaceColumns,
  subsurfaceColumns,
  subsurfaceColumnsWithoutRouteName,
} from 'constants/downtimeColumns';
import { selectShouldLoadData } from 'store/reducers/production';
import {
  selectCurrentAssetIdException,
  selectCurrentRouteLPO,
} from 'store/reducers/navigation';
import {
  getExceptionDowntimeGeneralInfo,
  getExceptionDowntimeTable,
  selectCurrentPrimaryDowntimeCard,
  selectCurrentSecondaryDowntimeCard,
  selectDowntimeGeneralInfo,
  selectDowntimeTableData,
  selectDowntimeTableRequestStatus,
  setCurrentPrimaryDowntimeCard,
  setCurrentSecondaryDowntimeCard,
  selectCommentModal,
  toggleECCommentModal,
  selectselectedException,
} from 'store/reducers/exception';

import {
  selectShowLpoEventDetailsModal,
  toggleLpoEventDetailsModal,
  setCurrentLpoEventDetails,
} from 'store/reducers/production';

import {
  downtimeCardTitles,
  secondaryHeirarchy,
  moreInfoTitle,
} from 'constants/exceptionManagement';
import TableCards from 'components/common/TableCards';
import Modal from 'components/common/Modal';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { appInsights } from 'App';
import NoContent from 'components/common/NoContent';
import classnames from 'classnames';
import EventDetailsModal from '../../../ProductionManagementPage/ProductionLpoEvents/LpoEventsTable/EventDetailsModal';

const mapStateToProps = state => ({
  currentDowntimeInfo: selectDowntimeGeneralInfo(state),
  currentPrimaryDowntimeCard: selectCurrentPrimaryDowntimeCard(state),
  currentSecondaryDowntimeCard: selectCurrentSecondaryDowntimeCard(state),
  downtimeTableData: selectDowntimeTableData(state),
  downtimeTableRequestStatus: selectDowntimeTableRequestStatus(state),
  selectedProductionAsset: selectCurrentAssetIdException(state),
  selectedProductionRoute: selectCurrentRouteLPO(state),
  shouldLoadData: selectShouldLoadData(state),
  showECCommentModal: selectCommentModal(state),
  selectedException: selectselectedException(state),
  showLpoEventDetailsModal: selectShowLpoEventDetailsModal(state),
});

const mapDispatchToProps = {
  getExceptionDowntimeGeneralInfo,
  getExceptionDowntimeTable,
  setCurrentPrimaryDowntimeCard,
  setCurrentSecondaryDowntimeCard,
  toggleECCommentModal,
  toggleLpoEventDetailsModal,
  setCurrentLpoEventDetails,
};

export class DowntimeCardTable extends Component {
  state = {
    loaded: false,
  };

  setLpoEvent = event => {
    const {
      setCurrentLpoEventDetails,
      toggleLpoEventDetailsModal,
    } = this.props;
    setCurrentLpoEventDetails(event.lpoDetails);
    toggleLpoEventDetailsModal();
  };

  moreInfoHeader = {
    Header: 'More Information',
    accessor: 'reasonCode',
    sortable: false,
    minWidth: 100,
    Cell: props => (
      <span className={styles.info_icon}>
        <img
          src={Info}
          alt="more_info"
          onClick={() => this.props.toggleECCommentModal(props.original)}
        />
      </span>
    ),
    headerClassName: 'centeredColumnHeader',
  };
  onLoad = () => {
    let tableColumns = {};
    let routeValueDropdown = this.props.selectedProductionRoute;
    const allExceptionsColumnsNew = setLpoEvent => [
      ...allExceptionsColumns(setLpoEvent),
      this.moreInfoHeader,
    ];
    const allExceptionsColumnsWithoutRouteNameNew = setLpoEvent => [
      ...allExceptionsColumnsWithoutRouteName(setLpoEvent),
      this.moreInfoHeader,
    ];
    const surfaceColumnsNew = setLpoEvent => 
    [...surfaceColumns(setLpoEvent), 
      this.moreInfoHeader];
    const surfaceColumnsWithoutRouteNameNew = setLpoEvent => [
      ...surfaceColumnsWithoutRouteName(setLpoEvent),
      this.moreInfoHeader,
    ];
    const subsurfaceColumnsNew = setLpoEvent => [...subsurfaceColumns(setLpoEvent), this.moreInfoHeader];
    const subsurfaceColumnsWithoutRouteNameNew = setLpoEvent => [
      ...subsurfaceColumnsWithoutRouteName(setLpoEvent),
      this.moreInfoHeader,
    ];
    tableColumns =
      routeValueDropdown !== '0'
        ? {
            [downtimeCardTitles.allExceptions]: allExceptionsColumnsWithoutRouteNameNew(
              this.setLpoEvent
            ),
            [downtimeCardTitles.surfaceExceptions]: surfaceColumnsWithoutRouteNameNew(this.setLpoEvent),
            [downtimeCardTitles.subsurfaceExceptions]: subsurfaceColumnsWithoutRouteNameNew(this.setLpoEvent),
            [downtimeCardTitles.troubleshooting]: troubleshootingColumnsWithoutRouteName(this.setLpoEvent),
            [downtimeCardTitles.noPullRequest]: noPullRequestColumnsWithoutRouteName(this.setLpoEvent),
            [downtimeCardTitles.pullRequestStatus]: pullRequestStatusColumnsWithoutRouteName(this.setLpoEvent),
            [downtimeCardTitles.completeNonProdSubsurface]: completeNonProdSubsurfaceColumnsWithoutRouteName(this.setLpoEvent),
            [downtimeCardTitles.noWorkOrder]: noWorkOrderWithotRouteName(this.setLpoEvent),
            [downtimeCardTitles.woPriorityLevel]: woPriorityLevelColumnsWithoutRouteName(this.setLpoEvent),
            [downtimeCardTitles.completeNonProdSurface]: completeNonProdSurfaceColumnsWithoutRouteName(this.setLpoEvent),
          }
        : {
            [downtimeCardTitles.allExceptions]: allExceptionsColumnsNew(
              this.setLpoEvent
            ),
            [downtimeCardTitles.surfaceExceptions]: surfaceColumnsNew(this.setLpoEvent),
            [downtimeCardTitles.subsurfaceExceptions]: subsurfaceColumnsNew(this.setLpoEvent),
            [downtimeCardTitles.troubleshooting]: troubleshootingColumns(this.setLpoEvent),
            [downtimeCardTitles.noPullRequest]: noPullRequestColumns(this.setLpoEvent),
            [downtimeCardTitles.pullRequestStatus]: pullRequestStatusColumns(this.setLpoEvent),
            [downtimeCardTitles.completeNonProdSubsurface]: completeNonProdSubsurfaceColumns(this.setLpoEvent),
            [downtimeCardTitles.noWorkOrder]: noWorkOrder(this.setLpoEvent),
            [downtimeCardTitles.woPriorityLevel]: woPriorityLevelColumns(this.setLpoEvent),
            [downtimeCardTitles.completeNonProdSurface]: completeNonProdSurfaceColumns(this.setLpoEvent),
          };
    return tableColumns;
  };

  tableColumns = this.onLoad();

  componentDidMount = () => {
    if (this.props.shouldLoadData && !this.state.loaded) {
      this.props.getExceptionDowntimeTable();
      this.setState({ loaded: true });
    }
    this.tableColumns = this.onLoad();
  };

  componentDidUpdate = () => {
    if (this.props.shouldLoadData && !this.state.loaded) {
      this.props.getExceptionDowntimeTable();
      this.setState({ loaded: true });
    }
    let lastSelectDropdownValue = this.props.selectedProductionRoute;
    const allExceptionsColumnsNew = [
      ...allExceptionsColumns(this.setLpoEvent),
      this.moreInfoHeader,
    ];
    const allExceptionsColumnsWithoutRouteNameNew = [
      ...allExceptionsColumnsWithoutRouteName(this.setLpoEvent),
      this.moreInfoHeader,
    ];
    const surfaceColumnsNew = [...surfaceColumns(this.setLpoEvent), this.moreInfoHeader];
    const surfaceColumnsWithoutRouteNameNew = [
      ...surfaceColumnsWithoutRouteName(this.setLpoEvent),
      this.moreInfoHeader,
    ];
    const subsurfaceColumnsNew = [...subsurfaceColumns(this.setLpoEvent), this.moreInfoHeader];
    const subsurfaceColumnsWithoutRouteNameNew = [
      ...subsurfaceColumnsWithoutRouteName(this.setLpoEvent),
      this.moreInfoHeader,
    ];
    this.tableColumns =
      lastSelectDropdownValue !== '0'
        ? {
            [downtimeCardTitles.allExceptions]: allExceptionsColumnsWithoutRouteNameNew,
            [downtimeCardTitles.troubleshooting]: troubleshootingColumnsWithoutRouteName(this.setLpoEvent),
            [downtimeCardTitles.surfaceExceptions]: surfaceColumnsWithoutRouteNameNew,
            [downtimeCardTitles.subsurfaceExceptions]: subsurfaceColumnsWithoutRouteNameNew,
            [downtimeCardTitles.noPullRequest]: noPullRequestColumnsWithoutRouteName(this.setLpoEvent),
            [downtimeCardTitles.pullRequestStatus]: pullRequestStatusColumnsWithoutRouteName(this.setLpoEvent),
            [downtimeCardTitles.completeNonProdSubsurface]: completeNonProdSubsurfaceColumnsWithoutRouteName(this.setLpoEvent),
            [downtimeCardTitles.noWorkOrder]: noWorkOrderWithotRouteName(this.setLpoEvent),
            [downtimeCardTitles.woPriorityLevel]: woPriorityLevelColumnsWithoutRouteName(this.setLpoEvent),
            [downtimeCardTitles.completeNonProdSurface]: completeNonProdSurfaceColumnsWithoutRouteName(this.setLpoEvent),
          }
        : {
            [downtimeCardTitles.allExceptions]: allExceptionsColumnsNew,
            [downtimeCardTitles.troubleshooting]: troubleshootingColumns(this.setLpoEvent),
            [downtimeCardTitles.surfaceExceptions]: surfaceColumnsNew,
            [downtimeCardTitles.subsurfaceExceptions]: subsurfaceColumnsNew,
            [downtimeCardTitles.noPullRequest]: noPullRequestColumns(this.setLpoEvent),
            [downtimeCardTitles.pullRequestStatus]: pullRequestStatusColumns(this.setLpoEvent),
            [downtimeCardTitles.completeNonProdSubsurface]: completeNonProdSubsurfaceColumns(this.setLpoEvent),
            [downtimeCardTitles.noWorkOrder]: noWorkOrder(this.setLpoEvent),
            [downtimeCardTitles.woPriorityLevel]: woPriorityLevelColumns(this.setLpoEvent),
            [downtimeCardTitles.completeNonProdSurface]: completeNonProdSurfaceColumns(this.setLpoEvent),
          };
  };
  render() {
    const {
      currentPrimaryDowntimeCard,
      currentSecondaryDowntimeCard,
      getExceptionDowntimeGeneralInfo,
      getExceptionDowntimeTable,
      setCurrentPrimaryDowntimeCard,
      setCurrentSecondaryDowntimeCard,
      currentDowntimeInfo,
      downtimeTableData,
      downtimeTableRequestStatus,
      toggleECCommentModal,
      showECCommentModal,
      selectedException,
      showLpoEventDetailsModal,
    } = this.props;
    const columns = this.tableColumns[currentSecondaryDowntimeCard];
    const { cards } = currentDowntimeInfo;
     try {
      return (
        <div>
          <TableCards
            isCentered={true}
            cards={[
              {
                title: downtimeCardTitles.allExceptions,
                isDisabled:
                  downtimeTableRequestStatus === requestStatuses.pending,
                number: cards.allExceptions.exceptions,
                new: cards.allExceptions.newExceptions,
                action: () => {
                  setCurrentPrimaryDowntimeCard(
                    downtimeCardTitles.allExceptions
                  );
                  getExceptionDowntimeTable();
                },
                isSelected:
                  currentPrimaryDowntimeCard ===
                  downtimeCardTitles.allExceptions,
                width: 'primary',
                hideRefresh: true,
              },
              // TODO:  Add in code below when No Downcode Exceptions is ready to incorporate into Exceptions
              // {
              //   title: downtimeCardTitles.noDowncodeException,
              //   isDisabled: true,
              //   number: cards.noDowncodeException.exceptions,
              //   new: cards.noDowncodeException.newExceptions,
              //   action: () => {
              //     // TODO: Uncomment when No Downcode Exceptions added to Force
              //     // setCurrentPrimaryDowntimeCard(downtimeCardTitles.noDowncodeException);
              //     // getExceptionDowntimeTable();
              //   },
              //   isSelected:
              //     currentPrimaryDowntimeCard ===
              //     downtimeCardTitles.noDowncodeException,
              //   comingSoon: true,
              //   width: 'primary',
              //   hideRefresh: true
              // },
              {
                title: downtimeCardTitles.surfaceExceptions,
                isDisabled: false,
                number: cards.surfaceExceptions.exceptions,
                new: cards.surfaceExceptions.newExceptions,
                action: () => {
                  // TODO: Uncomment when Surface Exceptions added to Force
                  setCurrentPrimaryDowntimeCard(
                    downtimeCardTitles.surfaceExceptions
                  );
                  setCurrentSecondaryDowntimeCard(
                    downtimeCardTitles.surfaceExceptions
                  );
                  getExceptionDowntimeTable();
                },
                isSelected:
                  currentPrimaryDowntimeCard ===
                    downtimeCardTitles.surfaceExceptions &&
                  currentSecondaryDowntimeCard ===
                    downtimeCardTitles.surfaceExceptions,
                width: 'primary',
                hideRefresh: true,
              },
              {
                title: downtimeCardTitles.subsurfaceExceptions,
                isDisabled: false,
                number: cards.subsurfaceExceptions.exceptions,
                new: cards.subsurfaceExceptions.newExceptions,
                action: () => {
                  setCurrentPrimaryDowntimeCard(
                    downtimeCardTitles.subsurfaceExceptions
                  );
                  setCurrentSecondaryDowntimeCard(
                    downtimeCardTitles.subsurfaceExceptions
                  );
                  getExceptionDowntimeTable();
                },
                isSelected:
                  currentPrimaryDowntimeCard ===
                    downtimeCardTitles.subsurfaceExceptions &&
                  currentSecondaryDowntimeCard ===
                    downtimeCardTitles.subsurfaceExceptions,
                width: 'primary',
                hideRefresh: true,
              },
              // {
              //   title: downtimeCardTitles.nonWoPrRelated,
              //   isDisabled: true,
              //   number: cards.nonWoPrRelated.exceptions,
              //   new: cards.nonWoPrRelated.newExceptions,
              //   action: () => {
              //     // TODO: Uncomment when Non WO/PR Related Exceptions added to Force
              //     // setCurrentPrimaryDowntimeCard(downtimeCardTitles.nonWoPrRelated);
              //     // getExceptionDowntimeTable();
              //   },
              //   isSelected:
              //     currentPrimaryDowntimeCard ===
              //     downtimeCardTitles.nonWoPrRelated,
              //   comingSoon: true,
              //   width: 'primary',
              //   hideRefresh: true
              // }
            ]}
          />

          {currentPrimaryDowntimeCard !== 'All Exceptions' && (
            <TableCards
              isCentered={true}
              cards={secondaryHeirarchy[currentPrimaryDowntimeCard].map(
                card => {
                  return {
                    // If the card title is Complete But Not Producing SURFACE or SUBSURFACE, show the generic Complete But Not Producing.
                    title:
                      card === downtimeCardTitles.completeNonProdSurface ||
                      card === downtimeCardTitles.completeNonProdSubsurface
                        ? downtimeCardTitles.completeNonProducing
                        : card,
                    isDisabled:
                      card !== downtimeCardTitles.troubleshooting &&
                      card !== downtimeCardTitles.noPullRequest &&
                      card !== downtimeCardTitles.pullRequestStatus &&
                      card !== downtimeCardTitles.completeNonProdSubsurface &&
                      card !== downtimeCardTitles.noWorkOrder &&
                      card !== downtimeCardTitles.woPriorityLevel &&
                      card !== downtimeCardTitles.completeNonProdSurface,
                    number:
                      card !== downtimeCardTitles.pullRequestStatus
                        ? card !== downtimeCardTitles.woPriorityLevel
                          ? cards[card].exceptions
                          : cards.workOrderStatus.exceptions
                        : cards.pullRequestStatus.exceptions,
                    new:
                      card !== downtimeCardTitles.pullRequestStatus
                        ? card !== downtimeCardTitles.woPriorityLevel
                          ? cards[card].newExceptions
                          : cards.workOrderStatus.newExceptions
                        : cards.pullRequestStatus.newExceptions,
                    action: () => {
                      setCurrentSecondaryDowntimeCard(card);
                      getExceptionDowntimeTable();
                    },
                    isSelected: currentSecondaryDowntimeCard === card,
                    width: 'secondary',
                    comingSoon:
                      card !== downtimeCardTitles.troubleshooting &&
                      card !== downtimeCardTitles.noPullRequest &&
                      card !== downtimeCardTitles.pullRequestStatus &&
                      card !== downtimeCardTitles.completeNonProdSubsurface &&
                      card !== downtimeCardTitles.noWorkOrder &&
                      card !== downtimeCardTitles.woPriorityLevel &&
                      card !== downtimeCardTitles.completeNonProdSurface,
                    hideRefresh: true,
                  };
                }
              )}
            />
          )}

          {showLpoEventDetailsModal && <EventDetailsModal />}

          {downtimeTableRequestStatus === requestStatuses.success && (
            <div
              className={
                currentSecondaryDowntimeCard === 'All Exceptions'
                  ? classnames(styles.reactTable, 'exceptionTable')
                  : classnames(styles.reactTable, 'exceptionTableOthers')
              }
            >
              <ReactTable
                columns={columns}
                data={downtimeTableData}
                defaultPageSize={9999}
                minRows={0}
                showPagination={false}
                resizable={false}
                className={'downtimeTable'}
                NoDataComponent={() => (
                  // If response is empty, show No Content.
                  <NoContent
                    containerStyle={styles.noData}
                    type={'data available.'}
                  />
                )}
                defaultSorted={[
                  {
                    id: 'isNew',
                    desc: true,
                  },
                  {
                    id: 'timeDown',
                    desc: true,
                  },
                ]}
              />
            </div>
          )}
          {showECCommentModal && (
            <Modal
              title={moreInfoTitle}
              onCloseClick={toggleECCommentModal}
              size={'modalContainerThresholdAdjust'}
            >
              <ECCommentModal
                record={selectedException}
                currentCard={currentSecondaryDowntimeCard}
                onCloseClick={toggleECCommentModal}
              />
            </Modal>
          )}
          {downtimeTableRequestStatus !== requestStatuses.success && (
            <LoadingPage
              containerStyles={styles.loading}
              requestStatus={downtimeTableRequestStatus}
              retryRequest={getExceptionDowntimeTable}
            />
          )}
        </div>
      );
    } catch (err) {
      appInsights.trackException({
        error: new Error(err),
        severityLevel: SeverityLevel.Error,
      });

      return (
        <LoadingPage
          containerStyles={styles.loading}
          requestStatus={requestStatuses.failure}
          retryRequest={() => {
            getExceptionDowntimeGeneralInfo();
            getExceptionDowntimeTable();
          }}
        />
      );
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DowntimeCardTable);
