export const selectActiveTool = ({ admin }) => admin.activeTool;

export const selectActiveCard = ({ admin }) => admin.card;
export const selectErrorMessage = ({ admin }) => admin.errorMsg;

export const selectAdminToolRequestStatus = ({ admin }) =>
  admin.adminToolRequest;
 
export const selectAddRoleRequestStatus = ({ admin }) =>
  admin.addRolerequest;
export const selectAssetOptions = ({ admin }) =>
  admin.assetOptions.map(option => {
    return {
      label: option.name,
      value: option.value,
    };
  });

export const selectColumns = ({ admin }) => admin.columns;

export const selectControlOptions = ({ admin }) => admin.controlOptions;

export const selectData = ({ admin }) => admin.data;

export const selectFocusedRecord = ({ admin }) => admin.focusedRecord;

export const selectPillarOptions = ({ admin }) => admin.pillarOptions;

export const selectSuperitendentOptions = ({ admin }) => admin.superitendentOptions.map(option => {
  return {
    label: option.title,
    value: option.roleId,
  };
});;


export const selectRequestStatus = ({ admin }) => admin.requestStatus;

export const selectRoleOptions = ({ admin }) => admin.roleOptions;

export const selectMultiRoleOptions = ({ admin }) =>
  admin.roleOptions.map(option => {
    return {
      label: option.name,
      value: option.value,
    };
  });

export const selectShowModal = ({ admin }) => admin.showModal;
export const selectShowDeleteModal = ({ admin }) => admin.showDeleteModal;
export const selectDeleteControlStatus = ({ admin }) => admin.deleteControl;
