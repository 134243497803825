import React, { Component } from 'react';
import classnames from 'classnames';
import styles from './styles.module.css';
import {
  adminConfig,
  adminTools,
  adminToolValues,
  defaultSorted,
} from 'constants/admin';
import { connect } from 'react-redux';
import {
  getAssetOptions,
  //getControlOptions,
  getData,
  getPillarOptions,
  getSuperitendentOptions,
  getRoleOptions,
  resetAdminToolRequestStatus,
  selectActiveTool,
  selectAdminToolRequestStatus,
  selectColumns,
  selectData,
  selectFocusedRecord,
  selectRequestStatus,
  selectShowModal,
  setActiveTool,
  setActiveCard,
  toggleModal,
  turnOffModal,
  selectRoleOptions,
  selectPillarOptions,
  selectAssetOptions,
  selectActiveCard
} from 'store/reducers/admin';
import { getAssetHierarchy, selectAssets } from 'store/reducers/navigation';
import { getUserInfo } from 'store/reducers/users';
import { withRouter } from 'react-router';
import AdminHeader from './AdminHeader';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Modal from 'components/common/Modal';
import TableCards from 'components/common/TableCards';
import LoadingPage from 'components/common/LoadingPage';
import { requestStatuses } from 'constants/values';
import Checked from 'assets/img/greenCheck.svg';
import UserModal from 'components/AdminPage/UserModal';
import RoleModal from 'components/AdminPage/RoleModal';
import TaskModal from 'components/AdminPage/TaskModal';
import ExportAdminCSV from 'components/AdminPage/ExportAdminCSV';
import spinner from 'assets/img/spinner.gif';
import Popover from 'components/common/Popover';
import { CSVLink } from 'react-csv';
import { flattenObject } from 'utils/flatten';
import { getTrPropsForDuplicateUsers } from 'utils/getTrProps';
import {
  getfilteredDatafromUtils,
  getClassNamefromUtils,
} from 'utils/getfiteredData';
import DowntimeModal from './DowntimeModal';

const mapStateToProps = state => {
  return {
    activeTool: selectActiveTool(state),
    columns: selectColumns(state),
    data: selectData(state),
    focusedRecord: selectFocusedRecord(state),
    requestStatus: selectRequestStatus(state),
    adminToolRequestStatus: selectAdminToolRequestStatus(state),
    showModal: selectShowModal(state),
    roleOptions: selectRoleOptions(state),
    pillarOptions: selectPillarOptions(state),
    assetOptions: selectAssetOptions(state),
    activeCard: selectActiveCard(state),
    assetList: selectAssets(state)
  };
};

const mapDispatchToProps = {
  getAssetOptions,
  setActiveCard,
  getData,
  getPillarOptions,
  getRoleOptions,
  getSuperitendentOptions,
  resetAdminToolRequestStatus,
  setActiveTool,
  toggleModal,
  turnOffModal,
  getUserInfo,
  getAssetHierarchy
};
const returnConrolAdminModalHeading = (focusedRecord) =>{
  return focusedRecord.isStandardControl
  ? 'edit standard control'
  : 'edit custom control'
}
const getAssetRoleHeadingInput =(inputValues)=> {
  return  inputValues.length === 1 ? inputValues[0] :
  'Multiple';
}
const getAssetRoleHeading = (inputValues) =>{ 
  return inputValues.length !== 0 ?  getAssetRoleHeadingInput (inputValues): 'Select an option'
 
}
export class AdminPage extends Component {
  state = {
    defaultSorted,
    downloadData: [],
    inputValues: [],
    inputRoleValues: [],
    expanded: false,
    expandedSecondDropdown: false,
  };

  constructor(props) {
    super(props);
    this.reactTable = React.createRef();
    this.csvLink = React.createRef();
  }

  componentDidMount() {
    this.props.getData();
    this.props.getPillarOptions();
    this.props.getAssetOptions();
    this.props.getRoleOptions();
    this.props.getSuperitendentOptions();
  }

  componentDidUpdate(prevProps) {
    // If the latest Admin Tool Request Status is Successful, grab new Data.
    if (
      this.props.adminToolRequestStatus === requestStatuses.success &&
      this.props.adminToolRequestStatus !== prevProps.adminToolRequestStatus
    ) {
      this.props.getData();
      this.props.getUserInfo();
      this.props.getRoleOptions();
      this.props.getSuperitendentOptions();
    }
    // If the latest add role  Request Status is Successful, grab new Data.
    // Reset to default sorting if the activeTool changes
    if (this.props.activeTool !== prevProps.activeTool) {
      this.setState({ defaultSorted });
      this.setState({
        inputValues: [],
        inputRoleValues: [],
      });
    }
  }
  
  renderModal = () => {
    const {
      activeTool,
      focusedRecord,
       adminToolRequestStatus,
    } = this.props;
   
    const adminModals = {
      users: {
        title: !focusedRecord ? 'add new user' : 'edit user',
        component: <UserModal record={focusedRecord} />,
      },
      taskTypes: {
        title: !focusedRecord
          ? 'create new control'
          : returnConrolAdminModalHeading(focusedRecord),
        component: <TaskModal record={focusedRecord} />,
      },
      downtimeCodes: {
        title: 'Edit DT downtimecode',
        component: <DowntimeModal record={focusedRecord} />,
      },
     roles: {
      title: !focusedRecord ? 'add new role' : 'edit role',
      component: <RoleModal record={focusedRecord} />,
     }
    };
    if (adminToolRequestStatus === requestStatuses.success) {
      setTimeout(() => {
        this.props.turnOffModal();
      }, 3000);
      return (
        <Modal onCloseClick={() => {return;}} size={'modalContainerSmall'}>
          <div className={styles.successModal}>
            <img
              src={Checked}
              className={styles.successIcon}
              alt={'Success Icon'}
            />
            <p className={styles.successMessage}>Successfully saved!</p>
          </div>
        </Modal>
      );
    }
    if (adminToolRequestStatus === requestStatuses.pending) {
      return (
        <Modal onCloseClick={() => {return;}} size={'pendingMessage'}>
          <div className={styles.spinner}>
            <img
              src={spinner}
              alt="loading spinner"
              width="100px"
              height="100px"
            />
          </div>
        </Modal>
      );
    } else {
      return (
        <Modal
          title={adminModals[activeTool].title}
          onCloseClick={() => this.props.turnOffModal()}
        >
          {!!adminModals[activeTool] && adminModals[activeTool].component}
        </Modal>
      );
    }
  };
  getfilteredData = () => {
    let activeCard = this.props.activeCard;
    let data = this.props.data;
    let activeTool = this.props.activeTool;
    const app = this.state.inputValues;
    const rollApp = this.state.inputRoleValues;
    return getfilteredDatafromUtils(activeCard, data, activeTool, app, rollApp);
  };

  download = r => {
    let records;
    records = this.reactTable.getResolvedState().sortedData;
    const recordsNew = records.map(a => {
      return {
        Title: a.title,
        Assets: a.assets.props.children.props.children,
        Roles: a.roles.props.children.props.children,
        'Pillar Association': a.pillarName,
      };
    });
    recordsNew.length > 0
      ? this.setState({ downloadData: recordsNew }, () => {
          this.csvLink.link.click();
        })
      : this.setState({ downloadData: recordsNew }, () => {return;});
  };

  getTrProps = (state, rowInfo) => {
    //state included as first parameter for getTrProps for react table
    return getTrPropsForDuplicateUsers(rowInfo);
  };

  showCheckboxes = () => {
    var checkboxes = document.getElementById('checkboxes');
    if (!this.state.expanded) {
      checkboxes.style.display = 'block';
      this.setState({ expanded: true });
    } else {
      checkboxes.style.display = 'none';
      this.setState({ expanded: false });
    }
  };

  showCheckboxesTwo = () => {
    var checkboxesTwo = document.getElementById('checkboxesTwo');
    if (!this.state.expandedSecondDropdown) {
      checkboxesTwo.style.display = 'block';
      this.setState({ expandedSecondDropdown: true });
    } else {
      checkboxesTwo.style.display = 'none';
      this.setState({ expandedSecondDropdown: false });
    }
  };

  handleChange = event => {
    let dropdownName = event.target.name;
    let dropdown_list =
      dropdownName === 'asset'
        ? this.state.inputValues
        : this.state.inputRoleValues;
    let check = event.target.checked;
    let checked_day = event.target.value;
    if (check) {
      dropdownName === 'asset'
        ? this.setState({
            inputValues: [...this.state.inputValues, checked_day],
          })
        : this.setState({
            inputRoleValues: [...this.state.inputRoleValues, checked_day],
          });
    } else {
      let index = dropdown_list.indexOf(checked_day);
      if (index > -1) {
        dropdown_list.splice(index, 1);
        dropdownName === 'asset'
          ? this.setState({
              inputValues: dropdown_list,
            })
          : this.setState({
              inputRoleValues: dropdown_list,
            });
      }
    }
  };

  render = () => {
    const {
      activeTool,
      columns,
      data,
      requestStatus,
      showModal,
      roleOptions,
      pillarOptions,
      assetOptions,
      activeCard,
      assetList
    } = this.props;

    if (
      activeTool === adminToolValues.taskTypes &&
      columns[columns.length - 1].Header !== ''
    ) {
      columns.push({
        Header: 'Assets',
        id: 'assets',
        accessor: assetData => {
          let output = [];
          assetData.assets.map(asset => {
            return output.push(asset.assetName);
          });

          let outputStr = output.join(',');
          return (
            <span style={{ display: 'flex' }}>
              <Popover text={outputStr} overflow={true}>
                {outputStr}
              </Popover>
            </span>
          );
        },
        minWidth: 150,
        headerClassName: 'columnHeader',
        defaultSortDesc: true,
        filterable: true,
        Filter: () => {
          return (
            <div className={styles.fix}>
              <div className={styles.multiselect}>
                <div className={styles.selectBox} onClick={this.showCheckboxes}>
                  <select>
                    <option>{
                       getAssetRoleHeading(this.state.inputValues)}</option>
                  </select>
                  <div className={styles.overSelect}></div>
                </div>
                <div id="checkboxes" className={styles.checkBoxLabel}>
                  {assetOptions.map(i => {
                    return (
                      <div key={i.label} className={styles.listMargin}>
                        <input
                          className={styles.checkedSpace}
                          key={i.value}
                          onChange={e => {
                            this.handleChange(e);
                          }}
                          value={i.label}
                          type="checkbox"
                          id={i}
                          name={'asset'}
                        />
                        <label
                          key={i.label}
                          className={styles.stopClick}
                          htmlFor={i}
                          disabled={true}
                        >
                          {i.label}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        },
      });
    }
    if (
      activeTool === adminToolValues.users &&
      columns[columns.length - 1].Header !== ''
    ) {
      columns.splice(4, 0, {
        Header: 'Assets',
        id: 'userAssets',
        accessor: userAssetData => {
          let output = [];
          userAssetData.userAssets.map(asset => {
            return output.push(asset.name);
          });
          let outputStr = output.join(',');
          return (
            <span style={{ display: 'flex' }}>
              <Popover text={outputStr} overflow={true}>
                {outputStr}
              </Popover>
            </span>
          );
        },
        minWidth: 150,
        headerClassName: 'columnHeader',
        defaultSortDesc: true,
        filterable: true,
        filterMethod: (filter, row) => {
          if (filter.value === 'all') {
            return true;
          }
          let checkFilterAssets = assetList.filter(function (asset) {
            return asset.name === filter.value;
          });
          let name = checkFilterAssets.map(item => {
            return item['name'];
          });
          return row[filter.id].props.children.props.children.includes(
            name.join('')
          );
        },
        Filter: ({ filter, onChange }) => {
          return (
            <select
              onChange={event => onChange(event.target.value)}
              style={{ width: '100%' }}
              value={filter ? filter.value : 'all'}
            >
              <option value="all">Show All</option>
              {assetList.map(i => {
                return <option key={i.value}>{i.name}</option>;
              })}
            </select>
          );
        },
      });
    }
    if (
      activeTool === adminToolValues.taskTypes &&
      columns[columns.length - 1].Header !== ''
    ) {
      columns.push({
        Header: 'Roles',
        id: 'roles',
        accessor: roleData => {
          let output = [];
          roleData.roles.map(role => {
            return output.push(role.title);
          });
          let outputStr = output.join(',');
          return (
            <span style={{ display: 'flex' }}>
              <Popover text={outputStr} overflow={true}>
                {outputStr}
              </Popover>
            </span>
          );
        },
        headerClassName: 'columnHeader',
        minWidth: 200,
        filterable: true,
        Filter: () => {
          return (
            <div className={styles.fix}>
              <div className={styles.multiselectRole}>
                <div
                  className={styles.selectBox}
                  onClick={this.showCheckboxesTwo}
                >
                  <select>
                  <option>{getAssetRoleHeading(this.state.inputRoleValues)}</option>
                  </select>
                  <div className={styles.overSelect}></div>
                </div>
                <div id="checkboxesTwo" className={styles.checkBoxLabelRole}>
                  {roleOptions.map(i => {
                    return (
                      <div key={i.name} className={styles.listMargin}>
                        <input
                          className={styles.checkedSpace}
                          onChange={e => {
                            this.handleChange(e);
                          }}
                          key={i.value}
                          value={i.name}
                          type="checkbox"
                          id={i}
                          name={'role'}
                        />
                        <label
                          key={i.name}
                          className={styles.stopClick}
                          htmlFor={i}
                          disabled={true}
                        >
                          {i.name}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        },
      });
    }

    if (
      activeTool === adminToolValues.taskTypes &&
      columns[columns.length - 1].Header !== ''
    ) {
      columns.push({
        Header: 'Pillar Association',
        accessor: 'pillarName',
        minWidth: 150,
        headerClassName: 'columnHeader',
        filterable: true,
        filterMethod: (filter, row) => {
          if (filter.value === 'all') {
            return true;
          }
          let checkFilterPillars = pillarOptions.filter(function (pillar) {
            return pillar.name === filter.value;
          });
          let name = checkFilterPillars.map(item => {
            return item['name'];
          });
          return row[filter.id].includes(name.join(''));
        },
        Filter: ({ filter, onChange }) => {
          return (
            <select
              onChange={event => onChange(event.target.value)}
              style={{ width: '100%' }}
              value={filter ? filter.value : 'all'}
            >
              <option value="all">Show All</option>
              {pillarOptions.map(i => {
                return <option key={i.pillarId}>{i.name}</option>;
              })}
            </select>
          );
        },
      });
    }

    if (columns[columns.length - 1].Header !== '') {
      columns.push({
        Header: '',
        Cell: props => (
          <span
            onClick={() => {
              this.props.toggleModal(props.original);
            }}
            className={styles.actionItem}
          >
            view / edit
          </span>
        ),
        minWidth: 120,
        sortable: false,
      });
    }
    return (
      <div className={styles.adminWrapper}>
        {showModal && this.renderModal()}
        <div className={styles.adminNavBar}>
          {adminTools.map(tool => {
            return (
              <div
                key={tool}
                className={classnames(styles.link, {
                  [styles.active]: activeTool === tool,
                })}
                onClick={() => {
                  if (requestStatus === requestStatuses.success) {
                    this.props.setActiveTool(tool);
                    this.props.getData();
                  }
                }}
              >
                {adminConfig[tool].title}
              </div>
            );
          })}
        </div>
        {activeTool !== adminToolValues.downtimeCodes ? activeTool !== adminToolValues.roles ? (
          <div className={styles.cardContainerActive}>
            <TableCards
              isCentered={true}
              cards={[
                {
                  title: 'Active',
                  action: () => {
                    this.props.setActiveCard('active');
                    this.getfilteredData();
                  },
                  isSelected: this.props.activeCard === 'active',
                  width: 'primary',
                  hideRefresh: true,
                },
                {
                  title: 'In-active',
                  action: () => {
                    this.props.setActiveCard('inactive');
                    this.getfilteredData();
                  },
                  isSelected: this.props.activeCard === 'inactive',
                  width: 'primary',
                  hideRefresh: true,
                },
              ]}
            />
          </div>
        ) : null:null}
        <div className={styles.content}>
          <AdminHeader
            headerText={
              activeTool === adminToolValues.taskTypes
                ? 'manage control tasks'
                : `manage ${adminConfig[activeTool].title}`
            }
            showHeaderButton={
              (activeTool === adminToolValues.users &&
                activeCard === 'active') ||
              (activeTool === adminToolValues.taskTypes &&
                activeCard === 'active') ||
              (activeTool === adminToolValues.roles)
            }
            buttonType={'newRecord'}
            buttonAction={() => {
              this.props.toggleModal();
            }}
            buttonText={
              activeTool === adminToolValues.taskTypes
                ? 'add standard control'
                : `add new ${adminConfig[activeTool].type}`
            }
          />
          {activeTool === 'users' &&
          activeCard === 'active' &&
          data.length > 0 ? (
            <ExportAdminCSV />
          ) : null}
          {activeTool === adminToolValues.taskTypes ? (
            <div className={styles.csvWrapper}>
              <button
                className={styles.exportButtonControls}
                onClick={this.download}
              >
                export control table
              </button>
              <CSVLink
                data={this.state.downloadData.map(row => flattenObject(row))}
                className={styles.csvLink}
                filename={`${activeTool.replace(/ /g, '_')}.csv`}
                ref={r => (this.csvLink = r)}
              >
                  
              </CSVLink>
            </div>
          ) : null}
          {data.length > 0 &&
          columns &&
          requestStatus !== requestStatuses.pending ? (
            <div className={getClassNamefromUtils(activeTool)}>
              <ReactTable
                ref={r => (this.reactTable = r)}
                columns={columns}
                data={this.getfilteredData()}
                defaultPageSize={9999}
                minRows={0}
                showPagination={false}
                resizable={false}
                defaultSorted={this.state.defaultSorted}
                getTrProps={this.getTrProps}
                onSortedChange={newSorted =>
                  this.setState({ defaultSorted: newSorted })
                }
              />
            </div>
          ) : (
            <LoadingPage
              containerStyles={styles.loading}
              requestStatus={requestStatus}
              retryRequest={this.props.getData}
            />
          )}
        </div>
      </div>
    );
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdminPage)
);
