import types from 'constants/actionTypes';

const cache = (state = {}, { type, payload }) => {
  switch (type) {
    case types.CACHE_RESPONSE:
      const { cacheStamp, endpoint, response } = payload;
      const { type: cacheType, lastCheckpoint } = cacheStamp;
      const cacheEntry = {};
      const previousCheckpoint = state.hasOwnProperty(cacheType) ? Object.keys(state[cacheType])[0] : '';
      const checkedCache = previousCheckpoint === lastCheckpoint ?
        { ...state[cacheType] } : {};
      cacheEntry[endpoint] = Array.isArray(response) ? [...response] : cacheEntry[endpoint] = {...response};
      return {
        ...state,
        [cacheType]: {
          ...checkedCache,
          [lastCheckpoint]: {
            ...checkedCache[lastCheckpoint],
            ...cacheEntry
          }
        }
      };
    case types.PURGE_CACHE:
      return {};
    default:
      return { ...state };
  }
};

export default cache;

// Purge Action
export const purgeCache = () => ({
  type: types.PURGE_CACHE
});

// Selector
export const selectCache = (cache, cacheStamp, endpoint) => {
  const { lastCheckpoint, type } = cacheStamp;
  const typeData = !!cache && !!cache[type] ? cache[type] : null;
  const checkpointData = !!typeData && !!typeData[lastCheckpoint] ? typeData[lastCheckpoint] : null;
  return !!typeData && !!checkpointData && !!checkpointData[endpoint] ? checkpointData[endpoint] : null;
};
