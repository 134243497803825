import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';
import classnames from 'classnames';

const TextInput = ({
  disabled,
  overrideStyles,
  placeholder,
  setAction,
  value
}) => {
  return (
    <div className={overrideStyles}>
      <input
        disabled={disabled}
        className={classnames(styles.text, { [styles.disabled]: disabled })}
        type="text"
        value={value}
        onChange={event => setAction(event.target.value)}
        placeholder={placeholder}
      />
    </div>
  );
};

TextInput.propTypes = {
  disabled: PropTypes.bool,
  overrideStyles: PropTypes.string,
  placeholder: PropTypes.string,
  setAction: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

export default TextInput;
