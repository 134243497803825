export const selectProductionSummaryRequestStatus = ({ production }) =>
  production.requestStatus.productionSummary;

export const selectSummaryTableRequestStatus = ({ production }) =>
  production.requestStatus.summaryTable;

export const selectSummaryChartRequestStatus = ({ production }) =>
  production.requestStatus.summaryChart;

export const selectTableTargetRequestStatus = ({ production }) =>
  production.requestStatus.tableTarget;

export const selectAssetTargetRequestStatus = ({ production }) =>
  production.requestStatus.assetTargetDetails;

export const selectAssetTargetInputBody = ({ production }) =>
  production.assetTargetDetailsInputBody;

export const selectRouteTargetRequestStatus = ({ production }) =>
  production.requestStatus.routeTargetDetails;

export const selectRouteTargetInputBody = ({ production }) =>
  production.routeTargetDetailsInputBody;

export const selectCurrentSummaryCard = ({ production }) =>
  production.currentSummaryCard;

export const selectSummaryTableData = ({ production }) =>
  production.summaryTableData;

export const selectSummaryTableResolvedData = ({ production }) =>
  production.summaryTableResolvedData;

export const selectSummaryChartData = ({ production }) =>
  production.summaryChartData;

export const selectCurrentSummaryData = ({ production }) =>
  production.currentSummaryData;

export const selectCustomTargetAssetName = ({ production }) =>
  production.customTargetAssetName;

export const selectHierarchy = ({ production }) => production.hierarchyLevel;

export const selectProductionDate = ({ production }) =>
  production.productionDate;

export const selectTargetType = ({ production }) =>
production.isAssetTarget;

export const selectShowCustomTargetModal = ({ production }) =>
  production.showCustomTargetModal;

export const selectNewTargetValues = ({ production }) =>
  production.newTargetValues;

export const selectTargetRequestStatus = ({ production }) =>
  production.requestStatus.customTarget;

export const selectAssetTargetPostStatus = ({ production }) =>
  production.requestStatus.assetTargetPostStatus;

export const selectRouteTargetPostStatus = ({ production }) =>
  production.requestStatus.routeTargetPostStatus;

export const selectTableTargetEditing = ({ production }) =>
  production.tableTargetEditing;

export const selectTableTargets = ({ production }) => production.tableTargets;

export const selectAssetTargetDetails = ({ production }) =>
  production.assetTargetDetails;
export const selectRouteTargetDetails = ({ production }) =>
  production.routeTargetDetails;

export const selectIfInvalidRouteTarget = ({ production }) =>
  production.invalidRouteTarget;

export const selectCurrentLpoEventsCard = ({ production }) =>
  production.currentLpoEventsCard;

export const selectCurrentLpoEventsData = ({ production }) =>
  production.currentLpoEventsData;

export const selectLpoEventsTableData = ({ production }) =>
  production.lpoEventsTableData;

export const selectLpoReasonCodes = ({ production }) =>
  production.reasonCodeList;

export const selectIsAllocatedProduction = ({ production }) =>
  production.isAllocatedProduction;

export const selectLpoEventsTableRequestStatus = ({ production }) =>
  production.requestStatus.lpoEventsTable;

export const selectFilteredLpoTable = ({ production }) =>
  production.filteredLpoTable;

export const selectShowLpoTableFilterModal = ({ production }) =>
  production.showLpoTableFilterModal;

export const selectLpoTableSelectedFilters = ({ production }) =>
  production.storedLpoTableFilters;

export const selectShowLpoEventDetailsModal = ({ production }) =>
  production.showLpoEventDetailsModal;

export const selectCurrentLpoEventDetails = ({ production }) =>
  production.currentLpoEventDetails;

export const selectTargetHistoryDaysAgo = ({ production }) =>
  production.targetHistoryDaysAgo;

export const selectShowTargetHistoryTable = ({ production }) =>
  production.showTargetHistoryTable;

export const selectReasonDescriptions = ({ production }) =>
  production.reasonDescriptions;

export const selectShowArrowButtons = ({ production }) =>
  production.showArrowButtons;

export const selectNumOfModalPages = ({ production }) =>
  production.numOfModalPages;

export const selectNumOfWorkOrders = ({ production }) =>
  production.numOfWorkorders;

export const selectNumOfActionItems = ({ production }) =>
  production.numOfActionItems;

export const selectNumOfWitPr = ({ production }) => production.numOfWitPR;

export const selectNumOfPR = ({ production }) => production.numOfPRs;

export const selectCurrentModalPageIndex = ({ production }) =>
  production.currentModalPageIndex;

export const selectShouldLoadData = ({ production }) =>
  production.shouldLoadData;

export const selectCollectionPointId = ({ production }) =>
  production.collectionPointId;

export const selectShowCustomRouteTargetModal = ({ production }) =>
  production.showCustomRouteTargetModal;
