import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const RequiredText = ({ showRequiredText, text = 'required' }) =>
  showRequiredText && <div className={styles.requiredText}>{text}</div>;

RequiredText.propTypes = {
  showRequiredText: PropTypes.bool.isRequired,
  text: PropTypes.string
};
export default RequiredText;
