import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';
import blueX from 'assets/img/close-x-blue.svg';

const Modal = ({
  title,
  onCloseClick,
  children,
  size = 'modalContainerMedium'
}) => (
  <div className={styles.modalOverlay}>
    <div
      className={`${styles.modalContainer} ${styles[size]}`}
      onClick={e => e.stopPropagation()}
    >
      {title && (
        <div className={styles.modalHeader}>
          <div className={styles.modalTitle}>{title}</div>
          <img
            src={blueX}
            alt="close-button"
            className={styles.modalCloseIcon}
            onClick={onCloseClick}
          />
        </div>
      )}
      <div className={styles.modalBody}>{children}</div>
    </div>
  </div>
);

Modal.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  children: PropTypes.node,
  title: PropTypes.string,
  size: PropTypes.string
};

export default Modal;
