import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './styles.module.css';
import { controlItemDetails, controlStatuses } from 'constants/controls';
import { selectIsSupretendent } from 'store/reducers/users';
import {
  changeOccurrenceStatus,
  selectControlTimePeriod,
  toggleFormControlModal,
  selectToggleRecord,
  selectDirectReportStatus,
} from 'store/reducers/controls';
import Truncate from 'react-truncate';
import moment from 'moment';
import { CompletionCircle } from '../CompletionCircle';
// SVGs
import close from 'assets/img/up-blue.svg';
import open from 'assets/img/down-blue.svg';
import { getToggleComplete , getCompletionClass } from 'utils/controls'

const mapStateToProps = state => {
  const { year, month } = selectControlTimePeriod(state);
  return {
    year,
    month,
    showToggleFocusRecord: selectToggleRecord(state),
    isSuperItendent: selectIsSupretendent(state),
    isReport: selectDirectReportStatus(state),
  };
};

const mapDispatchToProps = {
  changeOccurrenceStatus,
  toggleFormControlModal,
};

export class ControlItemCard extends Component {
  state = {
    showAll: false,
  };

  toggleMore = () => {
    this.setState({
      showAll: !this.state.showAll,
    });
  };

  toggleTaskComplete = () => {
    const {
      pillarTitle,
      controlItem: { occurrence },
    } = this.props;
    const { occurrenceId, status } = occurrence;
    let isComplete = false;
    switch (status) {
      case controlStatuses.ready:
      case controlStatuses.error:
        isComplete = true;
        this.props.changeOccurrenceStatus({ occurrenceId, isComplete, pillarTitle });
        break;
      case controlStatuses.complete:
        this.props.changeOccurrenceStatus({ occurrenceId, isComplete, pillarTitle });
        break;
      case controlStatuses.pending:
      default:
        break;
    }
  };

  render() {
    const {
      controlItem: { title, occurrence, frequency, description, references },
      last,
      isSuperItendent,
      isReport,
    } = this.props;


    let newLinks = references.map(link => {
      if (link.url !== '' && link.name === '') {
        link.type = link.url;
      }
      return {
        url: link.url,
        type: link.type,
        name: link.name,
        referenceId: link.referenceId,
      };
    });
    newLinks.forEach(function (obj) {
      if (obj.type.startsWith('//')) {
        obj.type = obj.type.slice(2);
      }
    });
    const { showAll } = this.state;
    const titleClass = styles.title + (showAll ? ` ${styles.expanded}` : '');
    let containerClass = styles.container;

    containerClass =
      occurrence.status === controlStatuses.complete ||
      occurrence.status === controlStatuses.overdue
        ? containerClass + ` ${styles.checked}`
        : containerClass;
    containerClass = last ? containerClass + ` ${styles.last}` : containerClass;

    return (
      <div className={containerClass}>
        <div className={titleClass}>
          <div
            className={getCompletionClass(isSuperItendent, isReport, styles)}
          >
            <CompletionCircle
              status={occurrence.status}
              clickable={occurrence.status !== controlStatuses.overdue}
              onClick={getToggleComplete(
                isReport,
                isSuperItendent,
                this.toggleTaskComplete
              )}
            />
          </div>

          <Truncate
            lines={showAll ? false : 3}
            ellipsis={
              <span className={styles.ellipsis} onClick={this.toggleMore}>
                ...
              </span>
            }
          >
            {title}
          </Truncate>
          <div className={styles.frequencyText}>
            {frequency.charAt(0).toUpperCase() + frequency.slice(1)}
          </div>
        </div>
        {this.state.showAll ? (
          <div className={styles.descriptionContainer}>
            {/* Expanded View */}
            {!!description && (
              <>
                <p className={styles.boldHeading}>
                  {controlItemDetails.description}
                </p>
                <p className={styles.descriptionText}>{description}</p>
              </>
            )}

            {newLinks.length > 0 && (
              <>
                <div className={`${styles.boldHeading} ${styles.subHeading}`}>
                  {controlItemDetails.links}
                </div>
                <div>
                  {newLinks.map((ref, i) => {
                    return (
                      <a
                        key={i}
                        href={ref.url}
                        target={'_blank'}
                        className={styles.actionLink}
                        // onClick={
                        //   status==='complete'? null:
                        //   references.length === 1 ?
                        //     () => this.toggleTaskComplete() :
                        //     null
                        // }
                      >
                        {ref.type}
                      </a>
                    );
                  })}
                </div>
              </>
            )}
            {occurrence.dueDate && (
              <>
                <p className={styles.dueBy}>
                  <span className={styles.dueByHeading}>
                    {controlItemDetails.dueBy}&nbsp;
                  </span>
                  {moment(occurrence.dueDate).format('MMM D, YYYY').toString()}
                </p>
              </>
            )}
            { this.props.pillarTitle ===
              'Custom' ? (
                occurrence.isOverdue === false ?
              <div className={styles.saveBtnFlex}>
                <button
                  className={styles.saveBtn}
                  onClick={() => this.props.toggleFormControlModal(this.props.controlItem)}
                >
                  edit Control
                </button>
              </div> : null
            ) : null} 
          </div>
        ) : null}

        {this.state.showAll ? (
          <div className={styles.expander} onClick={this.toggleMore}>
            <img
              src={close}
              className={styles.toggle}
              alt={'Click for less info'}
            />
          </div>
        ) : (
          <div className={styles.expander} onClick={this.toggleMore}>
            <img
              src={open}
              className={styles.toggle}
              alt={'Click for more info'}
            />
          </div>
        )}
      </div>
    );
  }
}

ControlItemCard.propTypes = {
  controlItem: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }),
  pillarTitle: PropTypes.string,
  completeTask: PropTypes.func,
  generateFormFieldState: PropTypes.func,
  incrementPillarCount: PropTypes.func,
  decrementPillarCount: PropTypes.func,
  year: PropTypes.number,
  month: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(ControlItemCard);
