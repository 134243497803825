import React, { Component } from 'react';
import Sidebar from 'react-sidebar';
import TopNav from 'components/Layout/TopNav';
import SidebarContent from 'components/Layout/SidebarContent';
import styles from './styles.module.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  selectShowFeedbackModal,
  selectAssets,
  setAsset,
  selectAssetFilterModalProductMangement,
  toggleAssetFilterModalProdManagement,
  selectSelectedAssetsProdManagement,
} from 'store/reducers/navigation';
import {
  getSummary,
  getSummaryChart,
  setHeirarchy,
  getSummaryTable,
  getAssetLevelTargets,
  getRouteLevelTargets,
} from 'store/reducers/production';
import { AssetFilterModal } from '../AssetFilterModal';

const mapStateToProps = state => ({
  showFeedbackModal: selectShowFeedbackModal(state),
  productionAssetOptions: selectAssets(state),
  prodManagementfilterModal: selectAssetFilterModalProductMangement(state),
  prodManagementAssets: selectSelectedAssetsProdManagement(state),
});

const mapDispatchToProps = {
  getSummary,
  getSummaryChart,
  getSummaryTable,
  setAsset,
  toggleAssetFilterModalProdManagement,
  setHeirarchy,
  getAssetLevelTargets,
  getRouteLevelTargets,
};

/* AppLayout setups up the Sidebar toggling and layout of the app.
  The docked state is managed in the React State for AppLayout.

  Feedback Modal (for adding new feedback) is also conditionally
  render within this component. This is because if it was inside
  the children of the sidebar component it became very weird.
*/
export class AppLayout extends Component {
  state = {
    open: false,
  };

  toggleSetOpen = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const {
      productionAssetOptions,
      getSummary,
      setAsset,
      getSummaryChart,
      getSummaryTable,
      prodManagementfilterModal,
      toggleAssetFilterModalProdManagement,
      prodManagementAssets,
      setHeirarchy,
      getAssetLevelTargets,
      getRouteLevelTargets,
    } = this.props;

    const sidebarStyles = {
      sidebar: {
        backgroundColor: 'var(--blue-twilight)',
        width: '340px',
        zIndex: '300',
      },
    };
    const prodAssetOptions =
      prodManagementAssets.length > 0
        ? prodManagementAssets.map(el => {
            return {
              label: el.name,
              value: el.assetId,
            };
          })
        : [];
    return (
      <div>
        {prodManagementfilterModal && (
          <AssetFilterModal
            productionAssetOptions={productionAssetOptions}
            setAssetMultiple={setAsset}
            toggleAssetFilterModal={toggleAssetFilterModalProdManagement}
            selectedDashboardAssets={prodAssetOptions}
            getSummary={getSummary}
            getSummaryChart={getSummaryChart}
            getSummaryTable={getSummaryTable}
            setHeirarchy={setHeirarchy}
            getAssetLevelTargets={getAssetLevelTargets}
            getRouteLevelTargets={getRouteLevelTargets}
          />
        )}
        <Sidebar
          overlayClassName={styles.overlay}
          sidebar={<SidebarContent toggleSetOpen={this.toggleSetOpen} />}
          open={this.state.open}
          onSetOpen={this.toggleSetOpen}
          styles={sidebarStyles}
        >
          <div className={styles.contentWrapper}>
            <TopNav
              toggleSetOpen={this.toggleSetOpen}
              docked={this.state.open}
            />
            {this.props.children}
          </div>
        </Sidebar>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppLayout)
);
