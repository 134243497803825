import React, { Component } from 'react';
import styles from './styles.module.css';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { routeNames } from 'constants/routes';
import { serviceURL } from 'constants/feedback';
import gear from 'assets/img/gear.svg';
import classnames from 'classnames';


const getActiveRouteCSS = (active,styles) =>{
  return active ? styles.activeRoute : styles.inactiveRoute
}
const isAdminRouteCSS = (routeName,styles) => {
  return routeName === routeNames.admin ? styles.center : null
}
// NavLink is one of the links rendered in the Sidebar.
class NavLink extends Component {
  render() {
    return this.props.url === '/feedback' ? (
      <Link
        className={styles.link}
        to={{ pathname: serviceURL.url }}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div
          className={classnames(getActiveRouteCSS(this.props.active,styles),
            isAdminRouteCSS(this.props.routeName,styles))
           }
        >
          {this.props.routeName === routeNames.admin ? (
            <img className={styles.img} alt={'gear'} src={gear} />
          ) : null}
          {this.props.routeName}
        </div>
      </Link>
    ) : (
      <Link
        className={styles.link}
        to={this.props.url}
        onClick={e => {
          if (this.props.formDirty) {
            e.preventDefault();
          }
          this.props.onClick();
        }}
      >
        <div
          className={classnames(
            getActiveRouteCSS(this.props.active,styles),
            isAdminRouteCSS(this.props.routeName,styles)
          )}
        >
          {this.props.routeName === routeNames.admin ? (
            <img className={styles.img} alt={'gear'} src={gear} />
          ) : null}
          {this.props.routeName}
        </div>
      </Link>
    );
  }
}

export default NavLink;

NavLink.propTypes = {
  active: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  routeName: PropTypes.string.isRequired,
};
