import React from 'react';
import { formatNumber } from 'utils/formatNumber';
import moment from 'moment';
import Popover from 'components/common/Popover';
import RedDot from 'components/common/RedDot';
import Info from 'assets/img/info.svg';


// This file contains the React-Table Columns configuration for the Downtime Exceptions Table that's on the Exception Management page.
// To add a new exception, you must add the Table Columns here in this file following the two examples.

// Text used in the columns below.


const tableText = {
  comingSoon: 'Coming soon',
  completedDate: 'Completed Date',
  daysInStep: 'Days In Step',
  ecComments: 'EC Comments',
  exceptionType: 'Exception Type',
  lossRate: 'Loss Rate (BOED)',
  moreInfo: 'More Information',
  pullRequest: 'Pull Request',
  wo: 'WO',
  priority: 'Priority',
  completed: 'Completed',
  scheduled: 'Sceduled',
  prStatus: 'PR Status',
  reasonCode: 'Reason Code',
  scheduledDate: 'Scheduled Date',
  seeActivity: 'See Activity',
  timeDown: 'Time Down',
  wellName: 'Well Name',
  routeName: 'Route',
};

// Common Headers
const isNewHeader = {
  Header: '',
  accessor: 'isNew',
  minWidth: 15,
  Cell: props => <span>{props.original.isNew ? <RedDot /> : ''}</span>,
  headerClassName: 'centeredColumnHidden',
  sortable: false,
  filterable: false
};
const wellNameHeader = setLpoEvent => {
  return {
    Header: tableText.wellName,
    accessor: 'wellName',
    minWidth: 150,
    sortable: false,
    // Cell: props => <span
    // >{props.original.wellName}</span>,
    Cell: props => (
      <span
        style={{ display: 'flex' }}
        className={'clickableRoute'}
        onClick={() => {
          setLpoEvent(props.original);
        }}
      >
        <Popover text={props.original.wellName} overflow={true}>
          {props.original.wellName}
        </Popover>
      </span>
    ),
    headerClassName: 'centeredColumnHeader'
  }
};
const routeNameHeader = {
  Header: tableText.routeName,
  accessor: 'routeName',
  minWidth: 150,
  Cell: props => <span>{props.original.routeName}</span>,
  headerClassName: 'centeredColumnHeader'
};
const timeDownHeader = {
  Header: tableText.timeDown,
  accessor: 'timeDown',
  minWidth: 100,
  Cell: props => <span>{`${props.original.timeDown} days`}</span>,
  headerClassName: 'centeredColumnHeader'
};
const lossRateHeader = {
  Header: tableText.lossRate,
  accessor: 'lossRate',
  minWidth: 125,
  Cell: props => <span>{formatNumber(props.original.lossRate)}</span>,
  headerClassName: 'centeredColumnHeader'
};
const exceptionTypeHeader = {
  Header: tableText.exceptionType,
  accessor: 'exceptionType',
  minWidth: 100,
  Cell: props => <span>{props.original.exceptionType}</span>,
  headerClassName: 'centeredColumnHeader'
};
const reasonCodeHeader = {
  Header: tableText.reasonCode,
  accessor: 'reasonCode',
  sortable: false,
  minWidth: 80,
  Cell: props => <span>{props.original.reasonCode}</span>,
  headerClassName: 'centeredColumnHeader'
};
const pullRequestHeader = {
  Header: tableText.pullRequest,
  accessor: 'pullRequest',
  minWidth: 100,
  Cell: props => <span>{props.original.pullRequest}</span>,
  headerClassName: 'centeredColumnHeader'
};
const prStatusHeader = {
  Header: tableText.prStatus,
  accessor: 'prStatus',
  minWidth: 100,
  Cell: props => (
    <span>
      <Popover text={props.original.prStatus}>
        <span className='break-word'>{props.original.prStatus}</span>
      </Popover>
    </span>
  ),
  headerClassName: 'centeredColumnHeader'
};
const woHeader = {
  Header: tableText.wo,
  accessor: 'woCaseId',
  minWidth: 100,
  Cell: props => <span>{props.original.woCaseId}</span>,
  headerClassName: 'centeredColumnHeader'
};
const priorityHeader = {
  Header: tableText.priority,
  accessor: 'priority',
  minWidth: 80,
  Cell: props => <span>{props.original.priority}</span>,
  headerClassName: 'centeredColumnHeader'
};
const completedHeader = {
  Header: tableText.completed,
  accessor: 'woCompletedDate',
  minWidth: 100,
  Cell: props => (
    <span>{(!props.original.woCompletedDate || props.original.scheduleStartDate === '0001-01-01T00:00:00'
      || props.original.scheduleStartDate === 'N/A') ? 'N/A' : props.original.scheduleStartDate === 'Multiple' ? 'Multiple' :
      moment(props.original.woCompletedDate).format('M/D/YYYY')}
    </span>
  ),
  headerClassName: 'centeredColumnHeader'
};
const daysInStepHeader = {
  Header: tableText.daysInStep,
  accessor: 'daysInStep',
  minWidth: 80,
  Cell: props => <span>{props.original.daysInStep}</span>,
  headerClassName: 'centeredColumnHeader'
};
const scheduledHeader = {
  Header: tableText.scheduledDate,
  accessor: 'scheduledDate',
  minWidth: 100,
  Cell: props => {
    return (
      <span>
        {(!props.original.scheduledDate || props.original.scheduledDate === '0001-01-01T00:00:00'
          || props.original.scheduledDate === 'N/A') ?
          'N/A' : props.original.scheduleStartDate === 'Multiple' ? 'Multiple' : moment(props.original.scheduledDate).format('M/D/YYYY')}
      </span>
    )
  },
  headerClassName: 'centeredColumnHeader'
};
const scheduledDateHeader = {
  Header: tableText.scheduledDate,
  accessor: 'woScheduledDate',
  minWidth: 100,
  Cell: props => {
    console.log(props.original.woScheduledDate)
    return (
      <span>
        {(!props.original.woScheduledDate || props.original.woScheduledDate === '0001-01-01T00:00:00'
          || props.original.woScheduledDate === 'N/A') ?
          'N/A' : props.original.scheduleStartDate === 'Multiple' ? 'Multiple' : moment(props.original.woScheduledDate).format('M/D/YYYY')}
      </span>
    )
  },
  headerClassName: 'centeredColumnHeader'
};
const completedDateHeader = {
  Header: tableText.completedDate,
  accessor: 'completedDate',
  minWidth: 100,
  Cell: props => (
    <span>
      {(!props.original.completedDate || props.original.completedDate === '0001-01-01T00:00:00'
        || props.original.completedDate === 'N/A') ?
        'N/A' : props.original.scheduleStartDate === 'Multiple' ? 'Multiple' : moment(props.original.completedDate).format('M/D/YYYY')}
    </span>
  ),
  headerClassName: 'centeredColumnHeader'
};
const ecCommentsHeader = {
  Header: tableText.ecComments,
  accessor: 'ecComments',
  sortable: false,
  minWidth: 100,
  Cell: props => (
    <span>
      <Popover text={props.original.comments}>
        <img src={Info} alt={tableText.ecComments} />
      </Popover>
    </span>
  ),
  headerClassName: 'centeredColumnHeader'
};

// TODO:  Uncomment/activate Send Message column when no longer 'coming soon'
// const sendMessageHeader = {
//   Header: () => (
//     <Popover text={tableText.sendMessage}>
//       <img src={MailDarkGrey} alt={tableText.sendMessage} />
//     </Popover>
//   ),
//   minWidth: 30,
//   Cell: () => (
//     <Popover text={tableText.comingSoon}>
//       <img src={MailLightGrey} alt={tableText.comingSoon} />
//     </Popover>
//   ),
//   sortable: false,
//   filterable: false
// };

// TODO:  Uncomment/activate See Activity column when no longer 'coming soon'
// const seeActivityHeader = {
//   Header: () => (
//     <Popover text={tableText.seeActivity}>
//       <img src={ActivityDarkGrey} alt={tableText.seeActivity} />
//     </Popover>
//   ),
//   minWidth: 30,
//   Cell: () => (
//     <Popover text={tableText.comingSoon}>
//       <img src={ActivityLightGrey} alt={tableText.comingSoon} />
//     </Popover>
//   ),
//   sortable: false,
//   filterable: false
// };

// All Exceptions, which is also a Primary Card.
export const allExceptionsColumns = setLpoEvent => [
  isNewHeader,
  // wellNameHeader,
  {
    Header: tableText.wellName,
    accessor: 'wellName',
    minWidth: 150,
    sortable: false,
    Cell: props => (
      <span
        style={{ display: 'flex' }}
        className={'clickableRoute'}
        onClick={() => {
          setLpoEvent(props.original);
        }}
      >
        <Popover text={props.original.wellName} overflow={true}>
          {props.original.wellName}
        </Popover>
      </span>
    ),
    headerClassName: 'centeredColumnHeader'
  },
  routeNameHeader,
  timeDownHeader,
  lossRateHeader,
  exceptionTypeHeader,
  ecCommentsHeader
];

// All exception without route column 
export const allExceptionsColumnsWithoutRouteName = setLpoEvent => [
  isNewHeader,
  {
    Header: tableText.wellName,
    accessor: 'wellName',
    minWidth: 150,
    sortable: false,
    Cell: props => (
      <span
        style={{ display: 'flex' }}
        className={'clickableRoute'}
        onClick={() => {
          setLpoEvent(props.original);
        }}
      >
        <Popover text={props.original.wellName} overflow={true}>
          {props.original.wellName}
        </Popover>
      </span>
    ),
    headerClassName: 'centeredColumnHeader'
  },
  timeDownHeader,
  lossRateHeader,
  exceptionTypeHeader,
  ecCommentsHeader
];

export const surfaceColumns = setLpoEvent => [
  isNewHeader,
  {
    Header: tableText.wellName,
    accessor: 'wellName',
    minWidth: 150,
    sortable: false,
    Cell: props => (
      <span
        style={{ display: 'flex' }}
        className={'clickableRoute'}
        onClick={() => {
          setLpoEvent(props.original);
        }}
      >
        <Popover text={props.original.wellName} overflow={true}>
          {props.original.wellName}
        </Popover>
      </span>
    ),
    headerClassName: 'centeredColumnHeader'
  },
  routeNameHeader,
  timeDownHeader,
  lossRateHeader,
  exceptionTypeHeader,
  ecCommentsHeader
];
export const surfaceColumnsWithoutRouteName = setLpoEvent => [
  isNewHeader,
  {
    Header: tableText.wellName,
    accessor: 'wellName',
    minWidth: 150,
    sortable: false,
    Cell: props => (
      <span
        style={{ display: 'flex' }}
        className={'clickableRoute'}
        onClick={() => {
          setLpoEvent(props.original);
        }}
      >
        <Popover text={props.original.wellName} overflow={true}>
          {props.original.wellName}
        </Popover>
      </span>
    ),
    headerClassName: 'centeredColumnHeader'
  },
  timeDownHeader,
  lossRateHeader,
  exceptionTypeHeader,
  ecCommentsHeader
];

export const subsurfaceColumns = setLpoEvent => [
  isNewHeader,
  {
    Header: tableText.wellName,
    accessor: 'wellName',
    minWidth: 150,
    sortable: false,
    Cell: props => (
      <span
        style={{ display: 'flex' }}
        className={'clickableRoute'}
        onClick={() => {
          setLpoEvent(props.original);
        }}
      >
        <Popover text={props.original.wellName} overflow={true}>
          {props.original.wellName}
        </Popover>
      </span>
    ),
    headerClassName: 'centeredColumnHeader'
  },
  routeNameHeader,
  timeDownHeader,
  lossRateHeader,
  exceptionTypeHeader,
  ecCommentsHeader
];
export const subsurfaceColumnsWithoutRouteName = setLpoEvent => [
  isNewHeader,
  {
    Header: tableText.wellName,
    accessor: 'wellName',
    minWidth: 150,
    sortable: false,
    Cell: props => (
      <span
        style={{ display: 'flex' }}
        className={'clickableRoute'}
        onClick={() => {
          setLpoEvent(props.original);
        }}
      >
        <Popover text={props.original.wellName} overflow={true}>
          {props.original.wellName}
        </Popover>
      </span>
    ),
    headerClassName: 'centeredColumnHeader'
  },
  timeDownHeader,
  lossRateHeader,
  exceptionTypeHeader,
  ecCommentsHeader
];

// Troubleshooting, part of the SubSurface Primary Card.
export const troubleshootingColumns = setLpoEvent => [
  isNewHeader,
  wellNameHeader(setLpoEvent),
  routeNameHeader,
  timeDownHeader,
  lossRateHeader,
  ecCommentsHeader
];

// Troubleshooting, part of the SubSurface Primary Card without route name.
export const troubleshootingColumnsWithoutRouteName = setLpoEvent => [
  isNewHeader,
  wellNameHeader(setLpoEvent),
  timeDownHeader,
  lossRateHeader,
  ecCommentsHeader
];

// No Pull Request, part of the SubSurface Primary Card.
export const noPullRequestColumns = setLpoEvent => [
  isNewHeader,
  wellNameHeader(setLpoEvent),
  routeNameHeader,
  timeDownHeader,
  lossRateHeader,
  reasonCodeHeader,
  ecCommentsHeader
];

// No Pull Request, part of the SubSurface Primary Card without route name.
export const noPullRequestColumnsWithoutRouteName = setLpoEvent => [
  isNewHeader,
  wellNameHeader(setLpoEvent),
  timeDownHeader,
  lossRateHeader,
  reasonCodeHeader,
  ecCommentsHeader
];

// Pull Request Status, part of the SubSurface Primary Card.
export const pullRequestStatusColumns = setLpoEvent => [
  isNewHeader,
  wellNameHeader(setLpoEvent),
  routeNameHeader,
  timeDownHeader,
  lossRateHeader,
  reasonCodeHeader,
  pullRequestHeader,
  prStatusHeader,
  daysInStepHeader,
  scheduledHeader,
  ecCommentsHeader
];

// Pull Request Status, part of the SubSurface Primary Card without route name.
export const pullRequestStatusColumnsWithoutRouteName = setLpoEvent => [
  isNewHeader,
  wellNameHeader(setLpoEvent),
  timeDownHeader,
  lossRateHeader,
  reasonCodeHeader,
  pullRequestHeader,
  prStatusHeader,
  daysInStepHeader,
  scheduledHeader,
  ecCommentsHeader
];

// Complete but not producing, part of the SubSurface Primary Card.
export const completeNonProdSubsurfaceColumns = setLpoEvent => [
  isNewHeader,
  wellNameHeader(setLpoEvent),
  routeNameHeader,
  timeDownHeader,
  lossRateHeader,
  reasonCodeHeader,
  pullRequestHeader,
  completedDateHeader,
  ecCommentsHeader
];

// Complete but not producing, part of the SubSurface Primary Card without route name.
export const completeNonProdSubsurfaceColumnsWithoutRouteName = setLpoEvent => [
  isNewHeader,
  wellNameHeader(setLpoEvent),
  timeDownHeader,
  lossRateHeader,
  reasonCodeHeader,
  pullRequestHeader,
  completedDateHeader,
  ecCommentsHeader
];

// Complete but not producing, part of the SubSurface Primary Card without route name.
export const noWorkOrder = setLpoEvent => [
  isNewHeader,
  wellNameHeader(setLpoEvent),
  routeNameHeader,
  timeDownHeader,
  lossRateHeader,
  reasonCodeHeader,
  ecCommentsHeader
];
export const noWorkOrderWithotRouteName = setLpoEvent => [
  isNewHeader,
  wellNameHeader(setLpoEvent),
  timeDownHeader,
  lossRateHeader,
  reasonCodeHeader,
  ecCommentsHeader
];

export const completeNonProdSurfaceColumns = setLpoEvent => [
  isNewHeader,
  wellNameHeader(setLpoEvent),
  routeNameHeader,
  timeDownHeader,
  lossRateHeader,
  reasonCodeHeader,
  woHeader,
  priorityHeader,
  completedHeader,
  ecCommentsHeader
];
export const completeNonProdSurfaceColumnsWithoutRouteName = setLpoEvent => [
  isNewHeader,
  wellNameHeader(setLpoEvent),
  timeDownHeader,
  lossRateHeader,
  reasonCodeHeader,
  woHeader,
  priorityHeader,
  completedHeader,
  ecCommentsHeader
];
export const woPriorityLevelColumns = setLpoEvent => [
  isNewHeader,
  wellNameHeader(setLpoEvent),
  routeNameHeader,
  timeDownHeader,
  lossRateHeader,
  reasonCodeHeader,
  woHeader,
  priorityHeader,
  scheduledDateHeader,
  ecCommentsHeader
];
export const woPriorityLevelColumnsWithoutRouteName = setLpoEvent => [
  isNewHeader,
  wellNameHeader(setLpoEvent),
  timeDownHeader,
  lossRateHeader,
  reasonCodeHeader,
  woHeader,
  priorityHeader,
  scheduledDateHeader,
  ecCommentsHeader
];

