import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';
import NavLink from './NavLink';
import UserInfo from './UserInfo';
import { withRouter } from 'react-router';
import { msalInstance } from 'index.js';
import { routeNames, routes } from 'constants/routes';
import { occurrenceStatus } from 'constants/controls';
import {
  selectIsGuestUser,
  selectUserInfo,
  selectIsReadOnlyUser,
} from 'store/reducers/users';
import { connect } from 'react-redux';
import classnames from 'classnames';
// SVGs
import { ReactComponent as CloseButton } from 'assets/img/close-x-white.svg';
import { serviceURL } from 'constants/feedback';

const mapStateToProps = state => ({
  userInfo: selectUserInfo(state),
  isGuestUser: selectIsGuestUser(state),
  isReadOnlyUser: selectIsReadOnlyUser(state),
});

/* SidebarContent manages everything inside of the Sidebar.

  Links are managed within the NavLinks under the React State. If
  you want to add a new route to the app, it should also be added here
  if you want it to show up in the sidebar. Based on the current route
  a link in the sidebar will be active or not. Based on the hidden property,
  it will be hidden (based on Guest User currently).

  This component shows the UserInfo component, navlinks, the logout button, the 
  feedback button, and launching the admin settings if the user is an admin.
*/
export class SidebarContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navLinks: [
        // {
        //   url: routes.controls + `?timeframe=${timeframes.all}&reset=true`,
        //   routeName: routeNames.controls,
        //   active: false,
        //   home: false,
        //   hidden: true,
        // },
        // {
        //   url: routes.controls,
        //   routeName: routeNames.controls,
        //   active: false,
        //   home: false,
        //   hidden: false,
        // },
        {
          url: routes.productionManagement.summary,
          routeName: routeNames.productionManagement,
          active: false,
          home: true,
          hidden: false,
        },
        {
          url: routes.exceptionManagement.downtime,
          routeName: routeNames.exceptionManagement,
          active: false,
          home: false,
          hidden: false,
        },
        {
          url: routes.feedback,
          routeName: routeNames.feedback,
          active: false,
          home: false,
          hidden: false,
        },
      ],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const currentPage = nextProps.location.pathname;
    const navLinks = prevState.navLinks.map(navLink => {
      if (currentPage === routes.home) {
        navLink.active = navLink.home;
      } else {
        navLink.active = navLink.url.includes(currentPage);

        if (navLink.routeName === routeNames.productionManagement) {
          navLink.active = currentPage.includes(
            routes.productionManagementParent
          );
        }
      }
      return navLink;
    });

    return { navLinks };
  }

  componentDidUpdate() {
    if (
      this.props.userInfo.isAdmin &&
      this.state.navLinks[this.state.navLinks.length - 1].url !== routes.admin
    ) {
      this.setState({
        ...this.state,
        navLinks: [
          ...this.state.navLinks,
          {
            url: routes.admin,
            routeName: routeNames.admin,
            active: false,
            home: false,
          },
        ],
      });
    }
    if (!this.props.isReadOnlyUser) {
      if (
        !this.state.navLinks.some(navlink => navlink.url === routes.controls)
      ) {
        this.setState({
          ...this.state,
          navLinks: [
            {
              url: routes.controls,
              routeName: routeNames.controls,
              active: false,
              home: false,
              hidden: false,
            },
            ...this.state.navLinks,
          ],
        });
      }
    } else {
      if (
        this.state.navLinks.some(navlink => navlink.url === routes.controls)
      ) {
        this.state.navLinks.shift();

        this.setState({
          ...this.state,
          navLinks: [...this.state.navLinks],
        });
      }
    }
  }

  render() {
    const {
      currentOccurrence,
      formDirty,
      isGuestUser,
      isReadOnlyUser,
      openConfirmFormModal,
      userInfo,
    } = this.props;
    return (
      <div className={styles.sidebarContent}>
        <CloseButton
          className={styles.closeButton}
          onClick={this.props.toggleSetOpen}
        />
        <UserInfo />
        <div className={styles.actions}>
          <span className={styles.logout} onClick={() => msalInstance.logoutRedirect()}>
            logout
          </span>
          <>
            <div className={styles.actionDivider}>&#124;</div>
            <a
              className={styles.feedback}
              href={serviceURL.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {isGuestUser ? 'request access' : 'feedback'}
            </a>
          </>
        </div>
        {this.state.navLinks.map(navLink => {
          if (
            (navLink.hidden && isGuestUser) ||
            (navLink.hidden && isReadOnlyUser)
          ) {
            return <Fragment key={navLink.url} />;
          }
          return (
            <div
              key={navLink.routeName}
              className={classnames(styles.navLink, {
                [styles.hasAdmin]: userInfo.isAdmin,
              })}
            >
              <NavLink
                url={navLink.url}
                active={navLink.active}
                routeName={navLink.routeName}
                formDirty={formDirty}
                onClick={() => {
                  if (
                    formDirty &&
                    currentOccurrence.status !== occurrenceStatus.complete
                  ) {
                    openConfirmFormModal(navLink.url);
                  }
                  this.props.toggleSetOpen();
                }}
              />
            </div>
          );
        })}
      </div>
    );
  }
}

SidebarContent.propTypes = {
  toggleSetOpen: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps)(SidebarContent));
