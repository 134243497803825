import { adminToolValues } from 'constants/admin';
import classnames from 'classnames';
import styles from '../components/AdminPage/styles.module.css';

export const getfilteredDatafromUtils = (
  activeCard,
  data,
  activeTool,
  app,
  rollApp
) => {
  let dataNew =
    activeTool !== adminToolValues.downtimeCodes ? activeTool !== adminToolValues.roles
      ? activeCard === 'active'
        ? data.filter(function (el) {
            return el.isActive === true;
          })
        : data.filter(function (el) {
            return el.isActive === false;
          })
      : data:data;

  if (activeTool === adminToolValues.taskTypes) {
    let filteredAsset = [];
    let filteredRole = [];
    if (app.length > 0 && rollApp.length <= 0) {
      for (let i = 0; i < dataNew.length; i++) {
        for (let z = 0; z < dataNew[i].assets.length; z++) {
          for (let j = 0; j < app.length; j++) {
            if (dataNew[i].assets[z]['assetName'] !== null) {
              if (dataNew[i].assets[z]['assetName'].includes(app[j])) {
                filteredAsset.push(dataNew[i]);
              }
            }
          }
        }
      }
      return filteredAsset;
    }
    if (rollApp.length > 0 && app.length <= 0) {
      for (let i = 0; i < dataNew.length; i++) {
        for (let z = 0; z < dataNew[i].roles.length; z++) {
          for (let j = 0; j < rollApp.length; j++) {
            if (dataNew[i].roles[z]['title'] !== null) {
              if (dataNew[i].roles[z]['title'].includes(rollApp[j])) {
                filteredRole.push(dataNew[i]);
              }
            }
          }
        }
      }
      return filteredRole;
    }
    if (rollApp.length > 0 && app.length > 0) {
      for (let i = 0; i < dataNew.length; i++) {
        for (let z = 0; z < dataNew[i].assets.length; z++) {
          for (let j = 0; j < app.length; j++) {
            if (dataNew[i].assets[z]['assetName'] !== null) {
              if (dataNew[i].assets[z]['assetName'].includes(app[j])) {
                filteredAsset.push(dataNew[i]);
              }
            }
          }
        }
      }
      for (let i = 0; i < filteredAsset.length; i++) {
        for (let z = 0; z < filteredAsset[i].roles.length; z++) {
          for (let j = 0; j < rollApp.length; j++) {
            if (filteredAsset[i].roles[z]['title'] !== null) {
              if (filteredAsset[i].roles[z]['title'].includes(rollApp[j])) {
                filteredRole.push(filteredAsset[i]);
              }
            }
          }
        }
      }
      return filteredRole;
    }
    return dataNew;
  }
  return dataNew;
};


export const getClassNamefromUtils = (activeTool) =>{
if(activeTool !==adminToolValues.downtimeCodes){
  return classnames(styles.reactTable, 'adminTable');
}
  else {
return classnames(styles.reactTable, 'adminTableDowntime');
  }

}
