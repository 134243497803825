import React, { Component } from 'react';
import { lpoEventDetailModalLabels } from 'constants/productionManagement';
import styles from './styles.module.css';
import { connect } from 'react-redux';
import { actionItemStatusSteps } from 'constants/statusSteps';
import { selectCurrentLpoEventDetails, selectCurrentModalPageIndex } from 'store/reducers/production';
import moment from 'moment';
import { formatNumber } from 'utils/formatNumber';
import { getCurrentAIIndex } from 'utils/lpoEventDetails';
import StepComponent from 'components/common/StepComponent';


const labels = lpoEventDetailModalLabels;

const mapStateToProps = state => ({
    event: selectCurrentLpoEventDetails(state),
    currentActionItemIndex: selectCurrentModalPageIndex(state)
});



export class ActionItemModal extends Component {

    render() {
        const { event } = this.props;
        let { currentActionItemIndex } = this.props;
        const { woConnected, prConnected, witPrConnected, actionItems, workOrders, witPullRequest, pullRequest } = event.wO_PR;
        currentActionItemIndex = getCurrentAIIndex(currentActionItemIndex, prConnected, witPrConnected, woConnected, workOrders, witPullRequest, pullRequest);

        const submittedDate = (actionItems[currentActionItemIndex].submittedDate || actionItems[currentActionItemIndex].submittedDate !== '0001-01-01T00:00:00') ? moment(actionItems[currentActionItemIndex].submittedDate).format('M/D/YYYY') : '';
        const resolvedDate = (actionItems[currentActionItemIndex].resolvedDate || actionItems[currentActionItemIndex].resolvedDate !== '0001-01-01T00:00:00') ? (moment(actionItems[currentActionItemIndex].resolvedDate).format('M/D/YYYY')) : '';
        const dueDate = (actionItems[currentActionItemIndex].dueDate || actionItems[currentActionItemIndex].dueDate !== '0001-01-01T00:00:00') ? (moment(actionItems[currentActionItemIndex].dueDate).format('M/D/YYYY')) : '';
        const initialDowndate = event.initialDownDate ? (moment(event.initialDownDate).format('M/D/YYYY')) : '';
        return (
            <div className={styles.container}>
                <StepComponent statusSteps={actionItemStatusSteps} status={actionItems[currentActionItemIndex].actionItemStatus} />
                <div className={styles.topDetails}>

                    <ul className={styles.detailColumn}>
                        <li>
                            <span>{labels.status}</span>
                            {actionItems[currentActionItemIndex].actionItemStatus}
                        </li>
                        <li>
                            <span>{labels.wellName}</span>
                            {event.wellName}
                        </li>
                        <li>
                            <span>{labels.collectionPoint}</span>
                            {event.collectionPoint}
                        </li>
                        <li>
                            <span>{labels.route}</span>
                            {event.route}
                        </li>
                        <li>
                            <span>{labels.downTimeType}</span>
                            {event.downTimeType}
                        </li>
                        <li>
                            <span>{labels.tier}</span>
                            {actionItems[currentActionItemIndex].tier}
                        </li>
                        <li>
                            <span>{labels.lpoId}</span>
                            {actionItems[currentActionItemIndex].idapLpoId}
                        </li>
                        <li>
                            <span>{labels.caseId}</span>
                            {actionItems[currentActionItemIndex].actionItemsId}
                        </li>
                        <li>
                            <span>{labels.assignedTeam}</span>
                            {actionItems[currentActionItemIndex].assignedTeam}
                        </li>



                    </ul>

                    <ul className={styles.detailColumn}>

                        <li>
                            <span>{labels.liftMethod}</span>
                            {actionItems[currentActionItemIndex].liftMethod}
                        </li>
                        <li>
                            <span>{labels.initialDownDate}</span>
                            {initialDowndate}
                        </li>
                        <li>
                            <span>{labels.submittedDate}</span>
                            {submittedDate}
                        </li>
                        <li>
                            <span>{labels.resolvedDate}</span>
                            {resolvedDate}
                        </li>
                        <li>
                            <span>{labels.dueDate}</span>
                            {dueDate}
                        </li>
                        <li>
                            <span>{labels.lpoType}</span>
                            {event.lpoType}
                        </li>
                        <li>
                            <span>{labels.createdBy}</span>
                            {actionItems[currentActionItemIndex].createdBy}
                        </li>
                        <li>
                            <span>{labels.createdByCAI}</span>
                            {actionItems[currentActionItemIndex].createdByCai}
                        </li>
                        <li>
                            <span>{labels.resolvedBy}</span>
                            {actionItems[currentActionItemIndex].resolvedBy}
                        </li>
                    </ul>
                </div>
                <p className={styles.resolvedComment}>
                    <span>{labels.resolvedComments}</span>
                    {actionItems[currentActionItemIndex].resolvedComment}
                </p>
                <p className={styles.reason}>
                    <span>{labels.reasonForCriticality}</span>
                    {event.reasonForCriticality}
                </p>
                <p className={styles.issueDescription}>
                    <span>{labels.issueDescription}</span>
                    {actionItems[currentActionItemIndex].issueDescription}
                </p>

                <table
                    className={styles.affectedTable}
                    cellPadding="0"
                    cellSpacing="0"
                >
                    <thead>
                        <tr>
                            <th />
                            <th>{labels.lossRate}</th>
                            <th>{labels.eventLoss}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{labels.oilProduction}</td>
                            <td>
                                {formatNumber(event.affectedProductionDetails.oil.lossRate)}
                                {labels.bopd}
                            </td>
                            <td>
                                {formatNumber(event.affectedProductionDetails.oil.eventLoss)}
                                {labels.boe}
                            </td>
                        </tr>
                        <tr>
                            <td>{labels.gasProduction}</td>
                            <td>
                                {formatNumber(event.affectedProductionDetails.gas.lossRate)}
                                {labels.mcfd}
                            </td>
                            <td>
                                {formatNumber(event.affectedProductionDetails.gas.eventLoss)}
                                {labels.mcf}
                            </td>
                        </tr>
                    </tbody>
                </table>

                <p className={styles.commentsTitle}>{labels.comments}</p>
                <p className={styles.comments}>{event.comments}</p>

            </div>
        );
    }
}

export default connect(mapStateToProps)(ActionItemModal);
