import React from 'react';
import styles from './styles.module.css';
import { connect } from 'react-redux';
import {
  getTargetHistory,
  selectShowTargetHistoryTable,
  selectTargetHistoryDaysAgo,
  setTargetHistoryDaysAgo,
  toggleTargetHistoryTable
} from 'store/reducers/production';
import classnames from 'classnames';
import SelectInput from 'components/common/SelectInput';
import { targetHistorySelectOptions } from 'constants/productionManagement';
import ChartBlue from 'assets/img/fa-bar-chart-blue.svg';
import ChartGrey from 'assets/img/fa-bar-chart.svg';
import TableBlue from 'assets/img/fa-table.svg';
import TableGrey from 'assets/img/fa-table-grey.svg';
import TargetHistoryTable from './TargetHistoryTable';
import TargetHistoryChart from './TargetHistoryChart';

const mapStateToProps = state => ({
  daysAgo: selectTargetHistoryDaysAgo(state),
  showTable: selectShowTargetHistoryTable(state)
});

const mapDispatchToProps = {
  getTargetHistory,
  setTargetHistoryDaysAgo,
  toggleTargetHistoryTable
};

export const TargetHistorySection = ({
  daysAgo,
  getTargetHistory,
  setTargetHistoryDaysAgo,
  showTable,
  toggleTargetHistoryTable
}) => (
  <div className={styles.targetHistorySection}>
    <div className={styles.targetHistoryHeader}>
      <div className={styles.alignLeft}>
        <h1 className={styles.title}>Target History</h1>
      </div>
      <div className={styles.alignRight}>
        <img
          className={classnames(styles.icon, {
            [styles.disabled]: !showTable
          })}
          src={showTable ? ChartGrey : ChartBlue}
          alt={'Target History Chart Button'}
          onClick={showTable ? toggleTargetHistoryTable : null}
          id={'targetHistoryChartIcon'}
        />

        <img
          className={classnames(styles.icon, {
            [styles.disabled]: showTable
          })}
          src={showTable ? TableBlue : TableGrey}
          alt={'Target History Table Button'}
          onClick={!showTable ? toggleTargetHistoryTable : null}
          id={'targetHistoryTableIcon'}
        />

        <SelectInput
          options={targetHistorySelectOptions}
          resetAction={() => {
            return;
          }}
          setAction={productionDay => {
            setTargetHistoryDaysAgo(productionDay);
            getTargetHistory();
          }}
          value={daysAgo}
          showPlaceholder={false}
          label={'Target History Days Ago Select'}
          overrideStyles={styles.select}
        />
      </div>
    </div>
    {showTable ? (
      <div className={styles.table}>
        <TargetHistoryTable />
      </div>
    ) : (
      <div className={styles.chartContainer}>
        <TargetHistoryChart />
      </div>
    )}
  </div>
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TargetHistorySection);
