import { allRoutes } from 'constants/productionManagement';

export const selectShowFeedbackModal = ({ navigation }) =>
  navigation.showFeedbackModal;

export const selectAssetFilterModalProductMangement = ({ navigation }) =>
  navigation.showProductAssetFilterModal;

export const selectAssets = ({ navigation }) =>
  !!navigation.assetHierarchy && navigation.assetHierarchy.length
    ? mapAssets(navigation.assetHierarchy)
    : [];

export const selectFls = ({ navigation }) =>
  !!navigation.roleHierarchyOptions && navigation.roleHierarchyOptions.length
    ? navigation.roleHierarchyOptions
    : [];

export const selectRoleDefault = ({ navigation }) => navigation.defaultRole;
export const selectAssetDefault = ({ navigation }) => navigation.defaultAsset;

export const selectRoutes = ({ navigation }) =>
  !!navigation.routeHierarchy && navigation.routeHierarchy.length
    ? [allRoutes, ...mapRoutes(navigation.routeHierarchy)]
    : [allRoutes];
export const selectRoutesDefault = ({ navigation }) =>
  !!navigation.routeHierarchyDefault && navigation.routeHierarchyDefault.length
    ? [allRoutes, ...mapRoutes(navigation.routeHierarchyDefault)]
    : [allRoutes];

export const selectIsAssetOrRoute = ({ navigation }) => navigation.assetOrRoute;

export const selectCurrentAsset = ({ navigation }) => navigation.assetIdUser;

export const selectCurrentAssetException = ({ navigation }) =>
  navigation.assetLpoException.name;
export const selectCurrentAssetIdException = ({ navigation }) =>
  navigation.assetLpoException.assetId;

export const selectCurrentRoute = ({ navigation }) =>
  navigation.productionRoute.routeId;
export const selectCurrentRouteLPO = ({ navigation }) =>
  navigation.productionLPORoute.routeId;
export const selectCurrentRouteNameLPO = ({ navigation }) =>
  navigation.productionLPORoute.name;

export const selectSelectedAssetsProdManagement = ({ navigation }) =>
  navigation.selectedAssetsProdManagement;

export const selectCurrentAssetName = ({ navigation }) =>
  navigation.assetNameUser;

export const selectCurrentRouteName = ({ navigation }) =>
  navigation.productionRoute.name;

export const mapAssets = assets =>
  assets
    .sort((a, b) => a.assetId - b.assetId)
    .map(asset => ({
      name: asset.name,
      value: `${asset.assetId}`,
    }));

export const mapRoutes = routes =>
  routes
    .sort((a, b) => a.routeId - b.routeId)
    .map(route => ({
      name: route.name,
      value: `${route.routeId}`,
    }));

export const selectAssetControlOptions = ({ navigation }) =>
!!navigation.assetOptions && navigation.assetOptions.length
    ? navigation.assetOptions.map(option => {
    return {
      label: option.name,
      value: option.assetId,
    };
  }):[];

  export const selectRoleControlOptions = ({ navigation }) =>
  !!navigation.roleOptions && navigation.roleOptions.length
      ? navigation.roleOptions.map(option => {
      return {
        label: option.supervisorTitle,
        value: option.supervisorRoleId,
      };
    }):[];

 