import React from 'react';
import styles from './styles.module.css';
import PropTypes from 'prop-types';
import ControlItemCard from '../ControlItemCard';
import { connect } from 'react-redux';
import { selectPillarCount } from 'store/reducers/controls';

const mapStateToProps = state => ({ getPillarCount: selectPillarCount(state) }) ;

export const ControlPillar = ({ pillarTitle, controlItems, height, getPillarCount }) => (
  <div className={styles.container}>
    <div className={styles.title}>
      {pillarTitle} ({getPillarCount(pillarTitle)})
    </div>
    <div style={{ minHeight: height }} className={styles.columnWrapper}>
      {controlItems.map((controlItem, i) => (
          <ControlItemCard
            key={i}
            pillarTitle={pillarTitle}
            controlItem={controlItem}
            last={i === controlItems.length - 1}
          />
        )
      )}
    </div>
  </div>
);

ControlPillar.propTypes = {
  pillarTitle: PropTypes.string,
  tasks: PropTypes.array,
  height: PropTypes.number,
  getPillarCount: PropTypes.func
};

export default connect(mapStateToProps)(ControlPillar);
