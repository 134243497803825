import React, { Component } from 'react';
import { requestStatuses } from 'constants/values';
import styles from './styles.module.css';
import LoadingPage from 'components/common/LoadingPage';
import { ModalRadioInput } from 'components/common/ModalFormItems/RadioInput';
import { connect } from 'react-redux';
import {
  getSummary,
  getSummaryChart,
  getSummaryTable,
  selectCurrentSummaryData,
  selectProductionSummaryRequestStatus,
  selectShouldLoadData,
  selectHierarchy,
  setHeirarchy,
  selectIsAllocatedProduction,
  setIsAllocatedProduction
} from 'store/reducers/production';
import {
  selectCurrentAsset,
  selectCurrentAssetName,
  selectCurrentRouteName,
  setAsset,
  setRoute,
  selectCurrentRoute,
} from 'store/reducers/navigation';
import { selectIsSupretendent, selectUserInfo, getUserInfo } from 'store/reducers/users';
import RouteProdDiff from './RouteProdDiff';
import ProdDetails from './ProdDetails';
import ProdChart from './ProdChart';
import ProdDaySelect from './ProdDaySelect';
import TargetToggle from './TargetToggle';
import SummaryCardTable from './SummaryCardTable';
import ProdSummaryExportCSV from './ProdSummaryExportCSV';
import Popover from 'components/common/Popover';
import InfoIcon from 'assets/img/info.svg';
import AssetCheck from 'utils/assetCheck';
import classnames from 'classnames';
import { productionManagementTimestampToString } from 'utils/timestamps';
import {
  getLabelValuePatternForAsset,
  getAssetName,
  getAssetForDropDown,
  getIsAsset,
  isHierarchyLevelEmpty,
  isHierarchyLevelWeLL
} from 'utils/production';
import { allocatedOptions, hierarchyLevelConstants } from 'constants/productionManagement';

const mapStateToProps = state => ({
  assetName: selectCurrentAssetName(state),
  routeName: selectCurrentRouteName(state),
  currentProductionSummary: selectCurrentSummaryData(state),
  productionSummaryRequestStatus: selectProductionSummaryRequestStatus(state),
  selectedProductionAsset: selectCurrentAsset(state),
  shouldLoadData: selectShouldLoadData(state),
  isSuperItendent: selectIsSupretendent(state),
  selectedProductionRoute: selectCurrentRoute(state),
  hierarchylevel: selectHierarchy(state),
  userInfo: selectUserInfo(state),
  isAllocatedProduction: selectIsAllocatedProduction(state),
});
const mapDispatchToProps = {
  getSummary,
  setHeirarchy,
  getSummaryChart,
  getSummaryTable,
  setAsset,
  setRoute,
  getUserInfo,
  setIsAllocatedProduction
};

export class ProductionSummary extends Component {
  state = {
    loaded: false,
  };

  componentDidMount = () => {
    if (this.props.shouldLoadData) {
      this.props.getSummary();
      this.setState({ loaded: true });
    }
  };

  componentDidUpdate = () => {

    if (this.props.shouldLoadData && !this.state.loaded) {
      this.props.getSummary();
      this.setState({ loaded: true });
    }
  };

  render() {
    const {
      currentProductionSummary,
      productionSummaryRequestStatus,
      assetName,
      isSuperItendent,
      routeName,
      selectedProductionRoute,
      hierarchylevel,
      userInfo,
      setIsAllocatedProduction,
      isAllocatedProduction
    } = this.props;
    const timestampText = productionManagementTimestampToString(
      currentProductionSummary.lastUpdated
    );
    const isAsset = AssetCheck(selectedProductionRoute);
    const assetNameMulti = getAssetName(assetName);
    const assetSetRoute = getAssetForDropDown(currentProductionSummary);
    const userAssetLink = getLabelValuePatternForAsset(userInfo.userAssets);
    const isAllocatedProductionObj = {
      value: isAllocatedProduction,
      key: 'isAllocatedProduction',
    };

    const radioAllocatedProductionDiv = () => {
      return (
        <div className={classnames(styles.modalInputContainer, 'col')}>
          <ModalRadioInput
            setAction={(event, value) => {
              if (
                productionSummaryRequestStatus === requestStatuses.success
              ) {
                setIsAllocatedProduction(value);
                this.props.getSummary();
                this.props.getSummaryTable();
                this.props.getSummaryChart();
              }
            }}
            disabled={false}
            label={'Production Type: '}
            resetAction={() => { return; }}
            options={allocatedOptions}
            fullWidth={true}
            justifyContent={'noSpace'}
            state={isAllocatedProductionObj}
            stateKey={'isAllocatedProduction'}
          />
        </div>
      )
    }
    return (
      <div className={styles.layout}>
        <div className={styles.titleRow}>

          {getIsAsset(currentProductionSummary, isAsset) ? (
            isHierarchyLevelEmpty(hierarchylevel) ? (
              <div className={classnames(styles.titleAsset, 'row')}>
                <div className={classnames(styles.alignStart, 'col')}>
                  <span className={styles.name}>{assetNameMulti}</span>
                  <Popover text={timestampText}>
                    <img
                      src={InfoIcon}
                      className={styles.infoIcon}
                      alt={timestampText}
                    />
                  </Popover>
                </div>
                {radioAllocatedProductionDiv()}
                <div className={classnames(styles.alignEnd, 'col')}>
                  <TargetToggle isSuperItendent={isSuperItendent} />
                  <ProdDaySelect isSuperItendent={isSuperItendent} />
                  <ProdSummaryExportCSV isSuperItendent={isSuperItendent} />
                </div>
              </div>
            ) : (
              <div>
                <div
                  className={styles.titleRowLink}
                  onClick={() => {
                    this.props.setAsset(userAssetLink);
                    this.props.setHeirarchy('');
                    this.props.getSummary();
                    this.props.getSummaryChart();
                    this.props.getSummaryTable();
                  }}
                >
                  {productionSummaryRequestStatus === requestStatuses.success ? 'Back to your Assets' : ''}
                </div>
                <div className={classnames(styles.titleRoute, 'row')}>
                  <div className={classnames(styles.alignStart, 'col')}>
                    <span className={styles.name}>{assetNameMulti}</span>
                    <Popover text={timestampText}>
                      <img
                        src={InfoIcon}
                        className={styles.infoIcon}
                        alt={timestampText}
                      />
                    </Popover>
                  </div>
                  {radioAllocatedProductionDiv()}
                  <div className={classnames(styles.alignEndRoute, 'col')}>
                    <TargetToggle />
                    <ProdDaySelect />
                    <ProdSummaryExportCSV />
                  </div>
                </div>
              </div>
            )
          ) : isHierarchyLevelWeLL(hierarchylevel) ? (
            <div>
              <div
                className={styles.titleRowLink}
                onClick={() => {
                  this.props.setHeirarchy(hierarchyLevelConstants.routeLevel);
                  this.props.setRoute(currentProductionSummary.route.routeId);
                  this.props.getSummary();
                  this.props.getSummaryChart();
                  this.props.getSummaryTable();
                }}
              >
                {productionSummaryRequestStatus === requestStatuses.success ? currentProductionSummary.route.name : ''}
              </div>
              <div className={classnames(styles.titleRoute, 'row')}>
                <div className={classnames(styles.alignStart, 'col')}>
                  <span className={styles.name}>
                    {currentProductionSummary.collectionPoint
                      ? currentProductionSummary.collectionPoint.name
                      : ''}
                  </span>
                  <Popover text={timestampText}>
                    <img
                      src={InfoIcon}
                      className={styles.infoIcon}
                      alt={timestampText}
                    />
                  </Popover>
                </div>
                {radioAllocatedProductionDiv()}
                <div className={classnames(styles.alignEndRoute, 'col')}>
                  <ProdDaySelect />
                  <ProdSummaryExportCSV />
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div
                className={styles.titleRowLink}
                onClick={() => {
                  if (!this.props.isSuperItendent) {
                    this.props.setHeirarchy('');
                  }
                  this.props.setAsset(assetSetRoute);
                  this.props.getSummary();
                  this.props.getSummaryChart();
                  this.props.getSummaryTable();
                }}
              >
                {productionSummaryRequestStatus === requestStatuses.success ? currentProductionSummary.asset[0].name : ''}
              </div>
              <div className={classnames(styles.titleRoute, 'row')}>
                <div className={classnames(styles.alignStart, 'col')}>
                  <span className={styles.name}>{routeName}</span>
                  <Popover text={timestampText}>
                    <img
                      src={InfoIcon}
                      className={styles.infoIcon}
                      alt={timestampText}
                    />
                  </Popover>
                </div>
                {radioAllocatedProductionDiv()}
                <div className={classnames(styles.alignEndRoute, 'col')}>
                  <ProdDaySelect />
                  <ProdSummaryExportCSV />
                </div>
              </div>
            </div>
          )}
        </div>

        <div className={'row'}>
          <div className={'col-md-6 col-xl-3'}>
            {productionSummaryRequestStatus === requestStatuses.success ? (
              <RouteProdDiff isSuperItendent={isSuperItendent} />
            ) : (
              <LoadingPage
                containerStyles={styles.smallBoxLoadingPage}
                retryRequest={() => this.props.getSummary()}
                requestStatus={productionSummaryRequestStatus}
              />
            )}
          </div>

          <div className={'col-md-6 col-xl-3'}>
            {productionSummaryRequestStatus === requestStatuses.success ? (
              <ProdDetails isSuperItendent={isSuperItendent} />
            ) : (
              <LoadingPage
                containerStyles={styles.smallBoxLoadingPage}
                retryRequest={() => this.props.getSummary()}
                requestStatus={productionSummaryRequestStatus}
              />
            )}
          </div>

          <div className={'col-md-12 col-xl-6'}>
            <ProdChart isSuperItendent={isSuperItendent} />
          </div>
        </div>

        <SummaryCardTable isSuperItendent={isSuperItendent} />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductionSummary);
