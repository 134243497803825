import types from 'constants/actionTypes';

export const initialState = {
  powerbiAccessToken: null
};

const metrics = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.SET_POWERBI_ACCESS_TOKEN:
      return {
        ...state,
        powerbiAccessToken: payload
      };
    default:
      return state;
  }
};

export default metrics;
export * from './actions';
export * from './selectors';
