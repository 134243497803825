import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from 'components/AdminPage/styles.module.css';
import ChevronDown from 'assets/img/chevron-down.svg';
import Select, { components } from 'react-select';

const customStyles = {
  multiValue: provided => {
    return {
      ...provided,
      backgroundColor: 'rgba(0, 102, 178, 0.2)',
    };
  },
  multiValueLabel: provided => {
    return {
      ...provided,
      color: 'var(--blue-deepSea)',
      fontFamily: 'Gotham',
      fontSize: 16,
      fill: 'var(--blue-deepSea)',
    };
  },
  valueContainer: provided => {
    return {
      ...provided,
      color: 'var(--blue-deepSea)',
    };
  },
  option: provided => {
    return {
      ...provided,
      borderBottom: '1px solid var(--grey-metal)',
      color: 'var(--grey-charcoal)',
      fontFamily: 'Gotham',
      fontSize: 16,
      padding: 14,
    };
  },
  indicatorSeparator: () => {
    return {
      display: 'none',
    };
  },
  clearIndicator: () => {
    return {
      display: 'none',
    };
  },
  dropdownIndicator: provided => {
    return {
      ...provided,
      color: 'var(--blue-deepSea)',
      cursor: 'pointer',
      fill: 'var(--blue-deepSea)',
      marginRight: 2,
      stroke: 'var(--blue-deepSea)',
      padding: 0,
    };
  },
  control: provided => {
    return {
      ...provided,
      border: '1px solid var(--grey-metal)',
      borderColor: 'var(--grey-metal)',
      boxShadow: 'none',

      '&:hover': {
        borderColor: 'var(--grey-metal)',
        boxShadow: 'none',
      },
    };
  },
  menu: provided => {
    return {
      ...provided,
      fontFamily: 'Gotham',
      zIndex: 4,
    };
  },
};

const determineBasis = (fullWidth, oneThird) => {
  if (fullWidth) {
    return styles.fullBasis;
  }

  if (oneThird) {
    return styles.oneThirdBasis;
  }

  return styles.basis;
};

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={ChevronDown} alt={props.label} />
    </components.DropdownIndicator>
  );
};

const mapOptions = (props, options) => {
  let mappedOptions = options;
  if (typeof options[0] !== 'object') {
    mappedOptions = options.map(option => ({
      label: option,
      value: option,
    }));

    if (props.showPlaceholder) {
      mappedOptions.unshift({ label: 'Select Option', value: '' });
    }
  } else {
    if (
      props.showPlaceholder &&
      props.placeholderText &&
      mappedOptions[0].label !== props.placeholderText
    ) {
      mappedOptions.unshift({ label: props.placeholderText, value: '' });
    }
  }
  return mappedOptions;
};

const ReactSelectInput = props => {
  const {
    disabled,
    disableReset,
    options,
    resetAction,
    placeholderText,
    dropdownPlacement,
    setAction,
    state,
    label,
    stateKey,
    oneThird,
    fullWidth,
  } = props;

  // Equivalent of ComponentDidUnMount
  useEffect(() => {
    return () => {
      if (!disableReset) resetAction();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className={determineBasis(fullWidth, oneThird)}>
      <label className={!disabled ? styles.label : styles.disabledLabel}>
        {label}
        {!state.valid && (
          <span className={styles.error}>{state.errorText}</span>
        )}
      </label>
      <Select
        className={styles.selectBox}
        styles={customStyles}
        isDisabled={disabled}
        onChange={selected => setAction(stateKey, selected)}
        value={state.value}
        aria-label={label || 'Multi Select'}
        options={mapOptions(props, options)}
        isMulti={true}
        resetAction={resetAction}
        placeholder={placeholderText}
        closeMenuOnSelect={false}
        components={{ DropdownIndicator }}
        blurInputOnSelect={false}
        autoFocus={false}
        isSearchable={false}
        maxMenuHeight={250}
        menuPlacement={dropdownPlacement}
      />
    </div>
  );
};

ReactSelectInput.propTypes = {
  disabled: PropTypes.bool,
  showPlaceholder: PropTypes.bool,
  placeholderText: PropTypes.string,
  dropdownPlacement: PropTypes.string,
  options: PropTypes.array.isRequired,
  resetAction: PropTypes.func.isRequired,
  setAction: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
  label: PropTypes.string,
  stateKey: PropTypes.string,
  state: PropTypes.object,
  oneThird: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

export default ReactSelectInput;
