import React, { Component } from 'react';
import styles from './styles.module.css';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { getTargetHistory, selectSummaryTableData } from 'store/reducers/production';
import { targetHistoryColumns } from 'constants/targetHistoryColumns';
import { requestStatuses } from 'constants/values';
import LoadingPage from 'components/common/LoadingPage';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { appInsights } from 'App';
import NoContent from 'components/common/NoContent';
import classnames from 'classnames';

const mapDispatchToProps = {
  getTargetHistory
};

const mapStateToProps = state => ({
  tableData: selectSummaryTableData(state)
});

export class TargetHistoryTable extends Component {
  render() {
    const {  tableData } = this.props;

    try {
      return (
        <div className={classnames(styles.reactTable, 'historyTable')}>
        <ReactTable
          columns={targetHistoryColumns}
          data={tableData}
          defaultPageSize={9999}
          minRows={0}
          showPagination={false}
          resizable={false}
          className={'historyTable'}
          defaultSorted={[{ id: 'date', desc: true }]}
          NoDataComponent={() => (
            <NoContent
              containerStyle={styles.noData}
              type={'data available.'}
            />
          )}
        />
        </div>
      );
    } catch (err) {
      appInsights.trackException({
        error: new Error(err),
        severityLevel: SeverityLevel.Error
      });

      return (
        <LoadingPage
          containerStyles={styles.loading}
          requestStatus={requestStatuses.failure}
          retryRequest={this.props.getTargetHistory}
        />
      );
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TargetHistoryTable);
