import React from 'react';
import Popover from 'components/common/Popover';
import { formatNumber } from 'utils/formatNumber';
import moment from 'moment';
import { sortDate } from 'utils/scheduledDate';
// This file contains the React-Table Columns configuration for the LPO Events Table that's on the Production Management page.

// Open Events and Third Party Events share the same columns in the LPO Table.
/* setLpoEvent is the redux action to set that event as the "currentLpoEvent"
  which is used for the LPO Event Details Modal to display the data from that row.*/

export const openAndThirdPartyEventsColumns = setLpoEvent => [
  {
    Header: 'Well Name',
    accessor: 'wellName',
    minWidth: 150,
    Cell: props => (
      <span
        style={{ display: 'flex' }}
        className={'clickableRoute'}
        onClick={() => {
          setLpoEvent(props.original);
        }}
      >
        <Popover text={props.original.wellName} overflow={true}>
          {props.original.wellName}
        </Popover>
      </span>
    ),
    headerClassName: 'columnHeader',
  },
  {
    Header: 'Initial Down Date',
    accessor: 'initialDownDate',
    minWidth: 100,
    Cell: props => (
      <span>{moment(props.original.initialDownDate).format('M/D/YYYY')}</span>
    ),
    headerClassName: 'centeredColumnHeader',
  },
  {
    Header: 'Days In Event',
    accessor: 'daysInEvent',
    minWidth: 100,
    Cell: props => <span>{parseInt(props.original.daysInEvent)}</span>,
    headerClassName: 'centeredColumnHeader',
  },
  {
    Header: 'Scheduled Date',
    accessor: 'scheduleStartDate',
    minWidth: 100,
    headerClassName: 'centeredColumnHeader',
    sortable: true,
    filterable: false,
    Cell: props => {
      return <span>{(props.original.scheduleStartDate === '01/01/0001' || props.original.scheduleStartDate === 'N/A') ? 'N/A' : props.original.scheduleStartDate === 'Multiple' ? 'Multiple' : moment(props.original.scheduleStartDate).format('M/D/YYYY')}</span>
    },
    sortMethod: (a, b) => sortDate(a, b),
  },
  {
    Header: 'Loss Rate (BOED)',
    accessor: 'lossRate',
    minWidth: 100,
    Cell: props => <span>{formatNumber(props.original.lossRate)}</span>,
    headerClassName: 'centeredColumnHeader',
  },
  {
    Header: 'Event Loss',
    accessor: 'eventLoss',
    minWidth: 100,
    Cell: props => <span>{formatNumber(props.original.eventLoss)}</span>,
    headerClassName: 'centeredColumnHeader',
  },
  {
    Header: 'LPO Type',
    accessor: 'lpoType',
    minWidth: 100,
    Cell: props => <span>{props.original.lpoType}</span>,
    headerClassName: 'centeredColumnHeader',
  },
  {
    Header: 'Reason Code',
    accessor: 'reasonCode',
    minWidth: 100,
    Cell: props => (
      <span>
        <Popover text={props.original.reasonForCriticality} overflow={true}>
          {props.original.reasonCode}
        </Popover>
      </span>
    ),
    headerClassName: 'centeredColumnHeader',
  },
  {
    Header: 'Dependency',
    accessor: 'dependency',
    minWidth: 120,
    headerClassName: 'centeredColumnHeader',
    filterable: false,
    sortable: true,
  },
];

/* setLpoEvent is the redux action to set that event as the "currentLpoEvent"
  which is used for the LPO Event Details Modal to display the data from that row.*/
export const ShutInWellsColumns = setLpoEvent => [
  {
    Header: 'Well Name',
    accessor: 'wellName',
    minWidth: 150,
    Cell: props => (
      <span
        style={{ display: 'flex' }}
        className={'clickableRoute'}
        onClick={() => {
          setLpoEvent(props.original);
        }}
      >
        <Popover text={props.original.wellName} overflow={true}>
          {props.original.wellName}
        </Popover>
      </span>
    ),
    headerClassName: 'columnHeader',
  },
  {
    Header: 'Date of Shut-In',
    accessor: 'dateOfShutIn',
    minWidth: 120,
    Cell: props => (
      <span>{moment(props.original.dateOfShutIn).format('M/D/YYYY')}</span>
    ),
    headerClassName: 'centeredColumnHeader',
  },
  {
    Header: 'Days In Shut-In',
    accessor: 'daysInShutIn',
    minWidth: 100,
    Cell: props => <span>{parseInt(props.original.daysInShutIn)}</span>,
    headerClassName: 'centeredColumnHeader',
  },
  {
    Header: 'LAWT Rate (BOED)',
    accessor: 'lawtRate',
    minWidth: 120,
    Cell: props => <span>{formatNumber(props.original.lawtRate)}</span>,
    headerClassName: 'centeredColumnHeader',
  },
  {
    Header: 'Future Utility',
    accessor: 'futureUtility',
    minWidth: 100,
    Cell: props => {
      if (props.original.futureUtility === null) {
        return <span>NULL</span>;
      }
      return <span>{props.original.futureUtility}</span>;
    },
    headerClassName: 'centeredColumnHeader',
    sortable: true,
  },
  {
    Header: 'Dependency',
    accessor: 'dependency',
    minWidth: 120,
    headerClassName: 'centeredColumnHeader',
    sortable: true,
    filterable: false,
  },
  {
    Header: 'Reason Code',
    accessor: 'reasonCode',
    minWidth: 100,
    Cell: props => (
      <span>
        <Popover text={props.original.reasonForCriticality}>
          {props.original.reasonCode}
        </Popover>
      </span>
    ),
    headerClassName: 'centeredColumnHeader',
  },
];
