import { prodSummaryCardTitles } from 'constants/productionManagement';

export const getSummaryData = (
  isAsset,
  currentSummaryCard,
  dataType,
  summaryTableData,
  isSuperItendent,
  hierarchyLevel
) => {
  let summarytable = [];
  let tabledata = [];
  if(isSuperItendent && hierarchyLevel===''){
    summarytable = [...summaryTableData]
  }
  else{
    if (isAsset && currentSummaryCard !== prodSummaryCardTitles.targetHistory) {
      tabledata = summaryTableData.map(el => el.routeDashboardList);
      for (let i = 0; i < tabledata.length; i++) {
        for (let j = 0; j < tabledata[i].length; j++) {
          summarytable.push(tabledata[i][j]);
        }
      }
    } else {
      if (dataType) {
        tabledata = summaryTableData.map(el => el.routeDashboardList);
        for (let i = 0; i < tabledata.length; i++) {
          for (let j = 0; j < tabledata[i].length; j++) {
            summarytable.push(tabledata[i][j]);
          }
        }
      } else summarytable = [...summaryTableData];
    }
  }
  
  return summarytable;
};
