import React, { Component } from 'react';
import styles from './styles.module.css';
import PropTypes from 'prop-types';
import Button from 'components/common/Button';
import RequiredText from 'components/common/RequiredText';
import { targetFormInputs } from 'constants/productionManagement.js';
import classnames from 'classnames';
import { calculateBoed } from 'utils/calculateBoed';
import { formatNumber } from 'utils/formatNumber';
import { determineInvalidTarget } from 'utils/validation';
import { connect } from 'react-redux';
import SuccessModal from 'components/common/SuccessModal';
import { selectIsGuestUser, selectIsReadOnlyUser, selectUserInfo } from 'store/reducers/users';
import { selectSelectedAssetsProdManagement } from 'store/reducers/navigation';
import {
  setCustomTargetAsset,
  selectAssetTargetDetails,
  selectAssetTargetRequestStatus,
  setAssetTargetDetails,
  getAssetLevelTargets,
  selectAssetTargetPostStatus,
  resetAssetLevelPostTargetStatus
} from 'store/reducers/production';
import { getAssetListForTargetModal, getYearListForSelectedAsset } from 'utils/production';
import LoadingPage from 'components/common/LoadingPage';


const mapStateToProps = state => ({
  isGuestUser: selectIsGuestUser(state),
  isReadOnlyUser: selectIsReadOnlyUser(state),
  assetName: selectSelectedAssetsProdManagement(state),
  assetTargetRequestStatus: selectAssetTargetRequestStatus(state),
  assetTargetDetails: selectAssetTargetDetails(state),
  assetTargetPostStatus: selectAssetTargetPostStatus(state),
  userInfo: selectUserInfo(state)
});
const mapDispatchToProps = {
  setCustomTargetAsset,
  setAssetTargetDetails,
  getAssetLevelTargets,
  resetAssetLevelPostTargetStatus
};
const modalSuccessTitle = 'Successfully saved!';

export class CustomAssetTargetForm extends Component {
  constructor(props) {
    super(props);

    const {
      oilTarget,
      gasTarget,
      waterTarget,
      isCustomTarget,
    } = props.targets[0];
    this.state = {
      _targets: {
        oilTarget: oilTarget || 0,
        waterTarget: waterTarget || 0,
        gasTarget: gasTarget || 0,
        isCustomTarget,
      },
      _assetTargetDetails: [...this.props.assetTargetDetails],
      selectedAsset: this.props.assetTargetDetails[0].assetId || '',
      assetYearDeFault: this.props.assetTargetDetails[0].yearlyDto[0].year,
      showRequired: false,
      gasTargetInvalid: false,
      oilTargetInvalid: false,
      waterTargetInvalid: false,
    };

  }
  splitKeyTarget(keyTarget) {
    return keyTarget.split('T')[0];
  }



  handleChange(el, event, key) {
    const { value } = event.target;
    let tempFullYrTarget = this.state._assetTargetDetails
    let showRequired = false;
    el[key] = +value;
    const gasTargetInvalid = determineInvalidTarget(el['gasTarget'], 'gas');
    const oilTargetInvalid = determineInvalidTarget(el['oilTarget'], 'oil');
    const waterTargetInvalid = determineInvalidTarget(
      el['waterTarget'],
      'water'
    );
    showRequired = gasTargetInvalid || oilTargetInvalid || waterTargetInvalid;
    let selectedYear = this.state.assetYearDeFault;
    let selectedAsset = this.state.selectedAsset;
    let selectedAssetIndex = tempFullYrTarget.findIndex(k => k.assetId === selectedAsset);
    let selectedYearIndex = tempFullYrTarget[selectedAssetIndex].yearlyDto.findIndex(j => j.year === selectedYear);
    const selectedMonthIndex = tempFullYrTarget[selectedAssetIndex].yearlyDto[selectedYearIndex].yearTargets.findIndex(i => i.monthName === el.monthName);
    tempFullYrTarget[selectedAssetIndex].yearlyDto[selectedYearIndex].yearTargets[selectedMonthIndex] = { ...el };
    this.setState({
      _assetTargetDetails: tempFullYrTarget,
      showRequired,
      gasTargetInvalid,
      oilTargetInvalid,
      waterTargetInvalid,
    });
  }
  handleAssetChange = event => {
    let temp = event.target.value;
    this.setState({
      selectedAsset: temp,
    });
  };
  handleYearChange = event => {
    let tempYr = event.target.value
    this.setState({
      assetYearDeFault: tempYr
    });

  }


  updateTargets(values) {
    let updated = this.state._targets;
    let targetAssetName = this.state.selectedAsset;
    updated.isCustomTarget = true;
    let assetTarget = this.state._assetTargetDetails
    this.setState({ _targets: updated, showRequired: false });
    this.props.setCustomTargetAsset(targetAssetName);
    this.props.setAssetTargetDetails(assetTarget);
    this.props.submitTargets(updated);
  }

  render() {
    const { isGuestUser, userInfo, closeModal, isReadOnlyUser, assetTargetPostStatus, assetTargetDetails, assetTargetRequestStatus, resetAssetLevelPostTargetStatus } = this.props;
    const { showRequired } = this.state;
    const yearOptions = assetTargetDetails.length > 0 ? getYearListForSelectedAsset(assetTargetDetails, this.state.selectedAsset) : [];
    const selectedYearTargets = yearOptions.filter(yeartargets => {
      return yeartargets.year === this.state.assetYearDeFault
    });
    const isProdEngineer = userInfo.roleTitle === 'Production Engineer';
    return (
      assetTargetRequestStatus === 'SUCCESS' ?
        (<div className={styles.formWrapper}>
          <div className={styles.title}>
            <span>Target Adjustment Details:</span>
            <span className={styles.selectYearFeild}>
              <select
                onChange={this.handleYearChange}
                value={this.state.assetYearDeFault}
                className={styles.selectAssetYearDropdown}
              >
                {yearOptions.map(year => {
                  return <option key={year.year}>{year.year}</option>
                })}
              </select>
            </span>
            <span className={styles.selectAssetFeild}>
              <select
                onChange={this.handleAssetChange}
                value={this.state.selectedAsset}
                className={styles.selectAssetDropdown}
              >
                {getAssetListForTargetModal(this.props.assetName)}
              </select>
            </span>
          </div>
          <div className={styles.formBox}>
            <div className={styles.formRow}>
              <div className={styles.label}>Targets</div>
              {selectedYearTargets[0].yearTargets.map(el => {
                return (<span key={el.monthName} className={styles.value}>{el.monthName}</span>)
              })}
            </div>

            {targetFormInputs.map(i => {
              return (
                <div key={i.key} className={styles.formRow}>
                  <div className={styles.label}>{i.label}</div>
                  {selectedYearTargets[0].yearTargets.map(el => {
                    return (
                      <input
                        className={classnames({
                          [styles.required]: this.state[`${i.key}Invalid`],
                          [styles.inputtype]: [styles.inputtype],
                          [styles.value]: [styles.value],
                          [styles.customWidth]: [styles.customWidth]
                        })}
                        key={el.monthName}
                        value={el[i.key]}
                        onChange={event => this.handleChange(el, event, i.key)}
                        type="all"
                        min="0"
                        disabled={isGuestUser || (isReadOnlyUser && !isProdEngineer)}
                      />
                    )
                  })}
                </div>
              );
            })}
            <div className={styles.formRow}>
              <div className={styles.label}>Daily Target BOED</div>
              {selectedYearTargets[0].yearTargets.map(el => {
                return (<div className={styles.value} key={el.monthName}>
                  {formatNumber(
                    calculateBoed(
                      el.oilTarget,
                      el.gasTarget
                    )
                  )}
                </div>)
              })}
            </div>
          </div>
          <div className={styles.buttonRow}>
            <div className={styles.left}>
              {!isGuestUser
                ? !isReadOnlyUser ? (
                  <Button
                    text={'save'}
                    type={'thresholdSave'}
                    click={() => this.updateTargets(this.state._targets)}
                    disabled={showRequired}
                  />
                ) : isProdEngineer ? (
                  <Button
                    text={'save'}
                    type={'thresholdSave'}
                    click={() => this.updateTargets(this.state._targets)}
                    disabled={showRequired}
                  />
                ) : null
                : null}
              <Button text={'cancel'} type={'cancelText'} click={closeModal} />
            </div>
          </div>
          <div className={styles.errorMessage}>
            <RequiredText
              showRequiredText={showRequired}
              text={'please complete all fields before saving'}
            />
          </div>

          {assetTargetPostStatus === 'SUCCESS' && (
            <SuccessModal
              title={modalSuccessTitle}
              toggleModal={resetAssetLevelPostTargetStatus}
            />
          )}
        </div>
        ) : (
          <LoadingPage
            requestStatus={assetTargetRequestStatus}
            retryRequest={
              this.props.getAssetLevelTargets
            }
          />
        )
    );
  }
}

CustomAssetTargetForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  submitTargets: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomAssetTargetForm);
