import React, { Component } from 'react';
import styles from './styles.module.css';
import ChevronLogo from 'assets/img/Chevron Bar.svg';
import { getUserInfo, selectUserInfo } from 'store/reducers/users';
import { getAssetHierarchy } from 'store/reducers/navigation';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  userInfo: selectUserInfo(state)
});

const mapDispatchToProps = {
  getUserInfo,
  getAssetHierarchy
};

// UserInfo renders the current user's information into the Sidebar.
// It also makes the API call for getting the user's info from the API.
export class UserInfo extends Component {
  componentDidMount() {
    this.props.getAssetHierarchy().then(() => this.props.getUserInfo())

  }

  render() {
    return (
      <div className={styles.userInfo}>
        <img src={ChevronLogo} alt="Chevron Logo" className={styles.userIcon} />
        <p className={styles.firstLastName}>
          {this.props.userInfo.firstName} {this.props.userInfo.lastName}
        </p>
        <p className={styles.secondaryUserInfo}>
          {this.props.userInfo.roleTitle}
          <span className={styles.userArea}>
            {this.props.userInfo.assetName}
          </span>
        </p>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);
