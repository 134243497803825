// Function used in editing Asset or Route Targets
export const isValid = (value, isPositiveNumber) => {
  if (isPositiveNumber && value < 0) {
    return false;
  }
  if (value && value.length > 0) {
    return true;
  }
  return false;
};

// Function used in editing Asset Targets
export const determineInvalidTarget = (value, target) => {
  let invalid = false;
  if (value < 0) {
    invalid = true;
  }

  if (
    (value === null || value === '') &&
    (target === 'oil' || target === 'gas')
  ) {
    invalid = true;
  }

  return invalid;
};

// Function used in editing Exception Threshold
export const determineInvalidThresholdDays = value => {
  let invalid = false;
  if (value === null || value === '' || value <= 0) {
    invalid = true;
  }
  return invalid;
};