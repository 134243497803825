import React, { Component } from 'react';
import styles from './styles.module.css';
import { connect } from 'react-redux';
import {
  selectLpoTableSelectedFilters,
  toggleLpoTableFilterModal,
  updateFilteredLpoTable,
  selectLpoReasonCodes,
} from 'store/reducers/production';
import CheckBoxInput from 'components/common/CheckBoxInput';
import { connections, lpoTypes } from 'constants/productionManagement';
import MultiSelectLpo from 'components/ProductionManagementPage/ProductionLpoEvents/LpoTableFiltersModal/MultiSelectLpo';
import mapOptions from 'utils/mapOptions';
import { getReasonCodes } from 'utils/production'

const mapStateToProps = state => ({
  currentFilters: selectLpoTableSelectedFilters(state),
  reasonCodes: selectLpoReasonCodes(state),
});

const mapDispatchToProps = {
  toggleLpoTableFilterModal,
  updateFilteredLpoTable,
};

export class LpoTableFiltersModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.currentFilters,
      reasonCodes: getReasonCodes(this.props.reasonCodes)
    };
  }

  applyFilters = () => {
    const { toggleLpoTableFilterModal, updateFilteredLpoTable } = this.props;
    updateFilteredLpoTable(this.state);
    toggleLpoTableFilterModal();
  };

  toggleAll = () => {
    if (!this.state.all) {
      this.setState({
        lpoTypes: mapOptions(lpoTypes),
        connections: mapOptions(connections),
        reasonCodes: mapOptions(this.state.reasonCodes),
        all: true,
      });
    } else {
      this.setState({
        lpoTypes: [],
        connections: [],
        reasonCodes: [],
        all: false,
      });
    }
  };

  render() {
    const { toggleLpoTableFilterModal, currentCard } = this.props;
    return (
      <div className={'container'}>
        <div className={styles.showAll}>
          <CheckBoxInput
            text={'Show All Codes'}
            checked={this.state.all}
            toggle={this.toggleAll}
            id={'showAllCheckbox'}
          />
        </div>

        <div className={styles.lineRow}>
          <span className={styles.filterByText}>or filter by</span>
          <span className={styles.line} />
        </div>

        <MultiSelectLpo
          label={'Dependencies:'}
          resetAction={() => {return;}}
          setAction={selectedConnections => {
            this.setState({
              connections: selectedConnections,
              all: false,
            });
          }}
          options={connections}
          placeholderText={'Select Dependencies...'}
          fullWidth={true}
          value={this.state.connections}
        />

        {currentCard !== 'Shut-in Wells' && (
          <MultiSelectLpo
            label={'LPO Types:'}
            resetAction={() => {return;}}
            setAction={selectedLpoTypes => {
              this.setState({
                lpoTypes: selectedLpoTypes,
                all: false,
              });
            }}
            options={lpoTypes}
            placeholderText={'Select LPO Types...'}
            fullWidth={true}
            value={this.state.lpoTypes}
          />
        )}

        <MultiSelectLpo
          label={'Reason Codes:'}
          resetAction={() => {return;}}
          setAction={selectedReasonCodes => {
            this.setState({
              reasonCodes: selectedReasonCodes,
              all: false,
            });
          }}
          options={this.props.reasonCodes}
          placeholderText={'Select Reason Codes...'}
          fullWidth={true}
          value={this.state.reasonCodes}
        />

        <div className={styles.buttonArea}>
          <button
            id={'applyLpoFilters'}
            className={styles.applyBtn}
            onClick={this.applyFilters}
          >
            apply
          </button>
          <button
            id={'cancelLpoFilters'}
            className={styles.cancelBtn}
            onClick={toggleLpoTableFilterModal}
          >
            cancel
          </button>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LpoTableFiltersModal);
