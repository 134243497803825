import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const DetailCardTitle = ({ title }) => (
  <div className={styles.title}>
    <div className={styles.text}>{title}:</div>
  </div>
);

DetailCardTitle.propTypes = {
  title: PropTypes.string.isRequired
};

export default DetailCardTitle;
