import React, { Component } from 'react';
import { formatNumber } from 'utils/formatNumber';
import styles from './styles.module.css';
import { connect } from 'react-redux';
import { selectTableTargetEditing, selectTableTargets } from 'store/reducers/production';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { determineInvalidTarget } from 'utils/validation';

const mapStateToProps = state => ({
  editing: selectTableTargetEditing(state),
  tableTargets: selectTableTargets(state)
});

export class EditableTargetCell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.tableTargets[props.route.routeId]
        ? props.tableTargets[props.route.routeId][`${props.target}Target`]
        : props.route[props.target].target
    };
  }

  render() {
    const {
      editing,
      route,
      updateTableTarget,
      target,
      tableTargets
    } = this.props;

    if (editing) {
      let invalid = determineInvalidTarget(this.state.value, target);

      return (
        <input
          className={classnames(styles.inputBox, {
            [styles.redBorder]: invalid
          })}
          type="number"
          min="0"
          onChange={event => {
            const { value } = event.target;

            updateTableTarget({
              routeId: route.routeId,
              ...tableTargets[route.routeId],
              [`${target}Target`]: value === '' ? '' : parseInt(value, 10),
              invalid: determineInvalidTarget(value, target)
            });
            this.setState({ value });
          }}
          value={this.state.value}
        />
      );
    }

    return (
      <span>
        {formatNumber(
          tableTargets[route.routeId]
            ? tableTargets[route.routeId][`${target}Target`]
            : route[target].target
        )}
      </span>
    );
  }
}

EditableTargetCell.propTypes = {
  editing: PropTypes.bool,
  route: PropTypes.object.isRequired,
  tableTargets: PropTypes.object,
  target: PropTypes.string.isRequired,
  updateTableTarget: PropTypes.func
};

export default connect(mapStateToProps)(EditableTargetCell);
