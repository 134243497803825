import moment from 'moment';

// Function to give us the timestamp for "Last Updated" in production management
export const productionManagementTimestampToString = timestamp => {
  if (timestamp === '') {
    return 'Last updated:';
  }
  const formattedDate = moment(timestamp).format('MMMM D, YYYY');
  const formattedTime = moment(timestamp).format('h:mm A');

  return `Last updated: ${formattedDate} at ${formattedTime}`;
};


export const getLastCheckpoint = () => {
  const currentDate = moment().format('YYYY-MM-DD');
  const yesterdaysDate = moment().subtract(1, 'd').format('YYYY-MM-DD');
  const updateTime = '12:30:00Z';
  const todaysUpdate = moment(`${currentDate}T${updateTime}`).utc();
  const yesterdaysUpdate = moment(`${yesterdaysDate}T${updateTime}`).utc();
  const now = moment().subtract(1, 'd');
  return now < todaysUpdate ? yesterdaysUpdate.format() : todaysUpdate.format();
};
