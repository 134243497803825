import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from 'components/AdminPage/styles.module.css';
import {
  toggleAddControlModal,
  resetControlToolRequestStatus,
  selectControlItemRequestStatus,
  selectErrorMessage,
} from 'store/reducers/controls';
import { connect } from 'react-redux';
import spinner from 'assets/img/spinner.gif';
import BigSuccessModal from 'components/ControlsPage/BigSuccessModal';

const mapStateToProps = state => {
  return {
    addCustomControlRequestStatus: selectControlItemRequestStatus(state),
    errorMsg: selectErrorMessage(state),
  };
};

const mapDispatchToProps = {
  toggleAddControlModal,
  resetControlToolRequestStatus,
};

export const ButtonArea = props => {
  const {
    formItems,
    saveAction,
    saveAttempted,
    addCustomControlRequestStatus,
    errorMsg,
    resetControlToolRequestStatus
  } = props;

  useEffect(() => {
    resetControlToolRequestStatus();
  }, [resetControlToolRequestStatus]);

  // Equivalent to setting a React Component State
  const [saveAttemptedState, setSaveAttemptedState] = useState(false);

  const invalidFormItems = Object.values(formItems).filter(
    formItem => formItem.valid === false && formItem.required === true
  );

  let validForm = true;

  if (invalidFormItems && invalidFormItems.length > 0) {
    validForm = false;
  }
  
  return (
    <div className={styles.flexContainer}>
      <div className={styles.buttonsArea}>
        {addCustomControlRequestStatus === 'EMPTY' ||
        addCustomControlRequestStatus === 'FAILURE' ? (
          <button
            className={styles.saveBtn}
            onClick={() => {
              setSaveAttemptedState(true);
              saveAttempted(invalidFormItems);
              if (validForm) {
                saveAction();
              }
            }}
          >
            {' '}
            Add
          </button>
        ) : (
          addCustomControlRequestStatus === 'PENDING' && (
            <Fragment>
              <img
                src={spinner}
                alt="loading spinner"
                className={styles.spinner}
                height="32"
                width="32"
              />
            </Fragment>
          )
        )}

        {addCustomControlRequestStatus === 'SUCCESS' && (
          <BigSuccessModal
            title={'Successfully saved!'}
            message={' '}
            toggleModal={props.toggleAddControlModal}
          />
        )}

        {addCustomControlRequestStatus === 'EMPTY' ||
        addCustomControlRequestStatus === 'FAILURE' ? (
          <button className={styles.cancelBtn} onClick={props.toggleAddControlModal}>
            cancel
          </button>
        ) : (
          <button className={styles.disableBtn}>cancel</button>
        )}
        <div className={styles.formErrorSection}>
          <span className={styles.error}>
            {saveAttemptedState &&
              !validForm && addCustomControlRequestStatus !== 'FAILURE' ?
              'please complete all required fields before submitting' :'' }
            {addCustomControlRequestStatus === 'FAILURE' && errorMsg}
          </span>
        </div>
      </div>
    </div>
  );
};

ButtonArea.propTypes = {
  saveAction: PropTypes.func.isRequired,
  formItems: PropTypes.object.isRequired,
  saveAttempted: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ButtonArea);
