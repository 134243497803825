import React from 'react';
import { connect } from 'react-redux';
import styles from './styles.module.css';
import Filter from 'assets/img/filter.svg';
import { toggleLpoTableFilterModal } from 'store/reducers/production';

const mapDispatchToProps = {
  toggleLpoTableFilterModal
};

export const TableFilterModalButton = ({ toggleLpoTableFilterModal }) => (
  <div className={styles.launchFilters} onClick={toggleLpoTableFilterModal}>
    <img className={styles.launchIcon} src={Filter} alt={'Filter'} />
    <span className={styles.launchText}>table filters</span>
  </div>
);

export default connect(
  null,
  mapDispatchToProps
)(TableFilterModalButton);
