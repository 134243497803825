import types from 'constants/actionTypes';
import {
  productionEmpty,
  summaryTypes,
  requestStatuses,
} from 'constants/values';
import getCurrentAsset from 'utils/getCurrentAsset';

export const initialState = {
  showFeedbackModal: false,
  assetHierarchy: [],
  routeHierarchy: [],
  assetOptions: [],
  roleOptions: [],
  routeHierarchyDefault: [],
  assetOrRoute: summaryTypes.asset,
  productionAsset: productionEmpty.asset,
  assetLpoException: productionEmpty.asset,
  productionRoute: productionEmpty.route,
  productionLPORoute: productionEmpty.route,
  selectedAssetsProdManagement: [],
  assetIdUser: '',
  assetNameUser: '',
  productionAssetArr: [],
  showProductAssetFilterModal: false,
  roleHierarchyOptions: [],
  defaultRole: '',
  defaultAsset: '',
};

const navigation = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.TOGGLE_FEEDBACK_MODAL:
      return { ...state, showFeedbackModal: !state.showFeedbackModal };
    case types.TOGGLE_ASSET_FILTER_MODAL_PM:
      return {
        ...state,
        showProductAssetFilterModal: !state.showProductAssetFilterModal,
      };
    case types.GET_ROLE_HIERARCHY_REQUEST_SUCCESS:
      const fls = payload.map(d => {
        return {
          name: d.supervisorTitle,
          value: d.supervisorRoleId,
        };
      });
      return {
        ...state,
        roleOptions: payload,
        roleHierarchyOptions: fls,
        defaultRole: fls[0].value,
      };

    case types.GET_ROLE_HIERARCHY_REQUEST_FAILURE:
      return {
        ...state,
        roleHierarchyOptions: requestStatuses.failure,
      };
    case types.GET_ASSET_HIERARCHY_REQUEST_SUCCESS:
      // TODO: Remove this filter when Backend removes Unknown
      const filteredAssets = payload.filter(asset => asset.name !== 'Unknown');
      const currentAssetId =
        state.selectedAssetsProdManagement !== productionEmpty.asset.assetId
          ? state.productionAsset.assetId
          : payload[0].assetId;
      const getAssetHierarchyRequestRoutes = getCurrentAsset(
        filteredAssets,
        currentAssetId
      );
      return {
        ...state,
        assetHierarchy: filteredAssets,
        routeHierarchy: getAssetHierarchyRequestRoutes
          ? getAssetHierarchyRequestRoutes
          : [],
        routeHierarchyDefault: getAssetHierarchyRequestRoutes
          ? getAssetHierarchyRequestRoutes.routes
          : [],
        defaultAsset: payload[0].assetId,
        assetOptions: payload
      };
    case types.GET_USER_INFO_REQUEST_SUCCESS:
      // Since they have Assets, set their first one as the currently selected asset.
      const { userAssets } = payload;
      let getUserInfoRoutes;
      if (userAssets && userAssets.length) {
        const newDefaultAsset = userAssets[0];
        let userAssetArr = [...userAssets];
        const filteredAssets = state.assetHierarchy ? state.assetHierarchy : [];
        const assetIdUser = userAssetArr.map(el => el.assetId).join(',');
        const assetNameUser = userAssetArr.map(el => el.name).join(',');
        const selectedProdAssets = [...userAssets];
        const assetList1 = state.selectedAssetsProdManagement
          ? state.selectedAssetsProdManagement.map(el => el.assetId)
          : [];

        let assetListArray = [];
        let assetListObject = [];

        if (filteredAssets.length > 0) {
          for (let i = 0; i < assetList1.length; i++) {
            assetListArray.push(getCurrentAsset(filteredAssets, assetList1[i]));
          }
          getUserInfoRoutes = getCurrentAsset(
            filteredAssets,
            newDefaultAsset.assetId
          );
        }
        if (assetListArray.length > 0) {
          for (let i = 0; i < assetListArray.length; i++) {
            for (let j = 0; j < assetListArray[i].routes.length; j++)
              assetListObject.push(assetListArray[i].routes[j]);
          }
        }
        return {
          ...state,
          routeHierarchy: assetListObject ? assetListObject : [],
          assetOrRoute: summaryTypes.asset,
          productionAsset: newDefaultAsset,
          assetLpoException: newDefaultAsset,
          routeHierarchyDefault: getUserInfoRoutes
            ? getUserInfoRoutes.routes
            : [],
          assetIdUser: assetIdUser,
          assetNameUser: assetNameUser,
          selectedAssetsProdManagement: selectedProdAssets,
        };
      }
      // The user won't have assets if they are a Guest User, defaulting to Andrews.
      return {
        ...state,
        routeHierarchy: state.assetHierarchy[0] !== undefined ? state.assetHierarchy[0].routes : [],
        routeHierarchyDefault: state.assetHierarchy[0] !== undefined ? state.assetHierarchy[0].routes : [],
        assetOrRoute: summaryTypes.asset,
        productionAsset: state.assetHierarchy[0] !== undefined ? state.assetHierarchy[0] : [], // TODO: Change this if we switch to UUID. Defaulting to Andrews.
        assetLpoException: state.assetHierarchy[0] !== undefined ? state.assetHierarchy[0] : [],
        assetIdUser: state.assetHierarchy[0] !== undefined ? state.assetHierarchy[0].assetId : '',
        assetNameUser: state.assetHierarchy[0] !== undefined ? state.assetHierarchy[0].name : '',
      };

    case types.SET_ASSET:
      let assetIdListString;
      let assetNameListString;
      let selectedAssetRoutesArr = [];
      let selectedAssetRoutes = [];
      let selectedAssetsProdManagement = [];
      let productionAsset = {};

      if (typeof payload !== 'object') {
        const assetListTotal = state.assetHierarchy ? state.assetHierarchy : [];
        let assetNameStr = getCurrentAsset(assetListTotal, payload);
        selectedAssetRoutes = [...assetNameStr.routes];
        assetIdListString = assetNameStr.assetId;
        assetNameListString = assetNameStr.name;
        const prodObjtoPush = {
          assetId: assetIdListString,
          name: assetNameListString
        }
        selectedAssetsProdManagement.push(prodObjtoPush);
        productionAsset = {
          assetId: assetIdListString,
          name: assetNameListString
        }
      }
      else {
        assetIdListString = payload.map(el => el.value).join(',');
        assetNameListString = payload.map(el => el.label).join(',');

        selectedAssetsProdManagement = payload.map(el => {
          return {
            assetId: el.value,
            name: el.label,
          };
        });
        const assetList2 = selectedAssetsProdManagement
          ? selectedAssetsProdManagement.map(el => el.assetId)
          : [];
        const filteredAssets2 = state.assetHierarchy
          ? state.assetHierarchy
          : [];

        if (filteredAssets2.length > 0) {
          for (let i = 0; i < assetList2.length; i++) {
            selectedAssetRoutesArr.push(
              getCurrentAsset(filteredAssets2, assetList2[i])
            );
          }
        }

        if (selectedAssetRoutesArr.length > 0) {
          for (let i = 0; i < selectedAssetRoutesArr.length; i++) {
            for (let j = 0; j < selectedAssetRoutesArr[i].routes.length; j++)
              selectedAssetRoutes.push(selectedAssetRoutesArr[i].routes[j]);
          }
        }
      }

      return {
        ...state,
        routeHierarchy: selectedAssetRoutes ? selectedAssetRoutes : [],
        assetOrRoute: summaryTypes.asset,
        productionAsset: productionAsset,
        assetIdUser: assetIdListString,
        assetNameUser: assetNameListString,
        productionRoute: productionEmpty.route,
        selectedAssetsProdManagement: selectedAssetsProdManagement,
      };
    case types.SET_ASSET_LPO_EXCEPTION:
      const assetListTotal = state.assetHierarchy ? state.assetHierarchy : [];
      let assetNameDefault = getCurrentAsset(assetListTotal, payload);
      let routeProdMgmt = [...state.routeHierarchy]
      let routeDefault = [...assetNameDefault.routes];
      let assetId = assetNameDefault.assetId;
      let assetName = assetNameDefault.name;
      let assetLpoException = {
        assetId: assetId,
        name: assetName,
      };
      return {
        ...state,
        assetLpoException: assetLpoException,
        routeHierarchy: routeProdMgmt,
        productionLPORoute: productionEmpty.route,
        routeHierarchyDefault: routeDefault,
      };
    case types.SET_ROUTE:
      const currentRoute =
        payload !== productionEmpty.route.routeId
          ? state.routeHierarchy.find(route => route.routeId === payload)
          : productionEmpty.route;
      return {
        ...state,
        assetOrRoute: summaryTypes.route,
        productionRoute: currentRoute,
      };
    case types.SET_ROUTE_LPO_EXCEPTION:
      const currentRouteLPO =
        payload !== productionEmpty.route.routeId
          ? state.routeHierarchyDefault.find(route => route.routeId === payload)
          : productionEmpty.route;
      return {
        ...state,
        assetOrRoute: summaryTypes.route,
        productionLPORoute: currentRouteLPO,
      };
    default:
      return state;
  }
};

export default navigation;
export * from './actions';
export * from './selectors';
