import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styles from './styles.module.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { routes } from 'constants/routes';
import { occurrenceStatus } from 'constants/controls';
import HamburgerIcon from 'assets/img/hamburger.svg';
import CloseXIcon from 'assets/img/close-x-blue.svg';
import Chevron from 'assets/img/main-logo.svg';
import { selectIsGuestUser , getUserInfo } from 'store/reducers/users';
import { getAssetHierarchy, getRoleHierarchy  } from 'store/reducers/navigation';

const mapStateToProps = state => ({
  isGuestUser: selectIsGuestUser(state),
});

const mapDispatchToProps = { getAssetHierarchy, getRoleHierarchy ,getUserInfo};

export class TopNav extends Component {
  componentDidMount() {
    const { getAssetHierarchy, getRoleHierarchy ,getUserInfo } = this.props;
    getAssetHierarchy().then(() => {
      getUserInfo();
    });
    getRoleHierarchy();
    
  }

  render() {
    const {
      currentOccurrence,
      docked,
      formDirty,
      openConfirmFormModal,
      toggleSetOpen,
    } = this.props;

    return (
      <div>
        <div className={styles.topNav}>
          <div onClick={toggleSetOpen}>
            {docked ? (
              <img
                src={CloseXIcon}
                alt="Close"
                className={styles.hamburgerIcon}
              />
            ) : (
              <img
                src={HamburgerIcon}
                alt="Open"
                className={styles.hamburgerIcon}
              />
            )}
          </div>

          <div className={styles.mainLogoSection}>
            <div className={styles.mainLogoContainer}>
              <Link
                onClick={e => {
                  if (
                    formDirty &&
                    currentOccurrence.status !== occurrenceStatus.complete
                  ) {
                    openConfirmFormModal(routes.home);
                    e.preventDefault();
                  }
                }}
                className={styles.chevronAnchor}
                to={routes.home}
              >
                <img
                  className={styles.mainLogoImage}
                  src={Chevron}
                  alt="Chevron"
                />
                <span className={styles.mainLogoName}>force</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TopNav.propTypes = {
  docked: PropTypes.bool.isRequired,
  toggleSetOpen: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(TopNav);
