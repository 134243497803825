/* Application Insight Events. The text/strings here are used in Azure Application
Insights in order to track Custom Events. If a new Custom Event is added or you need
to edit/remove any existing Exceptions, make sure you remove it from where its being
called from AND from this file to cleanup.
*/
export const aiEvents = {
  accessTokenSuccess: 'Successfully acquired PowerBI Token',
  addedComment: 'Added Comment on Occurrence',
  addedCommentFailure: 'Failure Adding Comment on Occurrence',
  deletedComment: 'Deleted Comment on Occurrence',
  exportDowntimeTable: 'Exported Downtime Table',
  exportAdminTable: 'Exported Admin Table',
  exportLpoEventsTable: 'Exported LPO Events Table',
  exportProdSummaryTable: 'Exported Production Summary Table',
  invalidPowerBIModel: 'Invalid PowerBI Model',
  noAccessToken: 'Failure to acquire PowerBI Token',
  occurrenceHistoryFailure: 'Failure Fetching Occurrence History',
  occurrenceHistoryMonthFilterSet: 'Occurrence History Month Filter Set',
  occurrenceHistoryYearFilterSet: 'Occurrence History Year Filter Set',
  productionLpoEventsFailure: 'Production LPO Events Failure',
  productionSummaryFailure: 'Production Summary Failure',
  saveRouteTargets: 'Save Route Targets',
  saveRouteTargetsFailure: 'Save Route Targets Failure',
  setCurrentLpoEventsCard: 'Set Current LPO Events Card',
  setCurrentSummaryCard: 'Set Current Summary Card',
  setProductionAsset: 'Set Production Asset',
  setProductionDate: 'Set Production Date',
  setProductionRoute: 'Set Production Route',
  setSummaryTableResolvedData: 'Set Summary Table Resolved Data',
  summaryChartDataFailure: 'Production Summary Chart Failure',
  summaryTableDataFailure: 'Production Summary Table Failure',
  taskFormSave: 'Task Form Save',
  taskFormSaveFailure: 'Task Form Save Failure',
  taskFormSubmit: 'Task Form Submit',
  taskFormSubmitFailure: 'Task Form Submit Failure',
  taskLaunchApplicationClicked: 'Task Launch Application Clicked',
  taskViewMetricClicked: 'Task View Metric Clicked',
  updateAssetTarget: 'Update Asset Target',
  updateAssetTargetFailure: 'Update Asset Target Failure',
  updateAssetThreshold: 'Update Asset Threshold',
  updateAssetThresholdFailure: 'Update Asset Threshold Failure',
  updateFilteredLpoTable: 'Update LPO Table Filter',
  userAuthFailure: 'User Failure To Authenticate',
  userAuthSuccess: 'User Successfully Authenticated',
  userUnauthorized: 'User Unauthorized, Forced Logout'
};
