import { authorizedRequest, checkApiResponse } from 'api/request';
import { PUT, POST, GET } from 'api/methods';
import {
  selectControlTimePeriod,
  selectedSuperintendentsRoleOption,
  selectedSuperintendentsAssetOption,
  selectDirectReportStatus,
  selectDropdownRole,
  selectDropdownAsset,
} from 'store/reducers/controls';
import {
  selectRoleDefault,
  selectAssetDefault,
} from 'store/reducers/navigation';
import { checkCache } from 'api/request';

const apiEndpoints = {
  controlManagement: (year, month, roleId, assetId) =>
    `/controlmanagement/controls/${year}?month=${month}&RoleId=${roleId}&AssetId=${assetId}`,
  getPillarOptions: '/pillars',
  changeOccurrenceStatus: (occurrenceId, isCompleted) =>
    `/controlmanagement/occurrence/set-occurrence-status?occurrenceId=${occurrenceId}&isCompleted=${isCompleted}`,
  saveControlItem:
    '/controlmanagement/controls/addcontrol/?isCustomControl=true',
  editControlItem: (taskTypeId, isCustomControl) =>
    `/controlmanagement/controls/updatecontrol/${taskTypeId}?isCustomControl=${isCustomControl}`,
  deleteControlItem: taskTypeId =>
    `/controlmanagement/controls/deletecontrol/${taskTypeId}`,
};


export const getUserControlItems = async ({ getState }) => {
  const state = getState();
  const { year, month } = selectControlTimePeriod(getState());

  const defaultResponse = [];
  const checkboxDirectReport = selectDirectReportStatus(state);
  const dropdownRoleFlag = selectDropdownRole(state);
  const dropdownAssetFlag = selectDropdownAsset(state);
  const roleIdSecondary = dropdownRoleFlag
    ? selectedSuperintendentsRoleOption(state)
    : selectRoleDefault(state)
  const roleId = checkboxDirectReport
    ? roleIdSecondary
    : '';
  const assetIdSecondary = dropdownAssetFlag
    ? selectedSuperintendentsAssetOption(state)
    : selectAssetDefault(state)
  const assetId = checkboxDirectReport
    ? assetIdSecondary
    : '';

  const url = apiEndpoints.controlManagement(year, month + 1, roleId, assetId);
  return authorizedRequest(url, GET)
    .then(response => checkApiResponse(response, defaultResponse));
};

export const getPillarOptions = async ({
  cache,
  cacheStamp,
  endpointCapture,
}) =>
  checkCache(cache, cacheStamp, apiEndpoints.getPillarOptions, endpointCapture);

export const changeOccurrenceStatus = async ({ payload }) => {
  const { occurrenceId, isComplete } = payload;
  const url = apiEndpoints.changeOccurrenceStatus(occurrenceId, isComplete);
  return authorizedRequest(url, PUT).then(response =>
    checkApiResponse(response)
  );
};

export const addControlItem = async ({ payload }) => {
  const body = payload;
  return authorizedRequest(
    apiEndpoints.saveControlItem,
    POST,
    body
  ).then(response => checkApiResponse(response));
};

export const editControlItem = async ({ payload }) => {
  const body = payload.formBody;
  return authorizedRequest(
    apiEndpoints.editControlItem(payload.taskTypeId, payload.isCustomControl),
    PUT,
    body
  ).then(response => checkApiResponse(response));
};

export const deleteControlItem = async ({ payload }) => {
  return authorizedRequest(
    apiEndpoints.deleteControlItem(payload),
    PUT
  ).then(response => checkApiResponse(response));
};
