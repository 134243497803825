import React from 'react';
import { lpoEventDetailModalLabels } from 'constants/productionManagement';
import styles from './styles.module.css';
import { formatNumber } from 'utils/formatNumber';
import { connect } from 'react-redux';
import { selectCurrentLpoEventDetails, toggleLpoEventDetailsModal } from 'store/reducers/production';
import moment from 'moment';

const labels = lpoEventDetailModalLabels;

const mapStateToProps = state => ({
  event: selectCurrentLpoEventDetails(state)
});

const mapDispatchToProps = {
  toggleLpoEventDetailsModal
};

export const ShutInDetailsModal = ({ toggleLpoEventDetailsModal, event }) => {
  return (
    <div className={styles.container}>
      <div className={styles.topDetails}>
        <ul className={styles.detailColumn}>
          <li>
            <span>{labels.wellName}</span>
            {event.wellName}
          </li>
          <li>
            <span>{labels.collectionPoint}</span>
            {event.collectionPoint}
          </li>
          <li>
            <span>{labels.route}</span>
            {event.route}
          </li>
          <li>
            <span>{labels.reasonCode}</span>
            {event.reasonCode}
          </li>
        </ul>

        <ul className={styles.detailColumn}>
          <li>
            <span>{labels.dateOfShutIn}</span>
            {moment(event.dateOfShutIn).format('M/D/YYYY')}
          </li>
          <li>
            <span>{labels.daysInShutIn}</span>
            {formatNumber(event.daysInShutIn)}
          </li>
          <li>
            <span>{labels.lawtRate}</span>
            {formatNumber(event.lawtRate)}
            {labels.boed}
          </li>
          <li>
            <span>{labels.lpoType}</span>
            {event.lpoType}
          </li>
        </ul>
      </div>

      <p className={styles.reason}>
        <span>{labels.reasonForCriticality}</span>
        {event.reasonForCriticality}
      </p>

      <p className={styles.commentsTitle}>{labels.lastEventsComments}</p>
      <p className={styles.comments}>{event.comments}</p>
      <button
        className={styles.closeButton}
        onClick={toggleLpoEventDetailsModal}
      >
        close
      </button>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ShutInDetailsModal);
