import React, { Component } from 'react';
import styles from './styles.module.css';
import Button from 'components/common/Button';

export class AdminHeader extends Component {
  render = () => {
    const {
      buttonAction,
      buttonText,
      buttonType,
      headerText,
      showHeaderButton,
    } = this.props;
    return (
      <div className={styles.wrapper}>
        <div className={styles.title}>{headerText}</div>
        {showHeaderButton && (
          <Button text={buttonText} type={buttonType} click={buttonAction} />
        )}
       
      </div>
    );
  };
}

export default AdminHeader;
