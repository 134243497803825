import types from 'constants/actionTypes';
import { requestStatuses } from 'constants/values';
import moment from 'moment';
import { columnTitles, controlStatuses } from 'constants/controls';
import { sortControls } from 'utils/sortControls';
import { checkOverdueStatus } from 'utils/checkOverdueStatus';

const CURRENT_YEAR = moment().year();
const CURRENT_MONTH = moment().month();

export const initialState = {
  controlCardsRequestStatus: requestStatuses.pending,
  controlTimePeriod: {
    year: CURRENT_YEAR,
    month: CURRENT_MONTH,
  },
  controlItems: {
    '2023': {
      '0': []
    },
  },
  pillarOptions: [],
  pillarCounts: {},
  showFormControlModal: false,
  focusedRecord: null,
  deleteControl: false,
  controlItemRequest: requestStatuses.empty,
  editControlItemRequest: requestStatuses.empty,
  errorMsg: '',
  assetControlOption: '',
  roleControlOption: 1,
  directReportCheckboxStatus: false,
  dropdownRoleStatus: false,
  dropdownAssetStatus: false,
};

const updateOccurrenceStatus = (
  state,
  pillarTitle,
  occurrenceId,
  newStatus
) => {
  const {
    controlTimePeriod: { year, month },
  } = state;
  const currentFullControlItems = [...state.controlItems[year][month]];
  const currentControlItems = currentFullControlItems.find(
    pillarGroup => pillarGroup.pillar === pillarTitle
  ).controlItems;
  const updatedControlItems = sortControls(
    currentControlItems.reduce((reducedCIs, controlItem, i) => {
      const { occurrence } = controlItem;
      if (occurrence.occurrenceId === occurrenceId) {
        const oldControlItem = { ...currentControlItems[i] };
        const updatedControlItem = {
          ...oldControlItem,
          occurrence: {
            ...oldControlItem.occurrence,
            status: newStatus,
          },
        };
        reducedCIs.push(updatedControlItem);
      } else {
        reducedCIs.push({ ...controlItem });
      }
      return reducedCIs;
    }, []),
    year,
    month
  );
  const updatedFullControlItems = currentFullControlItems.reduce(
    (reducedControlItems, pillarGroup, i) => {
      const newControlItemsArr = [...reducedControlItems];
      if (pillarGroup.pillar === pillarTitle) {
        newControlItemsArr.push({
          pillar: pillarTitle,
          controlItems: [...updatedControlItems],
        });
      } else {
        newControlItemsArr.push({ ...pillarGroup });
      }
      return newControlItemsArr;
    },
    []
  );
  return {
    ...state.controlItems,
    [year]: {
      ...state.controlItems[year],
      [month]: updatedFullControlItems,
    },
  };
};

export const controls = (
  state = initialState,
  { type, payload, requestPayload }
) => {
  const { year: stateYear, month: stateMonth } = state.controlTimePeriod;
  switch (type) {
    case types.SET_CONTROL_TIME_PERIOD:
      const { year, month } = payload;
      return {
        ...state,
        controlTimePeriod: { year, month },
      };
    case types.SET_CONTROL_REPORT_ROLE_OPTION:
      return {
        ...state,
        roleControlOption: payload,
        dropdownRoleStatus: true,
      };
    case types.DIRECT_REPORT_CHECKBOX:
      return {
        ...state,
        directReportCheckboxStatus: payload,
      };
    case types.SET_CONTROL_REPORT_ASSET_OPTION:
      return {
        ...state,
        assetControlOption: payload,
        dropdownAssetStatus: true,
      };
    case types.GET_CONTROL_ITEMS_REQUEST:
      return {
        ...state,
        controlCardsRequestStatus: requestStatuses.pending,
      };
    case types.GET_CONTROL_ITEMS_REQUEST_SUCCESS:

      const { controlItems: prevControlItems } = state;
      const processPillars = pillars => {
        // The 'Process Safety' and 'Workforce Safety' need to be combined
        // TODO: Add 'Custom' pillar support here when API developed. Will need to be after the sort
        const pillarsWithTasks = pillars.map(pillarGroup => pillarGroup.pillar);
        // emptyPillars is used to generate empty columns

        const emptyPillars = columnTitles.filter(title =>
          pillarsWithTasks.reduce(
            (doesNotIncludeTitle, pillarName) =>
              doesNotIncludeTitle
                ? pillarName.includes(title)
                : doesNotIncludeTitle,
            true
          )
        );
        const pillarDict = pillars.reduce((pillars, pillarGroup, i) => {
          const pillarName = columnTitles.find(title =>
            pillarGroup.pillar.toLowerCase().includes(title.split(' ')[0].toLowerCase())
          );
          const { tasktypes: controlItems } = pillarGroup;
          const combinedPillars = pillars[pillarName]
            ? [...pillars[pillarName]]
            : [];

          pillars[pillarName] = [...combinedPillars, ...controlItems];
          return pillars;
        }, {});
        return Object.entries(pillarDict)
          .map(([entry, controlItems]) => {
            const {
              year: sortYear,
              month: sortMonth,
            } = state.controlTimePeriod;
            const sortedControlItems = [
              ...sortControls(controlItems, sortYear, sortMonth),
            ];
            const correctedStatusControlItems = [
              ...sortedControlItems.map(controlItem => ({
                ...controlItem,
                occurrence: {
                  ...controlItem.occurrence,
                  status: checkOverdueStatus(
                    controlItem.occurrence,
                    sortYear,
                    sortMonth
                  ),
                },
              })),
            ];
            return { pillar: entry, controlItems: correctedStatusControlItems };
          })
          .concat(
            emptyPillars.map(entry => ({ pillar: entry, controlItems: [] }))
          )
          .sort((pillarA, pillarB) => {
            const titleA = pillarA.pillar.toLowerCase();
            const titleB = pillarB.pillar.toLowerCase();
            if (titleA.pillar === titleB.pillar) return 0;
            if (titleA.pillar === 'Custom') return -1;
            if (titleB.pillar === 'Custom') return 1;
            return titleA > titleB ? 1 : titleA < titleB ? -1 : 0;
          });
      };
      return {
        ...state,
        controlCardsRequestStatus: requestStatuses.success,
        controlItems: {
          ...prevControlItems,
          [stateYear]: {
            ...prevControlItems[stateYear],
            [stateMonth]: processPillars(payload),
          },
        },
      };
    case types.GET_CONTROL_ITEMS_REQUEST_FAILURE:
      return {
        ...state,
        controlCardsRequestStatus: requestStatuses.failure,
      };
    case types.INIT_PILLAR_COUNTS:
      const getPillarCounts = controlItems =>
        controlItems.reduce((counts, pillarGroup) => {
          const count = pillarGroup.controlItems.reduce(
            (unread, controlItem) =>
              unread +
              (controlItem.occurrence.status !== controlStatuses.ready ? 0 : 1),
            0
          );
          return {
            ...counts,
            [pillarGroup.pillar]: count,
          };
        }, {});

      return {
        ...state,
        pillarCounts: getPillarCounts(
          state.controlItems[stateYear][stateMonth]
        ),
      };

    case types.CHANGE_OCCURRENCE_STATUS_REQUEST:
      const {
        pillarTitle: pillarTitleRequest,
        occurrenceId: occurrenceIdRequest,
      } = payload;
      const updatedControlsRequest = updateOccurrenceStatus(
        state,
        pillarTitleRequest,
        occurrenceIdRequest,
        controlStatuses.pending
      );
      return {
        ...state,
        controlItems: { ...state.controlItems, ...updatedControlsRequest },
      };
    case types.CHANGE_OCCURRENCE_STATUS_REQUEST_SUCCESS:
      const {
        pillarTitle: pillarTitleSuccess,
        occurrenceId: occurrenceIdSuccess,
      } = requestPayload;
      const updatedControlsSuccess = updateOccurrenceStatus(
        state,
        pillarTitleSuccess,
        occurrenceIdSuccess,
        payload.status
      );
      const updatedPillarCount = {
        ...state.pillarCounts,
        [pillarTitleSuccess]:
          payload.status === controlStatuses.complete
            ? state.pillarCounts[pillarTitleSuccess] - 1
            : state.pillarCounts[pillarTitleSuccess] + 1,
      };
      return {
        ...state,
        controlItems: { ...state.controlItems, ...updatedControlsSuccess },
        pillarCounts: updatedPillarCount,
      };
    case types.CHANGE_OCCURRENCE_STATUS_REQUEST_FAILURE:
      const {
        pillarTitle: pillarTitleFailure,
        occurrenceId: occurrenceIdFailure,
      } = requestPayload;
      const updatedControlsFailure = updateOccurrenceStatus(
        state,
        pillarTitleFailure,
        occurrenceIdFailure,
        controlStatuses.error
      );
      return {
        ...state,
        controlItems: { ...state.controlItems, ...updatedControlsFailure },
      };
    case types.TOGGLE_FORM_CONTROL_MODAL:
      return {
        ...state,
        showFormControlModal: !state.showFormControlModal,
        focusedRecord: payload,
      };
    case types.RESET_CONTROL_TOOL_REQUEST_STATUS:
      return {
        ...state,
        controlItemRequest: requestStatuses.empty,
      };
    case types.TOGGLE_ADD_CONTROL_MODAL:
      return {
        ...state,
        showFormControlModal: !state.showFormControlModal,
      };
    case types.CREATE_CUSTOM_CONTROL_REQUEST:
      return {
        ...state,
        controlItemRequest: requestStatuses.pending,
      };
    case types.CREATE_CUSTOM_CONTROL_REQUEST_SUCCESS:
      return {
        ...state,
        controlItemRequest: requestStatuses.success,
      };
    case types.CREATE_CUSTOM_CONTROL_REQUEST_FAILURE:
      return {
        ...state,
        controlItemRequest: requestStatuses.failure,
      };
    case types.EDIT_CONTROL_ITEM_REQUEST:
      return {
        ...state,
        controlItemRequest: requestStatuses.pending,
      };
    case types.EDIT_CONTROL_ITEM_REQUEST_SUCCESS:
      return {
        ...state,
        controlItemRequest: requestStatuses.success,
      };
    case types.EDIT_CONTROL_ITEM_REQUEST_FAILURE:
      const errorcontroladmin = payload.toString();
      const errorMsg = errorcontroladmin.includes('Bad Request')
        ? 'Cannot change one-time control. Please create a new control instead of updating this control.'
        : 'unable to save your data as an error has occurred - try again later';
      return {
        ...state,
        controlItemRequest: requestStatuses.failure,
        errorMsg,
      };
    case types.TOGGLE_DELETE_CONTROL_MODAL:
      return {
        ...state,
        showDeleteModal: !state.showDeleteModal,
      };
    case types.TURN_OFF_DELETE_CONTROL_MODAL:
      return {
        ...state,
        showDeleteModal: false,
        controlItemRequest: requestStatuses.empty,
      };
    case types.DELETE_CONTROL_ITEM_REQUEST:
      return {
        ...state,
        controlItemRequest: requestStatuses.pending,
      };
    case types.DELETE_CONTROL_ITEM_REQUEST_SUCCESS:
      return {
        ...state,
        deleteControl: true,
        controlItemRequest: requestStatuses.success,
      };
    case types.DELETE_CONTROL_ITEM_REQUEST_FAILURE:
      return {
        ...state,
        deleteControl: false,
        controlItemRequest: requestStatuses.failure,
      };
    case types.GET_PILLAR_OPTIONS_REQUEST_SUCCESS:
      return {
        ...state,
        pillarOptions: payload,
      };
    default:
      return state;
  }
};

export default controls;
export * from './actions';
export * from './selectors';
