import React from 'react';
import styles from './styles.module.css';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { selectSummaryChartRequestStatus } from 'store/reducers/production';
import { requestStatuses } from 'constants/values';

const mapStateToProps = state => ({
  requestStatus: selectSummaryChartRequestStatus(state)
});

export const Legend = ({ requestStatus }) => {
  const textLabels = {
    target: 'Target',
    production: 'Production',
    downtime: 'Downtime LPO',
    constrained: 'Constrained LPO',
    shutin: 'Shut-In LPO'
  };

  return requestStatus === requestStatuses.success ? (
    <div className={classnames(styles.wrapper, 'row')}>
      <div className={'col-xs-2'}>
        <span className={classnames(styles.dot, styles.target)} />
        {textLabels.target}
      </div>
      <div className={'col-xs-2'}>
        <span className={classnames(styles.dot, styles.production)} />
        {textLabels.production}
      </div>
      <div className={'col-xs-2'}>
        <span className={classnames(styles.dot, styles.downtime)} />
        {textLabels.downtime}
      </div>
      <div className={'col-xs-2'}>
        <span className={classnames(styles.dot, styles.constrained)} />
        {textLabels.constrained}
      </div>
      <div className={'col-xs-2'}>
        <span className={classnames(styles.dot, styles.shutin)} />
        {textLabels.shutin}
      </div>
    </div>
  ) : (
    <div />
  );
};

export default connect(mapStateToProps)(Legend);
