import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import styles from './styles.module.css';
import MonthPicker from './MonthPicker';
import ControlForm from './ControlForm';
import {
  getUserControlItems,
  initPillarCounts,
  selectControlCardsRequestStatus,
  selectControlItemRequestStatus,
  selectControlTimePeriod,
  selectUserControlItems,
  toggleFormControlModal,
  selectShowFormControlModal,
  selectToggleRecord,
  selectDirectReportStatus,
  selectedSuperintendentsRoleOption,
  selectedSuperintendentsAssetOption,
  setControlRequestAssetOptions
} from 'store/reducers/controls';
import { selectAssets, selectFls, getRoleHierarchy, selectAssetControlOptions, getAssetHierarchy } from 'store/reducers/navigation';
import {
  selectIsGuestUser,
  selectIsSupretendent,
  selectIsReadOnlyUser,
} from 'store/reducers/users';
import ControlPillar from './ControlPillar';
import { controlAddNewLink, controlEditNewLink } from 'constants/controls';
import Modal from 'components/common/Modal';
import { requestStatuses } from 'constants/values';
import LoadingPage from 'components/common/LoadingPage';
import { getAssetProp, getRoleProp } from 'utils/controls';
// SVGs
import { ReactComponent as AddSVG } from 'assets/img/icon-add-blue.svg';

const mapStateToProps = state => {
  const { year, month } = selectControlTimePeriod(state);
  return {
    year,
    month,
    controlItems: selectUserControlItems(state),
    requestStatus: selectControlCardsRequestStatus(state),
    showFormControlModal: selectShowFormControlModal(state),
    addCustomControlRequestStatus: selectControlItemRequestStatus(state),
    selectFocusedRecord: selectToggleRecord(state),
    isGuestUser: selectIsGuestUser(state),
    isReadOnlyUser: selectIsReadOnlyUser(state),
    isSuperItendent: selectIsSupretendent(state),
    isReport: selectDirectReportStatus(state),
    assetValue: selectedSuperintendentsAssetOption(state),
    roleValue: selectedSuperintendentsRoleOption(state),
    assetList: selectAssets(state),
    roleList: selectFls(state),
    assetDefault: selectAssetControlOptions(state)
  };
};

const mapDispatchToProps = {
  getUserControlItems,
  initPillarCounts,
  getRoleHierarchy,
  toggleFormControlModal,
  getAssetHierarchy,
  setControlRequestAssetOptions
};

const getControldivForReadOnly = (isSuperItendent, isReport, styles) => {
  return (
    <div>
      {isSuperItendent && !isReport ? (
        <div className={styles.userControlHeading}>
          SUPERINTENDENT CONTROL
        </div>
      ) : null}
      {isSuperItendent && isReport ? (
        <div className={styles.userControlHeading}>
          SELECTED SUPERVISOR CONTROL{' '}
        </div>
      ) : null}
    </div>
  )
}
export class ControlsPage extends Component {
  componentDidMount() {
    this.props.getRoleHierarchy();
    if (this.isControlItemsEmpty()) {
      this.updateControlItems();
    }
    this.props.getAssetHierarchy().then(() =>
      this.props.setControlRequestAssetOptions(this.props.assetDefault[0]?.value));
  }

  componentDidUpdate(prevProps) {


    // TODO: Implement caching so that this request isn't always made unless necessary
    const { year: prevYear, month: prevMonth } = prevProps;
    const { year, month } = this.props;
    const timeChanged = year !== prevYear || month !== prevMonth;
    if (timeChanged && this.isControlItemsEmpty()) {
      this.updateControlItems();
    } else if (timeChanged) {
      this.props.initPillarCounts();
    }

    if (
      this.props.showFormControlModal !== prevProps.showFormControlModal &&
      this.props.addCustomControlRequestStatus === requestStatuses.success
    ) {
      this.updateControlItems();
    }
  }

  isControlItemsEmpty = () => {
    const { controlItems } = this.props;
    return Array.isArray(controlItems) && controlItems.length === 0;
  };

  updateControlItems = () => {
    const { getUserControlItems, initPillarCounts } = this.props;
    getUserControlItems().then(({ type }) => {
      // If the action was a success, generate pillars state and init pillar counts
      if (!!type && (typeof type === 'string') && type.includes('SUCCESS')) {
        initPillarCounts();
      }
    });
  };

  calculatePillarHeight = controlItems => {
    const sectionSize = window.innerHeight - 330;
    const cardsSize =
      controlItems.reduce(
        (max, pillar) =>
          pillar.controlItems.length > max ? pillar.controlItems.length : max,
        0
      ) *
      170 +
      10;
    return sectionSize > cardsSize ? sectionSize : cardsSize;
  };

  render() {
    const {
      controlItems,
      showFormControlModal,
      toggleFormControlModal,
      selectFocusedRecord,
      isGuestUser,
      isReadOnlyUser,
      isReport,
      isSuperItendent,
      assetValue,
      roleValue,
      assetList,
      roleList,
    } = this.props;

    //const assetVal = assetValue !== '' ? assetValue : assetList[0]?.value
    const assetName = getAssetProp(assetList, assetValue);
    const roleName = getRoleProp(roleList, roleValue);
    const pillarHeight = this.calculatePillarHeight(controlItems);

    const Model = {
      title: !selectFocusedRecord
        ? controlAddNewLink.formHeader
        : controlEditNewLink.formHeader,
    };

    return this.props.requestStatus !== requestStatuses.success ? (
      <LoadingPage
        containerStyles={styles.loading}
        requestStatus={this.props.requestStatus}
        retryRequest={this.updateControlItems}
      />
    ) : (
      <div className={styles.scrollableContainer}>
        <div className={styles.navBar}>
          <MonthPicker />
          {/*TODO: Add 'add new item' button back when functionality exists*/}
          {!isGuestUser
            ? isReadOnlyUser && getControldivForReadOnly(isSuperItendent, isReport, styles)
            : null}
          {!isGuestUser
            ? !isReadOnlyUser && (
              <div
                className={styles.addItemContainer}
                onClick={() => {
                  toggleFormControlModal();
                }}
              >
                {/* {isSuperItendent && !isReport ? <div className={styles.userControlHeading}>SUPRETENDENT Control</div> : null}
              {isSuperItendent && isReport ? <div className={styles.userControlHeading}>Selected SUPERVISOR CONTROL </div> : null} */}
                <div className={styles.addSvg}>
                  <AddSVG />
                </div>
                <div className={styles.controlFormText}>
                  <p>{controlAddNewLink.text}</p>
                </div>
              </div>
            )
            : null}
        </div>

        <div className={styles.content}>
          {controlItems.map((pillarGroup, i) => {
            return (
              <ControlPillar
                key={i}
                pillarTitle={pillarGroup.pillar}
                controlItems={pillarGroup.controlItems}
                height={pillarHeight}
              />
            );
          })}
        </div>
        {showFormControlModal && (
          <Modal
            title={Model.title}
            onCloseClick={toggleFormControlModal}
            size={'modalContainerMedium'}
          >
            <ControlForm assetValue={assetName[0]} roleValue={roleName[0]} />
          </Modal>
        )}
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ControlsPage)
);
