export const getProdDetails = (currentProductionSummary,details) =>{
    for (let i = 0; i < currentProductionSummary.productionDetails.length; i++) {
        details.prodCapability +=
          currentProductionSummary.productionDetails[i].prodCapability;
        details.totalOil += currentProductionSummary.productionDetails[i].totalOil;
        details.totalWater +=
          currentProductionSummary.productionDetails[i].totalWater;
        details.totalGas += currentProductionSummary.productionDetails[i].totalGas;
        details.productionEfficiency +=
          currentProductionSummary.productionDetails[i].productionEfficiency;
      }
  return details;  
}