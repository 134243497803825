import React from 'react';
import Popover from 'components/common/Popover';
import InfoIcon from 'assets/img/info.svg';
import { formatNumber } from 'utils/formatNumber';
import classnames from 'classnames';
import EditableTargetCell from 'components/ProductionManagementPage/ProductionSummary/SummaryCardTable/EditableTargetCell';
import TargetBoedCell from 'components/ProductionManagementPage/ProductionSummary/SummaryCardTable/TargetBoedCell';

// This file contains the React-Table Columns configuration for the Summary Table that's on the Production Management page.

// setNewRoute is the redux action to change the route. It is passed in from the SummaryCardTable component.
export const assetMeasuredProductionColumns = setNewRoute => [
  {
    Header: 'Routes',
    accessor: 'name',
    minWidth: 150,
    Cell: props => (
      <span
        className={'clickableRouteWithFlex'}
        onClick={() => {
          setNewRoute(props.original.routeId);
        }}
      >
        <Popover text={props.original.name} overflow={true}>
          {props.original.name}
        </Popover>
      </span>
    ),
    headerClassName: 'columnHeader',
    defaultSortDesc: true,
  },
  {
    Header: 'Oil',
    accessor: 'oil',
    minWidth: 100,
    Cell: props => (
      <span className={'summaryCell'}>{formatNumber(props.original.oil)}</span>
    ),
    headerClassName: 'centeredColumnHeader',
  },
  {
    Header: 'Gas',
    accessor: 'gas',
    minWidth: 100,
    Cell: props => <span>{formatNumber(props.original.gas)}</span>,
    headerClassName: 'centeredColumnHeader',
  },
  {
    Header: 'Water',
    accessor: 'water',
    minWidth: 100,
    Cell: props => <span>{formatNumber(props.original.water)}</span>,
    headerClassName: 'centeredColumnHeader',
    className: 'targetClass',
  },
  {
    Header: 'BOED',
    accessor: 'boed.production',
    minWidth: 120,
    Cell: props => <span>{formatNumber(props.original.boed.production)}</span>,
    headerClassName: 'centeredColumnHeader',
  },
  {
    Header: 'BOED Target',
    accessor: 'boed.target',
    minWidth: 120,
    Cell: props => <span>{formatNumber(props.original.boed.target)}</span>,
    headerClassName: 'centeredColumnHeader',
  },
  {
    Header: 'BOED Difference',
    accessor: 'boed.difference',
    minWidth: 120,
    Cell: props => {
      const {
        original: {
          boed: { difference },
        },
      } = props;
      return (
        <span
          className={
            difference > 0 ? 'greenDiff' : difference < 0 ? 'redDiff' : null
          }
        >
          {formatNumber(difference)}
        </span>
      );
    },
    headerClassName: 'centeredColumnHeader',
  },
  {
    Header: 'BOED 7-Day Avg.',
    accessor: 'boed._7DayAverage',
    minWidth: 120,
    Cell: props => (
      <span>{formatNumber(props.original.boed._7DayAverage)}</span>
    ),
    headerClassName: 'centeredColumnHeader',
  },
  {
    Header: 'BOED 30-Day Avg.',
    accessor: 'boed._30DayAverage',
    minWidth: 120,
    Cell: props => (
      <span>{formatNumber(props.original.boed._30DayAverage)}</span>
    ),
    headerClassName: 'centeredColumnHeader',
  },
];
export const assetMeasuredDashboardColumns = setNewAsset => [
  {
    Header: 'Assets ',
    accessor: 'assetName',
    minWidth: 150,
    Cell: props => (
      <span
        className={'clickableRouteWithFlex'}
        onClick={() => {
          setNewAsset(props.original.assetId);
        }}
      >
        <Popover text={props.original.assetName} overflow={true}>
          {props.original.assetName}
        </Popover>
      </span>
    ),
    headerClassName: 'columnHeader',
    defaultSortDesc: true,
  },
  {
    Header: 'Oil',
    accessor: 'assetOil',
    minWidth: 100,
    Cell: props => (
      <span className={'summaryCell'}>
        {formatNumber(props.original.assetOil)}
      </span>
    ),
    headerClassName: 'centeredColumnHeader',
  },
  {
    Header: 'Gas',
    accessor: 'assetGas',
    minWidth: 100,
    Cell: props => <span>{formatNumber(props.original.assetGas)}</span>,
    headerClassName: 'centeredColumnHeader',
  },
  {
    Header: 'Water',
    accessor: 'assetWater',
    minWidth: 100,
    Cell: props => <span>{formatNumber(props.original.assetWater)}</span>,
    headerClassName: 'centeredColumnHeader',
    className: 'targetClass',
  },
  {
    Header: 'BOED',
    accessor: 'assetBOED.production',
    minWidth: 120,
    Cell: props => (
      <span>{formatNumber(props.original.assetBOED.production)}</span>
    ),
    headerClassName: 'centeredColumnHeader',
  },
  {
    Header: 'BOED Target',
    accessor: 'assetBOED.target',
    minWidth: 120,
    Cell: props => <span>{formatNumber(props.original.assetBOED.target)}</span>,
    headerClassName: 'centeredColumnHeader',
  },
  {
    Header: 'BOED Difference',
    accessor: 'assetBOED.difference',
    minWidth: 120,
    Cell: props => {
      const {
        original: {
          assetBOED: { difference },
        },
      } = props;
      return (
        <span
          className={
            difference > 0 ? 'greenDiff' : difference < 0 ? 'redDiff' : null
          }
        >
          {formatNumber(difference)}
        </span>
      );
    },
    headerClassName: 'centeredColumnHeader',
  },
  {
    Header: 'BOED 7-Day Avg.',
    accessor: 'assetBOED._7DayAverage',
    minWidth: 120,
    Cell: props => (
      <span>{formatNumber(props.original.assetBOED._7DayAverage)}</span>
    ),
    headerClassName: 'centeredColumnHeader',
  },
  {
    Header: 'BOED 30-Day Avg.',
    accessor: 'assetBOED._30DayAverage',
    minWidth: 120,
    Cell: props => (
      <span>{formatNumber(props.original.assetBOED._30DayAverage)}</span>
    ),
    headerClassName: 'centeredColumnHeader',
  },
];

// setNewRoute is the redux action to change the route. It is passed in from the SummaryCardTable component.
// updateTableTargets is the redux action to update the Route Targets for Oil/Water/Gas/BOED when in editing mode.
export const assetTargetColumns = (setNewRoute, updateTableTarget) => [
  {
    Header: 'Routes',
    accessor: 'name',
    minWidth: 150,
    Cell: props => (
      <span
        className={'clickableRouteWithFlex'}
        onClick={() => {
          setNewRoute(props.original.routeId);
        }}
      >
        <Popover text={props.original.name} overflow={true}>
          {props.original.name}
        </Popover>
      </span>
    ),
    headerClassName: 'columnHeader',
    defaultSortDesc: true,
  },
  {
    Header: 'Oil 30-Day Avg.',
    accessor: 'oil._30DayAverage',
    minWidth: 120,
    Cell: props => (
      <span>{formatNumber(props.original.oil._30DayAverage)}</span>
    ),
    headerClassName: 'centeredColumnHeader',
  },
  {
    Header: 'Oil Target',
    accessor: 'oil.target',
    minWidth: 100,
    Cell: props => (
      <EditableTargetCell
        route={props.original}
        updateTableTarget={updateTableTarget}
        target={'oil'}
      />
    ),
    headerClassName: 'centeredColumnHeader',
    className: 'targetClass',
  },
  {
    Header: 'Gas 30-Day Avg.',
    accessor: 'gas._30DayAverage',
    minWidth: 120,
    Cell: props => (
      <span>{formatNumber(props.original.gas._30DayAverage)}</span>
    ),
    headerClassName: 'centeredColumnHeader',
  },
  {
    Header: 'Gas Target',
    accessor: 'gas.target',
    minWidth: 100,
    Cell: props => (
      <EditableTargetCell
        route={props.original}
        updateTableTarget={updateTableTarget}
        target={'gas'}
      />
    ),
    headerClassName: 'centeredColumnHeader',
    className: 'targetClass',
  },
  {
    Header: 'Water 30-Day Avg.',
    accessor: 'water._30DayAverage',
    minWidth: 120,
    Cell: props => (
      <span>{formatNumber(props.original.water._30DayAverage)}</span>
    ),
    headerClassName: 'centeredColumnHeader',
  },
  {
    Header: 'Water Target',
    accessor: 'water.target',
    minWidth: 100,
    Cell: props => (
      <EditableTargetCell
        route={props.original}
        updateTableTarget={updateTableTarget}
        target={'water'}
      />
    ),
    headerClassName: 'centeredColumnHeader',
    className: 'targetClass',
  },
  {
    Header: 'BOED 30-Day Avg.',
    accessor: 'boed._30DayAverage',
    minWidth: 120,
    Cell: props => (
      <span>{formatNumber(props.original.boed._30DayAverage)}</span>
    ),
    headerClassName: 'centeredColumnHeader',
  },
  {
    Header: 'BOED Target',
    accessor: 'boed.target',
    minWidth: 100,
    Cell: props => <TargetBoedCell route={props.original} />,
    headerClassName: 'centeredColumnHeader',
    className: 'targetClass',
  },
];

export const assetDashboardTargetColumns = setNewAsset => [
  {
    Header: 'Assets',
    accessor: 'assetName',
    minWidth: 150,
    Cell: props => (
      <span
        className={'clickableRouteWithFlex'}
        onClick={() => {
          setNewAsset(props.original.assetId);
        }}
      >
        <Popover text={props.original.assetName} overflow={true}>
          {props.original.assetName}
        </Popover>
      </span>
    ),
    headerClassName: 'columnHeader',
    defaultSortDesc: true,
  },
  {
    Header: 'Oil 30-Day Avg.',
    accessor: 'assetOil._30DayAverage',
    minWidth: 120,
    Cell: props => (
      <span>{formatNumber(props.original.assetOil._30DayAverage)}</span>
    ),
    headerClassName: 'centeredColumnHeader',
  },
  {
    Header: 'Oil Target',
    accessor: 'assetOil.target',
    minWidth: 100,
    Cell: props => <span>{formatNumber(props.original.assetOil.target)}</span>,
    headerClassName: 'centeredColumnHeader',
    className: 'targetClass',
  },
  {
    Header: 'Gas 30-Day Avg.',
    accessor: 'assetGas._30DayAverage',
    minWidth: 120,
    Cell: props => (
      <span>{formatNumber(props.original.assetGas._30DayAverage)}</span>
    ),
    headerClassName: 'centeredColumnHeader',
  },
  {
    Header: 'Gas Target',
    accessor: 'assetGas.target',
    minWidth: 100,
    Cell: props => <span>{formatNumber(props.original.assetGas.target)}</span>,
    headerClassName: 'centeredColumnHeader',
    className: 'targetClass',
  },
  {
    Header: 'Water 30-Day Avg.',
    accessor: 'assetWater._30DayAverage',
    minWidth: 120,
    Cell: props => (
      <span>{formatNumber(props.original.assetWater._30DayAverage)}</span>
    ),
    headerClassName: 'centeredColumnHeader',
  },
  {
    Header: 'Water Target',
    accessor: 'assetWater.target',
    minWidth: 100,
    Cell: props => (
      <span>{formatNumber(props.original.assetWater.target)}</span>
    ),
    headerClassName: 'centeredColumnHeader',
    className: 'targetClass',
  },
  {
    Header: 'BOED 30-Day Avg.',
    accessor: 'assetBOED._30DayAverage',
    minWidth: 120,
    Cell: props => (
      <span>{formatNumber(props.original.assetBOED._30DayAverage)}</span>
    ),
    headerClassName: 'centeredColumnHeader',
  },
  {
    Header: 'BOED Target',
    accessor: 'assetBOED.target',
    minWidth: 100,
    Cell: props => <span>{formatNumber(props.original.assetBOED.target)}</span>,
    headerClassName: 'centeredColumnHeader',
    className: 'targetClass',
  },
];

// setNewRoute is the redux action to change the route. It is passed in from the SummaryCardTable component.
export const assetDifferenceColumns = setNewRoute => [
  {
    Header: 'Routes',
    accessor: 'name',
    minWidth: 150,
    Cell: props => (
      <span
        className={'clickableRouteWithFlex'}
        onClick={() => {
          setNewRoute(props.original.routeId);
        }}
      >
        <Popover text={props.original.name} overflow={true}>
          {props.original.name}
        </Popover>
      </span>
    ),
    headerClassName: 'columnHeader',
  },
  {
    Header: 'BOED',
    accessor: 'boed.production',
    minWidth: 100,
    Cell: props => <span>{formatNumber(props.original.boed.production)}</span>,
    headerClassName: 'centeredColumnHeader',
  },
  {
    Header: 'BOED Target',
    accessor: 'boed.target',
    minWidth: 100,
    Cell: props => <span>{formatNumber(props.original.boed.target)}</span>,
    headerClassName: 'centeredColumnHeader',
  },
  {
    Header: 'BOED Difference',
    accessor: 'boed.difference',
    minWidth: 100,
    Cell: props => {
      const {
        original: {
          boed: { difference },
        },
      } = props;
      return (
        <span
          className={
            difference > 0 ? 'greenDiff' : difference < 0 ? 'redDiff' : null
          }
        >
          {formatNumber(difference)}
        </span>
      );
    },
    headerClassName: 'centeredColumnHeader',
    defaultSortDesc: true,
    sortMethod: (a, b) => {
      return a - b;
    },
  },
  {
    Header: 'LPO',
    accessor: 'lpo',
    minWidth: 100,
    Cell: props => <span>{formatNumber(props.original.lpo || 0)}</span>,
    headerClassName: 'centeredColumnHeader',
  },
  {
    Header: () => (
      <span>
        <Popover
          text={
            'Missing Barrels = Production Capability - Downtime LPO - Constrained LPO - BOED'
          }
        >
          <img
            src={InfoIcon}
            className={'infoIconCalculation'}
            alt={
              'Missing Barrels = Production Capability - Downtime LPO - Constrained LPO - BOED'
            }
          />
        </Popover>
        Missing Barrels
      </span>
    ),
    accessor: 'unexplainedLPO',
    minWidth: 100,
    Cell: props => (
      <span>{formatNumber(props.original.unexplainedLPO || 0)}</span>
    ),
    headerClassName: 'centeredColumnHeader',
  },
  {
    Header: 'BOED 7-Day Avg.',
    accessor: 'boed._7DayAverage',
    minWidth: 120,
    Cell: props => (
      <span>{formatNumber(props.original.boed._7DayAverage)}</span>
    ),
    headerClassName: 'centeredColumnHeader',
  },
];
export const assetDashboardDifferenceColumns = setNewAsset => [
  {
    Header: 'Assets',
    accessor: 'assetName',
    minWidth: 150,
    Cell: props => (
      <span
        className={'clickableRouteWithFlex'}
        onClick={() => {
          setNewAsset(props.original.assetId);
        }}
      >
        <Popover text={props.original.assetName} overflow={true}>
          {props.original.assetName}
        </Popover>
      </span>
    ),
    headerClassName: 'columnHeader',
  },
  {
    Header: 'BOED',
    accessor: 'assetBOED.production',
    minWidth: 100,
    Cell: props => (
      <span>{formatNumber(props.original.assetBOED.production)}</span>
    ),
    headerClassName: 'centeredColumnHeader',
  },
  {
    Header: 'BOED Target',
    accessor: 'assetBOED.target',
    minWidth: 100,
    Cell: props => <span>{formatNumber(props.original.assetBOED.target)}</span>,
    headerClassName: 'centeredColumnHeader',
  },
  {
    Header: 'BOED Difference',
    accessor: 'assetBOED.difference',
    minWidth: 100,
    Cell: props => {
      const {
        original: {
          assetBOED: { difference },
        },
      } = props;
      return (
        <span
          className={
            difference > 0 ? 'greenDiff' : difference < 0 ? 'redDiff' : null
          }
        >
          {formatNumber(difference)}
        </span>
      );
    },
    headerClassName: 'centeredColumnHeader',
    defaultSortDesc: true,
    sortMethod: (a, b) => {
      return a - b;
    },
  },
  {
    Header: 'LPO',
    accessor: 'assetLPO',
    minWidth: 100,
    Cell: props => <span>{formatNumber(props.original.assetLPO || 0)}</span>,
    headerClassName: 'centeredColumnHeader',
  },
  {
    Header: () => (
      <span>
        <Popover
          text={
            'Missing Barrels = Production Capability - Downtime LPO - Constrained LPO - BOED '
          }
        >
          <img
            src={InfoIcon}
            className={'infoIconCalculation'}
            alt={
              'Missing Barrels = Production Capability - Downtime LPO - Constrained LPO - BOED'
            }
          />
        </Popover>
        Missing Barrels
      </span>
    ),
    accessor: 'assetUnexplainedLPO',
    minWidth: 100,
    Cell: props => (
      <span>{formatNumber(props.original.assetUnexplainedLPO || 0)}</span>
    ),
    headerClassName: 'centeredColumnHeader',
  },
  {
    Header: 'BOED 7-Day Avg.',
    accessor: 'assetBOED._7DayAverage',
    minWidth: 120,
    Cell: props => (
      <span>{formatNumber(props.original.assetBOED._7DayAverage)}</span>
    ),
    headerClassName: 'centeredColumnHeader',
  },
];

const routeOil = {
  Header: 'Oil',
  accessor: 'oil',
  minWidth: 100,
  Cell: props => <span>{formatNumber(props.original.oil)}</span>,
  headerClassName: 'centeredColumnHeader',
};
const routeGas = {
  Header: 'Gas',
  accessor: 'gas',
  minWidth: 100,
  Cell: props => <span>{formatNumber(props.original.gas)}</span>,
  headerClassName: 'centeredColumnHeader',
};
const routeWater = {
  Header: 'Water',
  accessor: 'water',
  minWidth: 100,
  Cell: props => <span>{formatNumber(props.original.water)}</span>,
  headerClassName: 'centeredColumnHeader',
};
const routeBoed = {
  Header: 'BOED',
  accessor: 'boed.production',
  minWidth: 100,
  Cell: props => <span>{formatNumber(props.original.boed.production)}</span>,
  headerClassName: 'centeredColumnHeader',
};
const dayDelta7 = {
  Header: '7-Day Delta',
  // BOED Measured - BOED 7 Day Average
  accessor: 'boed._7DayDelta',
  minWidth: 100,
  headerClassName: 'centeredColumnHeader',
  Cell: props => {
    const delta = props.original.boed._7DayDelta;
    return (
      <span className={delta > 0 ? 'greenDiff' : delta < 0 ? 'redDiff' : null}>
        {formatNumber(delta)}
      </span>
    );
  },
  defaultSortDesc: true,
  sortMethod: (a, b) => {
    return a - b;
  },
};
const boed7dayAvg = {
  Header: 'BOED 7-Day Avg.',
  accessor: 'boed._7DayAverage',
  minWidth: 120,
  headerClassName: 'centeredColumnHeader',
  Cell: props => <span>{formatNumber(props.original.boed._7DayAverage)}</span>,
};
const boed30dayAvg = {
  Header: 'BOED 30-Day Avg.',
  accessor: 'boed._30DayAverage',
  minWidth: 120,
  Cell: props => <span>{formatNumber(props.original.boed._30DayAverage)}</span>,
  headerClassName: 'centeredColumnHeader',
};
// Route Measured Production Columns. No actions necessary.
export const routeMeasuredProductionColumns = [
  {
    Header: 'Collection Point',
    accessor: 'name',
    minWidth: 150,
    headerClassName: 'columnHeader',
    Cell: props => (
      <span className={'flexSpan'}>
        <Popover text={props.original.name} overflow={true}>
          {props.original.name}
        </Popover>
      </span>
    ),
    defaultSortDesc: true,
  },
  routeOil,
  routeGas,
  routeWater,
  routeBoed,
  dayDelta7,
  boed7dayAvg,
  boed30dayAvg,
];

export const collectionPointProductionColumns = [
  {
    Header: 'Well Name',
    accessor: 'name',
    minWidth: 150,
    headerClassName: 'columnHeader',
    Cell: props => (
      <span className={'flexSpan'}>
        <Popover text={props.original.name} overflow={true}>
          {props.original.name}
        </Popover>
      </span>
    ),
    defaultSortDesc: true,
  },
  routeOil,
  routeGas,
  routeWater,
  routeBoed,
  dayDelta7,
  boed7dayAvg,
  boed30dayAvg,
];

export const routeAllocatedProductionColumns = setWellLevel => [
  {
    Header: 'Collection Point',
    accessor: 'name',
    minWidth: 150,
    headerClassName: 'columnHeader',
    Cell: props => (
      <span
        className={classnames('clickableRouteWithFlex','flexSpan')}
        onClick={() => {
          setWellLevel(props.original.collectionPointId);
        }}
      >
        <Popover text={props.original.name} overflow={true}>
          {props.original.name}
        </Popover>
      </span>
    ),
    defaultSortDesc: true,
  },
  routeOil,
  routeGas,
  routeWater,
  routeBoed,
  dayDelta7,
  boed7dayAvg,
  boed30dayAvg,
];
