import React, { Component } from 'react';
import styles from './styles.module.css';
import { connect } from 'react-redux';
import {
  getSummary,
  getSummaryChart,
  getSummaryTable,
  selectTargetType,
  setIsRouteTarget
} from 'store/reducers/production';
import SelectInput from 'components/common/SelectInput';
import {targetOptions } from 'constants/productionManagement';

const mapStateToProps = state => ({
  isDefaultTargetRoute: selectTargetType(state)
});

const mapDispatchToProps = {
  setIsRouteTarget,
  getSummary,
  getSummaryTable,
  getSummaryChart
};

export class TargetToggle extends Component {
 
  render() {
    const {
      setIsRouteTarget,
      getSummary,
      getSummaryTable,
      getSummaryChart,
      isDefaultTargetRoute,
    } = this.props;
    return (
      <div className={styles.targetToggleComponent}>
        <span className={styles.label}>Target</span>
        <SelectInput
              options={targetOptions}
              overrideStyles={styles.selectContainerSmall}
              resetAction={() => {return;}}
              setAction={type => {
                setIsRouteTarget(type);
                getSummary();
                getSummaryTable();
                getSummaryChart();
              
            }}
              showPlaceholder={false}
              placeholderText={'Target'}
              value={isDefaultTargetRoute}
              disableReset={true}
              label={'Target'}
            />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TargetToggle);
