import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from 'components/AdminPage/styles.module.css';
import { AddLink } from 'components/AdminPage/AddLink';
import CheckboxInput from 'components/common/CheckBoxInput';
import { ModalRadioInput } from 'components/common/ModalFormItems/RadioInput';
import { purgeCache } from 'store/reducers/cache';
import {
  deleteStandardControl,
  editStandardControl,
  addStandardControl,
  turnOffDeleteModal,
  selectPillarOptions,
  selectMultiRoleOptions,
  selectAssetOptions,
  selectShowDeleteModal,
  toggleDeleteModal,
} from 'store/reducers/admin';
import { selectUserInfo } from 'store/reducers/users';
import { connect } from 'react-redux';
import { ModalTextInput } from 'components/common/ModalFormItems/TextInput';
import { ModalTextAreaInput } from 'components/common/ModalFormItems/TextAreaInput';
import ButtonArea from 'components/AdminPage/ButtonArea';
import ReactSelectInput from 'components/common/ModalFormItems/ReactSelectInput';
import ReactSelectInputSingle from 'components/common/ModalFormItems/ReactSelectInputSingle';
import { isValid } from 'utils/validation';
import { frequencyConstants, frequencyOptions } from 'constants/admin';
import { ModalDatePicker } from 'components/common/ModalFormItems/DatePicker';
import moment from 'moment';
import DeleteModal from 'components/common/DeleteModal';

const mapStateToProps = state => ({
  pillarOptions: selectPillarOptions(state),
  roleOptions: selectMultiRoleOptions(state),
  assetOptions: selectAssetOptions(state),
  showModal: selectShowDeleteModal(state),
  userAssets: selectUserInfo(state),
});

const mapDispatchToProps = {
  editStandardControl,
  addStandardControl,
  deleteStandardControl,
  turnOffDeleteModal,
  purgeCache,
  toggleDeleteModal,
};

const disableDueDate = selectedFrequency => {
  const { OneTime } = frequencyConstants;

  switch (selectedFrequency.value) {
    case OneTime:
      return false;
    default:
      return true;
  }
};

const getSlicedUrl = link => {
  let linkEl = link;
  if (linkEl.startsWith('//')) {
    linkEl = linkEl.slice(2);
  }
  return linkEl;
}
const disableIsCustomControl = customvalue => {
  switch (customvalue) {
    case null:
      return false;
    case undefined:
      return false;
    default:
      return true;
  }
};
export class TaskModal extends Component {
  constructor(props) {
    super(props);
    let active = true;
    let pillarId = '';
    let description = '';
    let assetopt = [];
    let frequency = '';
    let roles = [];
    let name = '';
    let dueDate = '';
    let dueDateReord = '';
    let links = [];
    let isCustomControl = false;

    //TODO:  The API data was revised to return just the role title as "roleTitle" vs roles as []
    //       This was updated in Layout>Sidebar>User. Admin currently is inactive and was therefore not
    //       updated to reflect. When Admin again becomes active, this must be updated/revised accordingly.
    let frequencyLabel = '';
    if (this.props.record) {
      switch (this.props.record.frequency) {
        case 'monthly':
          frequencyLabel = 'Monthly';
          break;
        case 'weekly':
          frequencyLabel = 'Weekly';
          break;
        case 'quarterly':
          frequencyLabel = 'Quarterly';
          break;
        case 'semi-annually':
          frequencyLabel = 'Semi-Annually';
          break;
        case 'yearly':
          frequencyLabel = 'Yearly';
          break;
        case 'bi-monthly':
          frequencyLabel = 'Bi-Monthly';
          break;
        case 'bi-weekly':
          frequencyLabel = 'Bi-Weekly';
          break;
        case 'one-time':
          frequencyLabel = 'One Time';
          break;
        default:
          
      }
    }
    if (this.props.record) {
      pillarId = {
        label: this.props.record.pillarName,
        value: this.props.record.pillarId,
      };
      roles = this.props.record.roles.map(role => {
        return {
          label: role.title,
          value: role.roleId,
        };
      });
      description = this.props.record.description;
      active = this.props.record.isActive;
      frequency = {
        label: frequencyLabel,
        value: this.props.record.frequency,
      };
      name = this.props.record.title;
      dueDateReord =
        this.props.record.frequency === 'one-time'
          ? this.props.record.specifiedDueDate
          : '';
      dueDate = dueDateReord;
      isCustomControl = !this.props.record.isStandardControl;
      assetopt = this.props.record.assets.map(asset => {
        return {
          label: asset.assetName,
          value: asset.iDAPAssetId,
        };
      });
      links = this.props.record.references.map(link => {
        if (link.url !== '' && link.name === '') {
          link.type = link.url;
        }
        return {
          url: link.url,
          type: link.type,
          name: link.name,
          referenceId: link.referenceId,
        };
      });
      links.forEach(function (obj) {
        obj.url = getSlicedUrl(obj.url);
        obj.type = getSlicedUrl(obj.type);
      });
    }
    this.state = {
      formItems: {
        pillar: {
          value: pillarId,
          pillarId: pillarId,
          errorText: '',
          valid: pillarId !== '',
          key: 'pillar',
          required: true,
        },
        role: {
          value: roles,
          errorText: '',
          valid: roles && roles.length > 0,
          key: 'role',
          required: true,
        },
        description: {
          value: description,
          errorText: '',
          valid: description !== '',
          key: 'description',
          required: true,
        },
        active: {
          value: active,
          valid: true,
          key: 'active',
          required: false,
        },
        isCustomControl: {
          value: isCustomControl,
          valid: true,
          key: 'isCustomControl',
          required: false,
        },
        frequency: {
          value: frequency,
          errorText: '',
          valid: frequency !== '',
          key: 'frequency',
          required: true,
        },
        name: {
          value: name,
          errorText: '',
          valid: name !== '',
          key: 'name',
          required: true,
        },
        dueDate: {
          value: dueDate,
          errorText: '',
          valid: dueDate !== '',
          key: 'dueDate',
          required: false,
        },
        assetopt: {
          value: assetopt,
          errorText: '',
          valid: assetopt && assetopt.length > 0,
          key: 'assetopt',
          required: false,
        },
        links: {
          value: links,
          required: false,
          errorText: '',
          valid: links && links.length > 0,
          key: 'links',
        },
      },
      dirty: false,
    };
  }

  setInputValue = (formInput, value, isPositiveNumber) => {
    if (this.state.formItems[formInput].key === 'frequency') {
      const { required } = this.state.formItems[formInput].value;
      const valid = isValid(value.value);
      if (value.value === 'one-time') {
        this.setState({
          formItems: {
            ...this.state.formItems,
            [formInput]: {
              ...this.state.formItems[formInput],
              value,
              valid,
              errorText: valid || !required ? '' : 'required',
            },
            dueDate: {
              ...this.state.formItems['dueDate'],
              required: true,
              valid: false,
            },
          },
        });
      } else {
        this.setState({
          formItems: {
            ...this.state.formItems,
            [formInput]: {
              ...this.state.formItems[formInput],
              value,
              valid,
              errorText: valid || !required ? '' : 'required',
            },
            dueDate: {
              ...this.state.formItems['dueDate'],
              value: '',
              required: false,
              valid: true,
              errorText: '',
            },
          },
          dirty: true,
        });
      }
    } else if (this.state.formItems[formInput].key === 'pillar') {
      const app = this.state.formItems[formInput].value.toString();
      const { required } = app;
      const valid = isValid(value.value.toString());
      this.setState({
        formItems: {
          ...this.state.formItems,
          [formInput]: {
            ...this.state.formItems[formInput],
            value,
            valid,
            errorText: valid || !required ? '' : 'required',
          },
        },
        dirty: true,
      });
    } else {
      const { required } = this.state.formItems[formInput];
      const valid = isValid(value, isPositiveNumber);
      const errorText = valid || !required ? '' : 'required';

      this.setState({
        formItems: {
          ...this.state.formItems,
          [formInput]: {
            ...this.state.formItems[formInput],
            value,
            valid,
            errorText,
          },
        },
        dirty: true,
      });
    }
  };

  saveAttempted = invalidFormItems => {
    const stateCopy = this.state.formItems;

    invalidFormItems.forEach(({ key }) => {
      stateCopy[key] = { ...stateCopy[key], errorText: 'required' };
    });
    this.setState({
      formItems: stateCopy,
    });
  };

  handleChange = (links, isPositiveNumber) => {
    let { required } = this.state.formItems['links'];
    let valid = isValid(links, isPositiveNumber);
    let errorText = valid || !required ? '' : 'required';
    const linkValid = links.map(k => {
      if (k.type !== '' && k.url === '') {
        return true;
      } else if (k.type === '' && k.url !== '') {
        return true;
      } else if (k.type === '' && k.url === '') {
        return false;
      } else {
        return false;
      }
    });

    if (linkValid.includes(true)) {
      this.setState({
        formItems: {
          ...this.state.formItems,
          links: {
            ...this.state.formItems.links,
            value: links,
            errorText: 'required',
            valid: false,
            required: true,
          },
          dirty: true,
        },
      });
    } else {
      this.setState({
        formItems: {
          ...this.state.formItems,
          links: {
            ...this.state.formItems.links,
            value: links,
            valid,
            errorText,
            required,
          },
          dirty: true,
        },
      });
    }
  };

  deleteData = () => {
    const deleteRecordData = this.props.record.taskTypeId;
    this.props.turnOffDeleteModal();
    this.props.deleteStandardControl(deleteRecordData);
  };
  saveTask = () => {
    const {
      active,
      pillar,
      description,
      frequency,
      name,
      role,
      dueDate,
      assetopt,
      links,
      isCustomControl,
    } = this.state.formItems;
    links.value.map(link => {
      return (
        (link.name = link.type),
        link.url,
        link.type,
        (link.referenceId = link.referenceId ? link.referenceId : 0)
      );
    });

    links.value.forEach(function (obj) {
      if (!obj.url.includes('//')) {
        obj.url = '//' + obj.url;
      }
    });

    const formBody = {
      isActive: active.value,
      title: name.value,
      description: description.value,
      pillarId: parseInt(pillar.value.value),
      frequency: frequency.value.value,
      references: links.value,
      assets:[],
      roles: role.value.map(roleEmement => parseInt(roleEmement.value)),
    };
    if (assetopt.value && assetopt.value.length > 0) {
      formBody.assets = assetopt.value.map(asset => asset.value);
    } else {
      formBody.assets = [];
    }

    if (
      dueDate.value &&
      dueDate.value !== '' &&
      !disableDueDate(frequency.value)
    ) {
      formBody.dueDate = moment(new Date(dueDate.value)).format(moment.HTML5_FMT.DATE);
    }
    const customvalue = isCustomControl.value;
    const taskId = this.props.record ? this.props.record.taskTypeId : '';
    this.props.record
      ? this.props.editStandardControl({
          taskTypeId: taskId,
          isCustomControl: customvalue,
          formBody,
        })
      : this.props.addStandardControl({
          isCustomControl: customvalue,
          formBody,
        });
  };

  render() {
    const {
      active,
      description,
      name,
      role,
      dueDate,
      assetopt,
      links,
      isCustomControl,
      frequency,
      pillar,
    } = this.state.formItems;

    const {
      pillarOptions,
      roleOptions,
      assetOptions,
      showModal
    } = this.props;
    const pillarOption = pillarOptions.map(pillarElement => {
      return {
        label: pillarElement.name,
        value: pillarElement.pillarId,
      };
    });
    const frequencyOptionList = frequencyOptions.map(a => {
      return {
        label: a.name,
        value: a.value,
      };
    });
    const controlOptions = [
      {
        name: 'Standard Control',
        value: false,
      },
      {
        name: 'Custom Control',
        value: true,
      },
    ];
    return (
      <Fragment>
        <div className={styles.flexContainer}>
          <div className={styles.maxheight}>
            <div className= {styles.radioContainerTaskModal}>
            <ModalRadioInput
              setAction={this.setInputValue}
              disabled={disableIsCustomControl(this.props.record)}
              label={'Control Type:'}
              resetAction={() => {return;}}
              options={controlOptions}
              state={isCustomControl}
              stateKey={'isCustomControl'}
              fullWidth={true}
            />
            </div>
          
            <ModalTextInput
              state={name}
              label={'Control title:'}
              setAction={this.setInputValue}
              stateKey={'name'}
              placeholder={'Enter control task title'}
              fullWidth={true}
            />

            <ModalTextAreaInput
              state={description}
              label={'Control Description:'}
              setAction={this.setInputValue}
              stateKey={'description'}
              fullWidth={true}
              placeholder={'Enter control task  description'}
            />

            <ReactSelectInput
              state={role}
              label={'Link to Role (s):'}
              resetAction={() => {return;}}
              setAction={this.setInputValue}
              stateKey={'role'}
              options={roleOptions}
              placeholderText={'Select role'}
              fullWidth={true}
            />

            <AddLink
              onHandleChange={this.handleChange}
              state={links}
              stateKey={'links'}
              linkdetails={links}
            />

            <div className={styles.flexContainer}>
              <ReactSelectInputSingle
                state={pillar}
                label={'Pillar:'}
                resetAction={() => {return;}}
                setAction={this.setInputValue}
                stateKey={'pillar'}
                options={pillarOption}
                placeholderText={'Select pillar'}
                oneThird={true}
              />
              <ReactSelectInputSingle
                state={frequency}
                label={'Control Frequency:'}
                resetAction={() => {return}}
                setAction={this.setInputValue}
                stateKey={'frequency'}
                oneThird={true}
                options={frequencyOptionList}
                placeholderText={'Select frequency'}
              />
              <ModalDatePicker
                state={dueDate}
                label={'Control Due Date:'}
                setAction={this.setInputValue}
                stateKey={'dueDate'}
                disabled={disableDueDate(frequency.value)}
                oneThird={true}
              />
            </div>
            {isCustomControl.value && (
              <ReactSelectInput
                state={assetopt}
                label={'Link to Asset (s):'}
                resetAction={() => {return;}}
                setAction={this.setInputValue}
                stateKey={'assetopt'}
                options={assetOptions}
                placeholderText={'Select assets..'}
                fullWidth={true}
              />
            )}

            <div className={styles.checkBoxesFull}>
              <CheckboxInput
                toggle={event =>
                  this.setInputValue('active', event.target.checked)
                }
                checked={active.value}
                text={'Task is active'}
                disabled={false}
              />
            </div>

            <div className={styles.buttonContainer}>
              <ButtonArea
                saveAction={this.saveTask}
                formItems={this.state.formItems}
                dirty={this.state.dirty}
                saveAttempted={this.saveAttempted}
              />

              {this.props.record && (
                <button
                  onClick={() => this.props.toggleDeleteModal()}
                  className={styles.deleteBtn}
                >
                  delete
                </button>
              )}
            </div>
          </div>
        </div>
        {showModal && (
          <DeleteModal
            deleteData={this.deleteData}
            onCloseClick={() => this.props.turnOffDeleteModal()}
            title='control'
          />
        )}
      </Fragment>
    );
  }
}

TaskModal.propTypes = {
  pillarOptions: PropTypes.array.isRequired,
  assetOptions: PropTypes.array.isRequired,
  record: PropTypes.object,
  roleOptions: PropTypes.array.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskModal);
