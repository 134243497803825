import React from 'react';
import PropTypes from 'prop-types';
import RadioInput from 'components/common/RadioInput';
import styles from 'components/AdminPage/styles.module.css';

export const ModalRadioInput = ({
    disabled,
    label,
    options,
    setAction,
    state,
    stateKey,
    fullWidth,
    justifyContent
}) => {
    const { errorText, text, valid, value } = state;
     return (
      <div className={!fullWidth ? styles.basis : justifyContent === 'noSpace' ? styles.fullBasisRadioNoSpace: styles.fullBasisRadio}>
        <label className={styles.radiolabel}>
          {label}
          {!valid && <span className={styles.error}>{errorText}</span>}
        </label>
        <RadioInput
        setAction={value => setAction(stateKey, value)}
        options={options ? options : [text]}
        label={label}
        value={value}
         resetAction={() => {
          return;
        }}
        overrideStyles={ styles.radiodiv }
        disabled={disabled}
       />
      </div>
    );
  };
  
  ModalRadioInput.propTypes = {
    disabled: PropTypes.bool,
    label: PropTypes.string.isRequired,
    options: PropTypes.array,
    setAction: PropTypes.func.isRequired,
    state: PropTypes.object.isRequired,
    stateKey: PropTypes.string.isRequired
  };
  