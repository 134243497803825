import React, { Component } from 'react';
import styles from './styles.module.css';
import { connect } from 'react-redux';
import { requestStatuses, summaryTypes } from 'constants/values';
import { selectShouldLoadData } from 'store/reducers/production';
import {
  selectCurrentAssetIdException,
  selectIsAssetOrRoute,
  selectCurrentAssetException,
  setAssetExceptionLPO,
  selectCurrentRouteNameLPO,
} from 'store/reducers/navigation';
import {
  getExceptionDowntimeGeneralInfo,
  getExceptionDowntimeTable,
  getExceptionDowntimeThresholds,
  resetThresholdAdjustRequestStatus,
  selectCurrentPrimaryDowntimeCard,
  selectDowntimeGeneralInfoRequestStatus,
  selectShowThresholdAdjustModal,
  selectThresholdRequestStatus,
  setNewThresholdCategoryValue,
  toggleThresholdAdjustModal,
} from 'store/reducers/exception';
import { thresholdModalTitle } from 'constants/exceptionManagement.js';
import DowntimeCardTable from './DowntimeCardTable';
import Modal from 'components/common/Modal';
import SuccessModal from 'components/common/SuccessModal';
import ThresholdAdjustForm from './ThresholdAdjustForm';
import gear from 'assets/img/gear-blue.svg';
import DowntimeExportCSV from './DowntimeExportCSV';
import spinner from 'assets/img/spinner.gif';

const mapStateToProps = state => ({
  currentPrimaryDowntimeCard: selectCurrentPrimaryDowntimeCard(state),
  downtimeInfoRequestStatus: selectDowntimeGeneralInfoRequestStatus(state),
  selectedProductionAsset: selectCurrentAssetIdException(state),
  shouldLoadData: selectShouldLoadData(state),
  showThresholdAdjustModal: selectShowThresholdAdjustModal(state),
  thresholdRequestStatus: selectThresholdRequestStatus(state),
  assetOrRoute: selectIsAssetOrRoute(state),
  assetName: selectCurrentAssetException(state),
  routeName: selectCurrentRouteNameLPO(state),
});

const mapDispatchToProps = {
  getExceptionDowntimeGeneralInfo,
  getExceptionDowntimeTable,
  getExceptionDowntimeThresholds,
  resetThresholdAdjustRequestStatus,
  setNewThresholdCategoryValue,
  setAssetExceptionLPO,
  toggleThresholdAdjustModal,
};

const modalSuccessTitle = 'Successfully saved!';

export class Downtime extends Component {
  state = {
    loaded: false,
  };

  componentDidMount = () => {
    const { shouldLoadData } = this.props;
    if (shouldLoadData && !this.state.loaded) {
      //  this.props.getExceptionDowntimeTable();
      this.props.getExceptionDowntimeGeneralInfo();
      this.props.getExceptionDowntimeThresholds();
      this.setState({ loaded: true });
    }
  };

  componentDidUpdate = () => {
    if (this.props.shouldLoadData && !this.state.loaded) {
      this.props.getExceptionDowntimeGeneralInfo();
      this.props.getExceptionDowntimeThresholds();
      // this.props.getExceptionDowntimeTable();
      this.setState({ loaded: true });
    }
  };

  render() {
    const {
      resetThresholdAdjustRequestStatus,
      showThresholdAdjustModal,
      thresholdRequestStatus,
      toggleThresholdAdjustModal,
      selectedProductionAsset,
      assetOrRoute,
      assetName,
      routeName,
    } = this.props;
    return (
      <div className={styles.layout}>
        <div className={styles.titleRow}>
          {assetOrRoute === summaryTypes.asset ? (
            <div className={styles.titleAsset}>
              <div className={styles.alignStart}>
                <span className={styles.name}>{assetName}</span>
              </div>

              <div className={styles.alignEnd}>
                <div
                  className={styles.iconContainer}
                  onClick={toggleThresholdAdjustModal}
                >
                  adjust exceptions thresholds
                  <img className={styles.img} alt={'gear'} src={gear} />
                </div>

                <DowntimeExportCSV />
              </div>
            </div>
          ) : (
            <div>
              <div
                className={styles.titleRowLink}
                onClick={() => {
                  this.props.setAssetExceptionLPO(selectedProductionAsset);
                  this.props.getExceptionDowntimeGeneralInfo();
                  this.props.getExceptionDowntimeTable();
                }}
              >
                {!!routeName && assetName}
              </div>
              <div className={styles.titleRoute}>
                <div className={styles.alignStart}>
                  <span className={styles.name}>
                    {routeName ? routeName : assetName}
                  </span>
                </div>

                <div className={styles.alignEndRoute}>
                  <div
                    className={styles.iconContainer}
                    onClick={toggleThresholdAdjustModal}
                  >
                    adjust exception thresholds
                    <img className={styles.img} alt={'gear'} src={gear} />
                  </div>

                  <DowntimeExportCSV />
                </div>
              </div>
            </div>
          )}
        </div>
        <DowntimeCardTable />

        {showThresholdAdjustModal && (
          <Modal
            title={thresholdModalTitle}
            onCloseClick={toggleThresholdAdjustModal}
            size={'modalContainerThresholdAdjust'}
          >
            <ThresholdAdjustForm />
          </Modal>
        )}

        {thresholdRequestStatus === requestStatuses.pending && (
          <Modal onCloseClick={() => {return;}} size={'pendingMessage'}>
            <div>
              <img
                src={spinner}
                alt="loading spinner"
                width="100px"
                height="100px"
              />
            </div>
          </Modal>
        )}

        {thresholdRequestStatus === requestStatuses.success && (
          <SuccessModal
            title={modalSuccessTitle}
            toggleModal={resetThresholdAdjustRequestStatus}
          />
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Downtime);
