import moment from 'moment';
import { controlStatuses } from 'constants/controls';

export const checkOverdueStatus = (occurrence, year, month) => {
  // Overdue failsafe check
  const CURRENT_YEAR = moment().year();
  const CURRENT_MONTH = moment().month();
  // First, check if we're current month and year
  if (year === CURRENT_YEAR && month === CURRENT_MONTH) {
    // Then check if the isOverdue flag is true and the status is complete or if it is the day after the due date
    const dueDate = moment(occurrence.dueDate);
    const currentDate = moment();
    const isPastDueDate = dueDate.add(1, 'd');
    const isDayAfterDueDate = isPastDueDate < currentDate;
    return (occurrence.isOverdue && (occurrence.status !== controlStatuses.complete)) ?
      controlStatuses.overdue :
      isDayAfterDueDate ?
        controlStatuses.overdue : occurrence.status;
  } else {
    return occurrence.status === controlStatuses.complete ? controlStatuses.complete : controlStatuses.overdue;
  }
};
