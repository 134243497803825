import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';
import DatePicker from 'react-datepicker';
import classnames from 'classnames';
import 'react-datepicker/dist/react-datepicker.css';
import calendar from 'assets/img/icon-calendar.svg';
import calender_disabled from 'assets/img/calender_disabled.svg';
import moment from 'moment';

const placeholderDate = 'MM / DD / YYYY';

class DateInput extends Component {
  componentWillUnmount = () => {
    this.props.resetAction();
  };

  determineDate = date => {
    let dateObj = new Date(date);

    if (!isNaN(dateObj)) {
      return dateObj;
    }
  };

  onChange = date => {
    const newDate = this.determineDate(date);

    const isInDateRange = moment(newDate).isBetween(
      moment().subtract(10, 'years'),
      moment().add(10, 'years')
    );

    if (newDate && isInDateRange) {
      this.props.setAction(newDate.toISOString());
    }
  };

  render = () => {
    const { disabled, overrideStyles, value } = this.props;
    const img = disabled ? (<img className={styles.calendarImg} src={calender_disabled} alt="cal" />):
          (<img className={styles.calendarImg} src={calendar} alt="cal" />);
    return (
      <div className={classnames(overrideStyles, styles.inputWrapper)}>
        
        <DatePicker
          className={classnames(styles.date, { [styles.disabled]: disabled })}
          disabled={disabled}
          popperPlacement={this.props.top ? 'top' : 'bottom'}
          popperModifiers={{
            flip: {
              behavior: ['bottom']
            },
            preventOverflow: {
              enabled: false
            },
            hide: {
              enabled: false
            }
          }}
          required={true}
          selected={this.determineDate(value)}
          onChange={this.onChange}
          placeholderText={placeholderDate}
          minDate={moment().toDate()}
          onClickOutside={() => this.props.setAction('')}
        />
        <div className={styles.calendarIconWrapper}>
          {img}
        </div>
      </div>
    );
  };
}

DateInput.propTypes = {
  disabled: PropTypes.bool,
  overrideStyles: PropTypes.string,
  resetAction: PropTypes.func.isRequired,
  setAction: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  top: PropTypes.bool
};

export default DateInput;
