import types from 'constants/actionTypes';
import { appInsights } from 'App';
import { aiEvents } from 'constants/aiEvents';

export const initialState = {
  userInfo: {
    admin: false,
    assetName: '',
    userAssets: [],
    firstName: '',
    lastName: '',
    pillars: [],
    roleTitle: '',
  },
  isGuestUser: true,
  isReadOnlyUser: true,
  isSuperIntendent: false,
};

export const users = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_USER_INFO_REQUEST:
      return { ...state };
    case types.GET_USER_INFO_REQUEST_SUCCESS:
      appInsights.clearAuthenticatedUserContext();
      const telemetryInitializer = () => {
        appInsights.setAuthenticatedUserContext(payload.email);
        appInsights.addTelemetryInitializer(envelope => {
          const tags = envelope.tags;
          if (tags && tags['ai.user.authUserId']) {
            tags['ai.user.id'] = tags['ai.user.authUserId'];
          } else {
            tags['ai.user.id'] = payload.email;
          }
        });
      };
      if (appInsights.queue !== undefined) {
        appInsights.queue.push(telemetryInitializer);
      } else {
        telemetryInitializer();
      }
      appInsights.trackEvent({ name: aiEvents.userAuthSuccess });
      return {
        ...state,
        userInfo: payload,
        isSuperIntendent: payload.isSuperIntendent,
        isGuestUser: payload.isGuestUser,
        isReadOnlyUser: payload.isReadOnlyUser,
      };
    case types.GET_USER_INFO_REQUEST_FAILURE:
      appInsights.trackEvent({ name: aiEvents.userAuthFailure });
      return { ...state };
    default:
      return state;
  }
};

export default users;
export * from './actions';
export * from './selectors';
