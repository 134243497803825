import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const NoContent = ({ containerStyle, type, time }) => (
  <div className={`${containerStyle} ${styles.status}`}>
    You have no {!!type ? type.toLowerCase() : ''}{' '}
    {!!time ? time.toLowerCase() : ''}
  </div>
);

NoContent.propTypes = {
  containerStyle: PropTypes.string,
  type: PropTypes.string.isRequired,
  time: PropTypes.string
};

export default NoContent;
