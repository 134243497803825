import _ from 'lodash';
/* IMPORTANT: If you add/remove/change any actions for the entire app you must
update this types file. Keep each section alphabetical for organization.

Lodash is being used to include a _SUCCESS or _FAILURE version of each action
that ends in _REQUEST. This is very important for API calls. If the action isn't
a request, don't add _REQUEST as the end of the action.
*/
export default [
  // ADMIN
  'ADMIN_TOOL_REQUEST',
  'RESET_ADD_ROLE_REQUEST',
  'GET_SUPERITENDENT_LIST_REQUEST',
  'TOGGLE_EC_COMMENT_MODAL',
  'GET_ASSET_OPTIONS_REQUEST',
  'GET_CONTROL_OPTIONS_REQUEST',
  'TURN_OFF_DELETE_MODAL',
  'DELETE_STANDARD_CONTROL_REQUEST',
  'TOGGLE_DELETE_MODAL',
  'ADD_ROLE_REQUEST',
  'DELETE_ROLE_REQUEST',
  'EDIT_ROLE_REQUEST',
  'GET_DATA_REQUEST',
  'GET_PILLAR_OPTIONS_REQUEST',
  'GET_ROLE_OPTIONS_REQUEST',
  'RESET_ADMIN_TOOL_REQUEST_STATUS',
  'SET_ACTIVE_TOOL',
  'SET_ACTIVE_CARD',
  'TOGGLE_MODAL',
  'TURN_OFF_MODAL',
  'EDIT_DOWNTIME_CODES_REQUEST',
  // USERS
  'GET_USER_INFO_REQUEST',
  // METRICS
  'SET_POWERBI_ACCESS_TOKEN',
  // NAVIGATION
  'GET_ASSET_HIERARCHY_REQUEST',
  'GET_ROLE_HIERARCHY_REQUEST',
  'SET_ASSET',
  'SET_ROUTE',
  'SET_COLLECTION_POINT',
  'TOGGLE_FEEDBACK_MODAL',
  'SET_DEFAULT_FLS_ROLE',
  // CONTROLS
  'TOGGLE_FORM_CONTROL_MODAL',
  'TOGGLE_ADD_CONTROL_MODAL',
  'RESET_CONTROL_TOOL_REQUEST_STATUS',
  'EDIT_CONTROL_ITEM_REQUEST',
  'TOGGLE_DELETE_CONTROL_MODAL',
  'TURN_OFF_DELETE_CONTROL_MODAL',
  'DELETE_CONTROL_ITEM_REQUEST',
  'CHANGE_OCCURRENCE_STATUS_REQUEST',
  'GET_CONTROL_ITEMS_REQUEST',
  'INIT_PILLAR_COUNTS',
  'SET_CONTROL_TIME_PERIOD',
  'SET_CONTROL_REPORT_ASSET_OPTION',
  'SET_CONTROL_REPORT_ROLE_OPTION',
  'CREATE_CUSTOM_CONTROL_REQUEST',
  'DIRECT_REPORT_CHECKBOX',
  // FEEDBACK
  'ADD_FEEDBACK_RANK_REQUEST',
  'GET_FEEDBACK_REQUEST',
  'POST_FEEDBACK_REQUEST',
  'RESET_EDIT_CONTROL_REQUEST_STATUS',
  'RESET_ADD_CONTROL_REQUEST_STATUS',
  'ADD_STANDARD_CONTROL_REQUEST',
  'EDIT_STANDARD_CONTROL_REQUEST',
  'RESET_FEEDBACK_REQUEST_STATUS',
  // COMMENTS
  'ADD_COMMENT_REQUEST',
  'DELETE_COMMENT_REQUEST',
  'RESET_COMMENT_REQUEST_STATUS',
  // PRODUCTION
  'GET_PRODUCTION_LPO_EVENTS_INFO_REQUEST',
  'GET_PRODUCTION_LPO_EVENTS_REQUEST',
  'GET_PRODUCTION_SUMMARY_REQUEST',
  'GET_SUMMARY_CHART_DATA_REQUEST',
  'GET_SUMMARY_TABLE_DATA_REQUEST',
  'SET_DASHBOARD_MULTI_OPTION',
  'MODAL_PAGE_SWAP_LEFT',
  'MODAL_PAGE_SWAP_RIGHT',
  'POST_ASSET_ADJUST_TARGET_REQUEST',
  'RESET_ASSET_TARGET_MODAL_STATE',
  'RESET_INVALID_ROUTE_TARGET',
  'RESET_TABLE_TARGET_REQUEST_STATUS',
  'RESET_TARGET_REQUEST_STATUS',
  'SAVE_TABLE_TARGETS_REQUEST',
  'SET_CURRENT_LPO_EVENTS_CARD',
  'SET_CURRENT_LPO_EVENT_DETAILS',
  'SET_CURRENT_SUMMARY_CARD',
  'SET_NEW_TARGET_VALUES',
  'SET_PRODUCTION_DATE',
  'SET_TARGET_TYPE',
  'SET_COLLECTION_POINT',
  'SET_PRODUCTION_DATE_ALLOCATED',
  'SET_SUMMARY_TABLE_RESOLVED_DATA',
  'SET_TARGET_HISTORY_DAYS_AGO',
  'TOGGLE_CUSTOM_TARGET_MODAL',
  'TOGGLE_LPO_EVENT_DETAILS_MODAL',
  'TOGGLE_LPO_TABLE_FILTER_MODAL',
  'TOGGLE_TARGET_HISTORY_TABLE',
  'TOGGLE_TARGET_TABLE_EDITING',
  'TOGGLE_ASSET_FILTER_MODAL_PM',
  'UPDATE_FILTERED_LPO_TABLE',
  'SET_CUSTOM_TARGET_ASSET',
  'UPDATE_TABLE_TARGET',
  'SET_ASSET_LPO_EXCEPTION',
  'SET_ROUTE_LPO_EXCEPTION',
  'SET_HIERARCHY',
  'SET_IS_ALLOCATED_PRODUCTION',
  'GET_ROUTE_LEVEL_TARGETS_REQUEST',
  'TOGGLE_CUSTOM_ROUTE_TARGET_MODAL',
  'SET_ROUTE_TARGET_DETAILS',
  // EXCEPTIONS
  'GET_EXCEPTION_DOWNTIME_GENERAL_INFO_REQUEST',
  'GET_EXCEPTION_DOWNTIME_TABLE_REQUEST',
  'GET_EXCEPTION_DOWNTIME_THRESHOLDS_REQUEST',
  'PUT_THRESHOLD_ADJUST_REQUEST',
  'RESET_THRESHOLD_ADJUST_REQUEST_STATUS',
  'SET_EXCEPTION_PRIMARY_DOWNTIME_CARD',
  'SET_EXCEPTION_SECONDARY_DOWNTIME_CARD',
  'SET_NEW_THRESHOLD_CATEGORY_VALUE',
  'SET_NEW_THRESHOLD_VALUES',
  'TOGGLE_THRESHOLD_ADJUST_MODAL',
  'SET_NEW_TIME_VALUE',
  // FORM MODAL
  'INIT_FORM_MODAL_STATE',
  'GENERATE_FORM_FIELD_STATE',
  'FORM_FIELD_UPDATE',
  'SUBMIT_FORM_MODAL_REQUEST',
  'RESET_FORM_MODAL_STATUS',
  // CACHE
  'CACHE_RESPONSE',
  'PURGE_CACHE',
  'GET_ASSET_LEVEL_TARGETS_REQUEST',
  'SET_ASSET_TARGET_DETAILS',
  'SET_ASSET_TARGET_POSTSTATUS',
  'RESET_ASSET_TARGET_POSTSTATUS',
  'RESET_ROUTE_TARGET_POSTSTATUS',
  'SET_ROUTE_TARGET_POSTSTATUS',
].reduce((types, type) => {
  if (_.endsWith(type, 'REQUEST')) {
    const failureType = `${type}_FAILURE`;
    const successType = `${type}_SUCCESS`;
    return {
      ...types,
      [type]: type,
      [failureType]: failureType,
      [successType]: successType,
    };
  }

  return { ...types, [type]: type };
}, {});
