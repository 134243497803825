import React from 'react';
import { lpoEventDetailModalLabels, witScheduleStatus } from 'constants/productionManagement';
import styles from './styles.module.css';
// import { formatNumber } from 'utils/formatNumber';
import { connect } from 'react-redux';
import {
  selectCurrentLpoEventDetails,
  toggleLpoEventDetailsModal,
  selectCurrentModalPageIndex
} from 'store/reducers/production';
import moment from 'moment';
import {getCurrentWitIndex} from 'utils/lpoEventDetails';
import StepComponent from 'components/common/StepComponent';
import { witStatusSteps } from 'constants/statusSteps';

const labels = lpoEventDetailModalLabels;

const mapStateToProps = state => ({
  event: selectCurrentLpoEventDetails(state),
  currentWitIndex: selectCurrentModalPageIndex(state),
});

const mapDispatchToProps = {
  toggleLpoEventDetailsModal,
};

export const WitDetailModal = ({ toggleLpoEventDetailsModal, event, currentWitIndex }) => {
  const { prConnected,pullRequest } = event.wO_PR;
  currentWitIndex = getCurrentWitIndex(currentWitIndex,prConnected,pullRequest);
  const lastStepIgnore = (event.wO_PR.witPullRequest[currentWitIndex].status === 'Complete' || (event.wO_PR.witPullRequest[currentWitIndex].status.startsWith('Resolved') 
  &&   ! event.wO_PR.witPullRequest[currentWitIndex].status.endsWith('Error') 
  &&   ! event.wO_PR.witPullRequest[currentWitIndex].status.endsWith('Divest')
  )) ?false :true;
  const initialDowndate = event.initialDownDate?(moment(event.initialDownDate).format('M/D/YYYY')):'';
  const failureDate = event.wO_PR.witPullRequest[currentWitIndex].failureDate ? (moment(event.wO_PR.witPullRequest[currentWitIndex].failureDate).format('M/D/YYYY')):'';
  
  return (
    <div className={styles.container}>
      <StepComponent statusSteps={witStatusSteps} status={witScheduleStatus[event.wO_PR.witPullRequest[currentWitIndex].status]} lastStepIgnore={lastStepIgnore}/>
      <div className={styles.topDetails}>
        <ul className={styles.detailColumn}>
          <li>
            <span>{labels.status}</span>
            {event.wO_PR.witPullRequest[currentWitIndex].status}
          </li>
          <li>
            <span>{labels.wellName}</span>
            {event.wellName}
          </li>
          <li>
            <span>{labels.collectionPoint}</span>
            {event.collectionPoint}
          </li>
          <li>
            <span>{labels.route}</span>
            {event.route}
          </li>
          <li>
            <span>{labels.downTimeType}</span>
            {event.downTimeType}
          </li>
          <li>
            <span>{labels.reasonCode}</span>
            {event.reasonCode}
          </li>
          <li>
            <span>{labels.lpoId}</span>
            {event.wO_PR.witPullRequest[currentWitIndex].lpoId}
          </li>
          <li>
            <span>{labels.caseId}</span>
            {event.wO_PR.witPullRequest[currentWitIndex].caseId}
          </li>
          <li>
            <span>{labels.currentStageName}</span>
            {event.wO_PR.witPullRequest[currentWitIndex].currentStageName}
          </li>
          <li>
            <span>{labels.wbsNumber}</span>
            {event.wO_PR.witPullRequest[currentWitIndex].wbsNumber}
          </li>
          <li>
            <span>{labels.daysInStep}</span>
            {event.wO_PR.witPullRequest[currentWitIndex].daysInStep}
          </li>
          <li>
            <span>{labels.avgTotalProductionBoed}</span>
            {event.wO_PR.witPullRequest[currentWitIndex].avgTotalProductionBoed}
          </li>
        </ul>

        <ul className={styles.detailColumnTwo}>
          <li>
            <span>{labels.daysDown}</span>
            {event.wO_PR.witPullRequest[currentWitIndex].daysDown}
          </li>
          <li>
            <span>{labels.submittedDate}</span>
            {moment(event.wO_PR.witPullRequest[currentWitIndex].submittedDate).format(
              'M/D/YYYY'
            )}
          </li>
          <li>
            <span>{labels.failureDate}</span>
            {failureDate}
          </li>
          <li>
            <span>{labels.initialDownDate}</span>
            {initialDowndate}
          </li>
          <li>
            <span>{labels.scheduleDate}</span>
            {event.wO_PR.witPullRequest[currentWitIndex].scheduleDate === null
              ? ''
              : moment(event.wO_PR.witPullRequest[currentWitIndex].scheduleDate).format(
                  'M/D/YYYY'
                )}
          </li>
          {/* <li>
            <span>{labels.lawtRate}</span>
            {formatNumber(event.lawtRate)}
            {labels.boed}
          </li> */}
          <li>
            <span>{labels.lpoType}</span>
            {event.lpoType}
          </li>
          <li>
            <span>{labels.submittedBy}</span>
            {event.wO_PR.witPullRequest[currentWitIndex].submittedBy}
          </li>
          <li>
            <span>{labels.submittedByCAI}</span>
            {event.wO_PR.witPullRequest[currentWitIndex].submittedByCAI}
          </li>
        </ul>
      </div>

      <p className={styles.reason}>
        <span>{labels.reasonForCriticality}</span>
        {event.reasonForCriticality}
      </p>

      <p className={styles.commentsTitle}>{labels.lastEventsComments}</p>
      <p className={styles.comments}>{event.comments}</p>
      <button
        className={styles.closeButton}
        onClick={toggleLpoEventDetailsModal}
      >
        close
      </button>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(WitDetailModal);
