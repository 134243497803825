import types from 'constants/actionTypes';
import {
  notFound,
  productionEmpty,
  requestStatuses,
  summaryTypes,
} from 'constants/values';
import {
  connections,
  connectionTypes,
  lpoTypes,
  prodLpoEventsCardTitles,
  prodSummaryCardTitles,
  reasonCodes,
} from 'constants/productionManagement';
import { calculateBoed } from 'utils/calculateBoed';
import { appInsights } from 'App';
import { aiEvents } from 'constants/aiEvents';
import mapOptions from 'utils/mapOptions';
//import cache from '../cache';

export const initialState = {
  currentSummaryData: {
    type: summaryTypes.asset,
    asset: [],
    cards: [],
    dashboardCards: {
      measuredProduction: 0,
      target: 0,
      difference: 0,
    },
  },
  productionDate: '',
  showCustomTargetModal: false,
  showCustomRouteTargetModal: false,
  newTargetValues: {
    oilTarget: 0,
    waterTarget: 0,
    gasTarget: 0,
    isCustomTarget: false,
  },
  requestStatus: {
    productionSummary: requestStatuses.pending,
    summaryTable: requestStatuses.pending,
    summaryChart: requestStatuses.pending,
    customTarget: requestStatuses.empty,
    tableTarget: requestStatuses.empty,
    lpoEventsTable: requestStatuses.pending,
    assetTargetDetails: requestStatuses.empty,
    routeTargetDetails: requestStatuses.empty,
    assetTargetPostStatus: requestStatuses.empty,
    routeTargetPostStatus: requestStatuses.empty,
  },
  currentSummaryCard: prodSummaryCardTitles.difference,
  summaryTableData: [],
  assetSummaryTableData: [],
  summaryTableResolvedData: [],
  summaryChartData: [],
  tableTargetEditing: false,
  tableTargets: {},
  assetTargetDetails: [],
  routeTargetDetails: [],
  assetTargetDetailsInputBody: [],
  routeTargetDetailsInputBody: [],
  hierarchyLevel: '',
  currentLpoEventsCard: prodLpoEventsCardTitles.openLpoEvents,
  currentLpoEventsData: {
    type: summaryTypes.asset,
    asset: {
      id: productionEmpty.asset.assetId,
      name: '',
    },
    cards: {
      openLPOEvents: 0,
      thirdPartyEvents: 0,
      shutInWells: 0,
    },
  },
  lpoEventsTableData: [],
  filteredLpoTable: [],
  showLpoTableFilterModal: false,
  storedLpoTableFilters: {
    lpoTypes: mapOptions(lpoTypes),
    connections: mapOptions(connections),
    reasonCodes: mapOptions(reasonCodes),
    all: true,
  },
  showLpoEventDetailsModal: false,
  currentLpoEventDetails: {},
  currentModalPageIndex: 0,
  showArrowButtons: false,
  numOfModalPages: 0,
  numOfWorkorders: 0,
  numOfActionItems: 0,
  numOfPRs:0,
  numOfWitPR: 0,
  invalidRouteTarget: false,
  targetHistoryDaysAgo: '90',
  showTargetHistoryTable: true,
  reasonDescriptions: {},
  reasonCodeList: [],
  customTargetAssetName: '',
  shouldLoadData: false,
  isAllocatedProduction: false,
  collectionPointId: '',
  isWellLeveldata: false,
  isAssetTarget: false,
};

const production = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.SET_SUMMARY_TABLE_RESOLVED_DATA:
      appInsights.trackEvent({ name: aiEvents.setSummaryTableResolvedData });
      return {
        ...state,
        summaryTableResolvedData: payload,
      };
    case types.GET_PRODUCTION_SUMMARY_REQUEST:
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          productionSummary: requestStatuses.pending,
        },
        currentSummaryData: {
          ...state.currentSummaryData,
          type: summaryTypes.asset,
          dashboardAsset: [],
          dashboardCards: {
            measuredProduction: 0,
            target: 0,
            difference: 0,
          },
          //  productionDate: !state.isAllocatedProduction ? '':state.productionDate
        },
      };
    case types.GET_PRODUCTION_SUMMARY_REQUEST_SUCCESS:
      if (payload.constructor === Object) {
        return {
          ...state,
          requestStatus: {
            ...state.requestStatus,
            productionSummary: requestStatuses.success,
          },
          currentSummaryData: payload,
          productionDate: payload.productionDay,
          newTargetValues: !!payload.target
            ? payload.target
            : initialState.newTargetValues,
          customTargetAssetName: !!payload.target
            ? payload.target[0].assetDetails.id
            : initialState.customTargetAssetName,
        };
      } else {
        return {
          ...state,
          requestStatus: {
            ...state.requestStatus,
            productionSummary: requestStatuses.success,
          },
          currentSummaryData: payload[0],
          productionDate: payload[0].productionDay,
          newTargetValues: !!payload[0].target
            ? payload[0].target
            : initialState.newTargetValues,
          customTargetAssetName: !!payload[0].target
            ? payload[0].target[0].assetDetails.id
            : initialState.customTargetAssetName,
        };
      }
    case types.SET_PRODUCTION_DATE:
      appInsights.trackEvent({ name: aiEvents.setProductionDate });
      return {
        ...state,
        productionDate: payload,
      };
      case types.SET_TARGET_TYPE:
        return {
          ...state,
          isAssetTarget: payload,
        };
    case types.SET_CUSTOM_TARGET_ASSET:
      return {
        ...state,
        customTargetAssetName: payload,
      };
    case types.SET_COLLECTION_POINT:
      return {
        ...state,
        collectionPointId: payload,
      };
    case types.SET_IS_ALLOCATED_PRODUCTION:
      return {
        ...state,
        isAllocatedProduction: payload,
      };

    case types.SET_HIERARCHY:
      return {
        ...state,
        hierarchyLevel: payload,
      };

    case types.GET_PRODUCTION_SUMMARY_REQUEST_FAILURE:
      appInsights.trackEvent({ name: aiEvents.productionSummaryFailure });
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          productionSummary:
            payload.message === notFound
              ? requestStatuses.notFound
              : requestStatuses.failure,
        },
      };
    case types.SET_ASSET:
      appInsights.trackEvent({ name: aiEvents.setProductionAsset });
      return {
        ...state,
        productionAssetId: payload,
        productionRouteId: initialState.productionRouteId,
        currentSummaryData: {
          ...state.currentSummaryData,
          type: summaryTypes.asset,
          asset: {
            name: '',
          },
          route: {
            name: '',
          },
        },
        currentLpoEventsData: {
          ...state.currentLpoEventsData,
          type: summaryTypes.asset,
          asset: {
            name: '',
          },
          route: {
            name: '',
          },
        },
        //  productionDate:
        //  state.productionAssetId !== payload ? '' : state.productionDate
      };
    case types.SET_ASSET_LPO_EXCEPTION:
      appInsights.trackEvent({ name: aiEvents.setProductionAsset });
      return {
        ...state,
        currentLpoEventsData: {
          ...state.currentLpoEventsData,
          type: summaryTypes.asset,
          asset: {
            name: '',
          },
          route: {
            name: '',
          },
        },
      };
    case types.SET_ROUTE:
      appInsights.trackEvent({ name: aiEvents.setProductionRoute });
      return {
        ...state,
        productionRouteId: payload,
        currentSummaryData: {
          ...state.currentSummaryData,
          type: summaryTypes.route,
          route: {
            name: '',
          },
        },
        currentLpoEventsData: {
          ...state.currentLpoEventsData,
          type: summaryTypes.route,
          route: {
            name: '',
          },
        },
        currentSummaryCard:
          payload === productionEmpty.route.routeId
            ? prodSummaryCardTitles.difference
            : prodSummaryCardTitles.measuredProduction,
      };
    case types.SET_ROUTE_LPO_EXCEPTION:
      appInsights.trackEvent({ name: aiEvents.setProductionRoute });
      return {
        ...state,
        currentLpoEventsData: {
          ...state.currentLpoEventsData,
          type: summaryTypes.route,
          route: {
            name: '',
          },
        },
      };
    case types.SET_CURRENT_SUMMARY_CARD:
      appInsights.trackEvent({ name: aiEvents.setCurrentSummaryCard });
      return {
        ...state,
        currentSummaryCard: payload,
        tableTargetEditing: false,
        requestStatus: {
          ...state.requestStatus,
          tableTarget: requestStatuses.empty,
        },
      };
    case types.GET_USER_INFO_REQUEST_SUCCESS:
      return {
        ...state,
        shouldLoadData: true,
      };
    case types.TOGGLE_CUSTOM_ROUTE_TARGET_MODAL:
      return {
        ...state,
        showCustomRouteTargetModal: !state.showCustomRouteTargetModal,
      };
    case types.GET_SUMMARY_TABLE_DATA_REQUEST:
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          summaryTable: requestStatuses.pending,
        },
        currentSummaryData: {
          ...state.currentSummaryData,
          type: summaryTypes.asset,
        },
        summaryTableData: [],
      };
    case types.GET_SUMMARY_TABLE_DATA_REQUEST_SUCCESS:
      if (Object.keys(payload).length === 0 && payload.constructor === Object) {
        return {
          ...state,
          requestStatus: {
            ...state.requestStatus,
            summaryTable: requestStatuses.success,
          },
          summaryTableData: [],
          tableTargets: {},
        };
      } else {
        let tableTargets = {};

        if (state.currentSummaryCard === prodSummaryCardTitles.target) {
          payload.forEach(route => {
            let oilTarget, gasTarget, waterTarget, routeId;
            if (route.oil && route.gas && route.water) {
              oilTarget = route.oil.target;
              gasTarget = route.gas.target;
              waterTarget = route.water.target;
              routeId = route.routeId;

              tableTargets[routeId] = {
                oilTarget,
                gasTarget,
                waterTarget,
                boed: calculateBoed(oilTarget, gasTarget),
              };
            }
          });
        }

        return {
          ...state,
          requestStatus: {
            ...state.requestStatus,
            summaryTable: requestStatuses.success,
          },
          summaryTableData: payload,
          tableTargets,
        };
      }
    case types.GET_SUMMARY_TABLE_DATA_REQUEST_FAILURE:
      appInsights.trackEvent({ name: aiEvents.summaryTableDataFailure });
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          summaryTable:
            payload.message === notFound
              ? requestStatuses.notFound
              : requestStatuses.failure,
        },
        summaryTableData: [],
      };
    case types.GET_SUMMARY_CHART_DATA_REQUEST:
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          summaryChart: requestStatuses.pending,
        },
      };
    case types.GET_SUMMARY_CHART_DATA_REQUEST_SUCCESS:
      if (Object.keys(payload).length === 0 && payload.constructor === Object) {
        return {
          ...state,
          requestStatus: {
            ...state.requestStatus,
            summaryChart: requestStatuses.success,
          },
          summaryChartData: [],
        };
      } else {
        return {
          ...state,
          requestStatus: {
            ...state.requestStatus,
            summaryChart: requestStatuses.success,
          },
          summaryChartData: payload,
        };
      }
    case types.GET_SUMMARY_CHART_DATA_REQUEST_FAILURE:
      appInsights.trackEvent({ name: aiEvents.summaryChartDataFailure });
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          summaryChart:
            payload.message === notFound
              ? requestStatuses.notFound
              : requestStatuses.failure,
        },
      };
    case types.TOGGLE_CUSTOM_TARGET_MODAL:
      return {
        ...state,
        showCustomTargetModal: !state.showCustomTargetModal,
      };
    case types.SET_NEW_TARGET_VALUES:
      return {
        ...state,
        newTargetValues: payload,
      };
    case types.POST_ASSET_ADJUST_TARGET_REQUEST:
      appInsights.trackEvent({ name: aiEvents.updateAssetTarget });
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          customTarget: requestStatuses.pending,
        },
      };
    case types.POST_ASSET_ADJUST_TARGET_REQUEST_SUCCESS:
      return {
        ...state,
        showCustomTargetModal: false,
        requestStatus: {
          ...state.requestStatus,
          customTarget: requestStatuses.success,
        },
      };
    case types.POST_ASSET_ADJUST_TARGET_REQUEST_FAILURE:
      appInsights.trackEvent({ name: aiEvents.updateAssetTargetFailure });
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          customTarget: requestStatuses.failure,
        },
      };
    case types.RESET_INVALID_ROUTE_TARGET:
      return {
        ...state,
        invalidRouteTarget: false,
      };
    case types.RESET_TARGET_REQUEST_STATUS:
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          customTarget: requestStatuses.empty,
        },
      };
    case types.RESET_TABLE_TARGET_REQUEST_STATUS:
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          tableTarget: requestStatuses.empty,
        },
      };
    case types.TOGGLE_TARGET_TABLE_EDITING:
      return {
        ...state,
        tableTargetEditing: !state.tableTargetEditing,
      };
    case types.UPDATE_TABLE_TARGET:
      const { routeId, oilTarget, gasTarget, waterTarget, invalid } = payload;

      const newTableTargets = {
        ...state.tableTargets,
        [routeId]: {
          oilTarget,
          gasTarget,
          waterTarget,
          boed: calculateBoed(oilTarget, gasTarget),
          routeId,
        },
      };

      let invalidRouteTarget = false;
      for (var targetRow in newTableTargets) {
        const { oilTarget, gasTarget, waterTarget } =
          newTableTargets[targetRow];

        if (oilTarget === '' || oilTarget === null || oilTarget < 0) {
          invalidRouteTarget = true;
        }
        if (gasTarget === '' || gasTarget === null || gasTarget < 0) {
          invalidRouteTarget = true;
        }
        if (waterTarget < 0) {
          invalidRouteTarget = true;
        }
      }

      return {
        ...state,
        tableTargets: newTableTargets,
        invalidRouteTarget: invalidRouteTarget || invalid,
      };
    case types.SAVE_TABLE_TARGETS_REQUEST:
      appInsights.trackEvent({ name: aiEvents.saveRouteTargets });

      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          tableTarget: requestStatuses.pending,
        },
        tableTargetEditing: false,
      };
    case types.SAVE_TABLE_TARGETS_REQUEST_FAILURE:
      appInsights.trackEvent({ name: aiEvents.saveRouteTargetsFailure });

      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          tableTarget: requestStatuses.failure,
        },
        tableTargetEditing: true,
      };
    case types.SAVE_TABLE_TARGETS_REQUEST_SUCCESS:
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          tableTarget: requestStatuses.success,
        },
      };
    case types.SET_CURRENT_LPO_EVENTS_CARD:
      appInsights.trackEvent({ name: aiEvents.setCurrentLpoEventsCard });
      return {
        ...state,
        currentLpoEventsCard: payload,
      };
    case types.GET_PRODUCTION_LPO_EVENTS_INFO_REQUEST:
      return {
        ...state,
        currentLpoEventsData: {
          type: summaryTypes.asset,
          asset: {
            id: productionEmpty.asset.assetId,
            name: '',
          },
          cards: {
            openLPOEvents: 0,
            thirdPartyEvents: 0,
            shutInWells: 0,
          },
        },
      };
    case types.GET_PRODUCTION_LPO_EVENTS_INFO_REQUEST_SUCCESS:
      const reasonDescriptions = {};
      const reasonCodeArr = [];

      if (payload.reasonCodes) {
        payload.reasonCodes.forEach(({ reasonCode, reasonDescription }) => {
          reasonDescriptions[reasonCode] = reasonDescription;
          reasonCodeArr.push(reasonCode);
        });
      }
      return {
        ...state,
        currentLpoEventsData: payload,
        reasonDescriptions,
        reasonCodeList: reasonCodeArr,
      };
    case types.GET_PRODUCTION_LPO_EVENTS_REQUEST:
      return {
        ...state,
        filteredLpoTable: [],
        storedLpoTableFilters: initialState.storedLpoTableFilters,
        requestStatus: {
          ...state.requestStatus,
          lpoEventsTable: requestStatuses.pending,
        },
      };
    case types.GET_PRODUCTION_LPO_EVENTS_REQUEST_SUCCESS:
      if (Object.keys(payload).length === 0 && payload.constructor === Object) {
        return {
          ...state,
          lpoEventsTableData: [],
          filteredLpoTable: [],
          requestStatus: {
            ...state.requestStatus,
            lpoEventsTable: requestStatuses.success,
          },
        };
      } else {
        return {
          ...state,
          lpoEventsTableData: payload,
          filteredLpoTable: payload,
          requestStatus: {
            ...state.requestStatus,
            lpoEventsTable: requestStatuses.success,
          },
        };
      }
    case types.GET_PRODUCTION_LPO_EVENTS_REQUEST_FAILURE:
      appInsights.trackEvent({ name: aiEvents.productionLpoEventsFailure });

      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          lpoEventsTable: requestStatuses.failure,
        },
      };
    case types.UPDATE_FILTERED_LPO_TABLE:
      appInsights.trackEvent({ name: aiEvents.updateFilteredLpoTable });

      return {
        ...state,
        storedLpoTableFilters: payload,
        filteredLpoTable: state.lpoEventsTableData.filter(row => {
          let reasonCodeIncluded = false;
          let lpoTypeIncluded = false;
          let connectionIncluded = false;

          const { woConnected, prConnected } = row.wO_PR;

          const reasonCodes = payload.reasonCodes.map(({ value }) => value);
          const lpoTypes = payload.lpoTypes.map(({ value }) => value);
          const connections = payload.connections.map(({ value }) => value);

          if (reasonCodes.includes(row.reasonCode)) {
            reasonCodeIncluded = true;
          }

          if (
            lpoTypes.includes(row.lpoType) ||
            state.currentLpoEventsCard === 'Shut-in Wells'
          ) {
            lpoTypeIncluded = true;
          }

          if (connections.includes(connectionTypes.workOrder) && woConnected) {
            connectionIncluded = true;
          }

          if (
            connections.includes(connectionTypes.pullRequest) &&
            prConnected
          ) {
            connectionIncluded = true;
          }

          if (
            connections.includes(connectionTypes.none) &&
            !woConnected &&
            !prConnected
          ) {
            connectionIncluded = true;
          }
          const rowVisible =
            reasonCodeIncluded && lpoTypeIncluded && connectionIncluded;

          return rowVisible;
        }),
      };
    case types.TOGGLE_LPO_TABLE_FILTER_MODAL:
      return {
        ...state,
        showLpoTableFilterModal: !state.showLpoTableFilterModal,
      };
    case types.TOGGLE_LPO_EVENT_DETAILS_MODAL:
      return {
        ...state,
        showLpoEventDetailsModal: !state.showLpoEventDetailsModal,
      };
    case types.SET_CURRENT_LPO_EVENT_DETAILS:
      const lpoEventDetails = {
        ...state,
        currentLpoEventDetails: payload,
        currentModalPageIndex: 0,
        showArrowButtons: false,
        numOfModalPages: 0,
        numOfActionItems: 0,
        numOfWorkorders: 0,
        numOfPRs: 0,
        numOfWitPR: 0,
      };
      let workOrdersLength = 0;
      let actionItemLength = 0;
      let witPrLength = 0;
      let prLength = 0;
      if (payload.wO_PR.workOrders != null) {
        workOrdersLength = payload.wO_PR.workOrders.length;
        lpoEventDetails.numOfWorkorders = workOrdersLength;
      }

      if (payload.wO_PR.actionItems != null) {
        actionItemLength = payload.wO_PR.actionItems.length;
        lpoEventDetails.numOfActionItems = actionItemLength;
      }

      if (payload.wO_PR.witPullRequest != null) {
        witPrLength = payload.wO_PR.witPullRequest.length;
        lpoEventDetails.numOfWitPR = witPrLength;
      }

      if (payload.wO_PR.pullRequest != null) {
        prLength = payload.wO_PR.pullRequest.length;
        lpoEventDetails.numOfPRs = prLength;
      }
      if (
        payload.wO_PR.witPrConnected &&
        payload.wO_PR.witPullRequest &&
        witPrLength
      ) {
        lpoEventDetails.showArrowButtons = witPrLength > 1;
        lpoEventDetails.numOfModalPages = witPrLength;
      }

      if (
        payload.wO_PR.woConnected &&
        payload.wO_PR.workOrders &&
        workOrdersLength
      ) {
        lpoEventDetails.showArrowButtons = workOrdersLength > 1;
        lpoEventDetails.numOfModalPages = workOrdersLength;
      }

      if (
        payload.wO_PR.aiConnected &&
        payload.wO_PR.actionItems &&
        actionItemLength
      ) {
        lpoEventDetails.showArrowButtons = actionItemLength > 1;
        lpoEventDetails.numOfModalPages = actionItemLength;
      }

      if (
        payload.wO_PR.prConnected &&
        payload.wO_PR.pullRequest &&
        prLength
      ) {
        lpoEventDetails.showArrowButtons = prLength > 1;
        lpoEventDetails.numOfModalPages = prLength;
      }

      if (payload.wO_PR.woConnected && payload.wO_PR.prConnected) {
        lpoEventDetails.showArrowButtons = workOrdersLength + prLength > 1;
        lpoEventDetails.numOfModalPages = workOrdersLength + prLength;
      }

      if (payload.wO_PR.woConnected && payload.wO_PR.witPrConnected) {
        lpoEventDetails.showArrowButtons = workOrdersLength + witPrLength > 1;
        lpoEventDetails.numOfModalPages = workOrdersLength + witPrLength;
      }
      if (payload.wO_PR.prConnected && payload.wO_PR.witPrConnected) {
        lpoEventDetails.showArrowButtons = true;
        lpoEventDetails.numOfModalPages = witPrLength + prLength;
      }

      if (payload.wO_PR.aiConnected && payload.wO_PR.prConnected) {
        lpoEventDetails.showArrowButtons = actionItemLength + prLength > 1;
        lpoEventDetails.numOfModalPages = actionItemLength + prLength;
      }

      if (payload.wO_PR.aiConnected && payload.wO_PR.witPrConnected) {
        lpoEventDetails.showArrowButtons = actionItemLength + witPrLength > 1;
        lpoEventDetails.numOfModalPages = actionItemLength + witPrLength;
      }

      if (payload.wO_PR.aiConnected && payload.wO_PR.woConnected) {
        lpoEventDetails.showArrowButtons =
          actionItemLength + workOrdersLength > 1;
        lpoEventDetails.numOfModalPages = actionItemLength + workOrdersLength;
      }

      if (
        payload.wO_PR.woConnected &&
        payload.wO_PR.witPrConnected &&
        payload.wO_PR.aiConnected
      ) {
        lpoEventDetails.showArrowButtons =
          workOrdersLength + actionItemLength + witPrLength > 1;
        lpoEventDetails.numOfModalPages =
          workOrdersLength + actionItemLength + witPrLength;
      }
      if (
        payload.wO_PR.woConnected &&
        payload.wO_PR.prConnected &&
        payload.wO_PR.aiConnected
      ) {
        lpoEventDetails.showArrowButtons =
          workOrdersLength + actionItemLength + prLength > 1;
        lpoEventDetails.numOfModalPages =
          workOrdersLength + actionItemLength + prLength;
      }
      if (
        payload.wO_PR.witPrConnected &&
        payload.wO_PR.prConnected &&
        payload.wO_PR.aiConnected
      ) {
        lpoEventDetails.showArrowButtons =
          actionItemLength + witPrLength + prLength > 1;
        lpoEventDetails.numOfModalPages = actionItemLength + witPrLength + prLength;
      }
      if (
        payload.wO_PR.witPrConnected &&
        payload.wO_PR.prConnected &&
        payload.wO_PR.woConnected
      ) {
        lpoEventDetails.showArrowButtons =
          workOrdersLength + witPrLength + prLength > 1;
        lpoEventDetails.numOfModalPages = workOrdersLength + witPrLength + prLength;
      }

      if (
        payload.wO_PR.woConnected &&
        payload.wO_PR.witPrConnected &&
        payload.wO_PR.aiConnected &&
        payload.wO_PR.prConnected
      ) {
        lpoEventDetails.showArrowButtons =
          workOrdersLength + actionItemLength + prLength + witPrLength > 1;
        lpoEventDetails.numOfModalPages =
          workOrdersLength + actionItemLength + witPrLength + prLength;
      }
      return lpoEventDetails;
    case types.MODAL_PAGE_SWAP_LEFT:
      let leftIndex = state.currentModalPageIndex;

      if (leftIndex - 1 < 0) {
        leftIndex = state.numOfModalPages - 1;
      } else {
        leftIndex = leftIndex - 1;
      }

      return {
        ...state,
        currentModalPageIndex: leftIndex,
      };
    case types.MODAL_PAGE_SWAP_RIGHT:
      let rightIndex = state.currentModalPageIndex;

      if (rightIndex + 1 >= state.numOfModalPages) {
        rightIndex = 0;
      } else {
        rightIndex = rightIndex + 1;
      }
      return {
        ...state,
        currentModalPageIndex: rightIndex,
      };
    case types.SET_TARGET_HISTORY_DAYS_AGO:
      return {
        ...state,
        targetHistoryDaysAgo: payload,
      };
    case types.TOGGLE_TARGET_HISTORY_TABLE:
      return {
        ...state,
        showTargetHistoryTable: !state.showTargetHistoryTable,
      };
    case types.GET_ASSET_LEVEL_TARGETS_REQUEST:
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          assetTargetDetails: requestStatuses.pending,
        },
      };
    case types.GET_ASSET_LEVEL_TARGETS_REQUEST_SUCCESS:
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          assetTargetDetails: requestStatuses.success,
          // assetTargetPostStatus:requestStatuses.empty
        },
        assetTargetDetails: payload,
        assetTargetDetailsInputBody: [],
      };
    case types.GET_ASSET_LEVEL_TARGETS_REQUEST_FAILURE:
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          assetTargetDetails: requestStatuses.failure,
        },
      };
    case types.GET_ROUTE_LEVEL_TARGETS_REQUEST:
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          routeTargetDetails: requestStatuses.pending,
        },
      };
    case types.GET_ROUTE_LEVEL_TARGETS_REQUEST_SUCCESS:
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          routeTargetDetails: requestStatuses.success,
        },
        routeTargetDetails: payload,
        routeTargetDetailsInputBody: [],
      };
    case types.GET_ROUTE_LEVEL_TARGETS_REQUEST_FAILURE:
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          routeTargetDetails: requestStatuses.failure,
        },
      };
    case types.SET_ROUTE_TARGET_DETAILS:
      return {
        ...state,
        routeTargetDetailsInputBody: payload,
      };
    case types.SET_ASSET_TARGET_DETAILS:
      return {
        ...state,
        assetTargetDetailsInputBody: payload,
      };
    case types.SET_ASSET_TARGET_POSTSTATUS:
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          assetTargetPostStatus: requestStatuses.success,
        },
      };
    case types.SET_ROUTE_TARGET_POSTSTATUS:
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          routeTargetPostStatus: requestStatuses.success,
        },
      };

    case types.RESET_ASSET_TARGET_POSTSTATUS:
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          assetTargetPostStatus: requestStatuses.empty,
        },
      };
    case types.RESET_ROUTE_TARGET_POSTSTATUS:
      return {
        ...state,
        requestStatus: {
          ...state.requestStatus,
          routeTargetPostStatus: requestStatuses.empty,
        },
      };
    default:
      return state;
  }
};

export default production;
export * from './actions';
export * from './selectors';
