import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from 'components/AdminPage/styles.module.css';
import CheckboxInput from 'components/common/CheckBoxInput';
import { addUser, editUser, selectAssetOptions, selectRoleOptions } from 'store/reducers/admin';
import { purgeCache } from 'store/reducers/cache';
import { selectUserInfo } from 'store/reducers/users';
import { connect } from 'react-redux';
import { ModalTextInput } from 'components/common/ModalFormItems/TextInput';
import { ModalSelectInput } from 'components/common/ModalFormItems/SelectInput';
import ButtonArea from 'components/AdminPage/ButtonArea';
import ReactSelectInput from 'components/common/ModalFormItems/ReactSelectInput';
import { isValid } from 'utils/validation';

const mapStateToProps = state => ({
  assetOptions: selectAssetOptions(state),
  roleOptions: selectRoleOptions(state),
  userInfo: selectUserInfo(state)
});

const mapDispatchToProps = {
  addUser,
  editUser,
  purgeCache
};

export class UserModal extends Component {
  constructor(props) {
    super(props);
    let assets = [];
    let firstName = '';
    let lastName = '';
    let roleId = 0;
    let roleName = '';
    let email = '';
    let active = true;
    let admin = false;

    if (this.props.record) {
      firstName = this.props.record.firstName;
      lastName = this.props.record.lastName;
      roleId = this.props.record.roleId;
      roleName = this.props.record.roleTitle;
      email = this.props.record.email;
      active = this.props.record.isActive;
      admin = this.props.record.isAdmin;
      assets = this.props.record.userAssets? this.props.record.userAssets.map(asset => {
        return {
          label: asset.name,
          value: asset.assetId
        };
      }):[];
    }

    this.state = {
      formItems: {
        firstName: {
          value: firstName,
          errorText: '',
          valid: true,
          key: 'firstName',
          required: false
        },
        lastName: {
          value: lastName,
          errorText: '',
          valid: lastName !== '',
          key: 'lastName',
          required: true
        },
        role: {
          text: roleName,
          value: roleId,
          errorText: '',
          valid: roleId !== 0,
          key: 'role',
          required: true
        },
        assets: {
          value: assets,
          errorText: '',
          valid: true,
          key: 'assets',
          required: false
        },
        email: {
          value: email,
          errorText: '',
          valid: email !== '',
          key: 'email',
          required: true
        },
        active: {
          value: active,
          valid: true,
          key: 'active',
          required: false
        },
        admin: {
          value: admin,
          valid: true,
          key: 'admin',
          required: false
        }
      },
      dirty: false
    };
  }

  setInputValue = (formInput, value) => {
    const { required } = this.state.formItems[formInput];
    const valid = isValid(value);
    this.setState({
      formItems: {
        ...this.state.formItems,
        [formInput]: {
          ...this.state.formItems[formInput],
          value,
          valid,
          errorText: valid || !required ? '' : 'required'
        }
      },
      dirty: true
    });
  };

  saveAttempted = invalidFormItems => {
    const stateCopy = this.state.formItems;

    invalidFormItems.forEach(({ key }) => {
      stateCopy[key] = { ...stateCopy[key], errorText: 'required' };
    });

    this.setState({
      formItems: stateCopy
    });
  };

  saveUser = () => {
    const {
      email,
      firstName,
      lastName,
      role,
      assets,
      active,
      admin
    } = this.state.formItems;
    const formBody = {
      firstName: firstName.value,
      lastName: lastName.value,
      roleId: parseInt(role.value, 10),
      email: email.value,
      assetIds: (assets.value || assets.value !== null) ? assets.value.map(asset => {
        return asset.value;
      }):[],
      isActive: active.value,
      isAdmin: admin.value
    };

    this.props.purgeCache();
    this.props.record
      ? this.props.editUser({ userId: this.props.record.userId, formBody })
      : this.props.addUser(formBody);
  };

  render() {
    const {
      active,
      admin,
      assets,
      email,
      firstName,
      lastName,
      role
    } = this.state.formItems;
    const { assetOptions, userInfo, record, roleOptions } = this.props;

    return (
      <Fragment>
        <div className={styles.flexContainer}>
          <ModalTextInput
            state={lastName}
            label={'Last Name:'}
            setAction={this.setInputValue}
            stateKey={'lastName'}
            placeholder={'Add last name...'}
          />

          <ModalTextInput
            state={firstName}
            label={'First Name:'}
            setAction={this.setInputValue}
            stateKey={'firstName'}
            placeholder={'Add first name...'}
          />

          <ModalSelectInput
            state={role}
            label={'Role:'}
            resetAction={() => {
              return;
            }}
            setAction={this.setInputValue}
            stateKey={'role'}
            options={roleOptions}
            placeholderText={'Select role...'}
          />

          <ReactSelectInput
            state={assets}
            label={'Asset:'}
            resetAction={() => {
              return;
            }}
            setAction={this.setInputValue}
            stateKey={'assets'}
            options={assetOptions}
            placeholderText={'Select asset(s)...'}
            fullWidth={true}
          />

          <ModalTextInput
            state={email}
            label={'Email:'}
            setAction={this.setInputValue}
            stateKey={'email'}
            placeholder={'Add email address...'}
          />
        </div>

        <div className={styles.checkBoxes}>
          <CheckboxInput
            toggle={event => this.setInputValue('active', event.target.checked)}
            checked={active.value}
            text={'User is Active'}
            disabled={
              !!record &&
              !!record.userId &&
              !!userInfo.userId &&
              record.userId === userInfo.userId
            }
          />

          <CheckboxInput
            toggle={event => this.setInputValue('admin', event.target.checked)}
            checked={admin.value}
            text={'Admin'}
            disabled={
              !!record &&
              !!record.userId &&
              !!userInfo.userId &&
              record.userId === userInfo.userId
            }
          />
        </div>

        <ButtonArea
          saveAction={this.saveUser}
          formItems={this.state.formItems}
          dirty={this.state.dirty}
          saveAttempted={this.saveAttempted}
        />
      </Fragment>
    );
  }
}

UserModal.propTypes = {
  addUser: PropTypes.func.isRequired,
  assetOptions: PropTypes.array.isRequired,
  editUser: PropTypes.func.isRequired,
  record: PropTypes.object,
  roleOptions: PropTypes.array.isRequired,
  purgeCache: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserModal);
