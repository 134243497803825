import { authorizedRequest, checkApiResponse } from 'api/request';
import { GET, POST, PUT } from 'api/methods';
import { adminToolValues } from 'constants/admin';
import { checkCache } from 'api/request';

export const apiEndpoints = {
  editTask: taskTypeId => `/tasktypes/${taskTypeId}`,
  editUser: userId => `/users/${userId}`,
  editStandardControl: (taskTypeId, isCustomControl) =>
    `/controlmanagement/controls/updatecontrol/${taskTypeId}?isCustomControl=${isCustomControl}`,
  addStandardControl: isCustomControl =>
    `/controlmanagement/controls/addcontrol/?isCustomControl=${isCustomControl}`,
  deleteStandardControl: taskTypeId =>
    `/controlmanagement/controls/deletecontrol/${taskTypeId}`,
  getAssetOptions: '/assets/options',
  getPillarOptions: '/pillars',
  getRoleOptions: '/roles/options',
  getTaskTypes: '/tasktypes',
  getDowntimes: '/users/reasoncodes/list',
  editDowntimeCodes: '/users/reasoncodes/updatereasoncode',
  getUsers: '/users',
  saveUser: '/users',
  addRole: '/roles',
  deleteRole: roleId => `/roles/deleterole/${roleId}`,
  getSuperitendentOptions: '/roles/superintendentoptions'
};



export const getData = async ({ getState }) => {
  const activeTool = getState().admin.activeTool;

  switch (activeTool) {
    case adminToolValues.controls:
      return getControls();
    case adminToolValues.taskTypes:
      return getTaskTypes();
    case adminToolValues.users:
      return getUsers();
    case adminToolValues.roles:
      return getRoles();
    case adminToolValues.downtimeCodes:
      return getDownTimeCodes();
    default:
      return [];
  }
};
export const getTaskTypes = async () => {
  return authorizedRequest(
    apiEndpoints.getTaskTypes,
    GET,
    null
  ).then(response => checkApiResponse(response));
};
export const getUsers = async () => {
  return authorizedRequest(apiEndpoints.getUsers, GET, null).then(response =>
    checkApiResponse(response)
  );
};
export const getSuperitendentOptions = async () => {
  return authorizedRequest(apiEndpoints.getSuperitendentOptions, GET, null).then(response =>
    checkApiResponse(response)
  );
};
export const getControls = async () => {
  return authorizedRequest(apiEndpoints.getControls, GET, null).then(response =>
    checkApiResponse(response)
  );
};
export const getRoles = async () => {
  return authorizedRequest(apiEndpoints.addRole, GET, null).then(response =>
    checkApiResponse(response)
  );
};
export const getDownTimeCodes = async () => {
  return authorizedRequest(
    apiEndpoints.getDowntimes,
    GET,
    null
  ).then(response => checkApiResponse(response));
};

export const getPillarOptions = async ({
  cache,
  cacheStamp,
  endpointCapture,
}) =>
  checkCache(cache, cacheStamp, apiEndpoints.getPillarOptions, endpointCapture);

export const getRoleOptions = async ({ cache, cacheStamp, endpointCapture }) =>
  checkCache(cache, cacheStamp, apiEndpoints.getRoleOptions, endpointCapture);

export const getAssetOptions = async ({ cache, cacheStamp, endpointCapture }) =>
  checkCache(cache, cacheStamp, apiEndpoints.getAssetOptions, endpointCapture);

export const getControlOptions = async ({
  cache,
  cacheStamp,
  endpointCapture,
}) =>
  checkCache(
    cache,
    cacheStamp,
    apiEndpoints.getControlOptions,
    endpointCapture
  );

export const editUser = async ({ payload }) => {
  const body = payload.formBody;

  return authorizedRequest(
    apiEndpoints.editUser(payload.userId),
    PUT,
    body
  ).then(response => checkApiResponse(response));
};

export const editTask = async ({ payload }) => {
  const body = payload.formBody;

  return authorizedRequest(
    apiEndpoints.editTask(payload.taskTypeId),
    PUT,
    body
  ).then(response => checkApiResponse(response));
};

export const editDowntimeCodes = async ({ payload }) => {
  const body = payload.formBody;
  return authorizedRequest(
    apiEndpoints.editDowntimeCodes,
    POST,
    body
  ).then(response => checkApiResponse(response));
};

export const addUser = async ({ payload }) => {
  const body = payload;
  return authorizedRequest(apiEndpoints.saveUser, POST, body).then(response =>
    checkApiResponse(response)
  );
};

export const addRole = async ({ payload }) => {
  const body = payload;
  return authorizedRequest(apiEndpoints.addRole, POST, body).then(response =>
    checkApiResponse(response)
  );
};

export const editRole = async ({ payload }) => {
  const body = payload;
  return authorizedRequest(
    apiEndpoints.addRole,
    PUT,
    body
  ).then(response => checkApiResponse(response));
};

export const deleteRole = async ({ payload }) => {
  return authorizedRequest(
    apiEndpoints.deleteRole(payload),
    PUT
  ).then(response => checkApiResponse(response));
};

export const addStandardControl = async ({ payload }) => {
  const body = payload.formBody;
  return authorizedRequest(
    apiEndpoints.addStandardControl(payload.isCustomControl),
    POST,
    body
  ).then(response => checkApiResponse(response));
};

export const editStandardControl = async ({ payload }) => {
  const body = payload.formBody;
  return authorizedRequest(
    apiEndpoints.editStandardControl(
      payload.taskTypeId,
      payload.isCustomControl
    ),
    PUT,
    body
  ).then(response => checkApiResponse(response));
};

export const deleteStandardControl = async ({ payload }) => {
  return authorizedRequest(
    apiEndpoints.deleteStandardControl(payload),
    PUT
  ).then(response => checkApiResponse(response));
};
