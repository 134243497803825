import types from 'constants/actionTypes';
import { createThunk } from 'store/createThunk';
import * as backendAPI from 'api/exception';

export const getExceptionDowntimeThresholds = createThunk({
  apiCall: backendAPI.getExceptionDowntimeThresholds,
  type: types.GET_EXCEPTION_DOWNTIME_THRESHOLDS_REQUEST
});


export const getExceptionDowntimeGeneralInfo = createThunk({
  apiCall: backendAPI.getExceptionDowntimeGeneralInfo,
  type: types.GET_EXCEPTION_DOWNTIME_GENERAL_INFO_REQUEST
});

export const getExceptionDowntimeTable = createThunk({
  apiCall: backendAPI.getExceptionDowntimeTable,
  type: types.GET_EXCEPTION_DOWNTIME_TABLE_REQUEST
});

export const setCurrentPrimaryDowntimeCard = downtimeCard => ({
  type: types.SET_EXCEPTION_PRIMARY_DOWNTIME_CARD,
  payload: downtimeCard
});

export const setCurrentSecondaryDowntimeCard = downtimeCard => ({
  type: types.SET_EXCEPTION_SECONDARY_DOWNTIME_CARD,
  payload: downtimeCard
});

export const toggleThresholdAdjustModal = () => ({
  type: types.TOGGLE_THRESHOLD_ADJUST_MODAL
});
export const toggleECCommentModal = record => ({
  type: types.TOGGLE_EC_COMMENT_MODAL,
  payload:record
});


export const resetThresholdAdjustRequestStatus = () => ({
  type: types.RESET_THRESHOLD_ADJUST_REQUEST_STATUS
});

export const setNewThresholdValues = payload => ({
  type: types.SET_NEW_THRESHOLD_VALUES,
  payload: payload
});

export const setNewThresholdCategoryValue = category => ({
  type: types.SET_NEW_THRESHOLD_CATEGORY_VALUE,
  payload: category
});

export const setTimeValue = time => ({
  type: types.SET_NEW_TIME_VALUE,
  payload: time
});

export const putThresholdAdjust = createThunk({
  apiCall: backendAPI.putThresholdAdjust,
  type: types.PUT_THRESHOLD_ADJUST_REQUEST
});
